.sc--location {
	&-contractor-send-otp-link{
		margin-bottom: $spacing-07;
	}
}

.sc--otp-valid{
	display: flex;
	align-items: center;
	margin-top: 2rem;
	svg{
		fill: $carbon--green-60;
		margin-right: 1rem;
	}
	color: $carbon--green-60;
}
