
$red-60: #da1e28;

.sc--business-logo{
	margin-top: 1rem;
	img{
		max-width: 180px;
		height: auto;
		width: 100%;
	}
}

.sc--warning{
	display: flex;
	align-items: center;
	margin-top: 2rem;
	svg{
		fill: $red-60;
		margin-right: 1rem;
	}
	color: $red-60
}
