

$red-60: #da1e28;
$gray-10: #F3F3F3;
$green-success: #198038;

.sc--map-container{
	// overflow:hidden;
	// padding-bottom:56.25%;
	// position:relative;
	// height:0;
	// > div {
	// 	//Override google-map-react property to enable responsive iframe
	// 	position: static !important;
	// }
	height: 450px;
	> div {
		border: 1px solid $carbon--gray-50;
	}
	.map-loader{
		background: $gray-10;
		display: flex;
		height: inherit;
		align-items: center;
		justify-content: center;
		.bx--inline-loading{
			display: flex;
			align-items: center;
		}
	}
}

.sc--map-marker{
	position: absolute;
  top: 50%;
  left: 50%;
  width: 18px;
  height: 18px;
  background-color: #d85040;
  border-radius: 100%;
  user-select: none;
	transform: translate(-50%, -50%);
	span{
		font-weight: bold;
    line-height: 18px;
    width: 120px;
    display: block;
    margin-left: 20px;
	}
}
.sc--map-marker-notification{
	.bx--toast-notification{
		color: inherit;
		border:none!important;
		background: $gray-10!important;
		&__icon{
			display: none;
		}
		&__title{
			@include carbon--type-style(productive-heading-03, true);
		}
		&__subtitle{
			color: inherit;
			margin-bottom: 0;
			@include carbon--type-style(body-short-01, true);
		}
		&__caption{
			color: $green-success;
			@include carbon--type-style(body-short-01, true);
		}
		&__close-button{
			.bx--toast-notification__close-icon{
				fill: inherit;
			}
		}
	}
}

.sc--map-marker-notification{
	position:relative;
	&--address {
		margin-top: $spacing-06;
		line-height: 1.4;
	}
	&--customer {
		margin-top: $spacing-06;
		line-height: 1.4;
	}
	&__caption {
		line-height: 1.4;
		display:flex;
		flex-direction: column;
		svg{
			margin-top: 4px;
			flex: 1 0 auto;
			margin-right: 1rem;
		}
	}
}

.service-locations-dropdown,
.service-locations-search-box {
	margin-bottom: $spacing-07;
}

.map-modal-active{
	overflow: hidden!important;
}

.sc--otp-inline-loader {
	display: flex;
	align-items: center;
	color: #161616;
	&.finished{
		svg {
			margin-right: 1rem;
			fill: $green-success
		}
		color: $green-success
	}
}

.sc--otp-container{
	margin: $spacing-07 0;
	line-height: 32px;
}

.sc--map-marker-notification .sc--send-otp-link{
	display: inline-block;
	color: $carbon--blue-60;
	&--disabled{
		color: $carbon--gray-30;
	}
}

.sc--services-checkbox-wrapper{
	margin-top: $spacing-07;
}

.sc--map-marker-notification--customer > div, .sc--map-marker-notification--address > div {
	margin-bottom: $spacing-02;
}

.bx--list-box.bx--list-box--inline, .sc--map-marker-notification .bx--dropdown--inline {
	min-width: 200px;
	border-width: thin;
	background-color: $carbon--white-0;
	border-color: $carbon--cool-gray-50;
}