.sc--login-page{
	// .sc--login-marketing{

	// }
	.sc--login-form-wrapper{
		height: calc(100vh - 6rem - 48px);
		align-items: center;
	}

	.bx--text-input-wrapper{
		margin-bottom: 1rem;
	}

	.bx--form__requirements{
		color: $support-01;
		line-height: 1.4;
	}

	.sc--success-message{
		color: $support-02;
		@include carbon--type-style(body-short-01, true);
	}

	.login-intro p{
		margin-bottom: $spacing-07;
		&:first-of-type{
			@include carbon--type-style(productive-heading-03, true);
		}
	}
}