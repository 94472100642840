.helper-text{
	@include carbon--type-style(helper-text-01, true);
}

.bx--loading-overlay{
	background-color:rgba(255, 255, 255, 0.8);
}

.bx--link{
	&:visited{
		color: $carbon--blue-60;
	}
}

.bx--label--disabled {
	color: inherit;
}