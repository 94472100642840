@include carbon--colors();

.sc--table-wrapper {
	.sc--search-input{
		.bx--search-input {
			background-color: $carbon--gray-10;
		}
	}
}

.sc--table-row td{
	vertical-align: middle;
	line-height: 38px;
}

.bx--data-table {
	th {
		height: 100%;
		padding: 0!important;
		&:last-of-type{
			width: 50px;
		}
	}
}

.sc--table-header {
	th:last-child{
		button{
			display:none;
		}
	}
}

.sc--edit-table-icon{
	color: $cool-gray-70;
	background: none!important;
	box-shadow: none!important;
	visibility: hidden;
	min-height: 0;
	border: none;
}

.sc--table-row{
	&:hover{
		.sc--edit-table-icon{
			&:hover{
				color: $carbon--blue-60;
			}
			visibility: visible;
		}
	}
}
