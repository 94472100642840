.bx--text-truncate--end {
  display: inline-block;
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap; }

.bx--text-truncate--front {
  display: inline-block;
  overflow: hidden;
  width: 100%;
  direction: rtl;
  text-overflow: ellipsis;
  white-space: nowrap; }

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  padding: 0;
  border: 0;
  margin: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline; }

button,
select,
input,
textarea {
  border-radius: 0;
  font-family: inherit; }

input[type='text']::-ms-clear {
  display: none; }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1; }

sup {
  vertical-align: super; }

sub {
  vertical-align: sub; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote::before,
blockquote::after,
q::before,
q::after {
  content: ''; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

* {
  box-sizing: border-box; }

button {
  margin: 0; }

html {
  font-size: 100%; }

body {
  font-weight: 400;
  font-family: 'IBM Plex Sans', 'Helvetica Neue', Arial, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility; }

code {
  font-family: 'IBM Plex Mono', 'Menlo', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', Courier, monospace; }

strong {
  font-weight: 600; }

@media screen and (-ms-high-contrast: active) {
  svg {
    fill: ButtonText; } }

h1 {
  font-size: 2.625rem;
  font-weight: 300;
  line-height: 1.199;
  letter-spacing: 0; }

h2 {
  font-size: 2rem;
  font-weight: 400;
  line-height: 1.25;
  letter-spacing: 0; }

h3 {
  font-size: 1.75rem;
  font-weight: 400;
  line-height: 1.28572;
  letter-spacing: 0; }

h4 {
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.4;
  letter-spacing: 0; }

h5 {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.375;
  letter-spacing: 0; }

h6 {
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.28572;
  letter-spacing: 0.16px; }

p {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0; }

a {
  color: #0f62fe; }

em {
  font-style: italic; }

@keyframes skeleton {
  0% {
    opacity: 0.3;
    transform: scaleX(0);
    transform-origin: left; }
  20% {
    opacity: 1;
    transform: scaleX(1);
    transform-origin: left; }
  28% {
    transform: scaleX(1);
    transform-origin: right; }
  51% {
    transform: scaleX(0);
    transform-origin: right; }
  58% {
    transform: scaleX(0);
    transform-origin: right; }
  82% {
    transform: scaleX(1);
    transform-origin: right; }
  83% {
    transform: scaleX(1);
    transform-origin: left; }
  96% {
    transform: scaleX(0);
    transform-origin: left; }
  100% {
    opacity: 0.3;
    transform: scaleX(0);
    transform-origin: left; } }

.bx--header {
  position: fixed;
  z-index: 8000;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  height: 3rem;
  align-items: center;
  border-bottom: 1px solid #393939;
  background-color: #161616; }

.bx--header__action {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  display: inline-block;
  padding: 0;
  border: 0;
  appearance: none;
  background: none;
  cursor: pointer;
  width: 100%;
  width: 3rem;
  height: 3rem;
  border: 0.0625rem solid transparent;
  transition: background-color 110ms, border-color 110ms; }
  .bx--header__action *,
  .bx--header__action *::before,
  .bx--header__action *::after {
    box-sizing: inherit; }
  .bx--header__action::-moz-focus-inner {
    border: 0; }

.bx--header__action > svg.bx--navigation-menu-panel-collapse-icon,
.bx--header__action--active > svg.bx--navigation-menu-panel-expand-icon {
  display: none; }

.bx--header__action--active > svg.bx--navigation-menu-panel-collapse-icon {
  display: inline; }

.bx--header__action:hover {
  background-color: #353535; }

.bx--header__action--active {
  border-right: 1px solid #393939;
  border-bottom: 1px solid #161616;
  border-left: 1px solid #393939; }

.bx--header__action:focus {
  border-color: #ffffff;
  outline: none; }

.bx--header__action:active {
  background-color: #393939; }

.bx--header__action.bx--btn--icon-only.bx--tooltip__trigger {
  justify-content: center; }

.bx--header__action > svg {
  fill: #ffffff; }

.bx--header__menu-trigger > svg {
  fill: #f4f4f4; }

.bx--header__menu-trigger:hover {
  fill: #2c2c2c; }

.bx--header__menu-toggle {
  display: flex;
  align-items: center;
  justify-content: center; }

@media (min-width: 66rem) {
  .bx--header__menu-toggle__hidden {
    display: none; } }

a.bx--header__name {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.28572;
  letter-spacing: 0.16px;
  display: flex;
  height: 100%;
  align-items: center;
  padding: 0 2rem 0 1rem;
  border: 0.125rem solid transparent;
  font-weight: 600;
  letter-spacing: 0.1px;
  line-height: 1.25rem;
  outline: none;
  text-decoration: none;
  transition: border-color 110ms;
  user-select: none; }

a.bx--header__name:focus {
  border-color: #ffffff; }

.bx--header__name--prefix {
  font-weight: 400; }

a.bx--header__name,
a.bx--header__name:hover {
  color: #f4f4f4; }

.bx--header__menu-toggle:not(.bx--header__menu-toggle__hidden) ~ .bx--header__name {
  padding-left: 0.5rem; }

.bx--header__nav {
  position: relative;
  display: none;
  height: 100%;
  padding-left: 1rem; }
  @media (min-width: 66rem) {
    .bx--header__nav {
      display: block; } }
  .bx--header__nav::before {
    position: absolute;
    top: 50%;
    left: 0;
    display: block;
    width: 0.0625rem;
    height: 1.5rem;
    background-color: #393939;
    content: '';
    transform: translateY(-50%); }

.bx--header__menu-bar {
  display: flex;
  height: 100%;
  padding: 0;
  margin: 0;
  list-style: none; }

a.bx--header__menu-item {
  position: relative;
  display: flex;
  height: 100%;
  align-items: center;
  padding: 0 1rem;
  border: 2px solid transparent;
  color: #c6c6c6;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.125rem;
  text-decoration: none;
  transition: background-color 110ms, border-color 110ms, color 110ms;
  user-select: none; }

a.bx--header__menu-item:hover {
  background-color: #2c2c2c;
  color: #f4f4f4; }

.bx--header__action:active,
a.bx--header__menu-item:active {
  background-color: #393939;
  color: #f4f4f4; }

a.bx--header__menu-item:focus {
  border-color: #ffffff;
  color: #f4f4f4;
  outline: none; }

a.bx--header__menu-item:hover > svg,
a.bx--header__menu-item:active > svg,
a.bx--header__menu-item:focus > svg {
  fill: #f4f4f4; }

a.bx--header__menu-item[aria-current='page']::after,
.bx--header__menu-item--current::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: -2px;
  left: 0;
  width: 100%;
  border-bottom: 3px solid #4589ff;
  content: ''; }

a.bx--header__menu-item[aria-current='page']:focus::after,
.bx--header__menu-item--current:focus::after {
  border: 0; }

a.bx--header__menu-item[aria-current='page']:focus,
a.bx--header__menu-item.bx--header__menu-item--current:focus {
  border: 2px solid #ffffff; }

.bx--header__submenu {
  position: relative; }

.bx--header__submenu--current::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  border-bottom: 3px solid #0f62fe;
  content: ''; }

.bx--header__submenu--current:focus {
  border: 2px solid #0f62fe; }

.bx--header__submenu--current:focus::after {
  border: 0; }

.bx--header__menu-title[aria-haspopup='true'] {
  position: relative; }

.bx--header__menu-title[aria-expanded='true'] {
  z-index: 8001;
  background-color: #262626;
  color: #ffffff; }

.bx--header__menu-title[aria-expanded='true'] > .bx--header__menu-arrow {
  transform: rotate(180deg); }

.bx--header__menu {
  display: none;
  padding: 0;
  margin: 0;
  list-style: none; }

.bx--header__menu-title[aria-expanded='true'] + .bx--header__menu {
  position: absolute;
  z-index: 8000;
  bottom: 0;
  left: 0;
  display: flex;
  width: 12.5rem;
  flex-direction: column;
  background-color: #262626;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);
  transform: translateY(100%); }

.bx--header__menu-title[aria-expanded='true'] + .bx--header__menu .bx--header__menu-item:hover {
  background-color: #353535; }

.bx--header__menu-title[aria-expanded='true'] + .bx--header__menu .bx--header__menu-item:active {
  background-color: #393939; }

.bx--header__menu .bx--header__menu-item {
  height: 3rem; }

.bx--header__menu .bx--header__menu-item:hover {
  background-color: #262626;
  color: #f4f4f4; }

.bx--header__menu-arrow {
  margin-left: 0.5rem;
  fill: #c6c6c6;
  transition: transform 110ms, fill 110ms; }

.bx--header__global {
  display: flex;
  height: 100%;
  flex: 1 1 0%;
  justify-content: flex-end; }

.bx--skip-to-content {
  position: absolute;
  overflow: hidden;
  width: 1px;
  height: 1px;
  padding: 0;
  border: 0;
  margin: -1px;
  clip: rect(0, 0, 0, 0);
  visibility: inherit;
  white-space: nowrap; }

.bx--skip-to-content:focus {
  z-index: 9999;
  top: 0;
  left: 0;
  display: flex;
  width: auto;
  height: 3rem;
  align-items: center;
  padding: 0 1rem;
  border: 4px solid #0f62fe;
  background-color: #161616;
  clip: auto;
  color: #f4f4f4;
  outline: none; }

.bx--assistive-text,
.bx--visually-hidden {
  position: absolute;
  overflow: hidden;
  width: 1px;
  height: 1px;
  padding: 0;
  border: 0;
  margin: -1px;
  clip: rect(0, 0, 0, 0);
  visibility: inherit;
  white-space: nowrap; }

.bx--body {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.28572;
  letter-spacing: 0.16px;
  background-color: #ffffff;
  color: #161616;
  line-height: 1; }
  .bx--body *,
  .bx--body *::before,
  .bx--body *::after {
    box-sizing: inherit; }

.bx--header-panel {
  transition-timing-function: cubic-bezier(0.2, 0, 1, 0.9);
  position: fixed;
  z-index: 8000;
  top: 3rem;
  right: 0;
  bottom: 0;
  overflow: hidden;
  width: 0;
  border: none;
  background-color: #161616;
  color: #c6c6c6;
  transition: width 0.11s;
  will-change: width; }

.bx--header-panel--expanded {
  width: 16rem;
  border-right: 1px solid #393939;
  border-left: 1px solid #393939; }

.bx--panel--overlay {
  position: fixed;
  z-index: 1000;
  top: 3rem;
  right: 0;
  bottom: 0;
  width: 16rem;
  height: 100%;
  padding: 1rem 0;
  background-color: #161616;
  overflow-x: hidden;
  overflow-y: auto;
  transform: translate3d(100%, 0, 0);
  transition: transform 0.11s cubic-bezier(0.2, 0, 0.38, 0.9);
  will-change: transform; }

.bx--panel--expanded {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.25);
  transform: translate3d(0, 0, 0); }

.bx--product-switcher__search {
  padding: 0 1rem;
  margin-bottom: 1.5rem; }

.bx--search--shell input {
  background-color: #e0e0e0; }

.bx--product-switcher__subheader,
.bx--product-switcher__all-btn {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.28572;
  letter-spacing: 0.16px;
  padding: 0.5rem;
  color: #c6c6c6; }

.bx--product-switcher__subheader {
  padding-left: 3.5rem; }

.bx--product-switcher__all-btn {
  padding-left: 3.5rem; }

.bx--product-switcher__all-btn,
.bx--product-switcher__back-btn {
  display: inline-block;
  width: 100%;
  border: none;
  background: transparent;
  color: #0f62fe;
  cursor: pointer;
  text-align: left; }

.bx--product-switcher__all-btn:hover,
.bx--product-switcher__back-btn:hover {
  text-decoration: underline; }

.bx--product-switcher__all-btn:focus,
.bx--product-switcher__back-btn:focus {
  box-shadow: inset 0 0 0 3px #0f62fe;
  outline: none; }

.bx--product-switcher__back-btn {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.28572;
  letter-spacing: 0.16px;
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem; }

.bx--product-switcher__back-arrow {
  margin-right: 1rem;
  fill: #0f62fe; }

.bx--product-list__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer; }

.bx--product-list__item:hover {
  background: #e0e0e0; }

.bx--product-link {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem 1rem;
  text-decoration: none; }

.bx--product-link:focus {
  box-shadow: inset 0 0 0 3px #0f62fe;
  outline: none; }

.bx--product-switcher__icon {
  margin-right: 1rem; }

.bx--product-link__name {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.28572;
  letter-spacing: 0.16px;
  margin-left: 0.25rem;
  color: #c6c6c6;
  font-weight: 400; }

.bx--product-switcher__product-list .bx--overflow-menu {
  display: none;
  width: 2.5rem;
  align-items: center;
  justify-content: center; }
  .bx--product-switcher__product-list .bx--overflow-menu.bx--overflow-menu--open {
    display: flex; }

.bx--product-switcher__product-list .bx--overflow-menu > svg {
  fill: #c6c6c6; }

.bx--product-switcher__product-list .bx--overflow-menu:hover {
  background: #c6c6c6; }

.bx--product-switcher__product-list .bx--overflow-menu:hover > svg {
  fill: #c6c6c6; }

.bx--product-switcher__product-list .bx--overflow-menu:focus {
  display: flex;
  box-shadow: inset 0 0 0 3px #0f62fe;
  outline: none; }

.bx--product-switcher__product-list .bx--overflow-menu-options__option:hover {
  background: #ffffff; }

.bx--product-list__item:hover .bx--overflow-menu {
  display: flex; }

.bx--switcher {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #c6c6c6; }

.bx--switcher__item {
  width: 100%;
  height: 2rem; }

.bx--switcher__item:nth-child(1) {
  margin-top: 1rem; }

.bx--switcher__item--divider {
  display: block;
  width: 14rem;
  height: 1px;
  border: none;
  margin: 0.5rem 1rem;
  background: #393939; }

.bx--switcher__item-link {
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.28572;
  letter-spacing: 0.16px;
  display: block;
  height: 2rem;
  padding: 0.375rem 1rem;
  color: #c6c6c6;
  text-decoration: none; }
  .bx--switcher__item-link:hover:not(.bx--switcher__item-link--selected) {
    background: #2c2c2c;
    color: #f4f4f4;
    cursor: pointer; }
  .bx--switcher__item-link:focus {
    outline: 2px solid #ffffff;
    outline-offset: -2px; }
  .bx--switcher__item-link:active {
    background: #393939;
    color: #f4f4f4; }

.bx--switcher__item-link--selected {
  background: #262626;
  color: #f4f4f4; }

.bx--side-nav {
  position: fixed;
  z-index: 8000;
  top: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  width: 3rem;
  max-width: 16rem;
  background-color: #ffffff;
  color: #525252;
  transition: width 0.11s cubic-bezier(0.2, 0, 1, 0.9);
  will-change: width; }

.bx--side-nav--ux {
  top: 3rem;
  width: 16rem; }
  @media (max-width: 65.98rem) {
    .bx--side-nav--ux {
      width: 0; } }

.bx--side-nav--rail {
  width: 3rem; }

.bx--side-nav--hidden {
  width: 0; }

.bx--side-nav.bx--side-nav--rail:not(.bx--side-nav--fixed):hover,
.bx--side-nav--expanded {
  width: 16rem; }

.bx--side-nav__overlay {
  position: fixed;
  top: 3rem;
  left: 0;
  width: 0;
  height: 0;
  background-color: transparent;
  opacity: 0;
  transition: opacity 240ms cubic-bezier(0.2, 0, 0.38, 0.9), background-color 240ms cubic-bezier(0.2, 0, 0.38, 0.9); }

@media (max-width: 65.98rem) {
  .bx--side-nav__overlay-active {
    width: 100vw;
    height: 100vh;
    background-color: rgba(22, 22, 22, 0.5);
    opacity: 1;
    transition: opacity 240ms cubic-bezier(0.2, 0, 0.38, 0.9), background-color 240ms cubic-bezier(0.2, 0, 0.38, 0.9); } }

.bx--header ~ .bx--side-nav {
  top: 3rem;
  height: calc(100% - 48px); }

.bx--side-nav--fixed {
  width: 16rem; }

.bx--side-nav--collapsed {
  width: 16rem;
  transform: translateX(-16rem); }

.bx--side-nav__navigation {
  display: flex;
  height: 100%;
  flex-direction: column; }

.bx--side-nav__header {
  display: flex;
  width: 100%;
  max-width: 100%;
  height: 3rem;
  border-bottom: 1px solid #393939; }
  .bx--side-nav:hover .bx--side-nav__header,
  .bx--side-nav--fixed .bx--side-nav__header,
  .bx--side-nav--expanded .bx--side-nav__header {
    height: auto; }

.bx--side-nav--ux .bx--side-nav__header {
  height: auto; }

.bx--side-nav__details {
  display: flex;
  min-width: 0;
  flex: 1;
  flex-direction: column;
  padding-right: 1rem;
  opacity: 0;
  visibility: hidden; }
  .bx--side-nav:hover .bx--side-nav__details,
  .bx--side-nav--fixed .bx--side-nav__details,
  .bx--side-nav--expanded .bx--side-nav__details {
    visibility: inherit;
    opacity: 1; }

.bx--side-nav--ux .bx--side-nav__details {
  opacity: 1;
  visibility: inherit; }

.bx--side-nav__title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: 1rem;
  font-size: 0.875rem;
  font-weight: 600;
  letter-spacing: 0.1px;
  user-select: none; }

.bx--side-nav__title,
.bx--side-nav__select {
  padding-left: 0.5rem; }

.bx--side-nav__switcher {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between; }

.bx--side-nav__switcher-chevron {
  position: absolute;
  top: 0;
  right: 0.5rem;
  bottom: 0;
  display: flex;
  align-items: center;
  fill: #525252; }

.bx--side-nav__select {
  outline: 2px solid transparent;
  outline-offset: -2px;
  min-width: 0;
  height: 2rem;
  flex: 1 1 0%;
  padding-right: 2rem;
  border: none;
  appearance: none;
  background-color: #161616;
  border-radius: 0;
  color: #f4f4f4;
  cursor: pointer;
  font-size: 0.75rem;
  transition: outline 110ms; }

.bx--side-nav__select:focus {
  outline: 2px solid #0f62fe;
  outline-offset: -2px; }
  @media screen and (prefers-contrast) {
    .bx--side-nav__select:focus {
      outline-style: dotted; } }

.bx--side-nav__footer {
  width: 100%;
  flex: 0 0 3rem;
  background-color: #ffffff; }

.bx--side-nav__toggle {
  outline: 2px solid transparent;
  outline-offset: -2px;
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  display: inline-block;
  padding: 0;
  border: 0;
  appearance: none;
  background: none;
  cursor: pointer;
  width: 100%;
  height: 100%;
  padding-left: 1rem;
  text-align: left;
  transition: outline 110ms; }
  .bx--side-nav__toggle *,
  .bx--side-nav__toggle *::before,
  .bx--side-nav__toggle *::after {
    box-sizing: inherit; }
  .bx--side-nav__toggle::-moz-focus-inner {
    border: 0; }

.bx--side-nav__toggle:focus {
  outline: 2px solid #0f62fe;
  outline-offset: -2px; }
  @media screen and (prefers-contrast) {
    .bx--side-nav__toggle:focus {
      outline-style: dotted; } }

.bx--side-nav__items {
  overflow: hidden;
  flex: 1 1 0%;
  padding: 1rem 0 0; }
  .bx--side-nav:hover .bx--side-nav__items,
  .bx--side-nav--fixed .bx--side-nav__items,
  .bx--side-nav--expanded .bx--side-nav__items {
    overflow-y: auto; }

.bx--side-nav--ux .bx--side-nav__items {
  overflow-y: auto; }

.bx--side-nav__item {
  overflow: hidden;
  width: auto;
  height: auto; }

.bx--side-nav--ux .bx--side-nav__item {
  width: auto;
  height: auto; }

.bx--side-nav__item:not(.bx--side-nav__item--active):hover .bx--side-nav__item:not(.bx--side-nav__item--active) > .bx--side-nav__submenu:hover,
.bx--side-nav__item:not(.bx--side-nav__item--active) > .bx--side-nav__link:hover,
.bx--side-nav__menu a.bx--side-nav__link:not(.bx--side-nav__link--current):not([aria-current='page']):hover,
.bx--side-nav a.bx--header__menu-item:hover,
.bx--side-nav .bx--header__menu-title[aria-expanded='true']:hover {
  background-color: #e5e5e5;
  color: #161616; }

.bx--side-nav__item:not(.bx--side-nav__item--active) > .bx--side-nav__link:hover > span,
.bx--side-nav__item:not(.bx--side-nav__item--active) .bx--side-nav__menu-item > .bx--side-nav__link:hover > span {
  color: #161616; }

.bx--side-nav__item--large {
  height: 3rem; }

.bx--side-nav__divider {
  height: 1px;
  margin: 0.5rem 1rem;
  background-color: #e0e0e0; }

.bx--side-nav__submenu {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  display: inline-block;
  padding: 0;
  border: 0;
  appearance: none;
  background: none;
  cursor: pointer;
  width: 100%;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.28572;
  letter-spacing: 0.16px;
  outline: 2px solid transparent;
  outline-offset: -2px;
  display: flex;
  height: 2rem;
  align-items: center;
  padding: 0 1rem;
  color: #525252;
  transition: color 110ms, background-color 110ms, outline 110ms;
  user-select: none; }
  .bx--side-nav__submenu *,
  .bx--side-nav__submenu *::before,
  .bx--side-nav__submenu *::after {
    box-sizing: inherit; }
  .bx--side-nav__submenu::-moz-focus-inner {
    border: 0; }

.bx--side-nav__submenu:hover {
  background-color: #e5e5e5;
  color: #161616; }

.bx--side-nav__submenu:focus {
  outline: 2px solid #0f62fe;
  outline-offset: -2px; }
  @media screen and (prefers-contrast) {
    .bx--side-nav__submenu:focus {
      outline-style: dotted; } }

.bx--side-nav__submenu-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left; }

.bx--side-nav__icon.bx--side-nav__submenu-chevron {
  display: flex;
  flex: 1;
  justify-content: flex-end; }

.bx--side-nav__submenu-chevron > svg {
  width: 1rem;
  height: 1rem;
  transition: transform 110ms; }

.bx--side-nav__submenu[aria-expanded='true'] .bx--side-nav__submenu-chevron > svg {
  transform: rotate(180deg); }

.bx--side-nav__item--large .bx--side-nav__submenu {
  height: 3rem; }

.bx--side-nav__item--active .bx--side-nav__submenu:hover {
  background-color: #e5e5e5;
  color: #161616; }

.bx--side-nav__item--active .bx--side-nav__submenu[aria-expanded='false'] {
  position: relative;
  background-color: #e5e5e5;
  color: #161616; }
  .bx--side-nav__item--active .bx--side-nav__submenu[aria-expanded='false']::before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 4px;
    background-color: #0f62fe;
    content: ''; }

.bx--side-nav__item--active .bx--side-nav__submenu-title {
  color: #161616;
  font-weight: 600; }

.bx--side-nav__menu {
  display: block;
  max-height: 0;
  visibility: hidden; }

.bx--side-nav__submenu[aria-expanded='true'] + .bx--side-nav__menu {
  max-height: 93.75rem;
  visibility: inherit; }

.bx--side-nav__menu a.bx--side-nav__link {
  height: 2rem;
  min-height: 2rem;
  padding-left: 2rem;
  font-weight: 400; }

.bx--side-nav__item.bx--side-nav__item--icon a.bx--side-nav__link {
  padding-left: 4.5rem; }

.bx--side-nav__menu a.bx--side-nav__link--current,
.bx--side-nav__menu a.bx--side-nav__link[aria-current='page'],
a.bx--side-nav__link--current {
  background-color: #e0e0e0; }
  .bx--side-nav__menu a.bx--side-nav__link--current > span,
  .bx--side-nav__menu a.bx--side-nav__link[aria-current='page'] > span,
  a.bx--side-nav__link--current > span {
    color: #161616;
    font-weight: 600; }

a.bx--side-nav__link,
.bx--side-nav a.bx--header__menu-item,
.bx--side-nav .bx--header__menu-title[aria-expanded='true'] + .bx--header__menu {
  outline: 2px solid transparent;
  outline-offset: -2px;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.28572;
  letter-spacing: 0.16px;
  position: relative;
  display: flex;
  min-height: 2rem;
  align-items: center;
  padding: 0 1rem;
  text-decoration: none;
  transition: color 110ms, background-color 110ms, outline 110ms; }

.bx--side-nav__item--large a.bx--side-nav__link {
  height: 3rem; }

a.bx--side-nav__link > .bx--side-nav__link-text,
.bx--side-nav a.bx--header__menu-item .bx--text-truncate-end {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #525252;
  font-size: 0.875rem;
  letter-spacing: 0.1px;
  line-height: 1.25rem;
  user-select: none; }

a.bx--side-nav__link:focus,
.bx--side-nav a.bx--header__menu-item:focus {
  outline: 2px solid #0f62fe;
  outline-offset: -2px; }
  @media screen and (prefers-contrast) {
    a.bx--side-nav__link:focus,
    .bx--side-nav a.bx--header__menu-item:focus {
      outline-style: dotted; } }

a.bx--side-nav__link[aria-current='page'],
a.bx--side-nav__link--current {
  background-color: #e5e5e5;
  font-weight: 600; }

a.bx--side-nav__link[aria-current='page'] .bx--side-nav__link-text,
a.bx--side-nav__link--current .bx--side-nav__link-text {
  color: #161616; }

a.bx--side-nav__link[aria-current='page']::before,
a.bx--side-nav__link--current::before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 4px;
  background-color: #0f62fe;
  content: ''; }

.bx--side-nav__icon {
  display: flex;
  flex: 0 0 1rem;
  align-items: center;
  justify-content: center; }

.bx--side-nav__icon:not(.bx--side-nav__submenu-chevron) {
  margin-right: 1.5rem; }

.bx--side-nav__icon > svg {
  width: 1rem;
  height: 1rem;
  fill: #525252; }

.bx--side-nav__icon > svg.bx--side-nav-collapse-icon {
  display: none; }

.bx--side-nav--expanded .bx--side-nav__icon > svg.bx--side-nav-expand-icon {
  display: none; }

.bx--side-nav--expanded .bx--side-nav__icon > svg.bx--side-nav-collapse-icon {
  display: block; }

.bx--side-nav--fixed a.bx--side-nav__link,
.bx--side-nav--fixed .bx--side-nav__submenu {
  padding-left: 1rem; }

.bx--side-nav--fixed .bx--side-nav__item:not(.bx--side-nav__item--icon) .bx--side-nav__menu a.bx--side-nav__link {
  padding-left: 2rem; }

@media (max-width: 65.98rem) {
  .bx--side-nav .bx--header__nav {
    display: block; } }

.bx--side-nav__header-navigation {
  display: none; }
  @media (max-width: 65.98rem) {
    .bx--side-nav__header-navigation {
      position: relative;
      display: block;
      margin-bottom: 2rem; } }

.bx--side-nav__header-divider::after {
  position: absolute;
  bottom: -1rem;
  left: 1rem;
  width: calc(100% - 32px);
  height: 0.0625rem;
  background: #e0e0e0;
  content: ''; }

.bx--side-nav a.bx--header__menu-item {
  justify-content: space-between;
  color: #525252;
  white-space: nowrap; }
  .bx--side-nav a.bx--header__menu-item[aria-expanded='true'] {
    background-color: transparent; }

.bx--side-nav .bx--header__menu-title[aria-expanded='true'] + .bx--header__menu {
  bottom: inherit;
  width: 100%;
  padding: 0;
  background-color: transparent;
  box-shadow: none;
  transform: none; }
  .bx--side-nav .bx--header__menu-title[aria-expanded='true'] + .bx--header__menu li {
    width: 100%; }
  .bx--side-nav .bx--header__menu-title[aria-expanded='true'] + .bx--header__menu a.bx--header__menu-item {
    padding-left: 4.25rem;
    font-weight: 400; }
  .bx--side-nav .bx--header__menu-title[aria-expanded='true'] + .bx--header__menu a.bx--header__menu-item:hover {
    background-color: #e5e5e5;
    color: #161616; }

.bx--side-nav .bx--header__menu a.bx--header__menu-item {
  height: inherit; }

.bx--side-nav a.bx--header__menu-item:hover .bx--header__menu-arrow,
.bx--side-nav a.bx--header__menu-item:focus .bx--header__menu-arrow,
.bx--side-nav .bx--header__menu-arrow {
  fill: #525252; }

@media screen and (-ms-high-contrast: active), (forced-colors: active), (prefers-contrast) {
  .bx--side-nav__icon > svg,
  .bx--side-nav a.bx--header__menu-item:hover .bx--header__menu-arrow,
  .bx--side-nav a.bx--header__menu-item:focus .bx--header__menu-arrow,
  .bx--side-nav .bx--header__menu-arrow {
    fill: ButtonText; } }

.bx--navigation {
  position: fixed;
  z-index: 9100;
  top: 3rem;
  bottom: 0;
  left: 0;
  width: 16rem;
  background-color: #262626;
  box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.25);
  color: #f4f4f4; }

.bx--navigation--right {
  right: 0;
  left: auto; }

.bx--navigation svg {
  fill: #f4f4f4; }

.bx--navigation-section:not(:last-child)::after {
  display: block;
  height: 1px;
  margin: 0 1rem;
  background-color: #393939;
  content: ''; }

.bx--navigation-item {
  position: relative;
  display: flex;
  align-items: center; }

.bx--navigation-item--active > a.bx--navigation-link {
  color: #ffffff;
  font-weight: 600; }

.bx--navigation-item--active::after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 4px;
  background-color: #0f62fe;
  content: ''; }

a.bx--navigation-link {
  display: flex;
  width: 100%;
  min-height: 2.5rem;
  align-items: center;
  padding-left: 1rem;
  color: #f4f4f4;
  font-size: 0.875rem;
  font-weight: 400;
  text-decoration: none; }

a.bx--navigation-link:hover {
  background-color: #333333;
  color: #ffffff; }

a.bx--navigation-link:focus {
  outline: 0.1875rem solid #0f62fe;
  outline-offset: -0.1875rem; }

.bx--navigation-item--icon > a.bx--navigation-link {
  padding-left: 0; }

.bx--navigation__category {
  width: 100%; }

.bx--navigation__category-toggle {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  display: inline-block;
  padding: 0;
  border: 0;
  appearance: none;
  background: none;
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center; }
  .bx--navigation__category-toggle *,
  .bx--navigation__category-toggle *::before,
  .bx--navigation__category-toggle *::after {
    box-sizing: inherit; }
  .bx--navigation__category-toggle::-moz-focus-inner {
    border: 0; }

.bx--navigation__category-toggle:hover {
  background-color: #333333; }

.bx--navigation__category-toggle:focus {
  outline: 0.1875rem solid #0f62fe;
  outline-offset: -0.1875rem; }

.bx--navigation__category-title {
  display: flex;
  width: 100%;
  min-height: 2.5rem;
  align-items: center;
  justify-content: space-between;
  padding-right: 1rem;
  padding-left: 1rem;
  color: #f4f4f4;
  font-size: 0.875rem;
  font-weight: 400; }

.bx--navigation-item--icon .bx--navigation__category-title {
  padding-left: 0; }

.bx--navigation__category-items {
  display: none;
  visibility: hidden; }

.bx--navigation__category-item > a.bx--navigation-link {
  display: flex;
  min-height: 2rem;
  align-items: center;
  padding-left: 2rem; }

.bx--navigation__category-item {
  position: relative; }

.bx--navigation-item--icon .bx--navigation__category-item > a.bx--navigation-link {
  padding-left: 3.5rem; }

.bx--navigation__category-item--active::after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 4px;
  background-color: #0f62fe;
  content: ''; }

.bx--navigation__category-item--active > a.bx--navigation-link {
  color: #ffffff;
  font-weight: 600; }

.bx--navigation__category--expanded .bx--navigation__category-title {
  font-weight: 600; }

.bx--navigation__category--expanded .bx--navigation__category-title > svg {
  transform: rotate(180deg); }

.bx--navigation__category--expanded .bx--navigation__category-items {
  display: block;
  visibility: inherit; }

.bx--navigation-icon {
  display: flex;
  width: 3rem;
  min-width: 3rem;
  height: 2.5rem;
  align-items: center;
  justify-content: center;
  margin-right: 0.5rem; }

.bx--content {
  padding: 2rem;
  background: #ffffff;
  will-change: margin-left; }

.bx--header ~ .bx--content {
  margin-top: 3rem; }

.bx--side-nav ~ .bx--content {
  margin-left: 3rem; }

.bx--side-nav.bx--side-nav--expanded ~ .bx--content {
  margin-left: 16rem; }

.bx--skeleton__text {
  position: relative;
  padding: 0;
  border: none;
  background: #e5e5e5;
  box-shadow: none;
  pointer-events: none;
  width: 100%;
  height: 1rem;
  margin-bottom: 0.5rem; }
  .bx--skeleton__text:hover, .bx--skeleton__text:focus, .bx--skeleton__text:active {
    border: none;
    cursor: default;
    outline: none; }
  .bx--skeleton__text::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    animation: 3000ms ease-in-out skeleton infinite;
    background: #c6c6c6;
    content: '';
    will-change: transform-origin, transform, opacity; }
    @media (prefers-reduced-motion: reduce) {
      .bx--skeleton__text::before {
        animation: none; } }

.bx--skeleton__heading {
  height: 1.5rem; }

.bx--icon--skeleton {
  position: relative;
  padding: 0;
  border: none;
  background: #e5e5e5;
  box-shadow: none;
  pointer-events: none;
  display: inline-block;
  width: 1rem;
  height: 1rem; }
  .bx--icon--skeleton:hover, .bx--icon--skeleton:focus, .bx--icon--skeleton:active {
    border: none;
    cursor: default;
    outline: none; }
  .bx--icon--skeleton::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    animation: 3000ms ease-in-out skeleton infinite;
    background: #c6c6c6;
    content: '';
    will-change: transform-origin, transform, opacity; }
    @media (prefers-reduced-motion: reduce) {
      .bx--icon--skeleton::before {
        animation: none; } }

.bx--skeleton__placeholder {
  position: relative;
  padding: 0;
  border: none;
  background: #e5e5e5;
  box-shadow: none;
  pointer-events: none;
  width: 6.25rem;
  height: 6.25rem; }
  .bx--skeleton__placeholder:hover, .bx--skeleton__placeholder:focus, .bx--skeleton__placeholder:active {
    border: none;
    cursor: default;
    outline: none; }
  .bx--skeleton__placeholder::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    animation: 3000ms ease-in-out skeleton infinite;
    background: #c6c6c6;
    content: '';
    will-change: transform-origin, transform, opacity; }
    @media (prefers-reduced-motion: reduce) {
      .bx--skeleton__placeholder::before {
        animation: none; } }

.security--input__background__color--light .bx--date-picker__input, .security--input__background__color--light .bx--dropdown,
.security--input__background__color--light .bx--number input[type='number'], .security--input__background__color--light .bx--search-input, .security--input__background__color--light .bx--select-input, .security--input__background__color--light .bx--text-area, .security--input__background__color--light .bx--text-input, .security--input__background__color--light .bx--list-box, .security--input__background__color--light .bx--list-box__menu, .security--input__background__color--light .security--button--icon:focus, .security--input__background__color--light .security--button--icon:hover, .security--tearsheet--small .bx--number .security--input__background__color--light input[type='number'], .security--tearsheet .bx--number .security--input__background__color--light input[type='number'] {
  background-color: var(--cds-ui-02, #ffffff); }

.security--theme--cg10 {
  --cds-interactive-01: #0f62fe;
  --cds-interactive-02: #343a3f;
  --cds-interactive-03: #0f62fe;
  --cds-interactive-04: #0f62fe;
  --cds-ui-background: #f2f4f8;
  --cds-ui-01: #ffffff;
  --cds-ui-02: #f2f4f8;
  --cds-ui-03: #dde1e6;
  --cds-ui-04: #878d96;
  --cds-ui-05: #121619;
  --cds-text-01: #121619;
  --cds-text-02: #4d5358;
  --cds-text-03: #a2a9b0;
  --cds-text-04: #ffffff;
  --cds-text-05: #697077;
  --cds-text-error: #da1e28;
  --cds-icon-01: #121619;
  --cds-icon-02: #4d5358;
  --cds-icon-03: #ffffff;
  --cds-link-01: #0f62fe;
  --cds-link-02: #0043ce;
  --cds-inverse-link: #78a9ff;
  --cds-field-01: #ffffff;
  --cds-field-02: #f2f4f8;
  --cds-inverse-01: #ffffff;
  --cds-inverse-02: #343a3f;
  --cds-support-01: #da1e28;
  --cds-support-02: #198038;
  --cds-support-03: #f1c21b;
  --cds-support-04: #0043ce;
  --cds-inverse-support-01: #fa4d56;
  --cds-inverse-support-02: #42be65;
  --cds-inverse-support-03: #f1c21b;
  --cds-inverse-support-04: #4589ff;
  --cds-overlay-01: rgba(22, 22, 22, 0.5);
  --cds-danger-01: #da1e28;
  --cds-danger-02: #da1e28;
  --cds-focus: #0f62fe;
  --cds-inverse-focus-ui: #ffffff;
  --cds-hover-primary: #0353e9;
  --cds-active-primary: #002d9c;
  --cds-hover-primary-text: #0043ce;
  --cds-hover-secondary: #464c51;
  --cds-active-secondary: #697077;
  --cds-hover-tertiary: #0353e9;
  --cds-active-tertiary: #002d9c;
  --cds-hover-ui: #e0e4ea;
  --cds-hover-light-ui: #e5e5e5;
  --cds-hover-selected-ui: #c4c9d1;
  --cds-active-ui: #c1c7cd;
  --cds-active-light-ui: #c1c7cd;
  --cds-selected-ui: #dde1e6;
  --cds-selected-light-ui: #dde1e6;
  --cds-inverse-hover-ui: #464c51;
  --cds-hover-danger: #b81921;
  --cds-active-danger: #750e13;
  --cds-hover-row: #e0e4ea;
  --cds-visited-link: #8a3ffc;
  --cds-disabled-01: #ffffff;
  --cds-disabled-02: #c1c7cd;
  --cds-disabled-03: #878d96;
  --cds-highlight: #d0e2ff;
  --cds-decorative-01: #dde1e6;
  --cds-button-separator: #dde1e6;
  --cds-skeleton-01: #e0e4ea;
  --cds-skeleton-02: #c1c7cd;
  --cds-background: #f2f4f8;
  --cds-layer: #ffffff;
  --cds-layer-accent: #dde1e6;
  --cds-layer-accent-hover: #d1d1d1;
  --cds-layer-accent-active: #a2a9b0;
  --cds-field: #ffffff;
  --cds-background-inverse: #343a3f;
  --cds-background-brand: #0f62fe;
  --cds-interactive: #0f62fe;
  --cds-border-subtle: #dde1e6;
  --cds-border-strong: #878d96;
  --cds-border-inverse: #121619;
  --cds-border-interactive: #0f62fe;
  --cds-text-primary: #121619;
  --cds-text-secondary: #4d5358;
  --cds-text-placeholder: #a2a9b0;
  --cds-text-helper: #697077;
  --cds-text-on-color: #ffffff;
  --cds-text-inverse: #ffffff;
  --cds-link-primary: #0f62fe;
  --cds-link-secondary: #0043ce;
  --cds-link-visited: #8a3ffc;
  --cds-link-inverse: #78a9ff;
  --cds-icon-primary: #121619;
  --cds-icon-secondary: #4d5358;
  --cds-icon-on-color: #ffffff;
  --cds-icon-inverse: #ffffff;
  --cds-support-error: #da1e28;
  --cds-support-success: #198038;
  --cds-support-warning: #f1c21b;
  --cds-support-info: #0043ce;
  --cds-support-error-inverse: #fa4d56;
  --cds-support-success-inverse: #42be65;
  --cds-support-warning-inverse: #f1c21b;
  --cds-support-info-inverse: #4589ff;
  --cds-overlay: rgba(22, 22, 22, 0.5);
  --cds-toggle-off: #878d96;
  --cds-shadow: rgba(0, 0, 0, 0.3);
  --cds-button-primary: #0f62fe;
  --cds-button-secondary: #343a3f;
  --cds-button-tertiary: #0f62fe;
  --cds-button-danger-primary: #da1e28;
  --cds-button-danger-secondary: #da1e28;
  --cds-background-active: #c1c7cd;
  --cds-layer-active: #c1c7cd;
  --cds-button-danger-active: #750e13;
  --cds-button-primary-active: #002d9c;
  --cds-button-secondary-active: #697077;
  --cds-button-tertiary-active: #002d9c;
  --cds-focus-inset: #ffffff;
  --cds-focus-inverse: #ffffff;
  --cds-background-hover: #e5e5e5;
  --cds-layer-hover: #e5e5e5;
  --cds-field-hover: #e5e5e5;
  --cds-background-inverse-hover: #4c4c4c;
  --cds-link-primary-hover: #0043ce;
  --cds-button-danger-hover: #b81921;
  --cds-button-primary-hover: #0353e9;
  --cds-button-secondary-hover: #4c4c4c;
  --cds-button-tertiary-hover: #0353e9;
  --cds-background-selected: #dde1e6;
  --cds-background-selected-hover: #cacaca;
  --cds-layer-selected: #dde1e6;
  --cds-layer-selected-hover: #cacaca;
  --cds-layer-selected-inverse: #121619;
  --cds-border-subtle-selected: #c1c7cd;
  --cds-layer-disabled: #ffffff;
  --cds-field-disabled: #ffffff;
  --cds-border-disabled: #ffffff;
  --cds-text-disabled: #c1c7cd;
  --cds-button-disabled: #c1c7cd;
  --cds-icon-disabled: #c1c7cd;
  --cds-text-on-color-disabled: #878d96;
  --cds-icon-on-color-disabled: #878d96;
  --cds-layer-selected-disabled: #878d96;
  --cds-skeleton-background: #e5e5e5;
  --cds-skeleton-element: #c1c7cd;
  --cds-brand-01: #0f62fe;
  --cds-brand-02: #343a3f;
  --cds-brand-03: #0f62fe;
  --cds-active-01: #c1c7cd;
  --cds-hover-field: #e0e4ea;
  --cds-danger: #da1e28;
  --cds-caption-01-font-size: 0.75rem;
  --cds-caption-01-font-weight: 400;
  --cds-caption-01-line-height: 1.33333;
  --cds-caption-01-letter-spacing: 0.32px;
  --cds-caption-02-font-size: 0.875rem;
  --cds-caption-02-font-weight: 400;
  --cds-caption-02-line-height: 1.28572;
  --cds-caption-02-letter-spacing: 0.32px;
  --cds-label-01-font-size: 0.75rem;
  --cds-label-01-font-weight: 400;
  --cds-label-01-line-height: 1.33333;
  --cds-label-01-letter-spacing: 0.32px;
  --cds-label-02-font-size: 0.875rem;
  --cds-label-02-font-weight: 400;
  --cds-label-02-line-height: 1.28572;
  --cds-label-02-letter-spacing: 0.16px;
  --cds-helper-text-01-font-size: 0.75rem;
  --cds-helper-text-01-line-height: 1.33333;
  --cds-helper-text-01-letter-spacing: 0.32px;
  --cds-helper-text-02-font-size: 0.875rem;
  --cds-helper-text-02-line-height: 1.28572;
  --cds-helper-text-02-letter-spacing: 0.16px;
  --cds-body-short-01-font-size: 0.875rem;
  --cds-body-short-01-font-weight: 400;
  --cds-body-short-01-line-height: 1.28572;
  --cds-body-short-01-letter-spacing: 0.16px;
  --cds-body-long-01-font-size: 0.875rem;
  --cds-body-long-01-font-weight: 400;
  --cds-body-long-01-line-height: 1.42857;
  --cds-body-long-01-letter-spacing: 0.16px;
  --cds-body-short-02-font-size: 1rem;
  --cds-body-short-02-font-weight: 400;
  --cds-body-short-02-line-height: 1.375;
  --cds-body-short-02-letter-spacing: 0;
  --cds-body-long-02-font-size: 1rem;
  --cds-body-long-02-font-weight: 400;
  --cds-body-long-02-line-height: 1.5;
  --cds-body-long-02-letter-spacing: 0;
  --cds-code-01-font-family: 'IBM Plex Mono', 'Menlo', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', Courier, monospace;
  --cds-code-01-font-size: 0.75rem;
  --cds-code-01-font-weight: 400;
  --cds-code-01-line-height: 1.33333;
  --cds-code-01-letter-spacing: 0.32px;
  --cds-code-02-font-family: 'IBM Plex Mono', 'Menlo', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', Courier, monospace;
  --cds-code-02-font-size: 0.875rem;
  --cds-code-02-font-weight: 400;
  --cds-code-02-line-height: 1.42857;
  --cds-code-02-letter-spacing: 0.32px;
  --cds-heading-01-font-size: 0.875rem;
  --cds-heading-01-font-weight: 600;
  --cds-heading-01-line-height: 1.42857;
  --cds-heading-01-letter-spacing: 0.16px;
  --cds-productive-heading-01-font-size: 0.875rem;
  --cds-productive-heading-01-font-weight: 600;
  --cds-productive-heading-01-line-height: 1.28572;
  --cds-productive-heading-01-letter-spacing: 0.16px;
  --cds-heading-02-font-size: 1rem;
  --cds-heading-02-font-weight: 600;
  --cds-heading-02-line-height: 1.5;
  --cds-heading-02-letter-spacing: 0;
  --cds-productive-heading-02-font-size: 1rem;
  --cds-productive-heading-02-font-weight: 600;
  --cds-productive-heading-02-line-height: 1.375;
  --cds-productive-heading-02-letter-spacing: 0;
  --cds-productive-heading-03-font-size: 1.25rem;
  --cds-productive-heading-03-font-weight: 400;
  --cds-productive-heading-03-line-height: 1.4;
  --cds-productive-heading-03-letter-spacing: 0;
  --cds-productive-heading-04-font-size: 1.75rem;
  --cds-productive-heading-04-font-weight: 400;
  --cds-productive-heading-04-line-height: 1.28572;
  --cds-productive-heading-04-letter-spacing: 0;
  --cds-productive-heading-05-font-size: 2rem;
  --cds-productive-heading-05-font-weight: 400;
  --cds-productive-heading-05-line-height: 1.25;
  --cds-productive-heading-05-letter-spacing: 0;
  --cds-productive-heading-06-font-size: 2.625rem;
  --cds-productive-heading-06-font-weight: 300;
  --cds-productive-heading-06-line-height: 1.199;
  --cds-productive-heading-06-letter-spacing: 0;
  --cds-productive-heading-07-font-size: 3.375rem;
  --cds-productive-heading-07-font-weight: 300;
  --cds-productive-heading-07-line-height: 1.199;
  --cds-productive-heading-07-letter-spacing: 0;
  --cds-expressive-heading-01-font-size: 0.875rem;
  --cds-expressive-heading-01-font-weight: 600;
  --cds-expressive-heading-01-line-height: 1.25;
  --cds-expressive-heading-01-letter-spacing: 0.16px;
  --cds-expressive-heading-02-font-size: 1rem;
  --cds-expressive-heading-02-font-weight: 600;
  --cds-expressive-heading-02-line-height: 1.5;
  --cds-expressive-heading-02-letter-spacing: 0;
  --cds-expressive-heading-03-font-size: 1.25rem;
  --cds-expressive-heading-03-font-weight: 400;
  --cds-expressive-heading-03-line-height: 1.4;
  --cds-expressive-heading-03-letter-spacing: 0;
  --cds-expressive-heading-04-font-size: 1.75rem;
  --cds-expressive-heading-04-font-weight: 400;
  --cds-expressive-heading-04-line-height: 1.28572;
  --cds-expressive-heading-04-letter-spacing: 0;
  --cds-expressive-heading-05-font-size: 2rem;
  --cds-expressive-heading-05-font-weight: 400;
  --cds-expressive-heading-05-line-height: 1.25;
  --cds-expressive-heading-05-letter-spacing: 0;
  --cds-expressive-heading-06-font-size: 2rem;
  --cds-expressive-heading-06-font-weight: 600;
  --cds-expressive-heading-06-line-height: 1.25;
  --cds-expressive-heading-06-letter-spacing: 0;
  --cds-expressive-paragraph-01-font-size: 1.5rem;
  --cds-expressive-paragraph-01-font-weight: 300;
  --cds-expressive-paragraph-01-line-height: 1.334;
  --cds-expressive-paragraph-01-letter-spacing: 0;
  --cds-quotation-01-font-family: 'IBM Plex Serif', 'Georgia', Times, serif;
  --cds-quotation-01-font-size: 1.25rem;
  --cds-quotation-01-font-weight: 400;
  --cds-quotation-01-line-height: 1.3;
  --cds-quotation-01-letter-spacing: 0;
  --cds-quotation-02-font-family: 'IBM Plex Serif', 'Georgia', Times, serif;
  --cds-quotation-02-font-size: 2rem;
  --cds-quotation-02-font-weight: 300;
  --cds-quotation-02-line-height: 1.25;
  --cds-quotation-02-letter-spacing: 0;
  --cds-display-01-font-size: 2.625rem;
  --cds-display-01-font-weight: 300;
  --cds-display-01-line-height: 1.19;
  --cds-display-01-letter-spacing: 0;
  --cds-display-02-font-size: 2.625rem;
  --cds-display-02-font-weight: 600;
  --cds-display-02-line-height: 1.19;
  --cds-display-02-letter-spacing: 0;
  --cds-display-03-font-size: 2.625rem;
  --cds-display-03-font-weight: 300;
  --cds-display-03-line-height: 1.19;
  --cds-display-03-letter-spacing: 0;
  --cds-display-04-font-size: 2.625rem;
  --cds-display-04-font-weight: 600;
  --cds-display-04-line-height: 1.19;
  --cds-display-04-letter-spacing: 0;
  --cds-legal-01-font-size: 0.75rem;
  --cds-legal-01-font-weight: 400;
  --cds-legal-01-line-height: 1.33333;
  --cds-legal-01-letter-spacing: 0.32px;
  --cds-legal-02-font-size: 0.875rem;
  --cds-legal-02-font-weight: 400;
  --cds-legal-02-line-height: 1.28572;
  --cds-legal-02-letter-spacing: 0.16px;
  --cds-body-compact-01-font-size: 0.875rem;
  --cds-body-compact-01-font-weight: 400;
  --cds-body-compact-01-line-height: 1.28572;
  --cds-body-compact-01-letter-spacing: 0.16px;
  --cds-body-compact-02-font-size: 1rem;
  --cds-body-compact-02-font-weight: 400;
  --cds-body-compact-02-line-height: 1.375;
  --cds-body-compact-02-letter-spacing: 0;
  --cds-body-01-font-size: 0.875rem;
  --cds-body-01-font-weight: 400;
  --cds-body-01-line-height: 1.42857;
  --cds-body-01-letter-spacing: 0.16px;
  --cds-body-02-font-size: 1rem;
  --cds-body-02-font-weight: 400;
  --cds-body-02-line-height: 1.5;
  --cds-body-02-letter-spacing: 0;
  --cds-heading-compact-01-font-size: 0.875rem;
  --cds-heading-compact-01-font-weight: 600;
  --cds-heading-compact-01-line-height: 1.28572;
  --cds-heading-compact-01-letter-spacing: 0.16px;
  --cds-heading-compact-02-font-size: 1rem;
  --cds-heading-compact-02-font-weight: 600;
  --cds-heading-compact-02-line-height: 1.375;
  --cds-heading-compact-02-letter-spacing: 0;
  --cds-heading-03-font-size: 1.25rem;
  --cds-heading-03-font-weight: 400;
  --cds-heading-03-line-height: 1.4;
  --cds-heading-03-letter-spacing: 0;
  --cds-heading-04-font-size: 1.75rem;
  --cds-heading-04-font-weight: 400;
  --cds-heading-04-line-height: 1.28572;
  --cds-heading-04-letter-spacing: 0;
  --cds-heading-05-font-size: 2rem;
  --cds-heading-05-font-weight: 400;
  --cds-heading-05-line-height: 1.25;
  --cds-heading-05-letter-spacing: 0;
  --cds-heading-06-font-size: 2.625rem;
  --cds-heading-06-font-weight: 300;
  --cds-heading-06-line-height: 1.199;
  --cds-heading-06-letter-spacing: 0;
  --cds-heading-07-font-size: 3.375rem;
  --cds-heading-07-font-weight: 300;
  --cds-heading-07-line-height: 1.199;
  --cds-heading-07-letter-spacing: 0;
  --cds-fluid-heading-03-font-size: 1.25rem;
  --cds-fluid-heading-03-font-weight: 400;
  --cds-fluid-heading-03-line-height: 1.4;
  --cds-fluid-heading-03-letter-spacing: 0;
  --cds-fluid-heading-04-font-size: 1.75rem;
  --cds-fluid-heading-04-font-weight: 400;
  --cds-fluid-heading-04-line-height: 1.28572;
  --cds-fluid-heading-04-letter-spacing: 0;
  --cds-fluid-heading-05-font-size: 2rem;
  --cds-fluid-heading-05-font-weight: 400;
  --cds-fluid-heading-05-line-height: 1.25;
  --cds-fluid-heading-05-letter-spacing: 0;
  --cds-fluid-heading-06-font-size: 2rem;
  --cds-fluid-heading-06-font-weight: 600;
  --cds-fluid-heading-06-line-height: 1.25;
  --cds-fluid-heading-06-letter-spacing: 0;
  --cds-fluid-paragraph-01-font-size: 1.5rem;
  --cds-fluid-paragraph-01-font-weight: 300;
  --cds-fluid-paragraph-01-line-height: 1.334;
  --cds-fluid-paragraph-01-letter-spacing: 0;
  --cds-fluid-quotation-01-font-family: 'IBM Plex Serif', 'Georgia', Times, serif;
  --cds-fluid-quotation-01-font-size: 1.25rem;
  --cds-fluid-quotation-01-font-weight: 400;
  --cds-fluid-quotation-01-line-height: 1.3;
  --cds-fluid-quotation-01-letter-spacing: 0;
  --cds-fluid-quotation-02-font-family: 'IBM Plex Serif', 'Georgia', Times, serif;
  --cds-fluid-quotation-02-font-size: 2rem;
  --cds-fluid-quotation-02-font-weight: 300;
  --cds-fluid-quotation-02-line-height: 1.25;
  --cds-fluid-quotation-02-letter-spacing: 0;
  --cds-fluid-display-01-font-size: 2.625rem;
  --cds-fluid-display-01-font-weight: 300;
  --cds-fluid-display-01-line-height: 1.19;
  --cds-fluid-display-01-letter-spacing: 0;
  --cds-fluid-display-02-font-size: 2.625rem;
  --cds-fluid-display-02-font-weight: 600;
  --cds-fluid-display-02-line-height: 1.19;
  --cds-fluid-display-02-letter-spacing: 0;
  --cds-fluid-display-03-font-size: 2.625rem;
  --cds-fluid-display-03-font-weight: 300;
  --cds-fluid-display-03-line-height: 1.19;
  --cds-fluid-display-03-letter-spacing: 0;
  --cds-fluid-display-04-font-size: 2.625rem;
  --cds-fluid-display-04-font-weight: 600;
  --cds-fluid-display-04-line-height: 1.19;
  --cds-fluid-display-04-letter-spacing: 0;
  --cds-spacing-01: 0.125rem;
  --cds-spacing-02: 0.25rem;
  --cds-spacing-03: 0.5rem;
  --cds-spacing-04: 0.75rem;
  --cds-spacing-05: 1rem;
  --cds-spacing-06: 1.5rem;
  --cds-spacing-07: 2rem;
  --cds-spacing-08: 2.5rem;
  --cds-spacing-09: 3rem;
  --cds-spacing-10: 4rem;
  --cds-spacing-11: 5rem;
  --cds-spacing-12: 6rem;
  --cds-spacing-13: 10rem;
  --cds-fluid-spacing-01: 0;
  --cds-fluid-spacing-02: 2vw;
  --cds-fluid-spacing-03: 5vw;
  --cds-fluid-spacing-04: 10vw;
  --cds-layout-01: 1rem;
  --cds-layout-02: 1.5rem;
  --cds-layout-03: 2rem;
  --cds-layout-04: 3rem;
  --cds-layout-05: 4rem;
  --cds-layout-06: 6rem;
  --cds-layout-07: 10rem;
  --cds-container-01: 1.5rem;
  --cds-container-02: 2rem;
  --cds-container-03: 2.5rem;
  --cds-container-04: 3rem;
  --cds-container-05: 4rem;
  --cds-size-xsmall: 1.5rem;
  --cds-size-small: 2rem;
  --cds-size-medium: 2.5rem;
  --cds-size-large: 3rem;
  --cds-size-xlarge: 4rem;
  --cds-size-2XLarge: 5rem;
  --cds-icon-size-01: 1rem;
  --cds-icon-size-02: 1.25rem;
  --interactive-01: var(--cds-interactive-01, #0f62fe);
  --interactive-02: var(--cds-interactive-02, #343a3f);
  --interactive-03: var(--cds-interactive-03, #0f62fe);
  --interactive-04: var(--cds-interactive-04, #0f62fe);
  --ui-background: var(--cds-ui-background, #f2f4f8);
  --ui-01: var(--cds-ui-01, #ffffff);
  --ui-02: var(--cds-ui-02, #f2f4f8);
  --ui-03: var(--cds-ui-03, #dde1e6);
  --ui-04: var(--cds-ui-04, #878d96);
  --ui-05: var(--cds-ui-05, #121619);
  --text-01: var(--cds-text-01, #121619);
  --text-02: var(--cds-text-02, #4d5358);
  --text-03: var(--cds-text-03, #a2a9b0);
  --text-04: var(--cds-text-04, #ffffff);
  --text-05: var(--cds-text-05, #697077);
  --text-error: var(--cds-text-error, #da1e28);
  --icon-01: var(--cds-icon-01, #121619);
  --icon-02: var(--cds-icon-02, #4d5358);
  --icon-03: var(--cds-icon-03, #ffffff);
  --link-01: var(--cds-link-01, #0f62fe);
  --link-02: var(--cds-link-02, #0043ce);
  --inverse-link: var(--cds-inverse-link, #78a9ff);
  --field-01: var(--cds-field-01, #ffffff);
  --field-02: var(--cds-field-02, #f2f4f8);
  --inverse-01: var(--cds-inverse-01, #ffffff);
  --inverse-02: var(--cds-inverse-02, #343a3f);
  --support-01: var(--cds-support-01, #da1e28);
  --support-02: var(--cds-support-02, #198038);
  --support-03: var(--cds-support-03, #f1c21b);
  --support-04: var(--cds-support-04, #0043ce);
  --inverse-support-01: var(--cds-inverse-support-01, #fa4d56);
  --inverse-support-02: var(--cds-inverse-support-02, #42be65);
  --inverse-support-03: var(--cds-inverse-support-03, #f1c21b);
  --inverse-support-04: var(--cds-inverse-support-04, #4589ff);
  --overlay-01: var(--cds-overlay-01, rgba(22, 22, 22, 0.5));
  --danger-01: var(--cds-danger-01, #da1e28);
  --danger-02: var(--cds-danger-02, #da1e28);
  --focus: var(--cds-focus, #0f62fe);
  --inverse-focus-ui: var(--cds-inverse-focus-ui, #ffffff);
  --hover-primary: var(--cds-hover-primary, #0353e9);
  --active-primary: var(--cds-active-primary, #002d9c);
  --hover-primary-text: var(--cds-hover-primary-text, #0043ce);
  --hover-secondary: var(--cds-hover-secondary, #464c51);
  --active-secondary: var(--cds-active-secondary, #697077);
  --hover-tertiary: var(--cds-hover-tertiary, #0353e9);
  --active-tertiary: var(--cds-active-tertiary, #002d9c);
  --hover-ui: var(--cds-hover-ui, #e0e4ea);
  --hover-light-ui: var(--cds-hover-light-ui, #e5e5e5);
  --active-ui: var(--cds-active-ui, #c1c7cd);
  --active-light-ui: var(--cds-active-light-ui, #c1c7cd);
  --selected-ui: var(--cds-selected-ui, #dde1e6);
  --selected-light-ui: var(--cds-selected-light-ui, #dde1e6);
  --inverse-hover-ui: var(--cds-inverse-hover-ui, #464c51);
  --hover-selected-ui: var(--cds-hover-selected-ui, #c4c9d1);
  --hover-danger: var(--cds-hover-danger, #b81921);
  --active-danger: var(--cds-active-danger, #750e13);
  --hover-row: var(--cds-hover-row, #e0e4ea);
  --visited-link: var(--cds-visited-link, #8a3ffc);
  --disabled-01: var(--cds-disabled-01, #ffffff);
  --disabled-02: var(--cds-disabled-02, #c1c7cd);
  --disabled-03: var(--cds-disabled-03, #878d96);
  --highlight: var(--cds-highlight, #d0e2ff);
  --decorative-01: var(--cds-decorative-01, #dde1e6);
  --button-separator: var(--cds-button-separator, #dde1e6);
  --skeleton-01: var(--cds-skeleton-01, #e0e4ea);
  --skeleton-02: var(--cds-skeleton-02, #c1c7cd);
  --background: var(--cds-background, #f2f4f8);
  --layer: var(--cds-layer, #ffffff);
  --layer-accent: var(--cds-layer-accent, #dde1e6);
  --layer-accent-active: var(--cds-layer-accent-active, #a2a9b0);
  --layer-accent-hover: var(--cds-layer-accent-hover, #d1d1d1);
  --field: var(--cds-field, #ffffff);
  --background-inverse: var(--cds-background-inverse, #343a3f);
  --background-brand: var(--cds-background-brand, #0f62fe);
  --interactive: var(--cds-interactive, #0f62fe);
  --border-subtle: var(--cds-border-subtle, #dde1e6);
  --border-strong: var(--cds-border-strong, #878d96);
  --border-inverse: var(--cds-border-inverse, #121619);
  --border-interactive: var(--cds-border-interactive, #0f62fe);
  --text-primary: var(--cds-text-primary, #121619);
  --text-secondary: var(--cds-text-secondary, #4d5358);
  --text-placeholder: var(--cds-text-placeholder, #a2a9b0);
  --text-helper: var(--cds-text-helper, #697077);
  --text-on-color: var(--cds-text-on-color, #ffffff);
  --text-inverse: var(--cds-text-inverse, #ffffff);
  --link-primary: var(--cds-link-primary, #0f62fe);
  --link-secondary: var(--cds-link-secondary, #0043ce);
  --link-visited: var(--cds-link-visited, #8a3ffc);
  --link-inverse: var(--cds-link-inverse, #78a9ff);
  --icon-primary: var(--cds-icon-primary, #121619);
  --icon-secondary: var(--cds-icon-secondary, #4d5358);
  --icon-on-color: var(--cds-icon-on-color, #ffffff);
  --icon-inverse: var(--cds-icon-inverse, #ffffff);
  --support-error: var(--cds-support-error, #da1e28);
  --support-success: var(--cds-support-success, #198038);
  --support-warning: var(--cds-support-warning, #f1c21b);
  --support-info: var(--cds-support-info, #0043ce);
  --support-error-inverse: var(--cds-support-error-inverse, #fa4d56);
  --support-success-inverse: var(--cds-support-success-inverse, #42be65);
  --support-warning-inverse: var(--cds-support-warning-inverse, #f1c21b);
  --support-info-inverse: var(--cds-support-info-inverse, #4589ff);
  --overlay: var(--cds-overlay, rgba(22, 22, 22, 0.5));
  --toggle-off: var(--cds-toggle-off, #878d96);
  --shadow: var(--cds-shadow, rgba(0, 0, 0, 0.3));
  --button-primary: var(--cds-button-primary, #0f62fe);
  --button-secondary: var(--cds-button-secondary, #343a3f);
  --button-tertiary: var(--cds-button-tertiary, #0f62fe);
  --button-danger-primary: var(--cds-button-danger-primary, #da1e28);
  --button-danger-secondary: var(--cds-button-danger-secondary, #da1e28);
  --background-active: var(--cds-background-active, #c1c7cd);
  --layer-active: var(--cds-layer-active, #c1c7cd);
  --button-danger-active: var(--cds-button-danger-active, #750e13);
  --button-primary-active: var(--cds-button-primary-active, #002d9c);
  --button-secondary-active: var(--cds-button-secondary-active, #697077);
  --button-tertiary-active: var(--cds-button-tertiary-active, #002d9c);
  --focus-inset: var(--cds-focus-inset, #ffffff);
  --focus-inverse: var(--cds-focus-inverse, #ffffff);
  --background-hover: var(--cds-background-hover, #e5e5e5);
  --layer-hover: var(--cds-layer-hover, #e5e5e5);
  --field-hover: var(--cds-field-hover, #e5e5e5);
  --background-inverse-hover: var(--cds-background-inverse-hover, #4c4c4c);
  --link-primary-hover: var(--cds-link-primary-hover, #0043ce);
  --button-danger-hover: var(--cds-button-danger-hover, #b81921);
  --button-primary-hover: var(--cds-button-primary-hover, #0353e9);
  --button-secondary-hover: var(--cds-button-secondary-hover, #4c4c4c);
  --button-tertiary-hover: var(--cds-button-tertiary-hover, #0353e9);
  --background-selected: var(--cds-background-selected, #dde1e6);
  --background-selected-hover: var(--cds-background-selected-hover, #cacaca);
  --layer-selected: var(--cds-layer-selected, #dde1e6);
  --layer-selected-hover: var(--cds-layer-selected-hover, #cacaca);
  --layer-selected-inverse: var(--cds-layer-selected-inverse, #121619);
  --border-subtle-selected: var(--cds-border-subtle-selected, #c1c7cd);
  --layer-disabled: var(--cds-layer-disabled, #ffffff);
  --field-disabled: var(--cds-field-disabled, #ffffff);
  --border-disabled: var(--cds-border-disabled, #ffffff);
  --text-disabled: var(--cds-text-disabled, #c1c7cd);
  --button-disabled: var(--cds-button-disabled, #c1c7cd);
  --icon-disabled: var(--cds-icon-disabled, #c1c7cd);
  --text-on-color-disabled: var(--cds-text-on-color-disabled, #878d96);
  --icon-on-color-disabled: var(--cds-icon-on-color-disabled, #878d96);
  --layer-selected-disabled: var(--cds-layer-selected-disabled, #878d96);
  --skeleton-background: var(--cds-skeleton-background, #e5e5e5);
  --skeleton-element: var(--cds-skeleton-element, #c1c7cd);
  --brand-01: var(--cds-brand-01, #0f62fe);
  --brand-02: var(--cds-brand-02, #343a3f);
  --brand-03: var(--cds-brand-03, #0f62fe);
  --active-01: var(--cds-active-01, #c1c7cd);
  --hover-field: var(--cds-hover-field, #e0e4ea);
  --danger: var(--cds-danger, #da1e28); }

.security--theme--g100 {
  --cds-interactive-01: #0f62fe;
  --cds-interactive-02: #6f6f6f;
  --cds-interactive-03: #ffffff;
  --cds-interactive-04: #4589ff;
  --cds-ui-background: #161616;
  --cds-ui-01: #262626;
  --cds-ui-02: #393939;
  --cds-ui-03: #393939;
  --cds-ui-04: #6f6f6f;
  --cds-ui-05: #f4f4f4;
  --cds-text-01: #f4f4f4;
  --cds-text-02: #c6c6c6;
  --cds-text-03: #6f6f6f;
  --cds-text-04: #ffffff;
  --cds-text-05: #8d8d8d;
  --cds-text-error: #ff8389;
  --cds-icon-01: #f4f4f4;
  --cds-icon-02: #c6c6c6;
  --cds-icon-03: #ffffff;
  --cds-link-01: #78a9ff;
  --cds-link-02: #a6c8ff;
  --cds-inverse-link: #0f62fe;
  --cds-field-01: #262626;
  --cds-field-02: #393939;
  --cds-inverse-01: #161616;
  --cds-inverse-02: #f4f4f4;
  --cds-support-01: #fa4d56;
  --cds-support-02: #42be65;
  --cds-support-03: #f1c21b;
  --cds-support-04: #4589ff;
  --cds-inverse-support-01: #da1e28;
  --cds-inverse-support-02: #24a148;
  --cds-inverse-support-03: #f1c21b;
  --cds-inverse-support-04: #0f62fe;
  --cds-overlay-01: rgba(0, 0, 0, 0.65);
  --cds-danger-01: #da1e28;
  --cds-danger-02: #fa4d56;
  --cds-focus: #ffffff;
  --cds-inverse-focus-ui: #0f62fe;
  --cds-hover-primary: #0353e9;
  --cds-active-primary: #002d9c;
  --cds-hover-primary-text: #a6c8ff;
  --cds-hover-secondary: #606060;
  --cds-active-secondary: #393939;
  --cds-hover-tertiary: #f4f4f4;
  --cds-active-tertiary: #c6c6c6;
  --cds-hover-ui: #353535;
  --cds-hover-light-ui: #4c4c4c;
  --cds-hover-selected-ui: #4c4c4c;
  --cds-active-ui: #525252;
  --cds-active-light-ui: #6f6f6f;
  --cds-selected-ui: #393939;
  --cds-selected-light-ui: #525252;
  --cds-inverse-hover-ui: #e5e5e5;
  --cds-hover-danger: #b81921;
  --cds-active-danger: #750e13;
  --cds-hover-row: #353535;
  --cds-visited-link: #be95ff;
  --cds-disabled-01: #262626;
  --cds-disabled-02: #525252;
  --cds-disabled-03: #8d8d8d;
  --cds-highlight: #002d9c;
  --cds-decorative-01: #525252;
  --cds-button-separator: #161616;
  --cds-skeleton-01: #353535;
  --cds-skeleton-02: #525252;
  --cds-background: #161616;
  --cds-layer: #262626;
  --cds-layer-accent: #393939;
  --cds-layer-accent-hover: #474747;
  --cds-layer-accent-active: #6f6f6f;
  --cds-field: #262626;
  --cds-background-inverse: #f4f4f4;
  --cds-background-brand: #0f62fe;
  --cds-interactive: #4589ff;
  --cds-border-subtle: #393939;
  --cds-border-strong: #6f6f6f;
  --cds-border-inverse: #f4f4f4;
  --cds-border-interactive: #4589ff;
  --cds-text-primary: #f4f4f4;
  --cds-text-secondary: #c6c6c6;
  --cds-text-placeholder: #6f6f6f;
  --cds-text-helper: #8d8d8d;
  --cds-text-on-color: #ffffff;
  --cds-text-inverse: #161616;
  --cds-link-primary: #78a9ff;
  --cds-link-secondary: #a6c8ff;
  --cds-link-visited: #be95ff;
  --cds-link-inverse: #0f62fe;
  --cds-icon-primary: #f4f4f4;
  --cds-icon-secondary: #c6c6c6;
  --cds-icon-on-color: #ffffff;
  --cds-icon-inverse: #161616;
  --cds-support-error: #fa4d56;
  --cds-support-success: #42be65;
  --cds-support-warning: #f1c21b;
  --cds-support-info: #4589ff;
  --cds-support-error-inverse: #da1e28;
  --cds-support-success-inverse: #24a148;
  --cds-support-warning-inverse: #f1c21b;
  --cds-support-info-inverse: #0f62fe;
  --cds-overlay: rgba(0, 0, 0, 0.65);
  --cds-toggle-off: #6f6f6f;
  --cds-shadow: rgba(0, 0, 0, 0.8);
  --cds-button-primary: #0f62fe;
  --cds-button-secondary: #6f6f6f;
  --cds-button-tertiary: #ffffff;
  --cds-button-danger-primary: #da1e28;
  --cds-button-danger-secondary: #fa4d56;
  --cds-background-active: #525252;
  --cds-layer-active: #525252;
  --cds-button-danger-active: #750e13;
  --cds-button-primary-active: #002d9c;
  --cds-button-secondary-active: #393939;
  --cds-button-tertiary-active: #c6c6c6;
  --cds-focus-inset: #161616;
  --cds-focus-inverse: #0f62fe;
  --cds-background-hover: #353535;
  --cds-layer-hover: #353535;
  --cds-field-hover: #353535;
  --cds-background-inverse-hover: #e5e5e5;
  --cds-link-primary-hover: #a6c8ff;
  --cds-button-danger-hover: #b81921;
  --cds-button-primary-hover: #0353e9;
  --cds-button-secondary-hover: #606060;
  --cds-button-tertiary-hover: #f4f4f4;
  --cds-background-selected: #393939;
  --cds-background-selected-hover: #4c4c4c;
  --cds-layer-selected: #393939;
  --cds-layer-selected-hover: #4c4c4c;
  --cds-layer-selected-inverse: #f4f4f4;
  --cds-border-subtle-selected: #525252;
  --cds-layer-disabled: #262626;
  --cds-field-disabled: #262626;
  --cds-border-disabled: #262626;
  --cds-text-disabled: #525252;
  --cds-button-disabled: #525252;
  --cds-icon-disabled: #525252;
  --cds-text-on-color-disabled: #8d8d8d;
  --cds-icon-on-color-disabled: #8d8d8d;
  --cds-layer-selected-disabled: #8d8d8d;
  --cds-skeleton-background: #353535;
  --cds-skeleton-element: #525252;
  --cds-brand-01: #0f62fe;
  --cds-brand-02: #6f6f6f;
  --cds-brand-03: #ffffff;
  --cds-active-01: #525252;
  --cds-hover-field: #353535;
  --cds-danger: #da1e28;
  --cds-caption-01-font-size: 0.75rem;
  --cds-caption-01-font-weight: 400;
  --cds-caption-01-line-height: 1.33333;
  --cds-caption-01-letter-spacing: 0.32px;
  --cds-caption-02-font-size: 0.875rem;
  --cds-caption-02-font-weight: 400;
  --cds-caption-02-line-height: 1.28572;
  --cds-caption-02-letter-spacing: 0.32px;
  --cds-label-01-font-size: 0.75rem;
  --cds-label-01-font-weight: 400;
  --cds-label-01-line-height: 1.33333;
  --cds-label-01-letter-spacing: 0.32px;
  --cds-label-02-font-size: 0.875rem;
  --cds-label-02-font-weight: 400;
  --cds-label-02-line-height: 1.28572;
  --cds-label-02-letter-spacing: 0.16px;
  --cds-helper-text-01-font-size: 0.75rem;
  --cds-helper-text-01-line-height: 1.33333;
  --cds-helper-text-01-letter-spacing: 0.32px;
  --cds-helper-text-02-font-size: 0.875rem;
  --cds-helper-text-02-line-height: 1.28572;
  --cds-helper-text-02-letter-spacing: 0.16px;
  --cds-body-short-01-font-size: 0.875rem;
  --cds-body-short-01-font-weight: 400;
  --cds-body-short-01-line-height: 1.28572;
  --cds-body-short-01-letter-spacing: 0.16px;
  --cds-body-long-01-font-size: 0.875rem;
  --cds-body-long-01-font-weight: 400;
  --cds-body-long-01-line-height: 1.42857;
  --cds-body-long-01-letter-spacing: 0.16px;
  --cds-body-short-02-font-size: 1rem;
  --cds-body-short-02-font-weight: 400;
  --cds-body-short-02-line-height: 1.375;
  --cds-body-short-02-letter-spacing: 0;
  --cds-body-long-02-font-size: 1rem;
  --cds-body-long-02-font-weight: 400;
  --cds-body-long-02-line-height: 1.5;
  --cds-body-long-02-letter-spacing: 0;
  --cds-code-01-font-family: 'IBM Plex Mono', 'Menlo', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', Courier, monospace;
  --cds-code-01-font-size: 0.75rem;
  --cds-code-01-font-weight: 400;
  --cds-code-01-line-height: 1.33333;
  --cds-code-01-letter-spacing: 0.32px;
  --cds-code-02-font-family: 'IBM Plex Mono', 'Menlo', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', Courier, monospace;
  --cds-code-02-font-size: 0.875rem;
  --cds-code-02-font-weight: 400;
  --cds-code-02-line-height: 1.42857;
  --cds-code-02-letter-spacing: 0.32px;
  --cds-heading-01-font-size: 0.875rem;
  --cds-heading-01-font-weight: 600;
  --cds-heading-01-line-height: 1.42857;
  --cds-heading-01-letter-spacing: 0.16px;
  --cds-productive-heading-01-font-size: 0.875rem;
  --cds-productive-heading-01-font-weight: 600;
  --cds-productive-heading-01-line-height: 1.28572;
  --cds-productive-heading-01-letter-spacing: 0.16px;
  --cds-heading-02-font-size: 1rem;
  --cds-heading-02-font-weight: 600;
  --cds-heading-02-line-height: 1.5;
  --cds-heading-02-letter-spacing: 0;
  --cds-productive-heading-02-font-size: 1rem;
  --cds-productive-heading-02-font-weight: 600;
  --cds-productive-heading-02-line-height: 1.375;
  --cds-productive-heading-02-letter-spacing: 0;
  --cds-productive-heading-03-font-size: 1.25rem;
  --cds-productive-heading-03-font-weight: 400;
  --cds-productive-heading-03-line-height: 1.4;
  --cds-productive-heading-03-letter-spacing: 0;
  --cds-productive-heading-04-font-size: 1.75rem;
  --cds-productive-heading-04-font-weight: 400;
  --cds-productive-heading-04-line-height: 1.28572;
  --cds-productive-heading-04-letter-spacing: 0;
  --cds-productive-heading-05-font-size: 2rem;
  --cds-productive-heading-05-font-weight: 400;
  --cds-productive-heading-05-line-height: 1.25;
  --cds-productive-heading-05-letter-spacing: 0;
  --cds-productive-heading-06-font-size: 2.625rem;
  --cds-productive-heading-06-font-weight: 300;
  --cds-productive-heading-06-line-height: 1.199;
  --cds-productive-heading-06-letter-spacing: 0;
  --cds-productive-heading-07-font-size: 3.375rem;
  --cds-productive-heading-07-font-weight: 300;
  --cds-productive-heading-07-line-height: 1.199;
  --cds-productive-heading-07-letter-spacing: 0;
  --cds-expressive-heading-01-font-size: 0.875rem;
  --cds-expressive-heading-01-font-weight: 600;
  --cds-expressive-heading-01-line-height: 1.25;
  --cds-expressive-heading-01-letter-spacing: 0.16px;
  --cds-expressive-heading-02-font-size: 1rem;
  --cds-expressive-heading-02-font-weight: 600;
  --cds-expressive-heading-02-line-height: 1.5;
  --cds-expressive-heading-02-letter-spacing: 0;
  --cds-expressive-heading-03-font-size: 1.25rem;
  --cds-expressive-heading-03-font-weight: 400;
  --cds-expressive-heading-03-line-height: 1.4;
  --cds-expressive-heading-03-letter-spacing: 0;
  --cds-expressive-heading-04-font-size: 1.75rem;
  --cds-expressive-heading-04-font-weight: 400;
  --cds-expressive-heading-04-line-height: 1.28572;
  --cds-expressive-heading-04-letter-spacing: 0;
  --cds-expressive-heading-05-font-size: 2rem;
  --cds-expressive-heading-05-font-weight: 400;
  --cds-expressive-heading-05-line-height: 1.25;
  --cds-expressive-heading-05-letter-spacing: 0;
  --cds-expressive-heading-06-font-size: 2rem;
  --cds-expressive-heading-06-font-weight: 600;
  --cds-expressive-heading-06-line-height: 1.25;
  --cds-expressive-heading-06-letter-spacing: 0;
  --cds-expressive-paragraph-01-font-size: 1.5rem;
  --cds-expressive-paragraph-01-font-weight: 300;
  --cds-expressive-paragraph-01-line-height: 1.334;
  --cds-expressive-paragraph-01-letter-spacing: 0;
  --cds-quotation-01-font-family: 'IBM Plex Serif', 'Georgia', Times, serif;
  --cds-quotation-01-font-size: 1.25rem;
  --cds-quotation-01-font-weight: 400;
  --cds-quotation-01-line-height: 1.3;
  --cds-quotation-01-letter-spacing: 0;
  --cds-quotation-02-font-family: 'IBM Plex Serif', 'Georgia', Times, serif;
  --cds-quotation-02-font-size: 2rem;
  --cds-quotation-02-font-weight: 300;
  --cds-quotation-02-line-height: 1.25;
  --cds-quotation-02-letter-spacing: 0;
  --cds-display-01-font-size: 2.625rem;
  --cds-display-01-font-weight: 300;
  --cds-display-01-line-height: 1.19;
  --cds-display-01-letter-spacing: 0;
  --cds-display-02-font-size: 2.625rem;
  --cds-display-02-font-weight: 600;
  --cds-display-02-line-height: 1.19;
  --cds-display-02-letter-spacing: 0;
  --cds-display-03-font-size: 2.625rem;
  --cds-display-03-font-weight: 300;
  --cds-display-03-line-height: 1.19;
  --cds-display-03-letter-spacing: 0;
  --cds-display-04-font-size: 2.625rem;
  --cds-display-04-font-weight: 600;
  --cds-display-04-line-height: 1.19;
  --cds-display-04-letter-spacing: 0;
  --cds-legal-01-font-size: 0.75rem;
  --cds-legal-01-font-weight: 400;
  --cds-legal-01-line-height: 1.33333;
  --cds-legal-01-letter-spacing: 0.32px;
  --cds-legal-02-font-size: 0.875rem;
  --cds-legal-02-font-weight: 400;
  --cds-legal-02-line-height: 1.28572;
  --cds-legal-02-letter-spacing: 0.16px;
  --cds-body-compact-01-font-size: 0.875rem;
  --cds-body-compact-01-font-weight: 400;
  --cds-body-compact-01-line-height: 1.28572;
  --cds-body-compact-01-letter-spacing: 0.16px;
  --cds-body-compact-02-font-size: 1rem;
  --cds-body-compact-02-font-weight: 400;
  --cds-body-compact-02-line-height: 1.375;
  --cds-body-compact-02-letter-spacing: 0;
  --cds-body-01-font-size: 0.875rem;
  --cds-body-01-font-weight: 400;
  --cds-body-01-line-height: 1.42857;
  --cds-body-01-letter-spacing: 0.16px;
  --cds-body-02-font-size: 1rem;
  --cds-body-02-font-weight: 400;
  --cds-body-02-line-height: 1.5;
  --cds-body-02-letter-spacing: 0;
  --cds-heading-compact-01-font-size: 0.875rem;
  --cds-heading-compact-01-font-weight: 600;
  --cds-heading-compact-01-line-height: 1.28572;
  --cds-heading-compact-01-letter-spacing: 0.16px;
  --cds-heading-compact-02-font-size: 1rem;
  --cds-heading-compact-02-font-weight: 600;
  --cds-heading-compact-02-line-height: 1.375;
  --cds-heading-compact-02-letter-spacing: 0;
  --cds-heading-03-font-size: 1.25rem;
  --cds-heading-03-font-weight: 400;
  --cds-heading-03-line-height: 1.4;
  --cds-heading-03-letter-spacing: 0;
  --cds-heading-04-font-size: 1.75rem;
  --cds-heading-04-font-weight: 400;
  --cds-heading-04-line-height: 1.28572;
  --cds-heading-04-letter-spacing: 0;
  --cds-heading-05-font-size: 2rem;
  --cds-heading-05-font-weight: 400;
  --cds-heading-05-line-height: 1.25;
  --cds-heading-05-letter-spacing: 0;
  --cds-heading-06-font-size: 2.625rem;
  --cds-heading-06-font-weight: 300;
  --cds-heading-06-line-height: 1.199;
  --cds-heading-06-letter-spacing: 0;
  --cds-heading-07-font-size: 3.375rem;
  --cds-heading-07-font-weight: 300;
  --cds-heading-07-line-height: 1.199;
  --cds-heading-07-letter-spacing: 0;
  --cds-fluid-heading-03-font-size: 1.25rem;
  --cds-fluid-heading-03-font-weight: 400;
  --cds-fluid-heading-03-line-height: 1.4;
  --cds-fluid-heading-03-letter-spacing: 0;
  --cds-fluid-heading-04-font-size: 1.75rem;
  --cds-fluid-heading-04-font-weight: 400;
  --cds-fluid-heading-04-line-height: 1.28572;
  --cds-fluid-heading-04-letter-spacing: 0;
  --cds-fluid-heading-05-font-size: 2rem;
  --cds-fluid-heading-05-font-weight: 400;
  --cds-fluid-heading-05-line-height: 1.25;
  --cds-fluid-heading-05-letter-spacing: 0;
  --cds-fluid-heading-06-font-size: 2rem;
  --cds-fluid-heading-06-font-weight: 600;
  --cds-fluid-heading-06-line-height: 1.25;
  --cds-fluid-heading-06-letter-spacing: 0;
  --cds-fluid-paragraph-01-font-size: 1.5rem;
  --cds-fluid-paragraph-01-font-weight: 300;
  --cds-fluid-paragraph-01-line-height: 1.334;
  --cds-fluid-paragraph-01-letter-spacing: 0;
  --cds-fluid-quotation-01-font-family: 'IBM Plex Serif', 'Georgia', Times, serif;
  --cds-fluid-quotation-01-font-size: 1.25rem;
  --cds-fluid-quotation-01-font-weight: 400;
  --cds-fluid-quotation-01-line-height: 1.3;
  --cds-fluid-quotation-01-letter-spacing: 0;
  --cds-fluid-quotation-02-font-family: 'IBM Plex Serif', 'Georgia', Times, serif;
  --cds-fluid-quotation-02-font-size: 2rem;
  --cds-fluid-quotation-02-font-weight: 300;
  --cds-fluid-quotation-02-line-height: 1.25;
  --cds-fluid-quotation-02-letter-spacing: 0;
  --cds-fluid-display-01-font-size: 2.625rem;
  --cds-fluid-display-01-font-weight: 300;
  --cds-fluid-display-01-line-height: 1.19;
  --cds-fluid-display-01-letter-spacing: 0;
  --cds-fluid-display-02-font-size: 2.625rem;
  --cds-fluid-display-02-font-weight: 600;
  --cds-fluid-display-02-line-height: 1.19;
  --cds-fluid-display-02-letter-spacing: 0;
  --cds-fluid-display-03-font-size: 2.625rem;
  --cds-fluid-display-03-font-weight: 300;
  --cds-fluid-display-03-line-height: 1.19;
  --cds-fluid-display-03-letter-spacing: 0;
  --cds-fluid-display-04-font-size: 2.625rem;
  --cds-fluid-display-04-font-weight: 600;
  --cds-fluid-display-04-line-height: 1.19;
  --cds-fluid-display-04-letter-spacing: 0;
  --cds-spacing-01: 0.125rem;
  --cds-spacing-02: 0.25rem;
  --cds-spacing-03: 0.5rem;
  --cds-spacing-04: 0.75rem;
  --cds-spacing-05: 1rem;
  --cds-spacing-06: 1.5rem;
  --cds-spacing-07: 2rem;
  --cds-spacing-08: 2.5rem;
  --cds-spacing-09: 3rem;
  --cds-spacing-10: 4rem;
  --cds-spacing-11: 5rem;
  --cds-spacing-12: 6rem;
  --cds-spacing-13: 10rem;
  --cds-fluid-spacing-01: 0;
  --cds-fluid-spacing-02: 2vw;
  --cds-fluid-spacing-03: 5vw;
  --cds-fluid-spacing-04: 10vw;
  --cds-layout-01: 1rem;
  --cds-layout-02: 1.5rem;
  --cds-layout-03: 2rem;
  --cds-layout-04: 3rem;
  --cds-layout-05: 4rem;
  --cds-layout-06: 6rem;
  --cds-layout-07: 10rem;
  --cds-container-01: 1.5rem;
  --cds-container-02: 2rem;
  --cds-container-03: 2.5rem;
  --cds-container-04: 3rem;
  --cds-container-05: 4rem;
  --cds-size-xsmall: 1.5rem;
  --cds-size-small: 2rem;
  --cds-size-medium: 2.5rem;
  --cds-size-large: 3rem;
  --cds-size-xlarge: 4rem;
  --cds-size-2XLarge: 5rem;
  --cds-icon-size-01: 1rem;
  --cds-icon-size-02: 1.25rem;
  --interactive-01: var(--cds-interactive-01, #0f62fe);
  --interactive-02: var(--cds-interactive-02, #6f6f6f);
  --interactive-03: var(--cds-interactive-03, #ffffff);
  --interactive-04: var(--cds-interactive-04, #4589ff);
  --ui-background: var(--cds-ui-background, #161616);
  --ui-01: var(--cds-ui-01, #262626);
  --ui-02: var(--cds-ui-02, #393939);
  --ui-03: var(--cds-ui-03, #393939);
  --ui-04: var(--cds-ui-04, #6f6f6f);
  --ui-05: var(--cds-ui-05, #f4f4f4);
  --text-01: var(--cds-text-01, #f4f4f4);
  --text-02: var(--cds-text-02, #c6c6c6);
  --text-03: var(--cds-text-03, #6f6f6f);
  --text-04: var(--cds-text-04, #ffffff);
  --text-05: var(--cds-text-05, #8d8d8d);
  --text-error: var(--cds-text-error, #ff8389);
  --icon-01: var(--cds-icon-01, #f4f4f4);
  --icon-02: var(--cds-icon-02, #c6c6c6);
  --icon-03: var(--cds-icon-03, #ffffff);
  --link-01: var(--cds-link-01, #78a9ff);
  --link-02: var(--cds-link-02, #a6c8ff);
  --inverse-link: var(--cds-inverse-link, #0f62fe);
  --field-01: var(--cds-field-01, #262626);
  --field-02: var(--cds-field-02, #393939);
  --inverse-01: var(--cds-inverse-01, #161616);
  --inverse-02: var(--cds-inverse-02, #f4f4f4);
  --support-01: var(--cds-support-01, #fa4d56);
  --support-02: var(--cds-support-02, #42be65);
  --support-03: var(--cds-support-03, #f1c21b);
  --support-04: var(--cds-support-04, #4589ff);
  --inverse-support-01: var(--cds-inverse-support-01, #da1e28);
  --inverse-support-02: var(--cds-inverse-support-02, #24a148);
  --inverse-support-03: var(--cds-inverse-support-03, #f1c21b);
  --inverse-support-04: var(--cds-inverse-support-04, #0f62fe);
  --overlay-01: var(--cds-overlay-01, rgba(0, 0, 0, 0.65));
  --danger-01: var(--cds-danger-01, #da1e28);
  --danger-02: var(--cds-danger-02, #fa4d56);
  --focus: var(--cds-focus, #ffffff);
  --inverse-focus-ui: var(--cds-inverse-focus-ui, #0f62fe);
  --hover-primary: var(--cds-hover-primary, #0353e9);
  --active-primary: var(--cds-active-primary, #002d9c);
  --hover-primary-text: var(--cds-hover-primary-text, #a6c8ff);
  --hover-secondary: var(--cds-hover-secondary, #606060);
  --active-secondary: var(--cds-active-secondary, #393939);
  --hover-tertiary: var(--cds-hover-tertiary, #f4f4f4);
  --active-tertiary: var(--cds-active-tertiary, #c6c6c6);
  --hover-ui: var(--cds-hover-ui, #353535);
  --hover-light-ui: var(--cds-hover-light-ui, #4c4c4c);
  --active-ui: var(--cds-active-ui, #525252);
  --active-light-ui: var(--cds-active-light-ui, #6f6f6f);
  --selected-ui: var(--cds-selected-ui, #393939);
  --selected-light-ui: var(--cds-selected-light-ui, #525252);
  --inverse-hover-ui: var(--cds-inverse-hover-ui, #e5e5e5);
  --hover-selected-ui: var(--cds-hover-selected-ui, #4c4c4c);
  --hover-danger: var(--cds-hover-danger, #b81921);
  --active-danger: var(--cds-active-danger, #750e13);
  --hover-row: var(--cds-hover-row, #353535);
  --visited-link: var(--cds-visited-link, #be95ff);
  --disabled-01: var(--cds-disabled-01, #262626);
  --disabled-02: var(--cds-disabled-02, #525252);
  --disabled-03: var(--cds-disabled-03, #8d8d8d);
  --highlight: var(--cds-highlight, #002d9c);
  --decorative-01: var(--cds-decorative-01, #525252);
  --button-separator: var(--cds-button-separator, #161616);
  --skeleton-01: var(--cds-skeleton-01, #353535);
  --skeleton-02: var(--cds-skeleton-02, #525252);
  --background: var(--cds-background, #161616);
  --layer: var(--cds-layer, #262626);
  --layer-accent: var(--cds-layer-accent, #393939);
  --layer-accent-active: var(--cds-layer-accent-active, #6f6f6f);
  --layer-accent-hover: var(--cds-layer-accent-hover, #474747);
  --field: var(--cds-field, #262626);
  --background-inverse: var(--cds-background-inverse, #f4f4f4);
  --background-brand: var(--cds-background-brand, #0f62fe);
  --interactive: var(--cds-interactive, #4589ff);
  --border-subtle: var(--cds-border-subtle, #393939);
  --border-strong: var(--cds-border-strong, #6f6f6f);
  --border-inverse: var(--cds-border-inverse, #f4f4f4);
  --border-interactive: var(--cds-border-interactive, #4589ff);
  --text-primary: var(--cds-text-primary, #f4f4f4);
  --text-secondary: var(--cds-text-secondary, #c6c6c6);
  --text-placeholder: var(--cds-text-placeholder, #6f6f6f);
  --text-helper: var(--cds-text-helper, #8d8d8d);
  --text-on-color: var(--cds-text-on-color, #ffffff);
  --text-inverse: var(--cds-text-inverse, #161616);
  --link-primary: var(--cds-link-primary, #78a9ff);
  --link-secondary: var(--cds-link-secondary, #a6c8ff);
  --link-visited: var(--cds-link-visited, #be95ff);
  --link-inverse: var(--cds-link-inverse, #0f62fe);
  --icon-primary: var(--cds-icon-primary, #f4f4f4);
  --icon-secondary: var(--cds-icon-secondary, #c6c6c6);
  --icon-on-color: var(--cds-icon-on-color, #ffffff);
  --icon-inverse: var(--cds-icon-inverse, #161616);
  --support-error: var(--cds-support-error, #fa4d56);
  --support-success: var(--cds-support-success, #42be65);
  --support-warning: var(--cds-support-warning, #f1c21b);
  --support-info: var(--cds-support-info, #4589ff);
  --support-error-inverse: var(--cds-support-error-inverse, #da1e28);
  --support-success-inverse: var(--cds-support-success-inverse, #24a148);
  --support-warning-inverse: var(--cds-support-warning-inverse, #f1c21b);
  --support-info-inverse: var(--cds-support-info-inverse, #0f62fe);
  --overlay: var(--cds-overlay, rgba(0, 0, 0, 0.65));
  --toggle-off: var(--cds-toggle-off, #6f6f6f);
  --shadow: var(--cds-shadow, rgba(0, 0, 0, 0.8));
  --button-primary: var(--cds-button-primary, #0f62fe);
  --button-secondary: var(--cds-button-secondary, #6f6f6f);
  --button-tertiary: var(--cds-button-tertiary, #ffffff);
  --button-danger-primary: var(--cds-button-danger-primary, #da1e28);
  --button-danger-secondary: var(--cds-button-danger-secondary, #fa4d56);
  --background-active: var(--cds-background-active, #525252);
  --layer-active: var(--cds-layer-active, #525252);
  --button-danger-active: var(--cds-button-danger-active, #750e13);
  --button-primary-active: var(--cds-button-primary-active, #002d9c);
  --button-secondary-active: var(--cds-button-secondary-active, #393939);
  --button-tertiary-active: var(--cds-button-tertiary-active, #c6c6c6);
  --focus-inset: var(--cds-focus-inset, #161616);
  --focus-inverse: var(--cds-focus-inverse, #0f62fe);
  --background-hover: var(--cds-background-hover, #353535);
  --layer-hover: var(--cds-layer-hover, #353535);
  --field-hover: var(--cds-field-hover, #353535);
  --background-inverse-hover: var(--cds-background-inverse-hover, #e5e5e5);
  --link-primary-hover: var(--cds-link-primary-hover, #a6c8ff);
  --button-danger-hover: var(--cds-button-danger-hover, #b81921);
  --button-primary-hover: var(--cds-button-primary-hover, #0353e9);
  --button-secondary-hover: var(--cds-button-secondary-hover, #606060);
  --button-tertiary-hover: var(--cds-button-tertiary-hover, #f4f4f4);
  --background-selected: var(--cds-background-selected, #393939);
  --background-selected-hover: var(--cds-background-selected-hover, #4c4c4c);
  --layer-selected: var(--cds-layer-selected, #393939);
  --layer-selected-hover: var(--cds-layer-selected-hover, #4c4c4c);
  --layer-selected-inverse: var(--cds-layer-selected-inverse, #f4f4f4);
  --border-subtle-selected: var(--cds-border-subtle-selected, #525252);
  --layer-disabled: var(--cds-layer-disabled, #262626);
  --field-disabled: var(--cds-field-disabled, #262626);
  --border-disabled: var(--cds-border-disabled, #262626);
  --text-disabled: var(--cds-text-disabled, #525252);
  --button-disabled: var(--cds-button-disabled, #525252);
  --icon-disabled: var(--cds-icon-disabled, #525252);
  --text-on-color-disabled: var(--cds-text-on-color-disabled, #8d8d8d);
  --icon-on-color-disabled: var(--cds-icon-on-color-disabled, #8d8d8d);
  --layer-selected-disabled: var(--cds-layer-selected-disabled, #8d8d8d);
  --skeleton-background: var(--cds-skeleton-background, #353535);
  --skeleton-element: var(--cds-skeleton-element, #525252);
  --brand-01: var(--cds-brand-01, #0f62fe);
  --brand-02: var(--cds-brand-02, #6f6f6f);
  --brand-03: var(--cds-brand-03, #ffffff);
  --active-01: var(--cds-active-01, #525252);
  --hover-field: var(--cds-hover-field, #353535);
  --danger: var(--cds-danger, #da1e28); }

html {
  font-size: 16px; }

body {
  margin: 0; }

.bx-container {
  width: 100vw;
  max-width: 120rem;
  box-sizing: border-box;
  margin-right: auto;
  margin-left: auto;
  overflow-x: hidden; }

.bx-container--left {
  margin-left: 0; }

.bx-container--right {
  margin-right: 0; }

.bx-grid {
  position: relative;
  display: grid;
  box-sizing: border-box;
  flex-wrap: wrap;
  align-items: flex-start; }
  .bx-grid > * {
    box-sizing: border-box; }
    .bx-grid > * :last-child,
    .bx-grid > * :last-child > :last-child,
    .bx-grid > * :last-child > :last-child > :last-child {
      margin-bottom: 0; }

[class*='bx-padding'] {
  box-sizing: border-box; }

@supports (display: grid) {
  .bx-grid > * {
    height: 100%; } }

:root {
  --bx-height-1: get-fixed-size($i);
  --bx-height-2: get-fixed-size($i);
  --bx-height-3: get-fixed-size($i);
  --bx-height-4: get-fixed-size($i);
  --bx-height-5: get-fixed-size($i);
  --bx-height-6: get-fixed-size($i);
  --bx-height-7: get-fixed-size($i);
  --bx-height-8: get-fixed-size($i);
  --bx-height-9: get-fixed-size($i);
  --bx-height-10: get-fixed-size($i);
  --bx-height-11: get-fixed-size($i);
  --bx-height-12: get-fixed-size($i);
  --bx-height-13: get-fixed-size($i);
  --bx-height-14: get-fixed-size($i);
  --bx-height-15: get-fixed-size($i);
  --bx-height-16: get-fixed-size($i);
  --bx-height-17: get-fixed-size($i);
  --bx-height-18: get-fixed-size($i);
  --bx-height-19: get-fixed-size($i);
  --bx-height-20: get-fixed-size($i);
  --bx-height-21: get-fixed-size($i);
  --bx-height-22: get-fixed-size($i);
  --bx-height-23: get-fixed-size($i);
  --bx-height-24: get-fixed-size($i);
  --bx-height-25: get-fixed-size($i);
  --bx-height-26: get-fixed-size($i);
  --bx-height-27: get-fixed-size($i);
  --bx-height-28: get-fixed-size($i);
  --bx-height-29: get-fixed-size($i); }

.bx-grid__height--sm--0 {
  height: 0;
  min-height: 0; }

.bx-grid__height--sm--1 {
  grid-row: span 1; }

.bx-grid__height--sm--2 {
  grid-row: span 2; }

.bx-grid__height--sm--3 {
  grid-row: span 3; }

.bx-grid__height--sm--4 {
  grid-row: span 4; }

.bx-grid__height--sm--5 {
  grid-row: span 5; }

.bx-grid__height--sm--6 {
  grid-row: span 6; }

.bx-grid__height--sm--7 {
  grid-row: span 7; }

.bx-grid__height--sm--8 {
  grid-row: span 8; }

.bx-grid__height--sm--9 {
  grid-row: span 9; }

.bx-grid__height--sm--10 {
  grid-row: span 10; }

.bx-grid__height--sm--11 {
  grid-row: span 11; }

.bx-grid__height--sm--12 {
  grid-row: span 12; }

.bx-grid__height--sm--13 {
  grid-row: span 13; }

.bx-grid__height--sm--14 {
  grid-row: span 14; }

.bx-grid__height--sm--15 {
  grid-row: span 15; }

.bx-grid__height--sm--16 {
  grid-row: span 16; }

.bx-grid__height--sm--17 {
  grid-row: span 17; }

.bx-grid__height--sm--18 {
  grid-row: span 18; }

.bx-grid__height--sm--19 {
  grid-row: span 19; }

.bx-grid__height--sm--20 {
  grid-row: span 20; }

.bx-grid__height--sm--21 {
  grid-row: span 21; }

.bx-grid__height--sm--22 {
  grid-row: span 22; }

.bx-grid__height--sm--23 {
  grid-row: span 23; }

.bx-grid__height--sm--24 {
  grid-row: span 24; }

.bx-grid__height--sm--25 {
  grid-row: span 25; }

.bx-grid__height--sm--26 {
  grid-row: span 26; }

.bx-grid__height--sm--27 {
  grid-row: span 27; }

.bx-grid__height--sm--28 {
  grid-row: span 28; }

.bx-grid__height--sm--29 {
  grid-row: span 29; }

[class*='bx-grid__col--sm--'] {
  display: block; }

.bx-grid__col--sm--0,
.bx-grid__col--sm--0--only {
  display: none; }

.bx-padding {
  padding: 1rem; }

.bx-padding--bottom {
  padding-bottom: 1rem; }

.bx-padding--left {
  padding-left: 1rem; }

.bx-padding--right {
  padding-right: 1rem; }

.bx-padding--top {
  padding-top: 1rem; }

.bx-padding--horizontal {
  padding-left: 1rem;
  padding-right: 1rem; }

.bx-padding--vertical {
  padding-bottom: 1rem;
  padding-top: 1rem; }

.bx-grid {
  grid-auto-rows: minmax(0.5rem, min-content);
  grid-template-columns: repeat(auto-fill, 25vw); }
  .bx-grid.bx-grid--fixed-columns {
    grid-template-columns: repeat(auto-fill, 5rem); }
  .bx-grid.bx-grid--fluid-rows {
    grid-auto-rows: 25vw; }
  .bx-grid > * {
    grid-column: span 4; }

.bx-grid__col--sm--1 {
  grid-column: span 1; }
  .bx-grid__col--sm--1.bx-grid > *:not([class*='bx-grid__col']) {
    grid-column: span 1; }

.bx-grid--fluid-rows > .bx-grid__height--sm--1 {
  grid-row: span 1; }

.bx-grid__col--sm--2 {
  grid-column: span 2; }
  .bx-grid__col--sm--2.bx-grid > *:not([class*='bx-grid__col']) {
    grid-column: span 2; }

.bx-grid--fluid-rows > .bx-grid__height--sm--2 {
  grid-row: span 2; }

.bx-grid__col--sm--3 {
  grid-column: span 3; }
  .bx-grid__col--sm--3.bx-grid > *:not([class*='bx-grid__col']) {
    grid-column: span 3; }

.bx-grid--fluid-rows > .bx-grid__height--sm--3 {
  grid-row: span 3; }

.bx-grid__col--sm--4 {
  grid-column: span 4; }
  .bx-grid__col--sm--4.bx-grid > *:not([class*='bx-grid__col']) {
    grid-column: span 4; }

.bx-grid--fluid-rows > .bx-grid__height--sm--4 {
  grid-row: span 4; }

@media screen and (min-width: 42rem) {
  .bx-grid__height--md--0 {
    height: 0;
    min-height: 0; }
  .bx-grid__height--md--1 {
    grid-row: span 1; }
  .bx-grid__height--md--2 {
    grid-row: span 2; }
  .bx-grid__height--md--3 {
    grid-row: span 3; }
  .bx-grid__height--md--4 {
    grid-row: span 4; }
  .bx-grid__height--md--5 {
    grid-row: span 5; }
  .bx-grid__height--md--6 {
    grid-row: span 6; }
  .bx-grid__height--md--7 {
    grid-row: span 7; }
  .bx-grid__height--md--8 {
    grid-row: span 8; }
  .bx-grid__height--md--9 {
    grid-row: span 9; }
  .bx-grid__height--md--10 {
    grid-row: span 10; }
  .bx-grid__height--md--11 {
    grid-row: span 11; }
  .bx-grid__height--md--12 {
    grid-row: span 12; }
  .bx-grid__height--md--13 {
    grid-row: span 13; }
  .bx-grid__height--md--14 {
    grid-row: span 14; }
  .bx-grid__height--md--15 {
    grid-row: span 15; }
  .bx-grid__height--md--16 {
    grid-row: span 16; }
  .bx-grid__height--md--17 {
    grid-row: span 17; }
  .bx-grid__height--md--18 {
    grid-row: span 18; }
  .bx-grid__height--md--19 {
    grid-row: span 19; }
  .bx-grid__height--md--20 {
    grid-row: span 20; }
  .bx-grid__height--md--21 {
    grid-row: span 21; }
  .bx-grid__height--md--22 {
    grid-row: span 22; }
  .bx-grid__height--md--23 {
    grid-row: span 23; }
  .bx-grid__height--md--24 {
    grid-row: span 24; }
  .bx-grid__height--md--25 {
    grid-row: span 25; }
  .bx-grid__height--md--26 {
    grid-row: span 26; }
  .bx-grid__height--md--27 {
    grid-row: span 27; }
  .bx-grid__height--md--28 {
    grid-row: span 28; }
  .bx-grid__height--md--29 {
    grid-row: span 29; }
  .bx-grid__col--sm--0--only {
    display: block; }
  [class*='bx-grid__col--md--'] {
    display: block; }
  .bx-grid__col--md--0,
  .bx-grid__col--md--0--only {
    display: none; }
  .bx-padding {
    padding: 1rem; }
  .bx-padding--bottom {
    padding-bottom: 1rem; }
  .bx-padding--left {
    padding-left: 1rem; }
  .bx-padding--right {
    padding-right: 1rem; }
  .bx-padding--top {
    padding-top: 1rem; }
  .bx-padding--horizontal {
    padding-left: 1rem;
    padding-right: 1rem; }
  .bx-padding--vertical {
    padding-bottom: 1rem;
    padding-top: 1rem; }
  .bx-grid {
    grid-template-columns: repeat(auto-fill, calc((100vw - 2rem) * 0.125)); }
    .bx-grid.bx-grid--fixed-columns {
      grid-template-columns: repeat(auto-fill, 5.25rem); }
    .bx-grid.bx-grid--fluid-rows {
      grid-auto-rows: calc((100vw - 2rem) * 0.125); }
    .bx-grid > * {
      grid-column: span 8; }
  .bx-grid__col--sm--1 {
    grid-column: span 2; }
    .bx-grid__col--sm--1.bx-grid > *:not([class*='bx-grid__col']) {
      grid-column: span 1; }
  .bx-grid--fluid-rows > .bx-grid__height--sm--1 {
    grid-row: span 1; }
  .bx-grid__col--sm--2 {
    grid-column: span 4; }
    .bx-grid__col--sm--2.bx-grid > *:not([class*='bx-grid__col']) {
      grid-column: span 2; }
  .bx-grid--fluid-rows > .bx-grid__height--sm--2 {
    grid-row: span 2; }
  .bx-grid__col--sm--3 {
    grid-column: span 6; }
    .bx-grid__col--sm--3.bx-grid > *:not([class*='bx-grid__col']) {
      grid-column: span 3; }
  .bx-grid--fluid-rows > .bx-grid__height--sm--3 {
    grid-row: span 3; }
  .bx-grid__col--sm--4 {
    grid-column: span 8; }
    .bx-grid__col--sm--4.bx-grid > *:not([class*='bx-grid__col']) {
      grid-column: span 4; }
  .bx-grid--fluid-rows > .bx-grid__height--sm--4 {
    grid-row: span 4; }
  .bx-grid__col--md--1 {
    grid-column: span 1; }
    .bx-grid__col--md--1.bx-grid > *:not([class*='bx-grid__col']) {
      grid-column: span 1; }
  .bx-grid--fluid-rows > .bx-grid__height--md--1 {
    grid-row: span 1; }
  .bx-grid__col--md--2 {
    grid-column: span 2; }
    .bx-grid__col--md--2.bx-grid > *:not([class*='bx-grid__col']) {
      grid-column: span 2; }
  .bx-grid--fluid-rows > .bx-grid__height--md--2 {
    grid-row: span 2; }
  .bx-grid__col--md--3 {
    grid-column: span 3; }
    .bx-grid__col--md--3.bx-grid > *:not([class*='bx-grid__col']) {
      grid-column: span 3; }
  .bx-grid--fluid-rows > .bx-grid__height--md--3 {
    grid-row: span 3; }
  .bx-grid__col--md--4 {
    grid-column: span 4; }
    .bx-grid__col--md--4.bx-grid > *:not([class*='bx-grid__col']) {
      grid-column: span 4; }
  .bx-grid--fluid-rows > .bx-grid__height--md--4 {
    grid-row: span 4; }
  .bx-grid__col--md--5 {
    grid-column: span 5; }
    .bx-grid__col--md--5.bx-grid > *:not([class*='bx-grid__col']) {
      grid-column: span 5; }
  .bx-grid--fluid-rows > .bx-grid__height--md--5 {
    grid-row: span 5; }
  .bx-grid__col--md--6 {
    grid-column: span 6; }
    .bx-grid__col--md--6.bx-grid > *:not([class*='bx-grid__col']) {
      grid-column: span 6; }
  .bx-grid--fluid-rows > .bx-grid__height--md--6 {
    grid-row: span 6; }
  .bx-grid__col--md--7 {
    grid-column: span 7; }
    .bx-grid__col--md--7.bx-grid > *:not([class*='bx-grid__col']) {
      grid-column: span 7; }
  .bx-grid--fluid-rows > .bx-grid__height--md--7 {
    grid-row: span 7; }
  .bx-grid__col--md--8 {
    grid-column: span 8; }
    .bx-grid__col--md--8.bx-grid > *:not([class*='bx-grid__col']) {
      grid-column: span 8; }
  .bx-grid--fluid-rows > .bx-grid__height--md--8 {
    grid-row: span 8; }
  .bx-container {
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem; }
  .bx-container--left {
    margin-left: 0; }
  .bx-container--right {
    margin-right: 0; }
  .bx-container__bleed--md,
  .bx-container__bleed--md--left,
  .bx-container__break--md,
  .bx-container__break--md--left {
    margin-left: -1rem; }
  .bx-container__bleed--md,
  .bx-container__bleed--md--right,
  .bx-container__break--md,
  .bx-container__break--md--right {
    margin-right: -1rem; }
  .bx-container__bleed--md,
  .bx-container__bleed--md--left {
    padding-left: 1rem; }
    .bx-container__bleed--md.bx-padding, .bx-container__bleed--md.bx-padding--horizontal, .bx-container__bleed--md.bx-padding--left,
    .bx-container__bleed--md--left.bx-padding,
    .bx-container__bleed--md--left.bx-padding--horizontal,
    .bx-container__bleed--md--left.bx-padding--left {
      padding-left: calc(1rem + 1rem); }
  .bx-container__bleed--md,
  .bx-container__bleed--md--right {
    padding-right: 1rem; }
    .bx-container__bleed--md.bx-padding, .bx-container__bleed--md.bx-padding--horizontal, .bx-container__bleed--md.bx-padding--right,
    .bx-container__bleed--md--right.bx-padding,
    .bx-container__bleed--md--right.bx-padding--horizontal,
    .bx-container__bleed--md--right.bx-padding--right {
      padding-right: calc(1rem + 1rem); } }

@media screen and (min-width: 66rem) {
  .bx-grid__height--lg--0 {
    height: 0;
    min-height: 0; }
  .bx-grid__height--lg--1 {
    grid-row: span 1; }
  .bx-grid__height--lg--2 {
    grid-row: span 2; }
  .bx-grid__height--lg--3 {
    grid-row: span 3; }
  .bx-grid__height--lg--4 {
    grid-row: span 4; }
  .bx-grid__height--lg--5 {
    grid-row: span 5; }
  .bx-grid__height--lg--6 {
    grid-row: span 6; }
  .bx-grid__height--lg--7 {
    grid-row: span 7; }
  .bx-grid__height--lg--8 {
    grid-row: span 8; }
  .bx-grid__height--lg--9 {
    grid-row: span 9; }
  .bx-grid__height--lg--10 {
    grid-row: span 10; }
  .bx-grid__height--lg--11 {
    grid-row: span 11; }
  .bx-grid__height--lg--12 {
    grid-row: span 12; }
  .bx-grid__height--lg--13 {
    grid-row: span 13; }
  .bx-grid__height--lg--14 {
    grid-row: span 14; }
  .bx-grid__height--lg--15 {
    grid-row: span 15; }
  .bx-grid__height--lg--16 {
    grid-row: span 16; }
  .bx-grid__height--lg--17 {
    grid-row: span 17; }
  .bx-grid__height--lg--18 {
    grid-row: span 18; }
  .bx-grid__height--lg--19 {
    grid-row: span 19; }
  .bx-grid__height--lg--20 {
    grid-row: span 20; }
  .bx-grid__height--lg--21 {
    grid-row: span 21; }
  .bx-grid__height--lg--22 {
    grid-row: span 22; }
  .bx-grid__height--lg--23 {
    grid-row: span 23; }
  .bx-grid__height--lg--24 {
    grid-row: span 24; }
  .bx-grid__height--lg--25 {
    grid-row: span 25; }
  .bx-grid__height--lg--26 {
    grid-row: span 26; }
  .bx-grid__height--lg--27 {
    grid-row: span 27; }
  .bx-grid__height--lg--28 {
    grid-row: span 28; }
  .bx-grid__height--lg--29 {
    grid-row: span 29; }
  .bx-grid__col--sm--0--only {
    display: block; }
  .bx-grid__col--md--0--only {
    display: block; }
  [class*='bx-grid__col--lg--'] {
    display: block; }
  .bx-grid__col--lg--0,
  .bx-grid__col--lg--0--only {
    display: none; }
  .bx-padding {
    padding: 1rem; }
  .bx-padding--bottom {
    padding-bottom: 1rem; }
  .bx-padding--left {
    padding-left: 1rem; }
  .bx-padding--right {
    padding-right: 1rem; }
  .bx-padding--top {
    padding-top: 1rem; }
  .bx-padding--horizontal {
    padding-left: 1rem;
    padding-right: 1rem; }
  .bx-padding--vertical {
    padding-bottom: 1rem;
    padding-top: 1rem; }
  .bx-grid {
    grid-template-columns: repeat(auto-fill, calc((100vw - 2rem) * 0.0625)); }
    .bx-grid.bx-grid--fixed-columns {
      grid-template-columns: repeat(auto-fill, 4.125rem); }
    .bx-grid.bx-grid--fluid-rows {
      grid-auto-rows: calc((100vw - 2rem) * 0.0625); }
    .bx-grid > * {
      grid-column: span 16; }
  .bx-grid__col--sm--1 {
    grid-column: span 4; }
    .bx-grid__col--sm--1.bx-grid > *:not([class*='bx-grid__col']) {
      grid-column: span 1; }
  .bx-grid--fluid-rows > .bx-grid__height--sm--1 {
    grid-row: span 1; }
  .bx-grid__col--sm--2 {
    grid-column: span 8; }
    .bx-grid__col--sm--2.bx-grid > *:not([class*='bx-grid__col']) {
      grid-column: span 2; }
  .bx-grid--fluid-rows > .bx-grid__height--sm--2 {
    grid-row: span 2; }
  .bx-grid__col--sm--3 {
    grid-column: span 12; }
    .bx-grid__col--sm--3.bx-grid > *:not([class*='bx-grid__col']) {
      grid-column: span 3; }
  .bx-grid--fluid-rows > .bx-grid__height--sm--3 {
    grid-row: span 3; }
  .bx-grid__col--sm--4 {
    grid-column: span 16; }
    .bx-grid__col--sm--4.bx-grid > *:not([class*='bx-grid__col']) {
      grid-column: span 4; }
  .bx-grid--fluid-rows > .bx-grid__height--sm--4 {
    grid-row: span 4; }
  .bx-grid__col--md--1 {
    grid-column: span 2; }
    .bx-grid__col--md--1.bx-grid > *:not([class*='bx-grid__col']) {
      grid-column: span 1; }
  .bx-grid--fluid-rows > .bx-grid__height--md--1 {
    grid-row: span 1; }
  .bx-grid__col--md--2 {
    grid-column: span 4; }
    .bx-grid__col--md--2.bx-grid > *:not([class*='bx-grid__col']) {
      grid-column: span 2; }
  .bx-grid--fluid-rows > .bx-grid__height--md--2 {
    grid-row: span 2; }
  .bx-grid__col--md--3 {
    grid-column: span 6; }
    .bx-grid__col--md--3.bx-grid > *:not([class*='bx-grid__col']) {
      grid-column: span 3; }
  .bx-grid--fluid-rows > .bx-grid__height--md--3 {
    grid-row: span 3; }
  .bx-grid__col--md--4 {
    grid-column: span 8; }
    .bx-grid__col--md--4.bx-grid > *:not([class*='bx-grid__col']) {
      grid-column: span 4; }
  .bx-grid--fluid-rows > .bx-grid__height--md--4 {
    grid-row: span 4; }
  .bx-grid__col--md--5 {
    grid-column: span 10; }
    .bx-grid__col--md--5.bx-grid > *:not([class*='bx-grid__col']) {
      grid-column: span 5; }
  .bx-grid--fluid-rows > .bx-grid__height--md--5 {
    grid-row: span 5; }
  .bx-grid__col--md--6 {
    grid-column: span 12; }
    .bx-grid__col--md--6.bx-grid > *:not([class*='bx-grid__col']) {
      grid-column: span 6; }
  .bx-grid--fluid-rows > .bx-grid__height--md--6 {
    grid-row: span 6; }
  .bx-grid__col--md--7 {
    grid-column: span 14; }
    .bx-grid__col--md--7.bx-grid > *:not([class*='bx-grid__col']) {
      grid-column: span 7; }
  .bx-grid--fluid-rows > .bx-grid__height--md--7 {
    grid-row: span 7; }
  .bx-grid__col--md--8 {
    grid-column: span 16; }
    .bx-grid__col--md--8.bx-grid > *:not([class*='bx-grid__col']) {
      grid-column: span 8; }
  .bx-grid--fluid-rows > .bx-grid__height--md--8 {
    grid-row: span 8; }
  .bx-grid__col--lg--1 {
    grid-column: span 1; }
    .bx-grid__col--lg--1.bx-grid > *:not([class*='bx-grid__col']) {
      grid-column: span 1; }
  .bx-grid--fluid-rows > .bx-grid__height--lg--1 {
    grid-row: span 1; }
  .bx-grid__col--lg--2 {
    grid-column: span 2; }
    .bx-grid__col--lg--2.bx-grid > *:not([class*='bx-grid__col']) {
      grid-column: span 2; }
  .bx-grid--fluid-rows > .bx-grid__height--lg--2 {
    grid-row: span 2; }
  .bx-grid__col--lg--3 {
    grid-column: span 3; }
    .bx-grid__col--lg--3.bx-grid > *:not([class*='bx-grid__col']) {
      grid-column: span 3; }
  .bx-grid--fluid-rows > .bx-grid__height--lg--3 {
    grid-row: span 3; }
  .bx-grid__col--lg--4 {
    grid-column: span 4; }
    .bx-grid__col--lg--4.bx-grid > *:not([class*='bx-grid__col']) {
      grid-column: span 4; }
  .bx-grid--fluid-rows > .bx-grid__height--lg--4 {
    grid-row: span 4; }
  .bx-grid__col--lg--5 {
    grid-column: span 5; }
    .bx-grid__col--lg--5.bx-grid > *:not([class*='bx-grid__col']) {
      grid-column: span 5; }
  .bx-grid--fluid-rows > .bx-grid__height--lg--5 {
    grid-row: span 5; }
  .bx-grid__col--lg--6 {
    grid-column: span 6; }
    .bx-grid__col--lg--6.bx-grid > *:not([class*='bx-grid__col']) {
      grid-column: span 6; }
  .bx-grid--fluid-rows > .bx-grid__height--lg--6 {
    grid-row: span 6; }
  .bx-grid__col--lg--7 {
    grid-column: span 7; }
    .bx-grid__col--lg--7.bx-grid > *:not([class*='bx-grid__col']) {
      grid-column: span 7; }
  .bx-grid--fluid-rows > .bx-grid__height--lg--7 {
    grid-row: span 7; }
  .bx-grid__col--lg--8 {
    grid-column: span 8; }
    .bx-grid__col--lg--8.bx-grid > *:not([class*='bx-grid__col']) {
      grid-column: span 8; }
  .bx-grid--fluid-rows > .bx-grid__height--lg--8 {
    grid-row: span 8; }
  .bx-grid__col--lg--9 {
    grid-column: span 9; }
    .bx-grid__col--lg--9.bx-grid > *:not([class*='bx-grid__col']) {
      grid-column: span 9; }
  .bx-grid--fluid-rows > .bx-grid__height--lg--9 {
    grid-row: span 9; }
  .bx-grid__col--lg--10 {
    grid-column: span 10; }
    .bx-grid__col--lg--10.bx-grid > *:not([class*='bx-grid__col']) {
      grid-column: span 10; }
  .bx-grid--fluid-rows > .bx-grid__height--lg--10 {
    grid-row: span 10; }
  .bx-grid__col--lg--11 {
    grid-column: span 11; }
    .bx-grid__col--lg--11.bx-grid > *:not([class*='bx-grid__col']) {
      grid-column: span 11; }
  .bx-grid--fluid-rows > .bx-grid__height--lg--11 {
    grid-row: span 11; }
  .bx-grid__col--lg--12 {
    grid-column: span 12; }
    .bx-grid__col--lg--12.bx-grid > *:not([class*='bx-grid__col']) {
      grid-column: span 12; }
  .bx-grid--fluid-rows > .bx-grid__height--lg--12 {
    grid-row: span 12; }
  .bx-grid__col--lg--13 {
    grid-column: span 13; }
    .bx-grid__col--lg--13.bx-grid > *:not([class*='bx-grid__col']) {
      grid-column: span 13; }
  .bx-grid--fluid-rows > .bx-grid__height--lg--13 {
    grid-row: span 13; }
  .bx-grid__col--lg--14 {
    grid-column: span 14; }
    .bx-grid__col--lg--14.bx-grid > *:not([class*='bx-grid__col']) {
      grid-column: span 14; }
  .bx-grid--fluid-rows > .bx-grid__height--lg--14 {
    grid-row: span 14; }
  .bx-grid__col--lg--15 {
    grid-column: span 15; }
    .bx-grid__col--lg--15.bx-grid > *:not([class*='bx-grid__col']) {
      grid-column: span 15; }
  .bx-grid--fluid-rows > .bx-grid__height--lg--15 {
    grid-row: span 15; }
  .bx-grid__col--lg--16 {
    grid-column: span 16; }
    .bx-grid__col--lg--16.bx-grid > *:not([class*='bx-grid__col']) {
      grid-column: span 16; }
  .bx-grid--fluid-rows > .bx-grid__height--lg--16 {
    grid-row: span 16; }
  [class^='bx-container__bleed--md'],
  [class^='bx-container__break--md'] {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0; }
    [class^='bx-container__bleed--md'][class^='bx-padding'],
    [class^='bx-container__break--md'][class^='bx-padding'] {
      padding-left: 1rem;
      padding-right: 1rem; }
  .bx-container {
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem; }
  .bx-container--left {
    margin-left: 0; }
  .bx-container--right {
    margin-right: 0; }
  .bx-container__bleed--lg,
  .bx-container__bleed--lg--left,
  .bx-container__break--lg,
  .bx-container__break--lg--left {
    margin-left: -1rem; }
  .bx-container__bleed--lg,
  .bx-container__bleed--lg--right,
  .bx-container__break--lg,
  .bx-container__break--lg--right {
    margin-right: -1rem; }
  .bx-container__bleed--lg,
  .bx-container__bleed--lg--left {
    padding-left: 1rem; }
    .bx-container__bleed--lg.bx-padding, .bx-container__bleed--lg.bx-padding--horizontal, .bx-container__bleed--lg.bx-padding--left,
    .bx-container__bleed--lg--left.bx-padding,
    .bx-container__bleed--lg--left.bx-padding--horizontal,
    .bx-container__bleed--lg--left.bx-padding--left {
      padding-left: calc(1rem + 1rem); }
  .bx-container__bleed--lg,
  .bx-container__bleed--lg--right {
    padding-right: 1rem; }
    .bx-container__bleed--lg.bx-padding, .bx-container__bleed--lg.bx-padding--horizontal, .bx-container__bleed--lg.bx-padding--right,
    .bx-container__bleed--lg--right.bx-padding,
    .bx-container__bleed--lg--right.bx-padding--horizontal,
    .bx-container__bleed--lg--right.bx-padding--right {
      padding-right: calc(1rem + 1rem); } }

@media screen and (min-width: 120rem) {
  .bx-grid__col--sm--0--only {
    display: block; }
  .bx-grid__col--md--0--only {
    display: block; }
  .bx-grid__col--lg--0--only {
    display: block; }
  [class*='bx-grid__col--max--'] {
    display: block; }
  .bx-grid__col--max--0,
  .bx-grid__col--max--0--only {
    display: none; }
  .bx-padding {
    padding: 1rem; }
  .bx-padding--bottom {
    padding-bottom: 1rem; }
  .bx-padding--left {
    padding-left: 1rem; }
  .bx-padding--right {
    padding-right: 1rem; }
  .bx-padding--top {
    padding-top: 1rem; }
  .bx-padding--horizontal {
    padding-left: 1rem;
    padding-right: 1rem; }
  .bx-padding--vertical {
    padding-bottom: 1rem;
    padding-top: 1rem; }
  .bx-grid {
    grid-template-columns: repeat(auto-fill, calc((120rem - 3rem) * 0.0625)); }
    .bx-grid.bx-grid--fixed-columns {
      grid-template-columns: repeat(auto-fill, 7.5rem); }
    .bx-grid.bx-grid--fluid-rows {
      grid-auto-rows: calc((120rem - 3rem) * 0.0625); }
  .bx-container {
    margin-left: auto;
    margin-right: auto;
    padding-left: 1.5rem;
    padding-right: 1.5rem; }
  .bx-container--left {
    margin-left: 0; }
  .bx-container--right {
    margin-right: 0; }
  .bx-container__bleed--lg,
  .bx-container__bleed--lg--left,
  .bx-container__break--lg,
  .bx-container__break--lg--left {
    margin-left: -1.5rem; }
  .bx-container__bleed--lg,
  .bx-container__bleed--lg--right,
  .bx-container__break--lg,
  .bx-container__break--lg--right {
    margin-right: -1.5rem; }
  .bx-container__bleed--lg,
  .bx-container__bleed--lg--left {
    padding-left: 1.5rem; }
    .bx-container__bleed--lg.bx-padding, .bx-container__bleed--lg.bx-padding--horizontal, .bx-container__bleed--lg.bx-padding--left,
    .bx-container__bleed--lg--left.bx-padding,
    .bx-container__bleed--lg--left.bx-padding--horizontal,
    .bx-container__bleed--lg--left.bx-padding--left {
      padding-left: calc(1.5rem + 1rem); }
  .bx-container__bleed--lg,
  .bx-container__bleed--lg--right {
    padding-right: 1.5rem; }
    .bx-container__bleed--lg.bx-padding, .bx-container__bleed--lg.bx-padding--horizontal, .bx-container__bleed--lg.bx-padding--right,
    .bx-container__bleed--lg--right.bx-padding,
    .bx-container__bleed--lg--right.bx-padding--horizontal,
    .bx-container__bleed--lg--right.bx-padding--right {
      padding-right: calc(1.5rem + 1rem); } }

@supports (display: grid) {
  .bx-grid > *,
  .bx-grid > [class*='bx-grid__col--'] {
    width: initial;
    min-width: initial;
    max-width: initial; }
    .bx-grid > *.bx-grid,
    .bx-grid > [class*='bx-grid__col--'].bx-grid {
      display: grid; }
  .bx-grid > [class*='bx-grid__height--'] {
    height: unset;
    min-height: initial;
    max-height: unset; } }

.bx-grid > script {
  display: none; }

@font-face {
  font-display: auto;
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 300;
  src: local("IBM Plex Mono Light Italic"), local("IBMPlexMono-LightItalic"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F6sfjptAgt5VM-kVkqdyU8n1ioSflV1gMoW.woff) format("woff"); }

@font-face {
  font-display: auto;
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 400;
  src: local("IBM Plex Mono Italic"), local("IBMPlexMono-Italic"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F6pfjptAgt5VM-kVkqdyU8n1ioa1Xdm.woff) format("woff"); }

@font-face {
  font-display: auto;
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 600;
  src: local("IBM Plex Mono SemiBold Italic"), local("IBMPlexMono-SemiBoldItalic"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F6sfjptAgt5VM-kVkqdyU8n1ioSClN1gMoW.woff) format("woff"); }

@font-face {
  font-display: auto;
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 300;
  src: local("IBM Plex Mono Light"), local("IBMPlexMono-Light"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F6qfjptAgt5VM-kVkqdyU8n3oQIwlBFhA.woff) format("woff"); }

@font-face {
  font-display: auto;
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 400;
  src: local("IBM Plex Mono"), local("IBMPlexMono"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F63fjptAgt5VM-kVkqdyU8n1i8q0Q.woff) format("woff"); }

@font-face {
  font-display: auto;
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 600;
  src: local("IBM Plex Mono SemiBold"), local("IBMPlexMono-SemiBold"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F6qfjptAgt5VM-kVkqdyU8n3vAOwlBFhA.woff) format("woff"); }

/* cyrillic-ext */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 300;
  src: local("IBM Plex Mono Light Italic"), local("IBMPlexMono-LightItalic"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F6sfjptAgt5VM-kVkqdyU8n1ioSflV1jcoQPttoz6Pz.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F; }

/* cyrillic */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 300;
  src: local("IBM Plex Mono Light Italic"), local("IBMPlexMono-LightItalic"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F6sfjptAgt5VM-kVkqdyU8n1ioSflV1hMoQPttoz6Pz.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* vietnamese */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 300;
  src: local("IBM Plex Mono Light Italic"), local("IBMPlexMono-LightItalic"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F6sfjptAgt5VM-kVkqdyU8n1ioSflV1j8oQPttoz6Pz.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 300;
  src: local("IBM Plex Mono Light Italic"), local("IBMPlexMono-LightItalic"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F6sfjptAgt5VM-kVkqdyU8n1ioSflV1jsoQPttoz6Pz.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 300;
  src: local("IBM Plex Mono Light Italic"), local("IBMPlexMono-LightItalic"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F6sfjptAgt5VM-kVkqdyU8n1ioSflV1gMoQPttozw.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* cyrillic-ext */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 400;
  src: local("IBM Plex Mono Italic"), local("IBMPlexMono-Italic"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F6pfjptAgt5VM-kVkqdyU8n1ioa2HdgregdFOFh.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F; }

/* cyrillic */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 400;
  src: local("IBM Plex Mono Italic"), local("IBMPlexMono-Italic"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F6pfjptAgt5VM-kVkqdyU8n1ioa0XdgregdFOFh.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* vietnamese */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 400;
  src: local("IBM Plex Mono Italic"), local("IBMPlexMono-Italic"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F6pfjptAgt5VM-kVkqdyU8n1ioa2ndgregdFOFh.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 400;
  src: local("IBM Plex Mono Italic"), local("IBMPlexMono-Italic"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F6pfjptAgt5VM-kVkqdyU8n1ioa23dgregdFOFh.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 400;
  src: local("IBM Plex Mono Italic"), local("IBMPlexMono-Italic"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F6pfjptAgt5VM-kVkqdyU8n1ioa1XdgregdFA.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* cyrillic-ext */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 600;
  src: local("IBM Plex Mono SemiBold Italic"), local("IBMPlexMono-SemiBoldItalic"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F6sfjptAgt5VM-kVkqdyU8n1ioSClN1jcoQPttoz6Pz.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F; }

/* cyrillic */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 600;
  src: local("IBM Plex Mono SemiBold Italic"), local("IBMPlexMono-SemiBoldItalic"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F6sfjptAgt5VM-kVkqdyU8n1ioSClN1hMoQPttoz6Pz.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* vietnamese */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 600;
  src: local("IBM Plex Mono SemiBold Italic"), local("IBMPlexMono-SemiBoldItalic"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F6sfjptAgt5VM-kVkqdyU8n1ioSClN1j8oQPttoz6Pz.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 600;
  src: local("IBM Plex Mono SemiBold Italic"), local("IBMPlexMono-SemiBoldItalic"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F6sfjptAgt5VM-kVkqdyU8n1ioSClN1jsoQPttoz6Pz.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 600;
  src: local("IBM Plex Mono SemiBold Italic"), local("IBMPlexMono-SemiBoldItalic"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F6sfjptAgt5VM-kVkqdyU8n1ioSClN1gMoQPttozw.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* cyrillic-ext */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 300;
  src: local("IBM Plex Mono Light"), local("IBMPlexMono-Light"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F6qfjptAgt5VM-kVkqdyU8n3oQIwl1FgsAXHNlYzg.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F; }

/* cyrillic */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 300;
  src: local("IBM Plex Mono Light"), local("IBMPlexMono-Light"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F6qfjptAgt5VM-kVkqdyU8n3oQIwlRFgsAXHNlYzg.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* vietnamese */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 300;
  src: local("IBM Plex Mono Light"), local("IBMPlexMono-Light"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F6qfjptAgt5VM-kVkqdyU8n3oQIwl9FgsAXHNlYzg.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 300;
  src: local("IBM Plex Mono Light"), local("IBMPlexMono-Light"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F6qfjptAgt5VM-kVkqdyU8n3oQIwl5FgsAXHNlYzg.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 300;
  src: local("IBM Plex Mono Light"), local("IBMPlexMono-Light"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F6qfjptAgt5VM-kVkqdyU8n3oQIwlBFgsAXHNk.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* cyrillic-ext */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 400;
  src: local("IBM Plex Mono"), local("IBMPlexMono"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F63fjptAgt5VM-kVkqdyU8n1iIq131nj-otFQ.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F; }

/* cyrillic */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 400;
  src: local("IBM Plex Mono"), local("IBMPlexMono"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F63fjptAgt5VM-kVkqdyU8n1isq131nj-otFQ.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* vietnamese */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 400;
  src: local("IBM Plex Mono"), local("IBMPlexMono"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F63fjptAgt5VM-kVkqdyU8n1iAq131nj-otFQ.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 400;
  src: local("IBM Plex Mono"), local("IBMPlexMono"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F63fjptAgt5VM-kVkqdyU8n1iEq131nj-otFQ.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 400;
  src: local("IBM Plex Mono"), local("IBMPlexMono"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F63fjptAgt5VM-kVkqdyU8n1i8q131nj-o.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* cyrillic-ext */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 600;
  src: local("IBM Plex Mono SemiBold"), local("IBMPlexMono-SemiBold"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F6qfjptAgt5VM-kVkqdyU8n3vAOwl1FgsAXHNlYzg.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F; }

/* cyrillic */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 600;
  src: local("IBM Plex Mono SemiBold"), local("IBMPlexMono-SemiBold"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F6qfjptAgt5VM-kVkqdyU8n3vAOwlRFgsAXHNlYzg.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* vietnamese */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 600;
  src: local("IBM Plex Mono SemiBold"), local("IBMPlexMono-SemiBold"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F6qfjptAgt5VM-kVkqdyU8n3vAOwl9FgsAXHNlYzg.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 600;
  src: local("IBM Plex Mono SemiBold"), local("IBMPlexMono-SemiBold"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F6qfjptAgt5VM-kVkqdyU8n3vAOwl5FgsAXHNlYzg.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 600;
  src: local("IBM Plex Mono SemiBold"), local("IBMPlexMono-SemiBold"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F6qfjptAgt5VM-kVkqdyU8n3vAOwlBFgsAXHNk.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

@font-face {
  font-display: auto;
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 300;
  src: local("IBM Plex Sans Light Italic"), local("IBMPlexSans-LightItalic"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX7KVElMYYaJe8bpLHnCwDKhdTmvIRcdvfo.woff) format("woff"); }

@font-face {
  font-display: auto;
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 400;
  src: local("IBM Plex Sans Italic"), local("IBMPlexSans-Italic"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX-KVElMYYaJe8bpLHnCwDKhdTuF6ZP.woff) format("woff"); }

@font-face {
  font-display: auto;
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 600;
  src: local("IBM Plex Sans SemiBold Italic"), local("IBMPlexSans-SemiBoldItalic"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX7KVElMYYaJe8bpLHnCwDKhdTmyIJcdvfo.woff) format("woff"); }

@font-face {
  font-display: auto;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 300;
  src: local("IBM Plex Sans Light"), local("IBMPlexSans-Light"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX9KVElMYYaJe8bpLHnCwDKjXr8AIFscg.woff) format("woff"); }

@font-face {
  font-display: auto;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  src: local("IBM Plex Sans"), local("IBMPlexSans"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYXgKVElMYYaJe8bpLHnCwDKhdHeEw.woff) format("woff"); }

@font-face {
  font-display: auto;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 600;
  src: local("IBM Plex Sans SemiBold"), local("IBMPlexSans-SemiBold"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX9KVElMYYaJe8bpLHnCwDKjQ76AIFscg.woff) format("woff"); }

/* cyrillic-ext */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 300;
  src: local("IBM Plex Sans Light Italic"), local("IBMPlexSans-LightItalic"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX7KVElMYYaJe8bpLHnCwDKhdTmvIRce_fuJGl18QRY.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F; }

/* cyrillic */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 300;
  src: local("IBM Plex Sans Light Italic"), local("IBMPlexSans-LightItalic"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX7KVElMYYaJe8bpLHnCwDKhdTmvIRccvfuJGl18QRY.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* greek */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 300;
  src: local("IBM Plex Sans Light Italic"), local("IBMPlexSans-LightItalic"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX7KVElMYYaJe8bpLHnCwDKhdTmvIRcdffuJGl18QRY.woff2) format("woff2");
  unicode-range: U+0370-03FF; }

/* vietnamese */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 300;
  src: local("IBM Plex Sans Light Italic"), local("IBMPlexSans-LightItalic"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX7KVElMYYaJe8bpLHnCwDKhdTmvIRceffuJGl18QRY.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 300;
  src: local("IBM Plex Sans Light Italic"), local("IBMPlexSans-LightItalic"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX7KVElMYYaJe8bpLHnCwDKhdTmvIRcePfuJGl18QRY.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 300;
  src: local("IBM Plex Sans Light Italic"), local("IBMPlexSans-LightItalic"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX7KVElMYYaJe8bpLHnCwDKhdTmvIRcdvfuJGl18Q.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* cyrillic-ext */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 400;
  src: local("IBM Plex Sans Italic"), local("IBMPlexSans-Italic"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX-KVElMYYaJe8bpLHnCwDKhdTuGqZJW9XjDlN8.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F; }

/* cyrillic */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 400;
  src: local("IBM Plex Sans Italic"), local("IBMPlexSans-Italic"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX-KVElMYYaJe8bpLHnCwDKhdTuE6ZJW9XjDlN8.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* greek */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 400;
  src: local("IBM Plex Sans Italic"), local("IBMPlexSans-Italic"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX-KVElMYYaJe8bpLHnCwDKhdTuFKZJW9XjDlN8.woff2) format("woff2");
  unicode-range: U+0370-03FF; }

/* vietnamese */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 400;
  src: local("IBM Plex Sans Italic"), local("IBMPlexSans-Italic"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX-KVElMYYaJe8bpLHnCwDKhdTuGKZJW9XjDlN8.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 400;
  src: local("IBM Plex Sans Italic"), local("IBMPlexSans-Italic"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX-KVElMYYaJe8bpLHnCwDKhdTuGaZJW9XjDlN8.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 400;
  src: local("IBM Plex Sans Italic"), local("IBMPlexSans-Italic"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX-KVElMYYaJe8bpLHnCwDKhdTuF6ZJW9XjDg.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* cyrillic-ext */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 600;
  src: local("IBM Plex Sans SemiBold Italic"), local("IBMPlexSans-SemiBoldItalic"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX7KVElMYYaJe8bpLHnCwDKhdTmyIJce_fuJGl18QRY.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F; }

/* cyrillic */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 600;
  src: local("IBM Plex Sans SemiBold Italic"), local("IBMPlexSans-SemiBoldItalic"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX7KVElMYYaJe8bpLHnCwDKhdTmyIJccvfuJGl18QRY.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* greek */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 600;
  src: local("IBM Plex Sans SemiBold Italic"), local("IBMPlexSans-SemiBoldItalic"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX7KVElMYYaJe8bpLHnCwDKhdTmyIJcdffuJGl18QRY.woff2) format("woff2");
  unicode-range: U+0370-03FF; }

/* vietnamese */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 600;
  src: local("IBM Plex Sans SemiBold Italic"), local("IBMPlexSans-SemiBoldItalic"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX7KVElMYYaJe8bpLHnCwDKhdTmyIJceffuJGl18QRY.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 600;
  src: local("IBM Plex Sans SemiBold Italic"), local("IBMPlexSans-SemiBoldItalic"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX7KVElMYYaJe8bpLHnCwDKhdTmyIJcePfuJGl18QRY.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 600;
  src: local("IBM Plex Sans SemiBold Italic"), local("IBMPlexSans-SemiBoldItalic"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX7KVElMYYaJe8bpLHnCwDKhdTmyIJcdvfuJGl18Q.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* cyrillic-ext */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 300;
  src: local("IBM Plex Sans Light"), local("IBMPlexSans-Light"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX9KVElMYYaJe8bpLHnCwDKjXr8AIxsdP3pBmtF8A.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F; }

/* cyrillic */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 300;
  src: local("IBM Plex Sans Light"), local("IBMPlexSans-Light"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX9KVElMYYaJe8bpLHnCwDKjXr8AIVsdP3pBmtF8A.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* greek */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 300;
  src: local("IBM Plex Sans Light"), local("IBMPlexSans-Light"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX9KVElMYYaJe8bpLHnCwDKjXr8AIJsdP3pBmtF8A.woff2) format("woff2");
  unicode-range: U+0370-03FF; }

/* vietnamese */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 300;
  src: local("IBM Plex Sans Light"), local("IBMPlexSans-Light"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX9KVElMYYaJe8bpLHnCwDKjXr8AI5sdP3pBmtF8A.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 300;
  src: local("IBM Plex Sans Light"), local("IBMPlexSans-Light"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX9KVElMYYaJe8bpLHnCwDKjXr8AI9sdP3pBmtF8A.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 300;
  src: local("IBM Plex Sans Light"), local("IBMPlexSans-Light"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX9KVElMYYaJe8bpLHnCwDKjXr8AIFsdP3pBms.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* cyrillic-ext */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  src: local("IBM Plex Sans"), local("IBMPlexSans"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYXgKVElMYYaJe8bpLHnCwDKhdzeFaxOedfTDw.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F; }

/* cyrillic */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  src: local("IBM Plex Sans"), local("IBMPlexSans"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYXgKVElMYYaJe8bpLHnCwDKhdXeFaxOedfTDw.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* greek */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  src: local("IBM Plex Sans"), local("IBMPlexSans"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYXgKVElMYYaJe8bpLHnCwDKhdLeFaxOedfTDw.woff2) format("woff2");
  unicode-range: U+0370-03FF; }

/* vietnamese */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  src: local("IBM Plex Sans"), local("IBMPlexSans"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYXgKVElMYYaJe8bpLHnCwDKhd7eFaxOedfTDw.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  src: local("IBM Plex Sans"), local("IBMPlexSans"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYXgKVElMYYaJe8bpLHnCwDKhd_eFaxOedfTDw.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  src: local("IBM Plex Sans"), local("IBMPlexSans"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYXgKVElMYYaJe8bpLHnCwDKhdHeFaxOedc.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* cyrillic-ext */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 600;
  src: local("IBM Plex Sans SemiBold"), local("IBMPlexSans-SemiBold"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX9KVElMYYaJe8bpLHnCwDKjQ76AIxsdP3pBmtF8A.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F; }

/* cyrillic */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 600;
  src: local("IBM Plex Sans SemiBold"), local("IBMPlexSans-SemiBold"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX9KVElMYYaJe8bpLHnCwDKjQ76AIVsdP3pBmtF8A.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* greek */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 600;
  src: local("IBM Plex Sans SemiBold"), local("IBMPlexSans-SemiBold"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX9KVElMYYaJe8bpLHnCwDKjQ76AIJsdP3pBmtF8A.woff2) format("woff2");
  unicode-range: U+0370-03FF; }

/* vietnamese */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 600;
  src: local("IBM Plex Sans SemiBold"), local("IBMPlexSans-SemiBold"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX9KVElMYYaJe8bpLHnCwDKjQ76AI5sdP3pBmtF8A.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 600;
  src: local("IBM Plex Sans SemiBold"), local("IBMPlexSans-SemiBold"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX9KVElMYYaJe8bpLHnCwDKjQ76AI9sdP3pBmtF8A.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-display: auto;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 600;
  src: local("IBM Plex Sans SemiBold"), local("IBMPlexSans-SemiBold"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX9KVElMYYaJe8bpLHnCwDKjQ76AIFsdP3pBms.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

[class*='ibm-type'] {
  margin-top: 0; }

.ibm-type {
  font-family: 'IBM Plex Sans', 'Helvetica Neue', Arial, sans-serif; }
  .ibm-type-italic {
    font-style: italic; }
  .ibm-type-mono {
    font-family: 'IBM Plex Mono', 'Menlo', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', Courier, monospace; }
  .ibm-type-serif {
    font-family: 'IBM Plex Serif', 'Georgia', Times, serif; }

.ibm-type-light {
  font-weight: 300; }

.ibm-type-semibold {
  font-weight: 600; }

.ibm-type-a {
  font-size: var(--cds-caption-01-font-size, 0.75rem);
  font-weight: var(--cds-caption-01-font-weight, 400);
  line-height: var(--cds-caption-01-line-height, 1.33333);
  letter-spacing: var(--cds-caption-01-letter-spacing, 0.32px); }

.ibm-type-b {
  font-size: var(--cds-body-long-01-font-size, 0.875rem);
  font-weight: var(--cds-body-long-01-font-weight, 400);
  line-height: var(--cds-body-long-01-line-height, 1.42857);
  letter-spacing: var(--cds-body-long-01-letter-spacing, 0.16px); }

.ibm-type-c {
  font-size: var(--cds-body-short-02-font-size, 1rem);
  font-weight: var(--cds-body-short-02-font-weight, 400);
  line-height: var(--cds-body-short-02-line-height, 1.375);
  letter-spacing: var(--cds-body-short-02-letter-spacing, 0); }

.ibm-type-d {
  font-size: var(--cds-heading-02-font-size, 1rem);
  font-weight: var(--cds-heading-02-font-weight, 600);
  line-height: var(--cds-heading-02-line-height, 1.5);
  letter-spacing: var(--cds-heading-02-letter-spacing, 0); }

.ibm-type-e {
  font-size: var(--cds-productive-heading-03-font-size, 1.25rem);
  font-weight: var(--cds-productive-heading-03-font-weight, 400);
  line-height: var(--cds-productive-heading-03-line-height, 1.4);
  letter-spacing: var(--cds-productive-heading-03-letter-spacing, 0); }

.ibm-type-f {
  font-size: 1.75rem;
  font-weight: 400;
  line-height: 1.28572;
  letter-spacing: 0;
  font-size: calc(1.75rem + 0 * ((100vw - 20rem) / 62)); }
  @media (min-width: 82rem) {
    .ibm-type-f {
      font-size: 1.75rem;
      line-height: 1.25;
      font-size: calc(1.75rem + 0.25 * ((100vw - 82rem) / 17)); } }
  @media (min-width: 99rem) {
    .ibm-type-f {
      font-size: 2rem;
      font-size: 2rem; } }

.ibm-type-g {
  font-size: var(--cds-productive-heading-04-font-size, 1.75rem);
  font-weight: var(--cds-productive-heading-04-font-weight, 400);
  line-height: var(--cds-productive-heading-04-line-height, 1.28572);
  letter-spacing: var(--cds-productive-heading-04-letter-spacing, 0); }

.ibm-type-h {
  font-size: var(--cds-productive-heading-05-font-size, 2rem);
  font-weight: var(--cds-productive-heading-05-font-weight, 400);
  line-height: var(--cds-productive-heading-05-line-height, 1.25);
  letter-spacing: var(--cds-productive-heading-05-letter-spacing, 0); }

.ibm-type-i {
  font-size: 2.625rem;
  font-weight: 300;
  line-height: 1.19;
  letter-spacing: 0;
  font-size: calc(2.625rem + 0 * ((100vw - 20rem) / 22)); }
  @media (min-width: 42rem) {
    .ibm-type-i {
      font-size: 2.625rem;
      font-size: calc(2.625rem + 0.75 * ((100vw - 42rem) / 24)); } }
  @media (min-width: 66rem) {
    .ibm-type-i {
      font-size: 3.375rem;
      font-size: calc(3.375rem + 0.375 * ((100vw - 66rem) / 16)); } }
  @media (min-width: 82rem) {
    .ibm-type-i {
      font-size: 3.75rem;
      line-height: 1.17;
      font-size: calc(3.75rem + 1 * ((100vw - 82rem) / 17)); } }
  @media (min-width: 99rem) {
    .ibm-type-i {
      font-size: 4.75rem;
      line-height: 1.13;
      font-size: 4.75rem; } }

.ibm-type-j {
  font-size: 2.625rem;
  font-weight: 300;
  line-height: 1.19;
  letter-spacing: 0;
  font-size: calc(2.625rem + 1.625 * ((100vw - 20rem) / 22)); }
  @media (min-width: 42rem) {
    .ibm-type-j {
      font-size: 4.25rem;
      line-height: 1.15;
      font-size: calc(4.25rem + 1.5 * ((100vw - 42rem) / 24)); } }
  @media (min-width: 66rem) {
    .ibm-type-j {
      font-size: 5.75rem;
      line-height: 1.11;
      letter-spacing: -0.64px;
      font-size: calc(5.75rem + 1.875 * ((100vw - 66rem) / 16)); } }
  @media (min-width: 82rem) {
    .ibm-type-j {
      font-size: 7.625rem;
      line-height: 1.07;
      font-size: calc(7.625rem + 2.125 * ((100vw - 82rem) / 17)); } }
  @media (min-width: 99rem) {
    .ibm-type-j {
      font-size: 9.75rem;
      line-height: 1.05;
      letter-spacing: -0.96px;
      font-size: 9.75rem; } }

.ibm-type-k {
  font-size: 2.625rem;
  font-weight: 600;
  line-height: 1.19;
  letter-spacing: 0;
  font-size: calc(2.625rem + 1.625 * ((100vw - 20rem) / 22)); }
  @media (min-width: 42rem) {
    .ibm-type-k {
      font-size: 4.25rem;
      line-height: 1.15;
      font-size: calc(4.25rem + 1.5 * ((100vw - 42rem) / 24)); } }
  @media (min-width: 66rem) {
    .ibm-type-k {
      font-size: 5.75rem;
      line-height: 1.11;
      letter-spacing: -0.64px;
      font-size: calc(5.75rem + 1.875 * ((100vw - 66rem) / 16)); } }
  @media (min-width: 82rem) {
    .ibm-type-k {
      font-size: 7.625rem;
      line-height: 1.07;
      letter-spacing: -0.64px;
      font-size: calc(7.625rem + 2.125 * ((100vw - 82rem) / 17)); } }
  @media (min-width: 99rem) {
    .ibm-type-k {
      font-size: 9.75rem;
      line-height: 1.05;
      letter-spacing: -0.96px;
      font-size: 9.75rem; } }

body {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  font-size: var(--cds-body-short-01-font-size, 0.875rem);
  font-weight: var(--cds-body-short-01-font-weight, 400);
  line-height: var(--cds-body-short-01-line-height, 1.28572);
  letter-spacing: var(--cds-body-short-01-letter-spacing, 0.16px);
  background-color: var(--cds-ui-background, #ffffff);
  color: var(--cds-text-01, #161616);
  line-height: 1; }
  body *,
  body *::before,
  body *::after {
    box-sizing: inherit; }

.bx--grid {
  margin-right: auto;
  margin-left: auto;
  max-width: 99rem;
  padding-right: 1rem;
  padding-left: 1rem; }
  @media (min-width: 42rem) {
    .bx--grid {
      padding-right: 2rem;
      padding-left: 2rem; } }
  @media (min-width: 99rem) {
    .bx--grid {
      padding-right: 2.5rem;
      padding-left: 2.5rem; } }

@media (min-width: 99rem) {
  .bx--grid--full-width {
    max-width: 100%; } }

.bx--row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -1rem;
  margin-left: -1rem; }

.bx--row-padding [class*='bx--col'],
.bx--col-padding {
  padding-top: 1rem;
  padding-bottom: 1rem; }

.bx--grid--condensed [class*='bx--col'] {
  padding-top: 0.03125rem;
  padding-bottom: 0.03125rem; }

.bx--col {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col,
  .bx--grid--condensed .bx--col {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col,
  .bx--grid--narrow .bx--col {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-sm-0 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-sm-0,
  .bx--grid--condensed .bx--col-sm-0 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-sm-0,
  .bx--grid--narrow .bx--col-sm-0 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-sm-1 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-sm-1,
  .bx--grid--condensed .bx--col-sm-1 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-sm-1,
  .bx--grid--narrow .bx--col-sm-1 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-sm-2 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-sm-2,
  .bx--grid--condensed .bx--col-sm-2 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-sm-2,
  .bx--grid--narrow .bx--col-sm-2 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-sm-3 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-sm-3,
  .bx--grid--condensed .bx--col-sm-3 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-sm-3,
  .bx--grid--narrow .bx--col-sm-3 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-sm-4 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-sm-4,
  .bx--grid--condensed .bx--col-sm-4 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-sm-4,
  .bx--grid--narrow .bx--col-sm-4 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-sm,
.bx--col-sm--auto {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-sm,
  .bx--grid--condensed .bx--col-sm, .bx--row--condensed
  .bx--col-sm--auto,
  .bx--grid--condensed
  .bx--col-sm--auto {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-sm,
  .bx--grid--narrow .bx--col-sm, .bx--row--narrow
  .bx--col-sm--auto,
  .bx--grid--narrow
  .bx--col-sm--auto {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col,
.bx--col-sm {
  max-width: 100%;
  flex-basis: 0;
  flex-grow: 1; }

.bx--col--auto,
.bx--col-sm--auto {
  width: auto;
  max-width: 100%;
  flex: 1 0 0%; }

.bx--col-sm-0 {
  display: none; }

.bx--col-sm-1 {
  display: block;
  max-width: 25%;
  flex: 0 0 25%; }

.bx--col-sm-2 {
  display: block;
  max-width: 50%;
  flex: 0 0 50%; }

.bx--col-sm-3 {
  display: block;
  max-width: 75%;
  flex: 0 0 75%; }

.bx--col-sm-4 {
  display: block;
  max-width: 100%;
  flex: 0 0 100%; }

.bx--offset-sm-0 {
  margin-left: 0; }

.bx--offset-sm-1 {
  margin-left: 25%; }

.bx--offset-sm-2 {
  margin-left: 50%; }

.bx--offset-sm-3 {
  margin-left: 75%; }

.bx--col-md-0 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-md-0,
  .bx--grid--condensed .bx--col-md-0 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-md-0,
  .bx--grid--narrow .bx--col-md-0 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-md-1 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-md-1,
  .bx--grid--condensed .bx--col-md-1 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-md-1,
  .bx--grid--narrow .bx--col-md-1 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-md-2 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-md-2,
  .bx--grid--condensed .bx--col-md-2 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-md-2,
  .bx--grid--narrow .bx--col-md-2 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-md-3 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-md-3,
  .bx--grid--condensed .bx--col-md-3 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-md-3,
  .bx--grid--narrow .bx--col-md-3 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-md-4 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-md-4,
  .bx--grid--condensed .bx--col-md-4 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-md-4,
  .bx--grid--narrow .bx--col-md-4 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-md-5 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-md-5,
  .bx--grid--condensed .bx--col-md-5 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-md-5,
  .bx--grid--narrow .bx--col-md-5 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-md-6 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-md-6,
  .bx--grid--condensed .bx--col-md-6 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-md-6,
  .bx--grid--narrow .bx--col-md-6 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-md-7 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-md-7,
  .bx--grid--condensed .bx--col-md-7 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-md-7,
  .bx--grid--narrow .bx--col-md-7 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-md-8 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-md-8,
  .bx--grid--condensed .bx--col-md-8 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-md-8,
  .bx--grid--narrow .bx--col-md-8 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-md,
.bx--col-md--auto {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-md,
  .bx--grid--condensed .bx--col-md, .bx--row--condensed
  .bx--col-md--auto,
  .bx--grid--condensed
  .bx--col-md--auto {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-md,
  .bx--grid--narrow .bx--col-md, .bx--row--narrow
  .bx--col-md--auto,
  .bx--grid--narrow
  .bx--col-md--auto {
    padding-right: 1rem;
    padding-left: 0; }

@media (min-width: 42rem) {
  .bx--col,
  .bx--col-md {
    max-width: 100%;
    flex-basis: 0;
    flex-grow: 1; }
  .bx--col--auto,
  .bx--col-md--auto {
    width: auto;
    max-width: 100%;
    flex: 1 0 0%; }
  .bx--col-md-0 {
    display: none; }
  .bx--col-md-1 {
    display: block;
    max-width: 12.5%;
    flex: 0 0 12.5%; }
  .bx--col-md-2 {
    display: block;
    max-width: 25%;
    flex: 0 0 25%; }
  .bx--col-md-3 {
    display: block;
    max-width: 37.5%;
    flex: 0 0 37.5%; }
  .bx--col-md-4 {
    display: block;
    max-width: 50%;
    flex: 0 0 50%; }
  .bx--col-md-5 {
    display: block;
    max-width: 62.5%;
    flex: 0 0 62.5%; }
  .bx--col-md-6 {
    display: block;
    max-width: 75%;
    flex: 0 0 75%; }
  .bx--col-md-7 {
    display: block;
    max-width: 87.5%;
    flex: 0 0 87.5%; }
  .bx--col-md-8 {
    display: block;
    max-width: 100%;
    flex: 0 0 100%; }
  .bx--offset-md-0 {
    margin-left: 0; }
  .bx--offset-md-1 {
    margin-left: 12.5%; }
  .bx--offset-md-2 {
    margin-left: 25%; }
  .bx--offset-md-3 {
    margin-left: 37.5%; }
  .bx--offset-md-4 {
    margin-left: 50%; }
  .bx--offset-md-5 {
    margin-left: 62.5%; }
  .bx--offset-md-6 {
    margin-left: 75%; }
  .bx--offset-md-7 {
    margin-left: 87.5%; } }

.bx--col-lg-0 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-lg-0,
  .bx--grid--condensed .bx--col-lg-0 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-lg-0,
  .bx--grid--narrow .bx--col-lg-0 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-lg-1 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-lg-1,
  .bx--grid--condensed .bx--col-lg-1 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-lg-1,
  .bx--grid--narrow .bx--col-lg-1 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-lg-2 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-lg-2,
  .bx--grid--condensed .bx--col-lg-2 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-lg-2,
  .bx--grid--narrow .bx--col-lg-2 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-lg-3 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-lg-3,
  .bx--grid--condensed .bx--col-lg-3 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-lg-3,
  .bx--grid--narrow .bx--col-lg-3 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-lg-4 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-lg-4,
  .bx--grid--condensed .bx--col-lg-4 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-lg-4,
  .bx--grid--narrow .bx--col-lg-4 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-lg-5 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-lg-5,
  .bx--grid--condensed .bx--col-lg-5 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-lg-5,
  .bx--grid--narrow .bx--col-lg-5 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-lg-6 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-lg-6,
  .bx--grid--condensed .bx--col-lg-6 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-lg-6,
  .bx--grid--narrow .bx--col-lg-6 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-lg-7 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-lg-7,
  .bx--grid--condensed .bx--col-lg-7 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-lg-7,
  .bx--grid--narrow .bx--col-lg-7 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-lg-8 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-lg-8,
  .bx--grid--condensed .bx--col-lg-8 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-lg-8,
  .bx--grid--narrow .bx--col-lg-8 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-lg-9 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-lg-9,
  .bx--grid--condensed .bx--col-lg-9 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-lg-9,
  .bx--grid--narrow .bx--col-lg-9 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-lg-10 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-lg-10,
  .bx--grid--condensed .bx--col-lg-10 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-lg-10,
  .bx--grid--narrow .bx--col-lg-10 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-lg-11 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-lg-11,
  .bx--grid--condensed .bx--col-lg-11 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-lg-11,
  .bx--grid--narrow .bx--col-lg-11 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-lg-12 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-lg-12,
  .bx--grid--condensed .bx--col-lg-12 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-lg-12,
  .bx--grid--narrow .bx--col-lg-12 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-lg-13 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-lg-13,
  .bx--grid--condensed .bx--col-lg-13 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-lg-13,
  .bx--grid--narrow .bx--col-lg-13 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-lg-14 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-lg-14,
  .bx--grid--condensed .bx--col-lg-14 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-lg-14,
  .bx--grid--narrow .bx--col-lg-14 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-lg-15 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-lg-15,
  .bx--grid--condensed .bx--col-lg-15 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-lg-15,
  .bx--grid--narrow .bx--col-lg-15 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-lg-16 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-lg-16,
  .bx--grid--condensed .bx--col-lg-16 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-lg-16,
  .bx--grid--narrow .bx--col-lg-16 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-lg,
.bx--col-lg--auto {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-lg,
  .bx--grid--condensed .bx--col-lg, .bx--row--condensed
  .bx--col-lg--auto,
  .bx--grid--condensed
  .bx--col-lg--auto {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-lg,
  .bx--grid--narrow .bx--col-lg, .bx--row--narrow
  .bx--col-lg--auto,
  .bx--grid--narrow
  .bx--col-lg--auto {
    padding-right: 1rem;
    padding-left: 0; }

@media (min-width: 66rem) {
  .bx--col,
  .bx--col-lg {
    max-width: 100%;
    flex-basis: 0;
    flex-grow: 1; }
  .bx--col--auto,
  .bx--col-lg--auto {
    width: auto;
    max-width: 100%;
    flex: 1 0 0%; }
  .bx--col-lg-0 {
    display: none; }
  .bx--col-lg-1 {
    display: block;
    max-width: 6.25%;
    flex: 0 0 6.25%; }
  .bx--col-lg-2 {
    display: block;
    max-width: 12.5%;
    flex: 0 0 12.5%; }
  .bx--col-lg-3 {
    display: block;
    max-width: 18.75%;
    flex: 0 0 18.75%; }
  .bx--col-lg-4 {
    display: block;
    max-width: 25%;
    flex: 0 0 25%; }
  .bx--col-lg-5 {
    display: block;
    max-width: 31.25%;
    flex: 0 0 31.25%; }
  .bx--col-lg-6 {
    display: block;
    max-width: 37.5%;
    flex: 0 0 37.5%; }
  .bx--col-lg-7 {
    display: block;
    max-width: 43.75%;
    flex: 0 0 43.75%; }
  .bx--col-lg-8 {
    display: block;
    max-width: 50%;
    flex: 0 0 50%; }
  .bx--col-lg-9 {
    display: block;
    max-width: 56.25%;
    flex: 0 0 56.25%; }
  .bx--col-lg-10 {
    display: block;
    max-width: 62.5%;
    flex: 0 0 62.5%; }
  .bx--col-lg-11 {
    display: block;
    max-width: 68.75%;
    flex: 0 0 68.75%; }
  .bx--col-lg-12 {
    display: block;
    max-width: 75%;
    flex: 0 0 75%; }
  .bx--col-lg-13 {
    display: block;
    max-width: 81.25%;
    flex: 0 0 81.25%; }
  .bx--col-lg-14 {
    display: block;
    max-width: 87.5%;
    flex: 0 0 87.5%; }
  .bx--col-lg-15 {
    display: block;
    max-width: 93.75%;
    flex: 0 0 93.75%; }
  .bx--col-lg-16 {
    display: block;
    max-width: 100%;
    flex: 0 0 100%; }
  .bx--offset-lg-0 {
    margin-left: 0; }
  .bx--offset-lg-1 {
    margin-left: 6.25%; }
  .bx--offset-lg-2 {
    margin-left: 12.5%; }
  .bx--offset-lg-3 {
    margin-left: 18.75%; }
  .bx--offset-lg-4 {
    margin-left: 25%; }
  .bx--offset-lg-5 {
    margin-left: 31.25%; }
  .bx--offset-lg-6 {
    margin-left: 37.5%; }
  .bx--offset-lg-7 {
    margin-left: 43.75%; }
  .bx--offset-lg-8 {
    margin-left: 50%; }
  .bx--offset-lg-9 {
    margin-left: 56.25%; }
  .bx--offset-lg-10 {
    margin-left: 62.5%; }
  .bx--offset-lg-11 {
    margin-left: 68.75%; }
  .bx--offset-lg-12 {
    margin-left: 75%; }
  .bx--offset-lg-13 {
    margin-left: 81.25%; }
  .bx--offset-lg-14 {
    margin-left: 87.5%; }
  .bx--offset-lg-15 {
    margin-left: 93.75%; } }

.bx--col-xlg-0 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-xlg-0,
  .bx--grid--condensed .bx--col-xlg-0 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-xlg-0,
  .bx--grid--narrow .bx--col-xlg-0 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-xlg-1 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-xlg-1,
  .bx--grid--condensed .bx--col-xlg-1 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-xlg-1,
  .bx--grid--narrow .bx--col-xlg-1 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-xlg-2 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-xlg-2,
  .bx--grid--condensed .bx--col-xlg-2 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-xlg-2,
  .bx--grid--narrow .bx--col-xlg-2 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-xlg-3 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-xlg-3,
  .bx--grid--condensed .bx--col-xlg-3 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-xlg-3,
  .bx--grid--narrow .bx--col-xlg-3 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-xlg-4 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-xlg-4,
  .bx--grid--condensed .bx--col-xlg-4 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-xlg-4,
  .bx--grid--narrow .bx--col-xlg-4 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-xlg-5 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-xlg-5,
  .bx--grid--condensed .bx--col-xlg-5 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-xlg-5,
  .bx--grid--narrow .bx--col-xlg-5 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-xlg-6 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-xlg-6,
  .bx--grid--condensed .bx--col-xlg-6 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-xlg-6,
  .bx--grid--narrow .bx--col-xlg-6 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-xlg-7 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-xlg-7,
  .bx--grid--condensed .bx--col-xlg-7 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-xlg-7,
  .bx--grid--narrow .bx--col-xlg-7 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-xlg-8 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-xlg-8,
  .bx--grid--condensed .bx--col-xlg-8 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-xlg-8,
  .bx--grid--narrow .bx--col-xlg-8 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-xlg-9 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-xlg-9,
  .bx--grid--condensed .bx--col-xlg-9 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-xlg-9,
  .bx--grid--narrow .bx--col-xlg-9 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-xlg-10 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-xlg-10,
  .bx--grid--condensed .bx--col-xlg-10 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-xlg-10,
  .bx--grid--narrow .bx--col-xlg-10 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-xlg-11 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-xlg-11,
  .bx--grid--condensed .bx--col-xlg-11 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-xlg-11,
  .bx--grid--narrow .bx--col-xlg-11 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-xlg-12 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-xlg-12,
  .bx--grid--condensed .bx--col-xlg-12 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-xlg-12,
  .bx--grid--narrow .bx--col-xlg-12 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-xlg-13 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-xlg-13,
  .bx--grid--condensed .bx--col-xlg-13 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-xlg-13,
  .bx--grid--narrow .bx--col-xlg-13 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-xlg-14 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-xlg-14,
  .bx--grid--condensed .bx--col-xlg-14 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-xlg-14,
  .bx--grid--narrow .bx--col-xlg-14 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-xlg-15 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-xlg-15,
  .bx--grid--condensed .bx--col-xlg-15 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-xlg-15,
  .bx--grid--narrow .bx--col-xlg-15 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-xlg-16 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-xlg-16,
  .bx--grid--condensed .bx--col-xlg-16 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-xlg-16,
  .bx--grid--narrow .bx--col-xlg-16 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-xlg,
.bx--col-xlg--auto {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-xlg,
  .bx--grid--condensed .bx--col-xlg, .bx--row--condensed
  .bx--col-xlg--auto,
  .bx--grid--condensed
  .bx--col-xlg--auto {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-xlg,
  .bx--grid--narrow .bx--col-xlg, .bx--row--narrow
  .bx--col-xlg--auto,
  .bx--grid--narrow
  .bx--col-xlg--auto {
    padding-right: 1rem;
    padding-left: 0; }

@media (min-width: 82rem) {
  .bx--col,
  .bx--col-xlg {
    max-width: 100%;
    flex-basis: 0;
    flex-grow: 1; }
  .bx--col--auto,
  .bx--col-xlg--auto {
    width: auto;
    max-width: 100%;
    flex: 1 0 0%; }
  .bx--col-xlg-0 {
    display: none; }
  .bx--col-xlg-1 {
    display: block;
    max-width: 6.25%;
    flex: 0 0 6.25%; }
  .bx--col-xlg-2 {
    display: block;
    max-width: 12.5%;
    flex: 0 0 12.5%; }
  .bx--col-xlg-3 {
    display: block;
    max-width: 18.75%;
    flex: 0 0 18.75%; }
  .bx--col-xlg-4 {
    display: block;
    max-width: 25%;
    flex: 0 0 25%; }
  .bx--col-xlg-5 {
    display: block;
    max-width: 31.25%;
    flex: 0 0 31.25%; }
  .bx--col-xlg-6 {
    display: block;
    max-width: 37.5%;
    flex: 0 0 37.5%; }
  .bx--col-xlg-7 {
    display: block;
    max-width: 43.75%;
    flex: 0 0 43.75%; }
  .bx--col-xlg-8 {
    display: block;
    max-width: 50%;
    flex: 0 0 50%; }
  .bx--col-xlg-9 {
    display: block;
    max-width: 56.25%;
    flex: 0 0 56.25%; }
  .bx--col-xlg-10 {
    display: block;
    max-width: 62.5%;
    flex: 0 0 62.5%; }
  .bx--col-xlg-11 {
    display: block;
    max-width: 68.75%;
    flex: 0 0 68.75%; }
  .bx--col-xlg-12 {
    display: block;
    max-width: 75%;
    flex: 0 0 75%; }
  .bx--col-xlg-13 {
    display: block;
    max-width: 81.25%;
    flex: 0 0 81.25%; }
  .bx--col-xlg-14 {
    display: block;
    max-width: 87.5%;
    flex: 0 0 87.5%; }
  .bx--col-xlg-15 {
    display: block;
    max-width: 93.75%;
    flex: 0 0 93.75%; }
  .bx--col-xlg-16 {
    display: block;
    max-width: 100%;
    flex: 0 0 100%; }
  .bx--offset-xlg-0 {
    margin-left: 0; }
  .bx--offset-xlg-1 {
    margin-left: 6.25%; }
  .bx--offset-xlg-2 {
    margin-left: 12.5%; }
  .bx--offset-xlg-3 {
    margin-left: 18.75%; }
  .bx--offset-xlg-4 {
    margin-left: 25%; }
  .bx--offset-xlg-5 {
    margin-left: 31.25%; }
  .bx--offset-xlg-6 {
    margin-left: 37.5%; }
  .bx--offset-xlg-7 {
    margin-left: 43.75%; }
  .bx--offset-xlg-8 {
    margin-left: 50%; }
  .bx--offset-xlg-9 {
    margin-left: 56.25%; }
  .bx--offset-xlg-10 {
    margin-left: 62.5%; }
  .bx--offset-xlg-11 {
    margin-left: 68.75%; }
  .bx--offset-xlg-12 {
    margin-left: 75%; }
  .bx--offset-xlg-13 {
    margin-left: 81.25%; }
  .bx--offset-xlg-14 {
    margin-left: 87.5%; }
  .bx--offset-xlg-15 {
    margin-left: 93.75%; } }

.bx--col-max-0 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-max-0,
  .bx--grid--condensed .bx--col-max-0 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-max-0,
  .bx--grid--narrow .bx--col-max-0 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-max-1 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-max-1,
  .bx--grid--condensed .bx--col-max-1 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-max-1,
  .bx--grid--narrow .bx--col-max-1 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-max-2 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-max-2,
  .bx--grid--condensed .bx--col-max-2 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-max-2,
  .bx--grid--narrow .bx--col-max-2 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-max-3 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-max-3,
  .bx--grid--condensed .bx--col-max-3 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-max-3,
  .bx--grid--narrow .bx--col-max-3 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-max-4 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-max-4,
  .bx--grid--condensed .bx--col-max-4 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-max-4,
  .bx--grid--narrow .bx--col-max-4 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-max-5 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-max-5,
  .bx--grid--condensed .bx--col-max-5 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-max-5,
  .bx--grid--narrow .bx--col-max-5 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-max-6 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-max-6,
  .bx--grid--condensed .bx--col-max-6 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-max-6,
  .bx--grid--narrow .bx--col-max-6 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-max-7 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-max-7,
  .bx--grid--condensed .bx--col-max-7 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-max-7,
  .bx--grid--narrow .bx--col-max-7 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-max-8 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-max-8,
  .bx--grid--condensed .bx--col-max-8 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-max-8,
  .bx--grid--narrow .bx--col-max-8 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-max-9 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-max-9,
  .bx--grid--condensed .bx--col-max-9 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-max-9,
  .bx--grid--narrow .bx--col-max-9 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-max-10 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-max-10,
  .bx--grid--condensed .bx--col-max-10 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-max-10,
  .bx--grid--narrow .bx--col-max-10 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-max-11 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-max-11,
  .bx--grid--condensed .bx--col-max-11 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-max-11,
  .bx--grid--narrow .bx--col-max-11 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-max-12 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-max-12,
  .bx--grid--condensed .bx--col-max-12 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-max-12,
  .bx--grid--narrow .bx--col-max-12 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-max-13 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-max-13,
  .bx--grid--condensed .bx--col-max-13 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-max-13,
  .bx--grid--narrow .bx--col-max-13 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-max-14 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-max-14,
  .bx--grid--condensed .bx--col-max-14 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-max-14,
  .bx--grid--narrow .bx--col-max-14 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-max-15 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-max-15,
  .bx--grid--condensed .bx--col-max-15 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-max-15,
  .bx--grid--narrow .bx--col-max-15 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-max-16 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-max-16,
  .bx--grid--condensed .bx--col-max-16 {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-max-16,
  .bx--grid--narrow .bx--col-max-16 {
    padding-right: 1rem;
    padding-left: 0; }

.bx--col-max,
.bx--col-max--auto {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-max,
  .bx--grid--condensed .bx--col-max, .bx--row--condensed
  .bx--col-max--auto,
  .bx--grid--condensed
  .bx--col-max--auto {
    padding-right: 0.03125rem;
    padding-left: 0.03125rem; }
  .bx--row--narrow .bx--col-max,
  .bx--grid--narrow .bx--col-max, .bx--row--narrow
  .bx--col-max--auto,
  .bx--grid--narrow
  .bx--col-max--auto {
    padding-right: 1rem;
    padding-left: 0; }

@media (min-width: 99rem) {
  .bx--col,
  .bx--col-max {
    max-width: 100%;
    flex-basis: 0;
    flex-grow: 1; }
  .bx--col--auto,
  .bx--col-max--auto {
    width: auto;
    max-width: 100%;
    flex: 1 0 0%; }
  .bx--col-max-0 {
    display: none; }
  .bx--col-max-1 {
    display: block;
    max-width: 6.25%;
    flex: 0 0 6.25%; }
  .bx--col-max-2 {
    display: block;
    max-width: 12.5%;
    flex: 0 0 12.5%; }
  .bx--col-max-3 {
    display: block;
    max-width: 18.75%;
    flex: 0 0 18.75%; }
  .bx--col-max-4 {
    display: block;
    max-width: 25%;
    flex: 0 0 25%; }
  .bx--col-max-5 {
    display: block;
    max-width: 31.25%;
    flex: 0 0 31.25%; }
  .bx--col-max-6 {
    display: block;
    max-width: 37.5%;
    flex: 0 0 37.5%; }
  .bx--col-max-7 {
    display: block;
    max-width: 43.75%;
    flex: 0 0 43.75%; }
  .bx--col-max-8 {
    display: block;
    max-width: 50%;
    flex: 0 0 50%; }
  .bx--col-max-9 {
    display: block;
    max-width: 56.25%;
    flex: 0 0 56.25%; }
  .bx--col-max-10 {
    display: block;
    max-width: 62.5%;
    flex: 0 0 62.5%; }
  .bx--col-max-11 {
    display: block;
    max-width: 68.75%;
    flex: 0 0 68.75%; }
  .bx--col-max-12 {
    display: block;
    max-width: 75%;
    flex: 0 0 75%; }
  .bx--col-max-13 {
    display: block;
    max-width: 81.25%;
    flex: 0 0 81.25%; }
  .bx--col-max-14 {
    display: block;
    max-width: 87.5%;
    flex: 0 0 87.5%; }
  .bx--col-max-15 {
    display: block;
    max-width: 93.75%;
    flex: 0 0 93.75%; }
  .bx--col-max-16 {
    display: block;
    max-width: 100%;
    flex: 0 0 100%; }
  .bx--offset-max-0 {
    margin-left: 0; }
  .bx--offset-max-1 {
    margin-left: 6.25%; }
  .bx--offset-max-2 {
    margin-left: 12.5%; }
  .bx--offset-max-3 {
    margin-left: 18.75%; }
  .bx--offset-max-4 {
    margin-left: 25%; }
  .bx--offset-max-5 {
    margin-left: 31.25%; }
  .bx--offset-max-6 {
    margin-left: 37.5%; }
  .bx--offset-max-7 {
    margin-left: 43.75%; }
  .bx--offset-max-8 {
    margin-left: 50%; }
  .bx--offset-max-9 {
    margin-left: 56.25%; }
  .bx--offset-max-10 {
    margin-left: 62.5%; }
  .bx--offset-max-11 {
    margin-left: 68.75%; }
  .bx--offset-max-12 {
    margin-left: 75%; }
  .bx--offset-max-13 {
    margin-left: 81.25%; }
  .bx--offset-max-14 {
    margin-left: 87.5%; }
  .bx--offset-max-15 {
    margin-left: 93.75%; } }

.bx--no-gutter,
.bx--row.bx--no-gutter [class*='bx--col'] {
  padding-right: 0;
  padding-left: 0; }

.bx--no-gutter--start,
.bx--row.bx--no-gutter--start [class*='bx--col'] {
  padding-left: 0; }

.bx--no-gutter--end,
.bx--row.bx--no-gutter--end [class*='bx--col'] {
  padding-right: 0; }

.bx--no-gutter--left,
.bx--row.bx--no-gutter--left [class*='bx--col'] {
  padding-left: 0; }

.bx--no-gutter--right,
.bx--row.bx--no-gutter--right [class*='bx--col'] {
  padding-right: 0; }

.bx--hang--start {
  padding-left: 1rem; }

.bx--hang--end {
  padding-right: 1rem; }

.bx--hang--left {
  padding-left: 1rem; }

.bx--hang--right {
  padding-right: 1rem; }

.bx--aspect-ratio {
  position: relative; }

.bx--aspect-ratio::before {
  width: 1px;
  height: 0;
  margin-left: -1px;
  content: '';
  float: left; }

.bx--aspect-ratio::after {
  display: table;
  clear: both;
  content: ''; }

.bx--aspect-ratio--16x9::before {
  padding-top: 56.25%; }

.bx--aspect-ratio--9x16::before {
  padding-top: 177.77778%; }

.bx--aspect-ratio--2x1::before {
  padding-top: 50%; }

.bx--aspect-ratio--1x2::before {
  padding-top: 200%; }

.bx--aspect-ratio--4x3::before {
  padding-top: 75%; }

.bx--aspect-ratio--3x4::before {
  padding-top: 133.33333%; }

.bx--aspect-ratio--3x2::before {
  padding-top: 66.66667%; }

.bx--aspect-ratio--2x3::before {
  padding-top: 150%; }

.bx--aspect-ratio--1x1::before {
  padding-top: 100%; }

.bx--aspect-ratio--object {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

html {
  font-size: 100%; }

body {
  font-weight: 400;
  font-family: 'IBM Plex Sans', 'Helvetica Neue', Arial, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility; }

code {
  font-family: 'IBM Plex Mono', 'Menlo', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', Courier, monospace; }

strong {
  font-weight: 600; }

.bx--type-mono {
  font-family: 'IBM Plex Mono', 'Menlo', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', Courier, monospace; }

.bx--type-sans {
  font-family: 'IBM Plex Sans', 'Helvetica Neue', Arial, sans-serif; }

.bx--type-sans-condensed {
  font-family: 'IBM Plex Sans Condensed', 'Helvetica Neue', Arial, sans-serif; }

.bx--type-sans-hebrew {
  font-family: 'IBM Plex Sans Hebrew', 'Helvetica Hebrew', 'Arial Hebrew', sans-serif; }

.bx--type-serif {
  font-family: 'IBM Plex Serif', 'Georgia', Times, serif; }

.bx--type-light {
  font-weight: 300; }

.bx--type-regular {
  font-weight: 400; }

.bx--type-semibold {
  font-weight: 600; }

.bx--type-italic {
  font-style: italic; }

.bx--type-caption-01 {
  font-size: var(--cds-caption-01-font-size, 0.75rem);
  font-weight: var(--cds-caption-01-font-weight, 400);
  line-height: var(--cds-caption-01-line-height, 1.33333);
  letter-spacing: var(--cds-caption-01-letter-spacing, 0.32px); }

.bx--type-caption-02 {
  font-size: var(--cds-caption-02-font-size, 0.875rem);
  font-weight: var(--cds-caption-02-font-weight, 400);
  line-height: var(--cds-caption-02-line-height, 1.28572);
  letter-spacing: var(--cds-caption-02-letter-spacing, 0.32px); }

.bx--type-label-01 {
  font-size: var(--cds-label-01-font-size, 0.75rem);
  font-weight: var(--cds-label-01-font-weight, 400);
  line-height: var(--cds-label-01-line-height, 1.33333);
  letter-spacing: var(--cds-label-01-letter-spacing, 0.32px); }

.bx--type-label-02 {
  font-size: var(--cds-label-02-font-size, 0.875rem);
  font-weight: var(--cds-label-02-font-weight, 400);
  line-height: var(--cds-label-02-line-height, 1.28572);
  letter-spacing: var(--cds-label-02-letter-spacing, 0.16px); }

.bx--type-helper-text-01 {
  font-size: var(--cds-helper-text-01-font-size, 0.75rem);
  line-height: var(--cds-helper-text-01-line-height, 1.33333);
  letter-spacing: var(--cds-helper-text-01-letter-spacing, 0.32px); }

.bx--type-helper-text-02 {
  font-size: var(--cds-helper-text-02-font-size, 0.875rem);
  line-height: var(--cds-helper-text-02-line-height, 1.28572);
  letter-spacing: var(--cds-helper-text-02-letter-spacing, 0.16px); }

.bx--type-body-short-01 {
  font-size: var(--cds-body-short-01-font-size, 0.875rem);
  font-weight: var(--cds-body-short-01-font-weight, 400);
  line-height: var(--cds-body-short-01-line-height, 1.28572);
  letter-spacing: var(--cds-body-short-01-letter-spacing, 0.16px); }

.bx--type-body-short-02 {
  font-size: var(--cds-body-short-02-font-size, 1rem);
  font-weight: var(--cds-body-short-02-font-weight, 400);
  line-height: var(--cds-body-short-02-line-height, 1.375);
  letter-spacing: var(--cds-body-short-02-letter-spacing, 0); }

.bx--type-body-long-01 {
  font-size: var(--cds-body-long-01-font-size, 0.875rem);
  font-weight: var(--cds-body-long-01-font-weight, 400);
  line-height: var(--cds-body-long-01-line-height, 1.42857);
  letter-spacing: var(--cds-body-long-01-letter-spacing, 0.16px); }

.bx--type-body-long-02 {
  font-size: var(--cds-body-long-02-font-size, 1rem);
  font-weight: var(--cds-body-long-02-font-weight, 400);
  line-height: var(--cds-body-long-02-line-height, 1.5);
  letter-spacing: var(--cds-body-long-02-letter-spacing, 0); }

.bx--type-code-01 {
  font-family: var(--cds-code-01-font-family, 'IBM Plex Mono', 'Menlo', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', Courier, monospace);
  font-size: var(--cds-code-01-font-size, 0.75rem);
  font-weight: var(--cds-code-01-font-weight, 400);
  line-height: var(--cds-code-01-line-height, 1.33333);
  letter-spacing: var(--cds-code-01-letter-spacing, 0.32px); }

.bx--type-code-02 {
  font-family: var(--cds-code-02-font-family, 'IBM Plex Mono', 'Menlo', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', Courier, monospace);
  font-size: var(--cds-code-02-font-size, 0.875rem);
  font-weight: var(--cds-code-02-font-weight, 400);
  line-height: var(--cds-code-02-line-height, 1.42857);
  letter-spacing: var(--cds-code-02-letter-spacing, 0.32px); }

.bx--type-heading-01 {
  font-size: var(--cds-heading-01-font-size, 0.875rem);
  font-weight: var(--cds-heading-01-font-weight, 600);
  line-height: var(--cds-heading-01-line-height, 1.42857);
  letter-spacing: var(--cds-heading-01-letter-spacing, 0.16px); }

.bx--type-heading-02 {
  font-size: var(--cds-heading-02-font-size, 1rem);
  font-weight: var(--cds-heading-02-font-weight, 600);
  line-height: var(--cds-heading-02-line-height, 1.5);
  letter-spacing: var(--cds-heading-02-letter-spacing, 0); }

.bx--type-productive-heading-01 {
  font-size: var(--cds-productive-heading-01-font-size, 0.875rem);
  font-weight: var(--cds-productive-heading-01-font-weight, 600);
  line-height: var(--cds-productive-heading-01-line-height, 1.28572);
  letter-spacing: var(--cds-productive-heading-01-letter-spacing, 0.16px); }

.bx--type-productive-heading-02 {
  font-size: var(--cds-productive-heading-02-font-size, 1rem);
  font-weight: var(--cds-productive-heading-02-font-weight, 600);
  line-height: var(--cds-productive-heading-02-line-height, 1.375);
  letter-spacing: var(--cds-productive-heading-02-letter-spacing, 0); }

.bx--type-productive-heading-03 {
  font-size: var(--cds-productive-heading-03-font-size, 1.25rem);
  font-weight: var(--cds-productive-heading-03-font-weight, 400);
  line-height: var(--cds-productive-heading-03-line-height, 1.4);
  letter-spacing: var(--cds-productive-heading-03-letter-spacing, 0); }

.bx--type-productive-heading-04 {
  font-size: var(--cds-productive-heading-04-font-size, 1.75rem);
  font-weight: var(--cds-productive-heading-04-font-weight, 400);
  line-height: var(--cds-productive-heading-04-line-height, 1.28572);
  letter-spacing: var(--cds-productive-heading-04-letter-spacing, 0); }

.bx--type-productive-heading-05 {
  font-size: var(--cds-productive-heading-05-font-size, 2rem);
  font-weight: var(--cds-productive-heading-05-font-weight, 400);
  line-height: var(--cds-productive-heading-05-line-height, 1.25);
  letter-spacing: var(--cds-productive-heading-05-letter-spacing, 0); }

.bx--type-productive-heading-06 {
  font-size: var(--cds-productive-heading-06-font-size, 2.625rem);
  font-weight: var(--cds-productive-heading-06-font-weight, 300);
  line-height: var(--cds-productive-heading-06-line-height, 1.199);
  letter-spacing: var(--cds-productive-heading-06-letter-spacing, 0); }

.bx--type-productive-heading-07 {
  font-size: var(--cds-productive-heading-07-font-size, 3.375rem);
  font-weight: var(--cds-productive-heading-07-font-weight, 300);
  line-height: var(--cds-productive-heading-07-line-height, 1.199);
  letter-spacing: var(--cds-productive-heading-07-letter-spacing, 0); }

.bx--type-expressive-paragraph-01 {
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 1.33333;
  letter-spacing: 0;
  font-size: calc(1.5rem + 0.25 * ((100vw - 20rem) / 46)); }
  @media (min-width: 66rem) {
    .bx--type-expressive-paragraph-01 {
      font-size: 1.75rem;
      line-height: 1.28572;
      font-size: calc(1.75rem + 0.25 * ((100vw - 66rem) / 33)); } }
  @media (min-width: 99rem) {
    .bx--type-expressive-paragraph-01 {
      font-size: 2rem;
      line-height: 1.25;
      font-size: 2rem; } }

.bx--type-expressive-heading-01 {
  font-size: var(--cds-expressive-heading-01-font-size, 0.875rem);
  font-weight: var(--cds-expressive-heading-01-font-weight, 600);
  line-height: var(--cds-expressive-heading-01-line-height, 1.25);
  letter-spacing: var(--cds-expressive-heading-01-letter-spacing, 0.16px); }

.bx--type-expressive-heading-02 {
  font-size: var(--cds-expressive-heading-02-font-size, 1rem);
  font-weight: var(--cds-expressive-heading-02-font-weight, 600);
  line-height: var(--cds-expressive-heading-02-line-height, 1.5);
  letter-spacing: var(--cds-expressive-heading-02-letter-spacing, 0); }

.bx--type-expressive-heading-03 {
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.4;
  letter-spacing: 0;
  font-size: calc(1.25rem + 0 * ((100vw - 20rem) / 62)); }
  @media (min-width: 82rem) {
    .bx--type-expressive-heading-03 {
      font-size: 1.25rem;
      line-height: 1.25;
      font-size: calc(1.25rem + 0.25 * ((100vw - 82rem) / 17)); } }
  @media (min-width: 99rem) {
    .bx--type-expressive-heading-03 {
      font-size: 1.5rem;
      line-height: 1.334;
      font-size: 1.5rem; } }

.bx--type-expressive-heading-04 {
  font-size: 1.75rem;
  font-weight: 400;
  line-height: 1.28572;
  letter-spacing: 0;
  font-size: calc(1.75rem + 0 * ((100vw - 20rem) / 62)); }
  @media (min-width: 82rem) {
    .bx--type-expressive-heading-04 {
      font-size: 1.75rem;
      line-height: 1.25;
      font-size: calc(1.75rem + 0.25 * ((100vw - 82rem) / 17)); } }
  @media (min-width: 99rem) {
    .bx--type-expressive-heading-04 {
      font-size: 2rem;
      font-size: 2rem; } }

.bx--type-expressive-heading-05 {
  font-size: 2rem;
  font-weight: 400;
  line-height: 1.25;
  letter-spacing: 0;
  font-size: calc(2rem + 0.25 * ((100vw - 20rem) / 22)); }
  @media (min-width: 42rem) {
    .bx--type-expressive-heading-05 {
      font-size: 2.25rem;
      font-weight: 300;
      line-height: 1.22;
      letter-spacing: 0;
      font-size: calc(2.25rem + 0.375 * ((100vw - 42rem) / 24)); } }
  @media (min-width: 66rem) {
    .bx--type-expressive-heading-05 {
      font-size: 2.625rem;
      font-weight: 300;
      line-height: 1.19;
      letter-spacing: 0;
      font-size: calc(2.625rem + 0.375 * ((100vw - 66rem) / 16)); } }
  @media (min-width: 82rem) {
    .bx--type-expressive-heading-05 {
      font-size: 3rem;
      font-weight: 300;
      line-height: 1.17;
      letter-spacing: 0;
      font-size: calc(3rem + 0.75 * ((100vw - 82rem) / 17)); } }
  @media (min-width: 99rem) {
    .bx--type-expressive-heading-05 {
      font-size: 3.75rem;
      font-weight: 300;
      letter-spacing: 0;
      font-size: 3.75rem; } }

.bx--type-expressive-heading-06 {
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.25;
  letter-spacing: 0;
  font-size: calc(2rem + 0.25 * ((100vw - 20rem) / 22)); }
  @media (min-width: 42rem) {
    .bx--type-expressive-heading-06 {
      font-size: 2.25rem;
      font-weight: 600;
      line-height: 1.22;
      letter-spacing: 0;
      font-size: calc(2.25rem + 0.375 * ((100vw - 42rem) / 24)); } }
  @media (min-width: 66rem) {
    .bx--type-expressive-heading-06 {
      font-size: 2.625rem;
      font-weight: 600;
      line-height: 1.19;
      letter-spacing: 0;
      font-size: calc(2.625rem + 0.375 * ((100vw - 66rem) / 16)); } }
  @media (min-width: 82rem) {
    .bx--type-expressive-heading-06 {
      font-size: 3rem;
      font-weight: 600;
      line-height: 1.17;
      letter-spacing: 0;
      font-size: calc(3rem + 0.75 * ((100vw - 82rem) / 17)); } }
  @media (min-width: 99rem) {
    .bx--type-expressive-heading-06 {
      font-size: 3.75rem;
      font-weight: 600;
      letter-spacing: 0;
      font-size: 3.75rem; } }

.bx--type-quotation-01 {
  font-family: 'IBM Plex Serif', 'Georgia', Times, serif;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.3;
  letter-spacing: 0;
  font-size: calc(1.25rem + 0 * ((100vw - 20rem) / 22)); }
  @media (min-width: 42rem) {
    .bx--type-quotation-01 {
      font-size: 1.25rem;
      font-weight: 400;
      letter-spacing: 0;
      font-size: calc(1.25rem + 0.25 * ((100vw - 42rem) / 24)); } }
  @media (min-width: 66rem) {
    .bx--type-quotation-01 {
      font-size: 1.5rem;
      font-weight: 400;
      line-height: 1.334;
      letter-spacing: 0;
      font-size: calc(1.5rem + 0.25 * ((100vw - 66rem) / 16)); } }
  @media (min-width: 82rem) {
    .bx--type-quotation-01 {
      font-size: 1.75rem;
      font-weight: 400;
      line-height: 1.28572;
      letter-spacing: 0;
      font-size: calc(1.75rem + 0.25 * ((100vw - 82rem) / 17)); } }
  @media (min-width: 99rem) {
    .bx--type-quotation-01 {
      font-size: 2rem;
      font-weight: 400;
      line-height: 1.25;
      letter-spacing: 0;
      font-size: 2rem; } }

.bx--type-quotation-02 {
  font-family: 'IBM Plex Serif', 'Georgia', Times, serif;
  font-size: 2rem;
  font-weight: 300;
  line-height: 1.25;
  letter-spacing: 0;
  font-size: calc(2rem + 0.25 * ((100vw - 20rem) / 22)); }
  @media (min-width: 42rem) {
    .bx--type-quotation-02 {
      font-size: 2.25rem;
      line-height: 1.22;
      font-size: calc(2.25rem + 0.375 * ((100vw - 42rem) / 24)); } }
  @media (min-width: 66rem) {
    .bx--type-quotation-02 {
      font-size: 2.625rem;
      line-height: 1.19;
      font-size: calc(2.625rem + 0.375 * ((100vw - 66rem) / 16)); } }
  @media (min-width: 82rem) {
    .bx--type-quotation-02 {
      font-size: 3rem;
      line-height: 1.17;
      font-size: calc(3rem + 0.75 * ((100vw - 82rem) / 17)); } }
  @media (min-width: 99rem) {
    .bx--type-quotation-02 {
      font-size: 3.75rem;
      font-size: 3.75rem; } }

.bx--type-display-01 {
  font-size: 2.625rem;
  font-weight: 300;
  line-height: 1.19;
  letter-spacing: 0;
  font-size: calc(2.625rem + 0 * ((100vw - 20rem) / 22)); }
  @media (min-width: 42rem) {
    .bx--type-display-01 {
      font-size: 2.625rem;
      font-size: calc(2.625rem + 0.75 * ((100vw - 42rem) / 24)); } }
  @media (min-width: 66rem) {
    .bx--type-display-01 {
      font-size: 3.375rem;
      font-size: calc(3.375rem + 0.375 * ((100vw - 66rem) / 16)); } }
  @media (min-width: 82rem) {
    .bx--type-display-01 {
      font-size: 3.75rem;
      line-height: 1.17;
      font-size: calc(3.75rem + 1 * ((100vw - 82rem) / 17)); } }
  @media (min-width: 99rem) {
    .bx--type-display-01 {
      font-size: 4.75rem;
      line-height: 1.13;
      font-size: 4.75rem; } }

.bx--type-display-02 {
  font-size: 2.625rem;
  font-weight: 600;
  line-height: 1.19;
  letter-spacing: 0;
  font-size: calc(2.625rem + 0 * ((100vw - 20rem) / 22)); }
  @media (min-width: 42rem) {
    .bx--type-display-02 {
      font-size: 2.625rem;
      font-size: calc(2.625rem + 0.75 * ((100vw - 42rem) / 24)); } }
  @media (min-width: 66rem) {
    .bx--type-display-02 {
      font-size: 3.375rem;
      font-size: calc(3.375rem + 0.375 * ((100vw - 66rem) / 16)); } }
  @media (min-width: 82rem) {
    .bx--type-display-02 {
      font-size: 3.75rem;
      line-height: 1.16;
      font-size: calc(3.75rem + 1 * ((100vw - 82rem) / 17)); } }
  @media (min-width: 99rem) {
    .bx--type-display-02 {
      font-size: 4.75rem;
      line-height: 1.13;
      font-size: 4.75rem; } }

.bx--type-display-03 {
  font-size: 2.625rem;
  font-weight: 300;
  line-height: 1.19;
  letter-spacing: 0;
  font-size: calc(2.625rem + 1.625 * ((100vw - 20rem) / 22)); }
  @media (min-width: 42rem) {
    .bx--type-display-03 {
      font-size: 4.25rem;
      line-height: 1.15;
      font-size: calc(4.25rem + 1.5 * ((100vw - 42rem) / 24)); } }
  @media (min-width: 66rem) {
    .bx--type-display-03 {
      font-size: 5.75rem;
      line-height: 1.11;
      letter-spacing: -0.64px;
      font-size: calc(5.75rem + 1.875 * ((100vw - 66rem) / 16)); } }
  @media (min-width: 82rem) {
    .bx--type-display-03 {
      font-size: 7.625rem;
      line-height: 1.07;
      font-size: calc(7.625rem + 2.125 * ((100vw - 82rem) / 17)); } }
  @media (min-width: 99rem) {
    .bx--type-display-03 {
      font-size: 9.75rem;
      line-height: 1.05;
      letter-spacing: -0.96px;
      font-size: 9.75rem; } }

.bx--type-display-04 {
  font-size: 2.625rem;
  font-weight: 600;
  line-height: 1.19;
  letter-spacing: 0;
  font-size: calc(2.625rem + 1.625 * ((100vw - 20rem) / 22)); }
  @media (min-width: 42rem) {
    .bx--type-display-04 {
      font-size: 4.25rem;
      line-height: 1.15;
      font-size: calc(4.25rem + 1.5 * ((100vw - 42rem) / 24)); } }
  @media (min-width: 66rem) {
    .bx--type-display-04 {
      font-size: 5.75rem;
      line-height: 1.11;
      letter-spacing: -0.64px;
      font-size: calc(5.75rem + 1.875 * ((100vw - 66rem) / 16)); } }
  @media (min-width: 82rem) {
    .bx--type-display-04 {
      font-size: 7.625rem;
      line-height: 1.07;
      letter-spacing: -0.64px;
      font-size: calc(7.625rem + 2.125 * ((100vw - 82rem) / 17)); } }
  @media (min-width: 99rem) {
    .bx--type-display-04 {
      font-size: 9.75rem;
      line-height: 1.05;
      letter-spacing: -0.96px;
      font-size: 9.75rem; } }

.bx--type-legal-01 {
  font-size: var(--cds-legal-01-font-size, 0.75rem);
  font-weight: var(--cds-legal-01-font-weight, 400);
  line-height: var(--cds-legal-01-line-height, 1.33333);
  letter-spacing: var(--cds-legal-01-letter-spacing, 0.32px); }

.bx--type-legal-02 {
  font-size: var(--cds-legal-02-font-size, 0.875rem);
  font-weight: var(--cds-legal-02-font-weight, 400);
  line-height: var(--cds-legal-02-line-height, 1.28572);
  letter-spacing: var(--cds-legal-02-letter-spacing, 0.16px); }

.bx--type-body-compact-01 {
  font-size: var(--cds-body-compact-01-font-size, 0.875rem);
  font-weight: var(--cds-body-compact-01-font-weight, 400);
  line-height: var(--cds-body-compact-01-line-height, 1.28572);
  letter-spacing: var(--cds-body-compact-01-letter-spacing, 0.16px); }

.bx--type-body-compact-02 {
  font-size: var(--cds-body-compact-02-font-size, 1rem);
  font-weight: var(--cds-body-compact-02-font-weight, 400);
  line-height: var(--cds-body-compact-02-line-height, 1.375);
  letter-spacing: var(--cds-body-compact-02-letter-spacing, 0); }

.bx--type-heading-compact-01 {
  font-size: var(--cds-heading-compact-01-font-size, 0.875rem);
  font-weight: var(--cds-heading-compact-01-font-weight, 600);
  line-height: var(--cds-heading-compact-01-line-height, 1.28572);
  letter-spacing: var(--cds-heading-compact-01-letter-spacing, 0.16px); }

.bx--type-heading-compact-02 {
  font-size: var(--cds-heading-compact-02-font-size, 1rem);
  font-weight: var(--cds-heading-compact-02-font-weight, 600);
  line-height: var(--cds-heading-compact-02-line-height, 1.375);
  letter-spacing: var(--cds-heading-compact-02-letter-spacing, 0); }

.bx--type-body-01 {
  font-size: var(--cds-body-01-font-size, 0.875rem);
  font-weight: var(--cds-body-01-font-weight, 400);
  line-height: var(--cds-body-01-line-height, 1.42857);
  letter-spacing: var(--cds-body-01-letter-spacing, 0.16px); }

.bx--type-body-02 {
  font-size: var(--cds-body-02-font-size, 1rem);
  font-weight: var(--cds-body-02-font-weight, 400);
  line-height: var(--cds-body-02-line-height, 1.5);
  letter-spacing: var(--cds-body-02-letter-spacing, 0); }

.bx--type-heading-03 {
  font-size: var(--cds-heading-03-font-size, 1.25rem);
  font-weight: var(--cds-heading-03-font-weight, 400);
  line-height: var(--cds-heading-03-line-height, 1.4);
  letter-spacing: var(--cds-heading-03-letter-spacing, 0); }

.bx--type-heading-04 {
  font-size: var(--cds-heading-04-font-size, 1.75rem);
  font-weight: var(--cds-heading-04-font-weight, 400);
  line-height: var(--cds-heading-04-line-height, 1.28572);
  letter-spacing: var(--cds-heading-04-letter-spacing, 0); }

.bx--type-heading-05 {
  font-size: var(--cds-heading-05-font-size, 2rem);
  font-weight: var(--cds-heading-05-font-weight, 400);
  line-height: var(--cds-heading-05-line-height, 1.25);
  letter-spacing: var(--cds-heading-05-letter-spacing, 0); }

.bx--type-heading-06 {
  font-size: var(--cds-heading-06-font-size, 2.625rem);
  font-weight: var(--cds-heading-06-font-weight, 300);
  line-height: var(--cds-heading-06-line-height, 1.199);
  letter-spacing: var(--cds-heading-06-letter-spacing, 0); }

.bx--type-heading-07 {
  font-size: var(--cds-heading-07-font-size, 3.375rem);
  font-weight: var(--cds-heading-07-font-weight, 300);
  line-height: var(--cds-heading-07-line-height, 1.199);
  letter-spacing: var(--cds-heading-07-letter-spacing, 0); }

.bx--type-fluid-heading-03 {
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.4;
  letter-spacing: 0;
  font-size: calc(1.25rem + 0 * ((100vw - 20rem) / 62)); }
  @media (min-width: 82rem) {
    .bx--type-fluid-heading-03 {
      font-size: 1.25rem;
      line-height: 1.25;
      font-size: calc(1.25rem + 0.25 * ((100vw - 82rem) / 17)); } }
  @media (min-width: 99rem) {
    .bx--type-fluid-heading-03 {
      font-size: 1.5rem;
      line-height: 1.334;
      font-size: 1.5rem; } }

.bx--type-fluid-heading-04 {
  font-size: 1.75rem;
  font-weight: 400;
  line-height: 1.28572;
  letter-spacing: 0;
  font-size: calc(1.75rem + 0 * ((100vw - 20rem) / 62)); }
  @media (min-width: 82rem) {
    .bx--type-fluid-heading-04 {
      font-size: 1.75rem;
      line-height: 1.25;
      font-size: calc(1.75rem + 0.25 * ((100vw - 82rem) / 17)); } }
  @media (min-width: 99rem) {
    .bx--type-fluid-heading-04 {
      font-size: 2rem;
      font-size: 2rem; } }

.bx--type-fluid-heading-05 {
  font-size: 2rem;
  font-weight: 400;
  line-height: 1.25;
  letter-spacing: 0;
  font-size: calc(2rem + 0.25 * ((100vw - 20rem) / 22)); }
  @media (min-width: 42rem) {
    .bx--type-fluid-heading-05 {
      font-size: 2.25rem;
      font-weight: 300;
      line-height: 1.22;
      letter-spacing: 0;
      font-size: calc(2.25rem + 0.375 * ((100vw - 42rem) / 24)); } }
  @media (min-width: 66rem) {
    .bx--type-fluid-heading-05 {
      font-size: 2.625rem;
      font-weight: 300;
      line-height: 1.19;
      letter-spacing: 0;
      font-size: calc(2.625rem + 0.375 * ((100vw - 66rem) / 16)); } }
  @media (min-width: 82rem) {
    .bx--type-fluid-heading-05 {
      font-size: 3rem;
      font-weight: 300;
      line-height: 1.17;
      letter-spacing: 0;
      font-size: calc(3rem + 0.75 * ((100vw - 82rem) / 17)); } }
  @media (min-width: 99rem) {
    .bx--type-fluid-heading-05 {
      font-size: 3.75rem;
      font-weight: 300;
      letter-spacing: 0;
      font-size: 3.75rem; } }

.bx--type-fluid-heading-06 {
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.25;
  letter-spacing: 0;
  font-size: calc(2rem + 0.25 * ((100vw - 20rem) / 22)); }
  @media (min-width: 42rem) {
    .bx--type-fluid-heading-06 {
      font-size: 2.25rem;
      font-weight: 600;
      line-height: 1.22;
      letter-spacing: 0;
      font-size: calc(2.25rem + 0.375 * ((100vw - 42rem) / 24)); } }
  @media (min-width: 66rem) {
    .bx--type-fluid-heading-06 {
      font-size: 2.625rem;
      font-weight: 600;
      line-height: 1.19;
      letter-spacing: 0;
      font-size: calc(2.625rem + 0.375 * ((100vw - 66rem) / 16)); } }
  @media (min-width: 82rem) {
    .bx--type-fluid-heading-06 {
      font-size: 3rem;
      font-weight: 600;
      line-height: 1.17;
      letter-spacing: 0;
      font-size: calc(3rem + 0.75 * ((100vw - 82rem) / 17)); } }
  @media (min-width: 99rem) {
    .bx--type-fluid-heading-06 {
      font-size: 3.75rem;
      font-weight: 600;
      letter-spacing: 0;
      font-size: 3.75rem; } }

.bx--type-fluid-paragraph-01 {
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 1.334;
  letter-spacing: 0;
  font-size: calc(1.5rem + 0.25 * ((100vw - 20rem) / 46)); }
  @media (min-width: 66rem) {
    .bx--type-fluid-paragraph-01 {
      font-size: 1.75rem;
      line-height: 1.28572;
      font-size: calc(1.75rem + 0.25 * ((100vw - 66rem) / 33)); } }
  @media (min-width: 99rem) {
    .bx--type-fluid-paragraph-01 {
      font-size: 2rem;
      line-height: 1.25;
      font-size: 2rem; } }

.bx--type-fluid-quotation-01 {
  font-family: 'IBM Plex Serif', 'Georgia', Times, serif;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.3;
  letter-spacing: 0;
  font-size: calc(1.25rem + 0 * ((100vw - 20rem) / 22)); }
  @media (min-width: 42rem) {
    .bx--type-fluid-quotation-01 {
      font-size: 1.25rem;
      font-weight: 400;
      letter-spacing: 0;
      font-size: calc(1.25rem + 0.25 * ((100vw - 42rem) / 24)); } }
  @media (min-width: 66rem) {
    .bx--type-fluid-quotation-01 {
      font-size: 1.5rem;
      font-weight: 400;
      line-height: 1.334;
      letter-spacing: 0;
      font-size: calc(1.5rem + 0.25 * ((100vw - 66rem) / 16)); } }
  @media (min-width: 82rem) {
    .bx--type-fluid-quotation-01 {
      font-size: 1.75rem;
      font-weight: 400;
      line-height: 1.28572;
      letter-spacing: 0;
      font-size: calc(1.75rem + 0.25 * ((100vw - 82rem) / 17)); } }
  @media (min-width: 99rem) {
    .bx--type-fluid-quotation-01 {
      font-size: 2rem;
      font-weight: 400;
      line-height: 1.25;
      letter-spacing: 0;
      font-size: 2rem; } }

.bx--type-fluid-quotation-02 {
  font-family: 'IBM Plex Serif', 'Georgia', Times, serif;
  font-size: 2rem;
  font-weight: 300;
  line-height: 1.25;
  letter-spacing: 0;
  font-size: calc(2rem + 0.25 * ((100vw - 20rem) / 22)); }
  @media (min-width: 42rem) {
    .bx--type-fluid-quotation-02 {
      font-size: 2.25rem;
      line-height: 1.22;
      font-size: calc(2.25rem + 0.375 * ((100vw - 42rem) / 24)); } }
  @media (min-width: 66rem) {
    .bx--type-fluid-quotation-02 {
      font-size: 2.625rem;
      line-height: 1.19;
      font-size: calc(2.625rem + 0.375 * ((100vw - 66rem) / 16)); } }
  @media (min-width: 82rem) {
    .bx--type-fluid-quotation-02 {
      font-size: 3rem;
      line-height: 1.17;
      font-size: calc(3rem + 0.75 * ((100vw - 82rem) / 17)); } }
  @media (min-width: 99rem) {
    .bx--type-fluid-quotation-02 {
      font-size: 3.75rem;
      font-size: 3.75rem; } }

.bx--type-fluid-display-01 {
  font-size: 2.625rem;
  font-weight: 300;
  line-height: 1.19;
  letter-spacing: 0;
  font-size: calc(2.625rem + 0 * ((100vw - 20rem) / 22)); }
  @media (min-width: 42rem) {
    .bx--type-fluid-display-01 {
      font-size: 2.625rem;
      font-size: calc(2.625rem + 0.75 * ((100vw - 42rem) / 24)); } }
  @media (min-width: 66rem) {
    .bx--type-fluid-display-01 {
      font-size: 3.375rem;
      font-size: calc(3.375rem + 0.375 * ((100vw - 66rem) / 16)); } }
  @media (min-width: 82rem) {
    .bx--type-fluid-display-01 {
      font-size: 3.75rem;
      line-height: 1.17;
      font-size: calc(3.75rem + 1 * ((100vw - 82rem) / 17)); } }
  @media (min-width: 99rem) {
    .bx--type-fluid-display-01 {
      font-size: 4.75rem;
      line-height: 1.13;
      font-size: 4.75rem; } }

.bx--type-fluid-display-02 {
  font-size: 2.625rem;
  font-weight: 600;
  line-height: 1.19;
  letter-spacing: 0;
  font-size: calc(2.625rem + 0 * ((100vw - 20rem) / 22)); }
  @media (min-width: 42rem) {
    .bx--type-fluid-display-02 {
      font-size: 2.625rem;
      font-size: calc(2.625rem + 0.75 * ((100vw - 42rem) / 24)); } }
  @media (min-width: 66rem) {
    .bx--type-fluid-display-02 {
      font-size: 3.375rem;
      font-size: calc(3.375rem + 0.375 * ((100vw - 66rem) / 16)); } }
  @media (min-width: 82rem) {
    .bx--type-fluid-display-02 {
      font-size: 3.75rem;
      line-height: 1.16;
      font-size: calc(3.75rem + 1 * ((100vw - 82rem) / 17)); } }
  @media (min-width: 99rem) {
    .bx--type-fluid-display-02 {
      font-size: 4.75rem;
      line-height: 1.13;
      font-size: 4.75rem; } }

.bx--type-fluid-display-03 {
  font-size: 2.625rem;
  font-weight: 300;
  line-height: 1.19;
  letter-spacing: 0;
  font-size: calc(2.625rem + 1.625 * ((100vw - 20rem) / 22)); }
  @media (min-width: 42rem) {
    .bx--type-fluid-display-03 {
      font-size: 4.25rem;
      line-height: 1.15;
      font-size: calc(4.25rem + 1.5 * ((100vw - 42rem) / 24)); } }
  @media (min-width: 66rem) {
    .bx--type-fluid-display-03 {
      font-size: 5.75rem;
      line-height: 1.11;
      letter-spacing: -0.64px;
      font-size: calc(5.75rem + 1.875 * ((100vw - 66rem) / 16)); } }
  @media (min-width: 82rem) {
    .bx--type-fluid-display-03 {
      font-size: 7.625rem;
      line-height: 1.07;
      font-size: calc(7.625rem + 2.125 * ((100vw - 82rem) / 17)); } }
  @media (min-width: 99rem) {
    .bx--type-fluid-display-03 {
      font-size: 9.75rem;
      line-height: 1.05;
      letter-spacing: -0.96px;
      font-size: 9.75rem; } }

.bx--type-fluid-display-04 {
  font-size: 2.625rem;
  font-weight: 600;
  line-height: 1.19;
  letter-spacing: 0;
  font-size: calc(2.625rem + 1.625 * ((100vw - 20rem) / 22)); }
  @media (min-width: 42rem) {
    .bx--type-fluid-display-04 {
      font-size: 4.25rem;
      line-height: 1.15;
      font-size: calc(4.25rem + 1.5 * ((100vw - 42rem) / 24)); } }
  @media (min-width: 66rem) {
    .bx--type-fluid-display-04 {
      font-size: 5.75rem;
      line-height: 1.11;
      letter-spacing: -0.64px;
      font-size: calc(5.75rem + 1.875 * ((100vw - 66rem) / 16)); } }
  @media (min-width: 82rem) {
    .bx--type-fluid-display-04 {
      font-size: 7.625rem;
      line-height: 1.07;
      letter-spacing: -0.64px;
      font-size: calc(7.625rem + 2.125 * ((100vw - 82rem) / 17)); } }
  @media (min-width: 99rem) {
    .bx--type-fluid-display-04 {
      font-size: 9.75rem;
      line-height: 1.05;
      letter-spacing: -0.96px;
      font-size: 9.75rem; } }

.bx--btn {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  font-size: var(--cds-body-short-01-font-size, 0.875rem);
  font-weight: var(--cds-body-short-01-font-weight, 400);
  line-height: var(--cds-body-short-01-line-height, 1.28572);
  letter-spacing: var(--cds-body-short-01-letter-spacing, 0.16px);
  position: relative;
  display: inline-flex;
  max-width: 20rem;
  min-height: 3rem;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: calc(0.875rem - 3px) 63px calc(0.875rem - 3px) 15px;
  margin: 0;
  border-radius: 0;
  cursor: pointer;
  outline: none;
  text-align: left;
  text-decoration: none;
  transition: background 70ms cubic-bezier(0, 0, 0.38, 0.9), box-shadow 70ms cubic-bezier(0, 0, 0.38, 0.9), border-color 70ms cubic-bezier(0, 0, 0.38, 0.9), outline 70ms cubic-bezier(0, 0, 0.38, 0.9);
  vertical-align: top; }
  .bx--btn *,
  .bx--btn *::before,
  .bx--btn *::after {
    box-sizing: inherit; }
  .bx--btn:disabled, .bx--btn:hover:disabled, .bx--btn:focus:disabled, .bx--btn.bx--btn--disabled, .bx--btn.bx--btn--disabled:hover, .bx--btn.bx--btn--disabled:focus {
    border-color: var(--cds-disabled-02, #c6c6c6);
    background: var(--cds-disabled-02, #c6c6c6);
    box-shadow: none;
    color: var(--cds-disabled-03, #8d8d8d);
    cursor: not-allowed; }
  .bx--btn .bx--btn__icon {
    position: absolute;
    right: 1rem;
    width: 1rem;
    height: 1rem;
    flex-shrink: 0; }

.bx--btn::-moz-focus-inner {
  padding: 0;
  border: 0; }

.bx--btn--primary {
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  background-color: var(--cds-interactive-01, #0f62fe);
  color: var(--cds-text-04, #ffffff); }
  .bx--btn--primary:hover {
    background-color: var(--cds-hover-primary, #0353e9); }
  .bx--btn--primary:focus {
    border-color: var(--cds-focus, #0f62fe);
    box-shadow: inset 0 0 0 1px var(--cds-focus, #0f62fe), inset 0 0 0 2px var(--cds-ui-background, #ffffff); }
  .bx--btn--primary:active {
    background-color: var(--cds-active-primary, #002d9c); }
  .bx--btn--primary .bx--btn__icon,
  .bx--btn--primary .bx--btn__icon path:not([data-icon-path]):not([fill='none']) {
    fill: currentColor; }
  .bx--btn--primary:hover {
    color: var(--cds-text-04, #ffffff); }

.bx--btn--secondary {
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  background-color: var(--cds-interactive-02, #393939);
  color: var(--cds-text-04, #ffffff); }
  .bx--btn--secondary:hover {
    background-color: var(--cds-hover-secondary, #4c4c4c); }
  .bx--btn--secondary:focus {
    border-color: var(--cds-focus, #0f62fe);
    box-shadow: inset 0 0 0 1px var(--cds-focus, #0f62fe), inset 0 0 0 2px var(--cds-ui-background, #ffffff); }
  .bx--btn--secondary:active {
    background-color: var(--cds-active-secondary, #6f6f6f); }
  .bx--btn--secondary .bx--btn__icon,
  .bx--btn--secondary .bx--btn__icon path:not([data-icon-path]):not([fill='none']) {
    fill: currentColor; }
  .bx--btn--secondary:hover, .bx--btn--secondary:focus {
    color: var(--cds-text-04, #ffffff); }

.bx--btn--tertiary {
  border-width: 1px;
  border-style: solid;
  border-color: var(--cds-interactive-03, #0f62fe);
  background-color: transparent;
  color: var(--cds-interactive-03, #0f62fe); }
  .bx--btn--tertiary:hover {
    background-color: var(--cds-hover-tertiary, #0353e9); }
  .bx--btn--tertiary:focus {
    border-color: var(--cds-focus, #0f62fe);
    box-shadow: inset 0 0 0 1px var(--cds-focus, #0f62fe), inset 0 0 0 2px var(--cds-ui-background, #ffffff); }
  .bx--btn--tertiary:active {
    background-color: var(--cds-active-tertiary, #002d9c); }
  .bx--btn--tertiary .bx--btn__icon,
  .bx--btn--tertiary .bx--btn__icon path:not([data-icon-path]):not([fill='none']) {
    fill: currentColor; }
  .bx--btn--tertiary:hover {
    color: var(--cds-inverse-01, #ffffff); }
  .bx--btn--tertiary:focus {
    background-color: var(--cds-interactive-03, #0f62fe);
    color: var(--cds-inverse-01, #ffffff); }
  .bx--btn--tertiary:active {
    border-color: transparent;
    background-color: var(--cds-active-tertiary, #002d9c);
    color: var(--cds-inverse-01, #ffffff); }
  .bx--btn--tertiary:disabled, .bx--btn--tertiary:hover:disabled, .bx--btn--tertiary:focus:disabled, .bx--btn--tertiary.bx--btn--disabled, .bx--btn--tertiary.bx--btn--disabled:hover, .bx--btn--tertiary.bx--btn--disabled:focus {
    background: transparent;
    color: var(--cds-disabled-03, #8d8d8d);
    outline: none; }

.bx--btn--ghost {
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  background-color: transparent;
  color: var(--cds-link-01, #0f62fe);
  padding: calc(0.875rem - 3px) 16px; }
  .bx--btn--ghost:hover {
    background-color: var(--cds-hover-ui, #e5e5e5); }
  .bx--btn--ghost:focus {
    border-color: var(--cds-focus, #0f62fe);
    box-shadow: inset 0 0 0 1px var(--cds-focus, #0f62fe), inset 0 0 0 2px var(--cds-ui-background, #ffffff); }
  .bx--btn--ghost:active {
    background-color: var(--cds-active-ui, #c6c6c6); }
  .bx--btn--ghost .bx--btn__icon,
  .bx--btn--ghost .bx--btn__icon path:not([data-icon-path]):not([fill='none']) {
    fill: currentColor; }
  .bx--btn--ghost .bx--btn__icon {
    position: static;
    margin-left: 0.5rem; }
  .bx--btn--ghost:hover, .bx--btn--ghost:active {
    color: var(--cds-hover-primary-text, #0043ce); }
  .bx--btn--ghost:active {
    background-color: var(--cds-active-ui, #c6c6c6); }
  .bx--btn--ghost:disabled, .bx--btn--ghost:hover:disabled, .bx--btn--ghost:focus:disabled, .bx--btn--ghost.bx--btn--disabled, .bx--btn--ghost.bx--btn--disabled:hover, .bx--btn--ghost.bx--btn--disabled:focus {
    border-color: transparent;
    background: transparent;
    color: var(--cds-disabled-03, #8d8d8d);
    outline: none; }
  .bx--btn--ghost.bx--btn--sm {
    padding: calc(0.375rem - 3px) 16px; }
  .bx--btn--ghost.bx--btn--field, .bx--btn--ghost.bx--btn--md {
    padding: calc(0.675rem - 3px) 16px; }

.bx--btn.bx--btn--icon-only.bx--tooltip__trigger {
  position: relative;
  display: inline-flex;
  overflow: visible;
  align-items: center;
  cursor: pointer; }
  .bx--btn.bx--btn--icon-only.bx--tooltip__trigger:focus {
    outline: 1px solid var(--cds-focus, #0f62fe); }
    @media screen and (prefers-contrast) {
      .bx--btn.bx--btn--icon-only.bx--tooltip__trigger:focus {
        outline-style: dotted; } }
  .bx--btn.bx--btn--icon-only.bx--tooltip__trigger:focus {
    outline: 1px solid transparent; }
    .bx--btn.bx--btn--icon-only.bx--tooltip__trigger:focus svg {
      outline: 1px solid var(--cds-focus, #0f62fe); }
      @media screen and (prefers-contrast) {
        .bx--btn.bx--btn--icon-only.bx--tooltip__trigger:focus svg {
          outline-style: dotted; } }
  .bx--btn.bx--btn--icon-only.bx--tooltip__trigger::before, .bx--btn.bx--btn--icon-only.bx--tooltip__trigger::after,
  .bx--btn.bx--btn--icon-only.bx--tooltip__trigger .bx--assistive-text,
  .bx--btn.bx--btn--icon-only.bx--tooltip__trigger + .bx--assistive-text {
    position: absolute;
    z-index: 6000;
    display: flex;
    align-items: center;
    opacity: 0;
    pointer-events: none; }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .bx--btn.bx--btn--icon-only.bx--tooltip__trigger::before, .bx--btn.bx--btn--icon-only.bx--tooltip__trigger::after,
      .bx--btn.bx--btn--icon-only.bx--tooltip__trigger .bx--assistive-text,
      .bx--btn.bx--btn--icon-only.bx--tooltip__trigger + .bx--assistive-text {
        display: inline-block; } }
  .bx--btn.bx--btn--icon-only.bx--tooltip__trigger::before, .bx--btn.bx--btn--icon-only.bx--tooltip__trigger::after {
    transition: opacity 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--btn.bx--btn--icon-only.bx--tooltip__trigger.bx--tooltip--a11y::before, .bx--btn.bx--btn--icon-only.bx--tooltip__trigger.bx--tooltip--a11y::after {
    transition: none; }
  .bx--btn.bx--btn--icon-only.bx--tooltip__trigger::before {
    width: 0;
    height: 0;
    border-style: solid;
    content: ''; }
  .bx--btn.bx--btn--icon-only.bx--tooltip__trigger .bx--assistive-text,
  .bx--btn.bx--btn--icon-only.bx--tooltip__trigger + .bx--assistive-text {
    box-sizing: content-box;
    color: inherit;
    opacity: 1;
    white-space: normal;
    word-break: break-word; }
  .bx--btn.bx--btn--icon-only.bx--tooltip__trigger::after,
  .bx--btn.bx--btn--icon-only.bx--tooltip__trigger .bx--assistive-text,
  .bx--btn.bx--btn--icon-only.bx--tooltip__trigger + .bx--assistive-text {
    box-shadow: 0 2px 6px var(--cds-shadow, rgba(0, 0, 0, 0.3));
    z-index: 6000;
    width: max-content;
    min-width: 1.5rem;
    max-width: 13rem;
    height: auto;
    padding: 0.1875rem 1rem;
    background-color: var(--cds-inverse-02, #393939);
    border-radius: 0.125rem;
    color: var(--cds-inverse-01, #ffffff);
    font-weight: 400;
    text-align: left;
    transform: translateX(-50%);
    font-size: var(--cds-body-short-01-font-size, 0.875rem);
    font-weight: var(--cds-body-short-01-font-weight, 400);
    line-height: var(--cds-body-short-01-line-height, 1.28572);
    letter-spacing: var(--cds-body-short-01-letter-spacing, 0.16px); }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .bx--btn.bx--btn--icon-only.bx--tooltip__trigger::after,
      .bx--btn.bx--btn--icon-only.bx--tooltip__trigger .bx--assistive-text,
      .bx--btn.bx--btn--icon-only.bx--tooltip__trigger + .bx--assistive-text {
        width: auto; } }
    @supports (-ms-accelerator: true) {
      .bx--btn.bx--btn--icon-only.bx--tooltip__trigger::after,
      .bx--btn.bx--btn--icon-only.bx--tooltip__trigger .bx--assistive-text,
      .bx--btn.bx--btn--icon-only.bx--tooltip__trigger + .bx--assistive-text {
        width: auto; } }
    @supports (-ms-ime-align: auto) {
      .bx--btn.bx--btn--icon-only.bx--tooltip__trigger::after,
      .bx--btn.bx--btn--icon-only.bx--tooltip__trigger .bx--assistive-text,
      .bx--btn.bx--btn--icon-only.bx--tooltip__trigger + .bx--assistive-text {
        width: auto; } }
    @media screen and (-ms-high-contrast: active), screen and (prefers-contrast) {
      .bx--btn.bx--btn--icon-only.bx--tooltip__trigger::after,
      .bx--btn.bx--btn--icon-only.bx--tooltip__trigger .bx--assistive-text,
      .bx--btn.bx--btn--icon-only.bx--tooltip__trigger + .bx--assistive-text {
        border: 1px solid transparent; } }
  .bx--btn.bx--btn--icon-only.bx--tooltip__trigger::after {
    content: attr(aria-label); }
  .bx--btn.bx--btn--icon-only.bx--tooltip__trigger.bx--tooltip--a11y::after {
    content: none; }
  .bx--btn.bx--btn--icon-only.bx--tooltip__trigger.bx--tooltip--visible::before, .bx--btn.bx--btn--icon-only.bx--tooltip__trigger.bx--tooltip--visible::after, .bx--btn.bx--btn--icon-only.bx--tooltip__trigger:hover::before, .bx--btn.bx--btn--icon-only.bx--tooltip__trigger:hover::after, .bx--btn.bx--btn--icon-only.bx--tooltip__trigger:focus::before, .bx--btn.bx--btn--icon-only.bx--tooltip__trigger:focus::after {
    opacity: 1; }

@keyframes tooltip-fade {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }
  .bx--btn.bx--btn--icon-only.bx--tooltip__trigger.bx--tooltip--visible .bx--assistive-text,
  .bx--btn.bx--btn--icon-only.bx--tooltip__trigger.bx--tooltip--visible + .bx--assistive-text, .bx--btn.bx--btn--icon-only.bx--tooltip__trigger:hover .bx--assistive-text,
  .bx--btn.bx--btn--icon-only.bx--tooltip__trigger:hover + .bx--assistive-text, .bx--btn.bx--btn--icon-only.bx--tooltip__trigger:focus .bx--assistive-text,
  .bx--btn.bx--btn--icon-only.bx--tooltip__trigger:focus + .bx--assistive-text {
    overflow: visible;
    margin: auto;
    clip: auto; }
  .bx--btn.bx--btn--icon-only.bx--tooltip__trigger.bx--tooltip--visible .bx--assistive-text,
  .bx--btn.bx--btn--icon-only.bx--tooltip__trigger.bx--tooltip--visible + .bx--assistive-text, .bx--btn.bx--btn--icon-only.bx--tooltip__trigger.bx--tooltip--visible.bx--tooltip--a11y::before, .bx--btn.bx--btn--icon-only.bx--tooltip__trigger:hover .bx--assistive-text,
  .bx--btn.bx--btn--icon-only.bx--tooltip__trigger:hover + .bx--assistive-text, .bx--btn.bx--btn--icon-only.bx--tooltip__trigger:hover.bx--tooltip--a11y::before, .bx--btn.bx--btn--icon-only.bx--tooltip__trigger:focus .bx--assistive-text,
  .bx--btn.bx--btn--icon-only.bx--tooltip__trigger:focus + .bx--assistive-text, .bx--btn.bx--btn--icon-only.bx--tooltip__trigger:focus.bx--tooltip--a11y::before {
    animation: tooltip-fade 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--btn.bx--btn--icon-only.bx--tooltip__trigger.bx--tooltip--hidden .bx--assistive-text,
  .bx--btn.bx--btn--icon-only.bx--tooltip__trigger.bx--tooltip--hidden + .bx--assistive-text {
    overflow: hidden;
    margin: -1px;
    clip: rect(0, 0, 0, 0); }
  .bx--btn.bx--btn--icon-only.bx--tooltip__trigger.bx--tooltip--hidden.bx--tooltip--a11y::before {
    animation: none;
    opacity: 0; }
  .bx--btn.bx--btn--icon-only.bx--tooltip__trigger svg,
  .bx--btn.bx--btn--icon-only.bx--tooltip__trigger:hover svg,
  .bx--btn.bx--btn--icon-only.bx--tooltip__trigger:focus svg {
    fill: currentColor; }
  .bx--btn.bx--btn--icon-only.bx--tooltip__trigger.bx--btn--disabled.bx--tooltip--a11y::before, .bx--btn.bx--btn--icon-only.bx--tooltip__trigger.bx--btn--disabled.bx--tooltip--a11y::after,
  .bx--btn.bx--btn--icon-only.bx--tooltip__trigger.bx--btn--disabled .bx--assistive-text {
    overflow: hidden;
    margin: -1px;
    clip: rect(0, 0, 0, 0);
    opacity: 0; }

.bx--btn.bx--btn--icon-only:not(.bx--tooltip--hidden) .bx--assistive-text {
  pointer-events: all; }

.bx--btn.bx--btn--icon-only.bx--tooltip__trigger:focus {
  border-color: var(--cds-focus, #0f62fe); }

.bx--btn.bx--btn--icon-only.bx--tooltip__trigger:active:not([disabled]) {
  border-color: transparent; }

.bx--btn.bx--btn--icon-only.bx--tooltip__trigger:focus svg {
  outline-color: transparent; }

.bx--btn.bx--btn--icon-only.bx--tooltip__trigger[disabled]:hover,
.bx--btn.bx--btn--icon-only.bx--tooltip__trigger[disabled]:focus,
.bx--btn.bx--btn--icon-only.bx--tooltip__trigger[disabled]:active {
  cursor: not-allowed;
  fill: var(--cds-disabled-03, #8d8d8d); }

.bx--tooltip__trigger.bx--btn--icon-only--top {
  position: relative;
  display: inline-flex;
  overflow: visible;
  align-items: center;
  cursor: pointer; }
  .bx--tooltip__trigger.bx--btn--icon-only--top:focus {
    outline: 1px solid var(--cds-focus, #0f62fe); }
    @media screen and (prefers-contrast) {
      .bx--tooltip__trigger.bx--btn--icon-only--top:focus {
        outline-style: dotted; } }
  .bx--tooltip__trigger.bx--btn--icon-only--top:focus {
    outline: 1px solid transparent; }
    .bx--tooltip__trigger.bx--btn--icon-only--top:focus svg {
      outline: 1px solid var(--cds-focus, #0f62fe); }
      @media screen and (prefers-contrast) {
        .bx--tooltip__trigger.bx--btn--icon-only--top:focus svg {
          outline-style: dotted; } }
  .bx--tooltip__trigger.bx--btn--icon-only--top::before, .bx--tooltip__trigger.bx--btn--icon-only--top::after,
  .bx--tooltip__trigger.bx--btn--icon-only--top .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--top + .bx--assistive-text {
    position: absolute;
    z-index: 6000;
    display: flex;
    align-items: center;
    opacity: 0;
    pointer-events: none; }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .bx--tooltip__trigger.bx--btn--icon-only--top::before, .bx--tooltip__trigger.bx--btn--icon-only--top::after,
      .bx--tooltip__trigger.bx--btn--icon-only--top .bx--assistive-text,
      .bx--tooltip__trigger.bx--btn--icon-only--top + .bx--assistive-text {
        display: inline-block; } }
  .bx--tooltip__trigger.bx--btn--icon-only--top::before, .bx--tooltip__trigger.bx--btn--icon-only--top::after {
    transition: opacity 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--tooltip__trigger.bx--btn--icon-only--top.bx--tooltip--a11y::before, .bx--tooltip__trigger.bx--btn--icon-only--top.bx--tooltip--a11y::after {
    transition: none; }
  .bx--tooltip__trigger.bx--btn--icon-only--top::before {
    width: 0;
    height: 0;
    border-style: solid;
    content: ''; }
  .bx--tooltip__trigger.bx--btn--icon-only--top .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--top + .bx--assistive-text {
    box-sizing: content-box;
    color: inherit;
    opacity: 1;
    white-space: normal;
    word-break: break-word; }
  .bx--tooltip__trigger.bx--btn--icon-only--top::after,
  .bx--tooltip__trigger.bx--btn--icon-only--top .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--top + .bx--assistive-text {
    box-shadow: 0 2px 6px var(--cds-shadow, rgba(0, 0, 0, 0.3));
    z-index: 6000;
    width: max-content;
    min-width: 1.5rem;
    max-width: 13rem;
    height: auto;
    padding: 0.1875rem 1rem;
    background-color: var(--cds-inverse-02, #393939);
    border-radius: 0.125rem;
    color: var(--cds-inverse-01, #ffffff);
    font-weight: 400;
    text-align: left;
    transform: translateX(-50%);
    font-size: var(--cds-body-short-01-font-size, 0.875rem);
    font-weight: var(--cds-body-short-01-font-weight, 400);
    line-height: var(--cds-body-short-01-line-height, 1.28572);
    letter-spacing: var(--cds-body-short-01-letter-spacing, 0.16px); }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .bx--tooltip__trigger.bx--btn--icon-only--top::after,
      .bx--tooltip__trigger.bx--btn--icon-only--top .bx--assistive-text,
      .bx--tooltip__trigger.bx--btn--icon-only--top + .bx--assistive-text {
        width: auto; } }
    @supports (-ms-accelerator: true) {
      .bx--tooltip__trigger.bx--btn--icon-only--top::after,
      .bx--tooltip__trigger.bx--btn--icon-only--top .bx--assistive-text,
      .bx--tooltip__trigger.bx--btn--icon-only--top + .bx--assistive-text {
        width: auto; } }
    @supports (-ms-ime-align: auto) {
      .bx--tooltip__trigger.bx--btn--icon-only--top::after,
      .bx--tooltip__trigger.bx--btn--icon-only--top .bx--assistive-text,
      .bx--tooltip__trigger.bx--btn--icon-only--top + .bx--assistive-text {
        width: auto; } }
    @media screen and (-ms-high-contrast: active), screen and (prefers-contrast) {
      .bx--tooltip__trigger.bx--btn--icon-only--top::after,
      .bx--tooltip__trigger.bx--btn--icon-only--top .bx--assistive-text,
      .bx--tooltip__trigger.bx--btn--icon-only--top + .bx--assistive-text {
        border: 1px solid transparent; } }
  .bx--tooltip__trigger.bx--btn--icon-only--top::after {
    content: attr(aria-label); }
  .bx--tooltip__trigger.bx--btn--icon-only--top.bx--tooltip--a11y::after {
    content: none; }
  .bx--tooltip__trigger.bx--btn--icon-only--top.bx--tooltip--visible::before, .bx--tooltip__trigger.bx--btn--icon-only--top.bx--tooltip--visible::after, .bx--tooltip__trigger.bx--btn--icon-only--top:hover::before, .bx--tooltip__trigger.bx--btn--icon-only--top:hover::after, .bx--tooltip__trigger.bx--btn--icon-only--top:focus::before, .bx--tooltip__trigger.bx--btn--icon-only--top:focus::after {
    opacity: 1; }

@keyframes tooltip-fade {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }
  .bx--tooltip__trigger.bx--btn--icon-only--top.bx--tooltip--visible .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--top.bx--tooltip--visible + .bx--assistive-text, .bx--tooltip__trigger.bx--btn--icon-only--top:hover .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--top:hover + .bx--assistive-text, .bx--tooltip__trigger.bx--btn--icon-only--top:focus .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--top:focus + .bx--assistive-text {
    overflow: visible;
    margin: auto;
    clip: auto; }
  .bx--tooltip__trigger.bx--btn--icon-only--top.bx--tooltip--visible .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--top.bx--tooltip--visible + .bx--assistive-text, .bx--tooltip__trigger.bx--btn--icon-only--top.bx--tooltip--visible.bx--tooltip--a11y::before, .bx--tooltip__trigger.bx--btn--icon-only--top:hover .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--top:hover + .bx--assistive-text, .bx--tooltip__trigger.bx--btn--icon-only--top:hover.bx--tooltip--a11y::before, .bx--tooltip__trigger.bx--btn--icon-only--top:focus .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--top:focus + .bx--assistive-text, .bx--tooltip__trigger.bx--btn--icon-only--top:focus.bx--tooltip--a11y::before {
    animation: tooltip-fade 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--tooltip__trigger.bx--btn--icon-only--top.bx--tooltip--hidden .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--top.bx--tooltip--hidden + .bx--assistive-text {
    overflow: hidden;
    margin: -1px;
    clip: rect(0, 0, 0, 0); }
  .bx--tooltip__trigger.bx--btn--icon-only--top.bx--tooltip--hidden.bx--tooltip--a11y::before {
    animation: none;
    opacity: 0; }
  .bx--tooltip__trigger.bx--btn--icon-only--top .bx--assistive-text::after {
    position: absolute;
    display: block;
    content: '';
    left: 0;
    width: 100%;
    height: 0.75rem;
    bottom: -0.75rem; }
  .bx--tooltip__trigger.bx--btn--icon-only--top::before, .bx--tooltip__trigger.bx--btn--icon-only--top::after,
  .bx--tooltip__trigger.bx--btn--icon-only--top .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--top + .bx--assistive-text {
    top: 0;
    left: 50%; }
  .bx--tooltip__trigger.bx--btn--icon-only--top::before {
    top: -0.5rem;
    border-width: 0.3125rem 0.25rem 0 0.25rem;
    border-color: var(--cds-inverse-02, #393939) transparent transparent transparent;
    transform: translate(-50%, -100%); }
  .bx--tooltip__trigger.bx--btn--icon-only--top::after,
  .bx--tooltip__trigger.bx--btn--icon-only--top .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--top + .bx--assistive-text {
    top: -0.8125rem;
    left: 50%;
    transform: translate(-50%, -100%); }
  .bx--tooltip__trigger.bx--btn--icon-only--top.bx--tooltip--align-start .bx--assistive-text::after {
    position: absolute;
    display: block;
    content: '';
    left: 0;
    width: 100%;
    height: 0.75rem;
    bottom: -0.75rem; }
  .bx--tooltip__trigger.bx--btn--icon-only--top.bx--tooltip--align-start::before, .bx--tooltip__trigger.bx--btn--icon-only--top.bx--tooltip--align-start::after,
  .bx--tooltip__trigger.bx--btn--icon-only--top.bx--tooltip--align-start .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--top.bx--tooltip--align-start + .bx--assistive-text {
    top: 0;
    left: 50%; }
  .bx--tooltip__trigger.bx--btn--icon-only--top.bx--tooltip--align-start::before {
    top: -0.5rem;
    border-width: 0.3125rem 0.25rem 0 0.25rem;
    border-color: var(--cds-inverse-02, #393939) transparent transparent transparent;
    transform: translate(-50%, -100%); }
  .bx--tooltip__trigger.bx--btn--icon-only--top.bx--tooltip--align-start::after,
  .bx--tooltip__trigger.bx--btn--icon-only--top.bx--tooltip--align-start .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--top.bx--tooltip--align-start + .bx--assistive-text {
    top: -0.8125rem;
    left: 0;
    transform: translate(0, -100%); }
  .bx--tooltip__trigger.bx--btn--icon-only--top.bx--tooltip--align-center .bx--assistive-text::after {
    position: absolute;
    display: block;
    content: '';
    left: 0;
    width: 100%;
    height: 0.75rem;
    bottom: -0.75rem; }
  .bx--tooltip__trigger.bx--btn--icon-only--top.bx--tooltip--align-center::before, .bx--tooltip__trigger.bx--btn--icon-only--top.bx--tooltip--align-center::after,
  .bx--tooltip__trigger.bx--btn--icon-only--top.bx--tooltip--align-center .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--top.bx--tooltip--align-center + .bx--assistive-text {
    top: 0;
    left: 50%; }
  .bx--tooltip__trigger.bx--btn--icon-only--top.bx--tooltip--align-center::before {
    top: -0.5rem;
    border-width: 0.3125rem 0.25rem 0 0.25rem;
    border-color: var(--cds-inverse-02, #393939) transparent transparent transparent;
    transform: translate(-50%, -100%); }
  .bx--tooltip__trigger.bx--btn--icon-only--top.bx--tooltip--align-center::after,
  .bx--tooltip__trigger.bx--btn--icon-only--top.bx--tooltip--align-center .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--top.bx--tooltip--align-center + .bx--assistive-text {
    top: -0.8125rem;
    left: 50%;
    transform: translate(-50%, -100%); }
  .bx--tooltip__trigger.bx--btn--icon-only--top.bx--tooltip--align-end .bx--assistive-text::after {
    position: absolute;
    display: block;
    content: '';
    left: 0;
    width: 100%;
    height: 0.75rem;
    bottom: -0.75rem; }
  .bx--tooltip__trigger.bx--btn--icon-only--top.bx--tooltip--align-end::before, .bx--tooltip__trigger.bx--btn--icon-only--top.bx--tooltip--align-end::after,
  .bx--tooltip__trigger.bx--btn--icon-only--top.bx--tooltip--align-end .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--top.bx--tooltip--align-end + .bx--assistive-text {
    top: 0;
    left: 50%; }
  .bx--tooltip__trigger.bx--btn--icon-only--top.bx--tooltip--align-end::before {
    top: -0.5rem;
    border-width: 0.3125rem 0.25rem 0 0.25rem;
    border-color: var(--cds-inverse-02, #393939) transparent transparent transparent;
    transform: translate(-50%, -100%); }
  .bx--tooltip__trigger.bx--btn--icon-only--top.bx--tooltip--align-end::after,
  .bx--tooltip__trigger.bx--btn--icon-only--top.bx--tooltip--align-end .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--top.bx--tooltip--align-end + .bx--assistive-text {
    top: -0.8125rem;
    right: 0;
    left: auto;
    transform: translate(0, -100%); }

.bx--tooltip__trigger.bx--btn--icon-only--right {
  position: relative;
  display: inline-flex;
  overflow: visible;
  align-items: center;
  cursor: pointer; }
  .bx--tooltip__trigger.bx--btn--icon-only--right:focus {
    outline: 1px solid var(--cds-focus, #0f62fe); }
    @media screen and (prefers-contrast) {
      .bx--tooltip__trigger.bx--btn--icon-only--right:focus {
        outline-style: dotted; } }
  .bx--tooltip__trigger.bx--btn--icon-only--right:focus {
    outline: 1px solid transparent; }
    .bx--tooltip__trigger.bx--btn--icon-only--right:focus svg {
      outline: 1px solid var(--cds-focus, #0f62fe); }
      @media screen and (prefers-contrast) {
        .bx--tooltip__trigger.bx--btn--icon-only--right:focus svg {
          outline-style: dotted; } }
  .bx--tooltip__trigger.bx--btn--icon-only--right::before, .bx--tooltip__trigger.bx--btn--icon-only--right::after,
  .bx--tooltip__trigger.bx--btn--icon-only--right .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--right + .bx--assistive-text {
    position: absolute;
    z-index: 6000;
    display: flex;
    align-items: center;
    opacity: 0;
    pointer-events: none; }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .bx--tooltip__trigger.bx--btn--icon-only--right::before, .bx--tooltip__trigger.bx--btn--icon-only--right::after,
      .bx--tooltip__trigger.bx--btn--icon-only--right .bx--assistive-text,
      .bx--tooltip__trigger.bx--btn--icon-only--right + .bx--assistive-text {
        display: inline-block; } }
  .bx--tooltip__trigger.bx--btn--icon-only--right::before, .bx--tooltip__trigger.bx--btn--icon-only--right::after {
    transition: opacity 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--tooltip__trigger.bx--btn--icon-only--right.bx--tooltip--a11y::before, .bx--tooltip__trigger.bx--btn--icon-only--right.bx--tooltip--a11y::after {
    transition: none; }
  .bx--tooltip__trigger.bx--btn--icon-only--right::before {
    width: 0;
    height: 0;
    border-style: solid;
    content: ''; }
  .bx--tooltip__trigger.bx--btn--icon-only--right .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--right + .bx--assistive-text {
    box-sizing: content-box;
    color: inherit;
    opacity: 1;
    white-space: normal;
    word-break: break-word; }
  .bx--tooltip__trigger.bx--btn--icon-only--right::after,
  .bx--tooltip__trigger.bx--btn--icon-only--right .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--right + .bx--assistive-text {
    box-shadow: 0 2px 6px var(--cds-shadow, rgba(0, 0, 0, 0.3));
    z-index: 6000;
    width: max-content;
    min-width: 1.5rem;
    max-width: 13rem;
    height: auto;
    padding: 0.1875rem 1rem;
    background-color: var(--cds-inverse-02, #393939);
    border-radius: 0.125rem;
    color: var(--cds-inverse-01, #ffffff);
    font-weight: 400;
    text-align: left;
    transform: translateX(-50%);
    font-size: var(--cds-body-short-01-font-size, 0.875rem);
    font-weight: var(--cds-body-short-01-font-weight, 400);
    line-height: var(--cds-body-short-01-line-height, 1.28572);
    letter-spacing: var(--cds-body-short-01-letter-spacing, 0.16px); }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .bx--tooltip__trigger.bx--btn--icon-only--right::after,
      .bx--tooltip__trigger.bx--btn--icon-only--right .bx--assistive-text,
      .bx--tooltip__trigger.bx--btn--icon-only--right + .bx--assistive-text {
        width: auto; } }
    @supports (-ms-accelerator: true) {
      .bx--tooltip__trigger.bx--btn--icon-only--right::after,
      .bx--tooltip__trigger.bx--btn--icon-only--right .bx--assistive-text,
      .bx--tooltip__trigger.bx--btn--icon-only--right + .bx--assistive-text {
        width: auto; } }
    @supports (-ms-ime-align: auto) {
      .bx--tooltip__trigger.bx--btn--icon-only--right::after,
      .bx--tooltip__trigger.bx--btn--icon-only--right .bx--assistive-text,
      .bx--tooltip__trigger.bx--btn--icon-only--right + .bx--assistive-text {
        width: auto; } }
    @media screen and (-ms-high-contrast: active), screen and (prefers-contrast) {
      .bx--tooltip__trigger.bx--btn--icon-only--right::after,
      .bx--tooltip__trigger.bx--btn--icon-only--right .bx--assistive-text,
      .bx--tooltip__trigger.bx--btn--icon-only--right + .bx--assistive-text {
        border: 1px solid transparent; } }
  .bx--tooltip__trigger.bx--btn--icon-only--right::after {
    content: attr(aria-label); }
  .bx--tooltip__trigger.bx--btn--icon-only--right.bx--tooltip--a11y::after {
    content: none; }
  .bx--tooltip__trigger.bx--btn--icon-only--right.bx--tooltip--visible::before, .bx--tooltip__trigger.bx--btn--icon-only--right.bx--tooltip--visible::after, .bx--tooltip__trigger.bx--btn--icon-only--right:hover::before, .bx--tooltip__trigger.bx--btn--icon-only--right:hover::after, .bx--tooltip__trigger.bx--btn--icon-only--right:focus::before, .bx--tooltip__trigger.bx--btn--icon-only--right:focus::after {
    opacity: 1; }

@keyframes tooltip-fade {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }
  .bx--tooltip__trigger.bx--btn--icon-only--right.bx--tooltip--visible .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--right.bx--tooltip--visible + .bx--assistive-text, .bx--tooltip__trigger.bx--btn--icon-only--right:hover .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--right:hover + .bx--assistive-text, .bx--tooltip__trigger.bx--btn--icon-only--right:focus .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--right:focus + .bx--assistive-text {
    overflow: visible;
    margin: auto;
    clip: auto; }
  .bx--tooltip__trigger.bx--btn--icon-only--right.bx--tooltip--visible .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--right.bx--tooltip--visible + .bx--assistive-text, .bx--tooltip__trigger.bx--btn--icon-only--right.bx--tooltip--visible.bx--tooltip--a11y::before, .bx--tooltip__trigger.bx--btn--icon-only--right:hover .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--right:hover + .bx--assistive-text, .bx--tooltip__trigger.bx--btn--icon-only--right:hover.bx--tooltip--a11y::before, .bx--tooltip__trigger.bx--btn--icon-only--right:focus .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--right:focus + .bx--assistive-text, .bx--tooltip__trigger.bx--btn--icon-only--right:focus.bx--tooltip--a11y::before {
    animation: tooltip-fade 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--tooltip__trigger.bx--btn--icon-only--right.bx--tooltip--hidden .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--right.bx--tooltip--hidden + .bx--assistive-text {
    overflow: hidden;
    margin: -1px;
    clip: rect(0, 0, 0, 0); }
  .bx--tooltip__trigger.bx--btn--icon-only--right.bx--tooltip--hidden.bx--tooltip--a11y::before {
    animation: none;
    opacity: 0; }
  .bx--tooltip__trigger.bx--btn--icon-only--right .bx--assistive-text::after {
    position: absolute;
    display: block;
    content: '';
    top: 0;
    width: 0.75rem;
    height: 100%;
    left: -0.75rem; }
  .bx--tooltip__trigger.bx--btn--icon-only--right::before, .bx--tooltip__trigger.bx--btn--icon-only--right::after,
  .bx--tooltip__trigger.bx--btn--icon-only--right .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--right + .bx--assistive-text {
    top: 50%;
    right: 0; }
  .bx--tooltip__trigger.bx--btn--icon-only--right::before {
    right: -0.5rem;
    border-width: 0.25rem 0.3125rem 0.25rem 0;
    border-color: transparent var(--cds-inverse-02, #393939) transparent transparent;
    transform: translate(100%, -50%); }
  .bx--tooltip__trigger.bx--btn--icon-only--right::after,
  .bx--tooltip__trigger.bx--btn--icon-only--right .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--right + .bx--assistive-text {
    right: -0.8125rem;
    transform: translate(100%, -50%); }
  .bx--tooltip__trigger.bx--btn--icon-only--right.bx--tooltip--align-start .bx--assistive-text::after {
    position: absolute;
    display: block;
    content: '';
    top: 0;
    width: 0.75rem;
    height: 100%;
    left: -0.75rem; }
  .bx--tooltip__trigger.bx--btn--icon-only--right.bx--tooltip--align-start::before, .bx--tooltip__trigger.bx--btn--icon-only--right.bx--tooltip--align-start::after,
  .bx--tooltip__trigger.bx--btn--icon-only--right.bx--tooltip--align-start .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--right.bx--tooltip--align-start + .bx--assistive-text {
    top: 50%;
    right: 0; }
  .bx--tooltip__trigger.bx--btn--icon-only--right.bx--tooltip--align-start::before {
    right: -0.5rem;
    border-width: 0.25rem 0.3125rem 0.25rem 0;
    border-color: transparent var(--cds-inverse-02, #393939) transparent transparent;
    transform: translate(100%, -50%); }
  .bx--tooltip__trigger.bx--btn--icon-only--right.bx--tooltip--align-start::after,
  .bx--tooltip__trigger.bx--btn--icon-only--right.bx--tooltip--align-start .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--right.bx--tooltip--align-start + .bx--assistive-text {
    right: -0.8125rem;
    transform: translate(100%, -50%); }
  .bx--tooltip__trigger.bx--btn--icon-only--right.bx--tooltip--align-center .bx--assistive-text::after {
    position: absolute;
    display: block;
    content: '';
    top: 0;
    width: 0.75rem;
    height: 100%;
    left: -0.75rem; }
  .bx--tooltip__trigger.bx--btn--icon-only--right.bx--tooltip--align-center::before, .bx--tooltip__trigger.bx--btn--icon-only--right.bx--tooltip--align-center::after,
  .bx--tooltip__trigger.bx--btn--icon-only--right.bx--tooltip--align-center .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--right.bx--tooltip--align-center + .bx--assistive-text {
    top: 50%;
    right: 0; }
  .bx--tooltip__trigger.bx--btn--icon-only--right.bx--tooltip--align-center::before {
    right: -0.5rem;
    border-width: 0.25rem 0.3125rem 0.25rem 0;
    border-color: transparent var(--cds-inverse-02, #393939) transparent transparent;
    transform: translate(100%, -50%); }
  .bx--tooltip__trigger.bx--btn--icon-only--right.bx--tooltip--align-center::after,
  .bx--tooltip__trigger.bx--btn--icon-only--right.bx--tooltip--align-center .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--right.bx--tooltip--align-center + .bx--assistive-text {
    right: -0.8125rem;
    transform: translate(100%, -50%); }
  .bx--tooltip__trigger.bx--btn--icon-only--right.bx--tooltip--align-end .bx--assistive-text::after {
    position: absolute;
    display: block;
    content: '';
    top: 0;
    width: 0.75rem;
    height: 100%;
    left: -0.75rem; }
  .bx--tooltip__trigger.bx--btn--icon-only--right.bx--tooltip--align-end::before, .bx--tooltip__trigger.bx--btn--icon-only--right.bx--tooltip--align-end::after,
  .bx--tooltip__trigger.bx--btn--icon-only--right.bx--tooltip--align-end .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--right.bx--tooltip--align-end + .bx--assistive-text {
    top: 50%;
    right: 0; }
  .bx--tooltip__trigger.bx--btn--icon-only--right.bx--tooltip--align-end::before {
    right: -0.5rem;
    border-width: 0.25rem 0.3125rem 0.25rem 0;
    border-color: transparent var(--cds-inverse-02, #393939) transparent transparent;
    transform: translate(100%, -50%); }
  .bx--tooltip__trigger.bx--btn--icon-only--right.bx--tooltip--align-end::after,
  .bx--tooltip__trigger.bx--btn--icon-only--right.bx--tooltip--align-end .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--right.bx--tooltip--align-end + .bx--assistive-text {
    right: -0.8125rem;
    transform: translate(100%, -50%); }

.bx--tooltip__trigger.bx--btn--icon-only--bottom {
  position: relative;
  display: inline-flex;
  overflow: visible;
  align-items: center;
  cursor: pointer; }
  .bx--tooltip__trigger.bx--btn--icon-only--bottom:focus {
    outline: 1px solid var(--cds-focus, #0f62fe); }
    @media screen and (prefers-contrast) {
      .bx--tooltip__trigger.bx--btn--icon-only--bottom:focus {
        outline-style: dotted; } }
  .bx--tooltip__trigger.bx--btn--icon-only--bottom:focus {
    outline: 1px solid transparent; }
    .bx--tooltip__trigger.bx--btn--icon-only--bottom:focus svg {
      outline: 1px solid var(--cds-focus, #0f62fe); }
      @media screen and (prefers-contrast) {
        .bx--tooltip__trigger.bx--btn--icon-only--bottom:focus svg {
          outline-style: dotted; } }
  .bx--tooltip__trigger.bx--btn--icon-only--bottom::before, .bx--tooltip__trigger.bx--btn--icon-only--bottom::after,
  .bx--tooltip__trigger.bx--btn--icon-only--bottom .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--bottom + .bx--assistive-text {
    position: absolute;
    z-index: 6000;
    display: flex;
    align-items: center;
    opacity: 0;
    pointer-events: none; }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .bx--tooltip__trigger.bx--btn--icon-only--bottom::before, .bx--tooltip__trigger.bx--btn--icon-only--bottom::after,
      .bx--tooltip__trigger.bx--btn--icon-only--bottom .bx--assistive-text,
      .bx--tooltip__trigger.bx--btn--icon-only--bottom + .bx--assistive-text {
        display: inline-block; } }
  .bx--tooltip__trigger.bx--btn--icon-only--bottom::before, .bx--tooltip__trigger.bx--btn--icon-only--bottom::after {
    transition: opacity 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--tooltip__trigger.bx--btn--icon-only--bottom.bx--tooltip--a11y::before, .bx--tooltip__trigger.bx--btn--icon-only--bottom.bx--tooltip--a11y::after {
    transition: none; }
  .bx--tooltip__trigger.bx--btn--icon-only--bottom::before {
    width: 0;
    height: 0;
    border-style: solid;
    content: ''; }
  .bx--tooltip__trigger.bx--btn--icon-only--bottom .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--bottom + .bx--assistive-text {
    box-sizing: content-box;
    color: inherit;
    opacity: 1;
    white-space: normal;
    word-break: break-word; }
  .bx--tooltip__trigger.bx--btn--icon-only--bottom::after,
  .bx--tooltip__trigger.bx--btn--icon-only--bottom .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--bottom + .bx--assistive-text {
    box-shadow: 0 2px 6px var(--cds-shadow, rgba(0, 0, 0, 0.3));
    z-index: 6000;
    width: max-content;
    min-width: 1.5rem;
    max-width: 13rem;
    height: auto;
    padding: 0.1875rem 1rem;
    background-color: var(--cds-inverse-02, #393939);
    border-radius: 0.125rem;
    color: var(--cds-inverse-01, #ffffff);
    font-weight: 400;
    text-align: left;
    transform: translateX(-50%);
    font-size: var(--cds-body-short-01-font-size, 0.875rem);
    font-weight: var(--cds-body-short-01-font-weight, 400);
    line-height: var(--cds-body-short-01-line-height, 1.28572);
    letter-spacing: var(--cds-body-short-01-letter-spacing, 0.16px); }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .bx--tooltip__trigger.bx--btn--icon-only--bottom::after,
      .bx--tooltip__trigger.bx--btn--icon-only--bottom .bx--assistive-text,
      .bx--tooltip__trigger.bx--btn--icon-only--bottom + .bx--assistive-text {
        width: auto; } }
    @supports (-ms-accelerator: true) {
      .bx--tooltip__trigger.bx--btn--icon-only--bottom::after,
      .bx--tooltip__trigger.bx--btn--icon-only--bottom .bx--assistive-text,
      .bx--tooltip__trigger.bx--btn--icon-only--bottom + .bx--assistive-text {
        width: auto; } }
    @supports (-ms-ime-align: auto) {
      .bx--tooltip__trigger.bx--btn--icon-only--bottom::after,
      .bx--tooltip__trigger.bx--btn--icon-only--bottom .bx--assistive-text,
      .bx--tooltip__trigger.bx--btn--icon-only--bottom + .bx--assistive-text {
        width: auto; } }
    @media screen and (-ms-high-contrast: active), screen and (prefers-contrast) {
      .bx--tooltip__trigger.bx--btn--icon-only--bottom::after,
      .bx--tooltip__trigger.bx--btn--icon-only--bottom .bx--assistive-text,
      .bx--tooltip__trigger.bx--btn--icon-only--bottom + .bx--assistive-text {
        border: 1px solid transparent; } }
  .bx--tooltip__trigger.bx--btn--icon-only--bottom::after {
    content: attr(aria-label); }
  .bx--tooltip__trigger.bx--btn--icon-only--bottom.bx--tooltip--a11y::after {
    content: none; }
  .bx--tooltip__trigger.bx--btn--icon-only--bottom.bx--tooltip--visible::before, .bx--tooltip__trigger.bx--btn--icon-only--bottom.bx--tooltip--visible::after, .bx--tooltip__trigger.bx--btn--icon-only--bottom:hover::before, .bx--tooltip__trigger.bx--btn--icon-only--bottom:hover::after, .bx--tooltip__trigger.bx--btn--icon-only--bottom:focus::before, .bx--tooltip__trigger.bx--btn--icon-only--bottom:focus::after {
    opacity: 1; }

@keyframes tooltip-fade {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }
  .bx--tooltip__trigger.bx--btn--icon-only--bottom.bx--tooltip--visible .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--bottom.bx--tooltip--visible + .bx--assistive-text, .bx--tooltip__trigger.bx--btn--icon-only--bottom:hover .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--bottom:hover + .bx--assistive-text, .bx--tooltip__trigger.bx--btn--icon-only--bottom:focus .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--bottom:focus + .bx--assistive-text {
    overflow: visible;
    margin: auto;
    clip: auto; }
  .bx--tooltip__trigger.bx--btn--icon-only--bottom.bx--tooltip--visible .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--bottom.bx--tooltip--visible + .bx--assistive-text, .bx--tooltip__trigger.bx--btn--icon-only--bottom.bx--tooltip--visible.bx--tooltip--a11y::before, .bx--tooltip__trigger.bx--btn--icon-only--bottom:hover .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--bottom:hover + .bx--assistive-text, .bx--tooltip__trigger.bx--btn--icon-only--bottom:hover.bx--tooltip--a11y::before, .bx--tooltip__trigger.bx--btn--icon-only--bottom:focus .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--bottom:focus + .bx--assistive-text, .bx--tooltip__trigger.bx--btn--icon-only--bottom:focus.bx--tooltip--a11y::before {
    animation: tooltip-fade 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--tooltip__trigger.bx--btn--icon-only--bottom.bx--tooltip--hidden .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--bottom.bx--tooltip--hidden + .bx--assistive-text {
    overflow: hidden;
    margin: -1px;
    clip: rect(0, 0, 0, 0); }
  .bx--tooltip__trigger.bx--btn--icon-only--bottom.bx--tooltip--hidden.bx--tooltip--a11y::before {
    animation: none;
    opacity: 0; }
  .bx--tooltip__trigger.bx--btn--icon-only--bottom .bx--assistive-text::after {
    position: absolute;
    display: block;
    content: '';
    left: 0;
    width: 100%;
    height: 0.75rem;
    top: -0.75rem; }
  .bx--tooltip__trigger.bx--btn--icon-only--bottom::before, .bx--tooltip__trigger.bx--btn--icon-only--bottom::after,
  .bx--tooltip__trigger.bx--btn--icon-only--bottom .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--bottom + .bx--assistive-text {
    bottom: 0;
    left: 50%; }
  .bx--tooltip__trigger.bx--btn--icon-only--bottom::before {
    bottom: -0.5rem;
    border-width: 0 0.25rem 0.3125rem 0.25rem;
    border-color: transparent transparent var(--cds-inverse-02, #393939) transparent;
    transform: translate(-50%, 100%); }
  .bx--tooltip__trigger.bx--btn--icon-only--bottom::after,
  .bx--tooltip__trigger.bx--btn--icon-only--bottom .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--bottom + .bx--assistive-text {
    bottom: -0.8125rem;
    transform: translate(-50%, 100%); }
  .bx--tooltip__trigger.bx--btn--icon-only--bottom.bx--tooltip--align-start .bx--assistive-text::after {
    position: absolute;
    display: block;
    content: '';
    left: 0;
    width: 100%;
    height: 0.75rem;
    top: -0.75rem; }
  .bx--tooltip__trigger.bx--btn--icon-only--bottom.bx--tooltip--align-start::before, .bx--tooltip__trigger.bx--btn--icon-only--bottom.bx--tooltip--align-start::after,
  .bx--tooltip__trigger.bx--btn--icon-only--bottom.bx--tooltip--align-start .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--bottom.bx--tooltip--align-start + .bx--assistive-text {
    bottom: 0;
    left: 50%; }
  .bx--tooltip__trigger.bx--btn--icon-only--bottom.bx--tooltip--align-start::before {
    bottom: -0.5rem;
    border-width: 0 0.25rem 0.3125rem 0.25rem;
    border-color: transparent transparent var(--cds-inverse-02, #393939) transparent;
    transform: translate(-50%, 100%); }
  .bx--tooltip__trigger.bx--btn--icon-only--bottom.bx--tooltip--align-start::after,
  .bx--tooltip__trigger.bx--btn--icon-only--bottom.bx--tooltip--align-start .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--bottom.bx--tooltip--align-start + .bx--assistive-text {
    bottom: -0.8125rem;
    left: 0;
    transform: translate(0, 100%); }
  .bx--tooltip__trigger.bx--btn--icon-only--bottom.bx--tooltip--align-center .bx--assistive-text::after {
    position: absolute;
    display: block;
    content: '';
    left: 0;
    width: 100%;
    height: 0.75rem;
    top: -0.75rem; }
  .bx--tooltip__trigger.bx--btn--icon-only--bottom.bx--tooltip--align-center::before, .bx--tooltip__trigger.bx--btn--icon-only--bottom.bx--tooltip--align-center::after,
  .bx--tooltip__trigger.bx--btn--icon-only--bottom.bx--tooltip--align-center .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--bottom.bx--tooltip--align-center + .bx--assistive-text {
    bottom: 0;
    left: 50%; }
  .bx--tooltip__trigger.bx--btn--icon-only--bottom.bx--tooltip--align-center::before {
    bottom: -0.5rem;
    border-width: 0 0.25rem 0.3125rem 0.25rem;
    border-color: transparent transparent var(--cds-inverse-02, #393939) transparent;
    transform: translate(-50%, 100%); }
  .bx--tooltip__trigger.bx--btn--icon-only--bottom.bx--tooltip--align-center::after,
  .bx--tooltip__trigger.bx--btn--icon-only--bottom.bx--tooltip--align-center .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--bottom.bx--tooltip--align-center + .bx--assistive-text {
    bottom: -0.8125rem;
    transform: translate(-50%, 100%); }
  .bx--tooltip__trigger.bx--btn--icon-only--bottom.bx--tooltip--align-end .bx--assistive-text::after {
    position: absolute;
    display: block;
    content: '';
    left: 0;
    width: 100%;
    height: 0.75rem;
    top: -0.75rem; }
  .bx--tooltip__trigger.bx--btn--icon-only--bottom.bx--tooltip--align-end::before, .bx--tooltip__trigger.bx--btn--icon-only--bottom.bx--tooltip--align-end::after,
  .bx--tooltip__trigger.bx--btn--icon-only--bottom.bx--tooltip--align-end .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--bottom.bx--tooltip--align-end + .bx--assistive-text {
    bottom: 0;
    left: 50%; }
  .bx--tooltip__trigger.bx--btn--icon-only--bottom.bx--tooltip--align-end::before {
    bottom: -0.5rem;
    border-width: 0 0.25rem 0.3125rem 0.25rem;
    border-color: transparent transparent var(--cds-inverse-02, #393939) transparent;
    transform: translate(-50%, 100%); }
  .bx--tooltip__trigger.bx--btn--icon-only--bottom.bx--tooltip--align-end::after,
  .bx--tooltip__trigger.bx--btn--icon-only--bottom.bx--tooltip--align-end .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--bottom.bx--tooltip--align-end + .bx--assistive-text {
    bottom: -0.8125rem;
    right: 0;
    left: auto;
    transform: translate(0, 100%); }

.bx--tooltip__trigger.bx--btn--icon-only--left {
  position: relative;
  display: inline-flex;
  overflow: visible;
  align-items: center;
  cursor: pointer; }
  .bx--tooltip__trigger.bx--btn--icon-only--left:focus {
    outline: 1px solid var(--cds-focus, #0f62fe); }
    @media screen and (prefers-contrast) {
      .bx--tooltip__trigger.bx--btn--icon-only--left:focus {
        outline-style: dotted; } }
  .bx--tooltip__trigger.bx--btn--icon-only--left:focus {
    outline: 1px solid transparent; }
    .bx--tooltip__trigger.bx--btn--icon-only--left:focus svg {
      outline: 1px solid var(--cds-focus, #0f62fe); }
      @media screen and (prefers-contrast) {
        .bx--tooltip__trigger.bx--btn--icon-only--left:focus svg {
          outline-style: dotted; } }
  .bx--tooltip__trigger.bx--btn--icon-only--left::before, .bx--tooltip__trigger.bx--btn--icon-only--left::after,
  .bx--tooltip__trigger.bx--btn--icon-only--left .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--left + .bx--assistive-text {
    position: absolute;
    z-index: 6000;
    display: flex;
    align-items: center;
    opacity: 0;
    pointer-events: none; }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .bx--tooltip__trigger.bx--btn--icon-only--left::before, .bx--tooltip__trigger.bx--btn--icon-only--left::after,
      .bx--tooltip__trigger.bx--btn--icon-only--left .bx--assistive-text,
      .bx--tooltip__trigger.bx--btn--icon-only--left + .bx--assistive-text {
        display: inline-block; } }
  .bx--tooltip__trigger.bx--btn--icon-only--left::before, .bx--tooltip__trigger.bx--btn--icon-only--left::after {
    transition: opacity 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--tooltip__trigger.bx--btn--icon-only--left.bx--tooltip--a11y::before, .bx--tooltip__trigger.bx--btn--icon-only--left.bx--tooltip--a11y::after {
    transition: none; }
  .bx--tooltip__trigger.bx--btn--icon-only--left::before {
    width: 0;
    height: 0;
    border-style: solid;
    content: ''; }
  .bx--tooltip__trigger.bx--btn--icon-only--left .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--left + .bx--assistive-text {
    box-sizing: content-box;
    color: inherit;
    opacity: 1;
    white-space: normal;
    word-break: break-word; }
  .bx--tooltip__trigger.bx--btn--icon-only--left::after,
  .bx--tooltip__trigger.bx--btn--icon-only--left .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--left + .bx--assistive-text {
    box-shadow: 0 2px 6px var(--cds-shadow, rgba(0, 0, 0, 0.3));
    z-index: 6000;
    width: max-content;
    min-width: 1.5rem;
    max-width: 13rem;
    height: auto;
    padding: 0.1875rem 1rem;
    background-color: var(--cds-inverse-02, #393939);
    border-radius: 0.125rem;
    color: var(--cds-inverse-01, #ffffff);
    font-weight: 400;
    text-align: left;
    transform: translateX(-50%);
    font-size: var(--cds-body-short-01-font-size, 0.875rem);
    font-weight: var(--cds-body-short-01-font-weight, 400);
    line-height: var(--cds-body-short-01-line-height, 1.28572);
    letter-spacing: var(--cds-body-short-01-letter-spacing, 0.16px); }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .bx--tooltip__trigger.bx--btn--icon-only--left::after,
      .bx--tooltip__trigger.bx--btn--icon-only--left .bx--assistive-text,
      .bx--tooltip__trigger.bx--btn--icon-only--left + .bx--assistive-text {
        width: auto; } }
    @supports (-ms-accelerator: true) {
      .bx--tooltip__trigger.bx--btn--icon-only--left::after,
      .bx--tooltip__trigger.bx--btn--icon-only--left .bx--assistive-text,
      .bx--tooltip__trigger.bx--btn--icon-only--left + .bx--assistive-text {
        width: auto; } }
    @supports (-ms-ime-align: auto) {
      .bx--tooltip__trigger.bx--btn--icon-only--left::after,
      .bx--tooltip__trigger.bx--btn--icon-only--left .bx--assistive-text,
      .bx--tooltip__trigger.bx--btn--icon-only--left + .bx--assistive-text {
        width: auto; } }
    @media screen and (-ms-high-contrast: active), screen and (prefers-contrast) {
      .bx--tooltip__trigger.bx--btn--icon-only--left::after,
      .bx--tooltip__trigger.bx--btn--icon-only--left .bx--assistive-text,
      .bx--tooltip__trigger.bx--btn--icon-only--left + .bx--assistive-text {
        border: 1px solid transparent; } }
  .bx--tooltip__trigger.bx--btn--icon-only--left::after {
    content: attr(aria-label); }
  .bx--tooltip__trigger.bx--btn--icon-only--left.bx--tooltip--a11y::after {
    content: none; }
  .bx--tooltip__trigger.bx--btn--icon-only--left.bx--tooltip--visible::before, .bx--tooltip__trigger.bx--btn--icon-only--left.bx--tooltip--visible::after, .bx--tooltip__trigger.bx--btn--icon-only--left:hover::before, .bx--tooltip__trigger.bx--btn--icon-only--left:hover::after, .bx--tooltip__trigger.bx--btn--icon-only--left:focus::before, .bx--tooltip__trigger.bx--btn--icon-only--left:focus::after {
    opacity: 1; }

@keyframes tooltip-fade {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }
  .bx--tooltip__trigger.bx--btn--icon-only--left.bx--tooltip--visible .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--left.bx--tooltip--visible + .bx--assistive-text, .bx--tooltip__trigger.bx--btn--icon-only--left:hover .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--left:hover + .bx--assistive-text, .bx--tooltip__trigger.bx--btn--icon-only--left:focus .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--left:focus + .bx--assistive-text {
    overflow: visible;
    margin: auto;
    clip: auto; }
  .bx--tooltip__trigger.bx--btn--icon-only--left.bx--tooltip--visible .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--left.bx--tooltip--visible + .bx--assistive-text, .bx--tooltip__trigger.bx--btn--icon-only--left.bx--tooltip--visible.bx--tooltip--a11y::before, .bx--tooltip__trigger.bx--btn--icon-only--left:hover .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--left:hover + .bx--assistive-text, .bx--tooltip__trigger.bx--btn--icon-only--left:hover.bx--tooltip--a11y::before, .bx--tooltip__trigger.bx--btn--icon-only--left:focus .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--left:focus + .bx--assistive-text, .bx--tooltip__trigger.bx--btn--icon-only--left:focus.bx--tooltip--a11y::before {
    animation: tooltip-fade 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--tooltip__trigger.bx--btn--icon-only--left.bx--tooltip--hidden .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--left.bx--tooltip--hidden + .bx--assistive-text {
    overflow: hidden;
    margin: -1px;
    clip: rect(0, 0, 0, 0); }
  .bx--tooltip__trigger.bx--btn--icon-only--left.bx--tooltip--hidden.bx--tooltip--a11y::before {
    animation: none;
    opacity: 0; }
  .bx--tooltip__trigger.bx--btn--icon-only--left .bx--assistive-text::after {
    position: absolute;
    display: block;
    content: '';
    top: 0;
    width: 0.75rem;
    height: 100%;
    right: -0.75rem; }
  .bx--tooltip__trigger.bx--btn--icon-only--left::before, .bx--tooltip__trigger.bx--btn--icon-only--left::after,
  .bx--tooltip__trigger.bx--btn--icon-only--left .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--left + .bx--assistive-text {
    top: 50%;
    left: 0; }
  .bx--tooltip__trigger.bx--btn--icon-only--left::before {
    left: -0.5rem;
    border-width: 0.25rem 0 0.25rem 0.3125rem;
    border-color: transparent transparent transparent var(--cds-inverse-02, #393939);
    transform: translate(-100%, -50%); }
  .bx--tooltip__trigger.bx--btn--icon-only--left::after,
  .bx--tooltip__trigger.bx--btn--icon-only--left .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--left + .bx--assistive-text {
    left: -0.8125rem;
    transform: translate(-100%, -50%); }
  .bx--tooltip__trigger.bx--btn--icon-only--left.bx--tooltip--align-start .bx--assistive-text::after {
    position: absolute;
    display: block;
    content: '';
    top: 0;
    width: 0.75rem;
    height: 100%;
    right: -0.75rem; }
  .bx--tooltip__trigger.bx--btn--icon-only--left.bx--tooltip--align-start::before, .bx--tooltip__trigger.bx--btn--icon-only--left.bx--tooltip--align-start::after,
  .bx--tooltip__trigger.bx--btn--icon-only--left.bx--tooltip--align-start .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--left.bx--tooltip--align-start + .bx--assistive-text {
    top: 50%;
    left: 0; }
  .bx--tooltip__trigger.bx--btn--icon-only--left.bx--tooltip--align-start::before {
    left: -0.5rem;
    border-width: 0.25rem 0 0.25rem 0.3125rem;
    border-color: transparent transparent transparent var(--cds-inverse-02, #393939);
    transform: translate(-100%, -50%); }
  .bx--tooltip__trigger.bx--btn--icon-only--left.bx--tooltip--align-start::after,
  .bx--tooltip__trigger.bx--btn--icon-only--left.bx--tooltip--align-start .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--left.bx--tooltip--align-start + .bx--assistive-text {
    left: -0.8125rem;
    transform: translate(-100%, -50%); }
  .bx--tooltip__trigger.bx--btn--icon-only--left.bx--tooltip--align-center .bx--assistive-text::after {
    position: absolute;
    display: block;
    content: '';
    top: 0;
    width: 0.75rem;
    height: 100%;
    right: -0.75rem; }
  .bx--tooltip__trigger.bx--btn--icon-only--left.bx--tooltip--align-center::before, .bx--tooltip__trigger.bx--btn--icon-only--left.bx--tooltip--align-center::after,
  .bx--tooltip__trigger.bx--btn--icon-only--left.bx--tooltip--align-center .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--left.bx--tooltip--align-center + .bx--assistive-text {
    top: 50%;
    left: 0; }
  .bx--tooltip__trigger.bx--btn--icon-only--left.bx--tooltip--align-center::before {
    left: -0.5rem;
    border-width: 0.25rem 0 0.25rem 0.3125rem;
    border-color: transparent transparent transparent var(--cds-inverse-02, #393939);
    transform: translate(-100%, -50%); }
  .bx--tooltip__trigger.bx--btn--icon-only--left.bx--tooltip--align-center::after,
  .bx--tooltip__trigger.bx--btn--icon-only--left.bx--tooltip--align-center .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--left.bx--tooltip--align-center + .bx--assistive-text {
    left: -0.8125rem;
    transform: translate(-100%, -50%); }
  .bx--tooltip__trigger.bx--btn--icon-only--left.bx--tooltip--align-end .bx--assistive-text::after {
    position: absolute;
    display: block;
    content: '';
    top: 0;
    width: 0.75rem;
    height: 100%;
    right: -0.75rem; }
  .bx--tooltip__trigger.bx--btn--icon-only--left.bx--tooltip--align-end::before, .bx--tooltip__trigger.bx--btn--icon-only--left.bx--tooltip--align-end::after,
  .bx--tooltip__trigger.bx--btn--icon-only--left.bx--tooltip--align-end .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--left.bx--tooltip--align-end + .bx--assistive-text {
    top: 50%;
    left: 0; }
  .bx--tooltip__trigger.bx--btn--icon-only--left.bx--tooltip--align-end::before {
    left: -0.5rem;
    border-width: 0.25rem 0 0.25rem 0.3125rem;
    border-color: transparent transparent transparent var(--cds-inverse-02, #393939);
    transform: translate(-100%, -50%); }
  .bx--tooltip__trigger.bx--btn--icon-only--left.bx--tooltip--align-end::after,
  .bx--tooltip__trigger.bx--btn--icon-only--left.bx--tooltip--align-end .bx--assistive-text,
  .bx--tooltip__trigger.bx--btn--icon-only--left.bx--tooltip--align-end + .bx--assistive-text {
    left: -0.8125rem;
    transform: translate(-100%, -50%); }

.bx--btn--icon-only {
  padding-right: 0.9375rem;
  padding-left: 0.9375rem; }
  .bx--btn--icon-only .bx--btn__icon {
    position: static; }
  .bx--btn--icon-only.bx--btn--ghost .bx--btn__icon,
  .bx--btn--icon-only.bx--btn--danger--ghost .bx--btn__icon {
    margin: 0; }

.bx--btn--icon-only.bx--btn--selected {
  background: var(--cds-selected-ui, #e0e0e0); }

.bx--btn path[data-icon-path='inner-path'] {
  fill: none; }

.bx--btn--ghost.bx--btn--icon-only .bx--btn__icon path:not([data-icon-path]):not([fill='none']),
.bx--btn--ghost.bx--btn--icon-only .bx--btn__icon {
  fill: var(--cds-icon-01, #161616); }

.bx--btn--ghost.bx--btn--icon-only[disabled] .bx--btn__icon path:not([data-icon-path]):not([fill='none']),
.bx--btn--ghost.bx--btn--icon-only[disabled] .bx--btn__icon,
.bx--btn.bx--btn--icon-only.bx--btn--ghost[disabled]:hover .bx--btn__icon {
  fill: var(--cds-disabled-03, #8d8d8d); }

.bx--btn--ghost.bx--btn--icon-only[disabled] {
  cursor: not-allowed; }

.bx--btn--field.bx--btn--icon-only,
.bx--btn--md.bx--btn--icon-only {
  padding-right: 0.6875rem;
  padding-left: 0.6875rem; }

.bx--btn--sm.bx--btn--icon-only {
  padding-right: 0.4375rem;
  padding-left: 0.4375rem; }

.bx--btn--danger {
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  background-color: var(--cds-danger-01, #da1e28);
  color: var(--cds-text-04, #ffffff); }
  .bx--btn--danger:hover {
    background-color: var(--cds-hover-danger, #b81921); }
  .bx--btn--danger:focus {
    border-color: var(--cds-focus, #0f62fe);
    box-shadow: inset 0 0 0 1px var(--cds-focus, #0f62fe), inset 0 0 0 2px var(--cds-ui-background, #ffffff); }
  .bx--btn--danger:active {
    background-color: var(--cds-active-danger, #750e13); }
  .bx--btn--danger .bx--btn__icon,
  .bx--btn--danger .bx--btn__icon path:not([data-icon-path]):not([fill='none']) {
    fill: currentColor; }
  .bx--btn--danger:hover {
    color: var(--cds-text-04, #ffffff); }
  .bx--btn--danger-tertiary, .bx--btn--danger--tertiary {
    border-width: 1px;
    border-style: solid;
    border-color: var(--cds-danger-02, #da1e28);
    background-color: transparent;
    color: var(--cds-danger-02, #da1e28); }
    .bx--btn--danger-tertiary:hover, .bx--btn--danger--tertiary:hover {
      background-color: var(--cds-hover-danger, #b81921); }
    .bx--btn--danger-tertiary:focus, .bx--btn--danger--tertiary:focus {
      border-color: var(--cds-focus, #0f62fe);
      box-shadow: inset 0 0 0 1px var(--cds-focus, #0f62fe), inset 0 0 0 2px var(--cds-ui-background, #ffffff); }
    .bx--btn--danger-tertiary:active, .bx--btn--danger--tertiary:active {
      background-color: var(--cds-active-danger, #750e13); }
    .bx--btn--danger-tertiary .bx--btn__icon,
    .bx--btn--danger-tertiary .bx--btn__icon path:not([data-icon-path]):not([fill='none']), .bx--btn--danger--tertiary .bx--btn__icon,
    .bx--btn--danger--tertiary .bx--btn__icon path:not([data-icon-path]):not([fill='none']) {
      fill: currentColor; }
    .bx--btn--danger-tertiary:hover, .bx--btn--danger--tertiary:hover {
      border-color: var(--cds-hover-danger, #b81921);
      color: var(--cds-text-04, #ffffff); }
    .bx--btn--danger-tertiary:focus, .bx--btn--danger--tertiary:focus {
      background-color: var(--cds-danger-01, #da1e28);
      color: var(--cds-text-04, #ffffff); }
    .bx--btn--danger-tertiary:active, .bx--btn--danger--tertiary:active {
      border-color: var(--cds-active-danger, #750e13);
      color: var(--cds-text-04, #ffffff); }
    .bx--btn--danger-tertiary:disabled, .bx--btn--danger-tertiary:hover:disabled, .bx--btn--danger-tertiary:focus:disabled, .bx--btn--danger-tertiary.bx--btn--disabled, .bx--btn--danger-tertiary.bx--btn--disabled:hover, .bx--btn--danger-tertiary.bx--btn--disabled:focus, .bx--btn--danger--tertiary:disabled, .bx--btn--danger--tertiary:hover:disabled, .bx--btn--danger--tertiary:focus:disabled, .bx--btn--danger--tertiary.bx--btn--disabled, .bx--btn--danger--tertiary.bx--btn--disabled:hover, .bx--btn--danger--tertiary.bx--btn--disabled:focus {
      background: transparent;
      color: var(--cds-disabled-03, #8d8d8d);
      outline: none; }
  .bx--btn--danger-ghost, .bx--btn--danger--ghost {
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    background-color: transparent;
    color: var(--cds-danger-02, #da1e28);
    padding: calc(0.875rem - 3px) 16px; }
    .bx--btn--danger-ghost:hover, .bx--btn--danger--ghost:hover {
      background-color: var(--cds-hover-danger, #b81921); }
    .bx--btn--danger-ghost:focus, .bx--btn--danger--ghost:focus {
      border-color: var(--cds-focus, #0f62fe);
      box-shadow: inset 0 0 0 1px var(--cds-focus, #0f62fe), inset 0 0 0 2px var(--cds-ui-background, #ffffff); }
    .bx--btn--danger-ghost:active, .bx--btn--danger--ghost:active {
      background-color: var(--cds-active-danger, #750e13); }
    .bx--btn--danger-ghost .bx--btn__icon,
    .bx--btn--danger-ghost .bx--btn__icon path:not([data-icon-path]):not([fill='none']), .bx--btn--danger--ghost .bx--btn__icon,
    .bx--btn--danger--ghost .bx--btn__icon path:not([data-icon-path]):not([fill='none']) {
      fill: currentColor; }
    .bx--btn--danger-ghost .bx--btn__icon, .bx--btn--danger--ghost .bx--btn__icon {
      position: static;
      margin-left: 0.5rem; }
    .bx--btn--danger-ghost:hover, .bx--btn--danger-ghost:active, .bx--btn--danger--ghost:hover, .bx--btn--danger--ghost:active {
      color: var(--cds-text-04, #ffffff); }
    .bx--btn--danger-ghost:disabled, .bx--btn--danger-ghost:hover:disabled, .bx--btn--danger-ghost:focus:disabled, .bx--btn--danger-ghost.bx--btn--disabled, .bx--btn--danger-ghost.bx--btn--disabled:hover, .bx--btn--danger-ghost.bx--btn--disabled:focus, .bx--btn--danger--ghost:disabled, .bx--btn--danger--ghost:hover:disabled, .bx--btn--danger--ghost:focus:disabled, .bx--btn--danger--ghost.bx--btn--disabled, .bx--btn--danger--ghost.bx--btn--disabled:hover, .bx--btn--danger--ghost.bx--btn--disabled:focus {
      border-color: transparent;
      background: transparent;
      color: #c6c6c6;
      outline: none; }
    .bx--btn--danger-ghost.bx--btn--sm, .bx--btn--danger--ghost.bx--btn--sm {
      padding: calc(0.375rem - 3px) 16px; }
    .bx--btn--danger-ghost.bx--btn--field, .bx--btn--danger-ghost.bx--btn--md, .bx--btn--danger--ghost.bx--btn--field, .bx--btn--danger--ghost.bx--btn--md {
      padding: calc(0.675rem - 3px) 16px; }

.bx--btn--sm {
  min-height: 2rem;
  padding: calc(0.375rem - 3px) 60px calc(0.375rem - 3px) 12px; }

.bx--btn--xl:not(.bx--btn--icon-only) {
  align-items: baseline;
  padding-top: var(--cds-spacing-05, 1rem);
  padding-right: var(--cds-spacing-10, 4rem);
  padding-left: var(--cds-spacing-05, 1rem);
  min-height: 5rem; }

.bx--btn--lg:not(.bx--btn--icon-only) {
  align-items: baseline;
  padding-top: var(--cds-spacing-05, 1rem);
  padding-right: var(--cds-spacing-10, 4rem);
  padding-left: var(--cds-spacing-05, 1rem);
  min-height: 4rem; }

.bx--btn--field,
.bx--btn--md {
  min-height: 2.5rem;
  padding: calc(0.675rem - 3px) 60px calc(0.675rem - 3px) 12px; }

.bx--btn--expressive {
  font-size: var(--cds-body-short-02-font-size, 1rem);
  font-weight: var(--cds-body-short-02-font-weight, 400);
  line-height: var(--cds-body-short-02-line-height, 1.375);
  letter-spacing: var(--cds-body-short-02-letter-spacing, 0);
  min-height: 3rem; }

.bx--btn--icon-only.bx--btn--expressive {
  padding: 12px 13px; }

.bx--btn.bx--btn--expressive .bx--btn__icon {
  width: 1.25rem;
  height: 1.25rem; }

.bx--btn-set .bx--btn.bx--btn--expressive {
  max-width: 20rem; }

.bx--btn.bx--skeleton {
  position: relative;
  padding: 0;
  border: none;
  background: var(--cds-skeleton-01, #e5e5e5);
  box-shadow: none;
  pointer-events: none;
  width: 9.375rem; }
  .bx--btn.bx--skeleton:hover, .bx--btn.bx--skeleton:focus, .bx--btn.bx--skeleton:active {
    border: none;
    cursor: default;
    outline: none; }
  .bx--btn.bx--skeleton::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    animation: 3000ms ease-in-out skeleton infinite;
    background: var(--cds-skeleton-02, #c6c6c6);
    content: '';
    will-change: transform-origin, transform, opacity; }
    @media (prefers-reduced-motion: reduce) {
      .bx--btn.bx--skeleton::before {
        animation: none; } }

.bx--btn-set {
  display: flex; }

.bx--btn-set--stacked {
  flex-direction: column; }

.bx--btn-set .bx--btn {
  width: 100%;
  max-width: 12.25rem; }
  .bx--btn-set .bx--btn:not(:focus) {
    box-shadow: -0.0625rem 0 0 0 var(--cds-button-separator, #e0e0e0); }
  .bx--btn-set .bx--btn:first-of-type:not(:focus) {
    box-shadow: inherit; }

.bx--btn-set .bx--btn:focus + .bx--btn {
  box-shadow: inherit; }

.bx--btn-set--stacked .bx--btn:not(:focus) {
  box-shadow: 0 -0.0625rem 0 0 var(--cds-button-separator, #e0e0e0); }

.bx--btn-set--stacked .bx--btn:first-of-type:not(:focus) {
  box-shadow: inherit; }

.bx--btn-set .bx--btn.bx--btn--disabled {
  box-shadow: -0.0625rem 0 0 0 var(--cds-disabled-03, #8d8d8d); }
  .bx--btn-set .bx--btn.bx--btn--disabled:first-of-type {
    box-shadow: none; }

.bx--btn-set--stacked .bx--btn.bx--btn--disabled {
  box-shadow: 0 -0.0625rem 0 0 var(--cds-disabled-03, #8d8d8d); }
  .bx--btn-set--stacked .bx--btn.bx--btn--disabled:first-of-type {
    box-shadow: none; }

@media screen and (-ms-high-contrast: active), (forced-colors: active), (prefers-contrast) {
  .bx--btn:focus {
    color: Highlight;
    outline: 1px solid Highlight; } }

@media screen and (-ms-high-contrast: active), (forced-colors: active), (prefers-contrast) {
  .bx--btn--ghost.bx--btn--icon-only .bx--btn__icon path:not([data-icon-path]):not([fill='none']),
  .bx--btn--ghost.bx--btn--icon-only .bx--btn__icon {
    fill: ButtonText; } }

.bx--tooltip__label {
  font-size: var(--cds-label-01-font-size, 0.75rem);
  font-weight: var(--cds-label-01-font-weight, 400);
  line-height: var(--cds-label-01-line-height, 1.33333);
  letter-spacing: var(--cds-label-01-letter-spacing, 0.32px);
  display: inline-flex;
  align-items: center;
  color: var(--cds-text-02, #525252); }
  .bx--tooltip__label:focus {
    outline: 1px solid var(--cds-focus, #0f62fe); }
    @media screen and (prefers-contrast) {
      .bx--tooltip__label:focus {
        outline-style: dotted; } }

.bx--tooltip__trigger svg {
  fill: var(--cds-icon-02, #525252); }

.bx--tooltip__trigger:not(.bx--btn--icon-only) {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  display: inline-block;
  padding: 0;
  border: 0;
  appearance: none;
  background: none;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  font-size: 1rem; }
  .bx--tooltip__trigger:not(.bx--btn--icon-only) *,
  .bx--tooltip__trigger:not(.bx--btn--icon-only) *::before,
  .bx--tooltip__trigger:not(.bx--btn--icon-only) *::after {
    box-sizing: inherit; }
  .bx--tooltip__trigger:not(.bx--btn--icon-only)::-moz-focus-inner {
    border: 0; }
  .bx--tooltip__trigger:not(.bx--btn--icon-only):focus {
    outline: 1px solid var(--cds-focus, #0f62fe);
    fill: var(--cds-hover-primary, #0353e9); }
    @media screen and (prefers-contrast) {
      .bx--tooltip__trigger:not(.bx--btn--icon-only):focus {
        outline-style: dotted; } }

.bx--tooltip__trigger:not(.bx--btn--icon-only)[disabled] svg {
  fill: var(--cds-icon-disabled, #c6c6c6); }

.bx--tooltip__label .bx--tooltip__trigger {
  margin-left: 0.5rem; }

.bx--tooltip__label--bold {
  font-weight: 600; }

.bx--tooltip {
  box-shadow: 0 2px 6px var(--cds-shadow, rgba(0, 0, 0, 0.3));
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  position: absolute;
  z-index: 6000;
  display: none;
  min-width: 13rem;
  max-width: 18rem;
  padding: 1rem;
  margin-top: 0.25rem;
  background: var(--cds-inverse-02, #393939);
  border-radius: 0.125rem;
  color: var(--cds-inverse-01, #ffffff);
  word-wrap: break-word; }
  .bx--tooltip *,
  .bx--tooltip *::before,
  .bx--tooltip *::after {
    box-sizing: inherit; }
  .bx--tooltip:focus {
    box-shadow: inset 0 0 0 1px var(--cds-inverse-02, #393939), inset 0 0 0 2px var(--cds-ui-background, #ffffff);
    outline: 0; }
  .bx--tooltip.bx--tooltip--top.bx--tooltip--align-start, .bx--tooltip.bx--tooltip--bottom.bx--tooltip--align-start {
    transform: translate(calc(50% - 22px), 0); }
    .bx--tooltip.bx--tooltip--top.bx--tooltip--align-start .bx--tooltip__caret, .bx--tooltip.bx--tooltip--bottom.bx--tooltip--align-start .bx--tooltip__caret {
      margin-left: 15px; }
  .bx--tooltip.bx--tooltip--top.bx--tooltip--align-end, .bx--tooltip.bx--tooltip--bottom.bx--tooltip--align-end {
    transform: translate(calc(22px - 50%), 0); }
    .bx--tooltip.bx--tooltip--top.bx--tooltip--align-end .bx--tooltip__caret, .bx--tooltip.bx--tooltip--bottom.bx--tooltip--align-end .bx--tooltip__caret {
      margin-right: 15px; }
  .bx--tooltip.bx--tooltip--left.bx--tooltip--align-start {
    transform: translate(0, calc(-15px + 50%)); }
    .bx--tooltip.bx--tooltip--left.bx--tooltip--align-start .bx--tooltip__caret {
      top: 14px; }
  .bx--tooltip.bx--tooltip--left.bx--tooltip--align-end {
    transform: translate(0, calc(31px - 50%)); }
    .bx--tooltip.bx--tooltip--left.bx--tooltip--align-end .bx--tooltip__caret {
      top: initial;
      bottom: 25px; }
  .bx--tooltip.bx--tooltip--right.bx--tooltip--align-start {
    transform: translate(0, calc(-26px + 50%)); }
    .bx--tooltip.bx--tooltip--right.bx--tooltip--align-start .bx--tooltip__caret {
      top: 26px; }
  .bx--tooltip.bx--tooltip--right.bx--tooltip--align-end {
    transform: translate(0, calc(20px - 50%)); }
    .bx--tooltip.bx--tooltip--right.bx--tooltip--align-end .bx--tooltip__caret {
      top: initial;
      bottom: 12px; }
  .bx--tooltip p {
    font-size: var(--cds-body-short-01-font-size, 0.875rem);
    font-weight: var(--cds-body-short-01-font-weight, 400);
    line-height: var(--cds-body-short-01-line-height, 1.28572);
    letter-spacing: var(--cds-body-short-01-letter-spacing, 0.16px); }
  .bx--tooltip button {
    padding-right: 2rem; }
  .bx--tooltip .bx--btn:focus {
    border-color: var(--cds-inverse-focus-ui, #ffffff);
    outline-color: var(--cds-inverse-02, #393939); }
  .bx--tooltip .bx--link {
    color: var(--cds-inverse-link, #78a9ff);
    font-size: 0.875rem; }
    .bx--tooltip .bx--link:focus {
      outline: 1px solid var(--cds-inverse-focus-ui, #ffffff);
      outline-offset: 2px; }
    .bx--tooltip .bx--link:active, .bx--tooltip .bx--link:active:visited, .bx--tooltip .bx--link:active:visited:hover {
      color: var(--cds-inverse-01, #ffffff); }
    .bx--tooltip .bx--link:visited {
      color: var(--cds-inverse-link, #78a9ff); }
  .bx--tooltip .bx--tooltip__content[tabindex='-1']:focus {
    outline: none; }
  .bx--tooltip .bx--tooltip__caret {
    position: absolute;
    top: calc(-0.42969rem + 1px);
    right: 0;
    left: 0;
    width: 0;
    height: 0;
    border-right: 0.42969rem solid transparent;
    border-bottom: 0.42969rem solid var(--cds-inverse-02, #393939);
    border-left: 0.42969rem solid transparent;
    margin: 0 auto;
    content: ''; }
  .bx--tooltip .bx--tooltip__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem; }
  .bx--tooltip[data-floating-menu-direction='left'] {
    margin-left: calc(var(--cds-spacing-03, 0.5rem) * -1); }
    .bx--tooltip[data-floating-menu-direction='left'] .bx--tooltip__caret {
      top: 50%;
      right: calc(-0.42969rem + 1px);
      left: auto;
      transform: rotate(90deg) translate(50%, -50%); }
  .bx--tooltip[data-floating-menu-direction='top'] {
    margin-top: calc(var(--cds-spacing-03, 0.5rem) * -1); }
    .bx--tooltip[data-floating-menu-direction='top'] .bx--tooltip__caret {
      top: auto;
      bottom: calc(-0.42969rem + 1px);
      transform: rotate(180deg); }
  .bx--tooltip[data-floating-menu-direction='right'] {
    margin-left: var(--cds-spacing-03, 0.5rem); }
    .bx--tooltip[data-floating-menu-direction='right'] .bx--tooltip__caret {
      top: 50%;
      right: auto;
      left: calc(-0.42969rem + 1px);
      transform: rotate(270deg) translate(50%, -50%); }
  .bx--tooltip[data-floating-menu-direction='bottom'] {
    margin-top: var(--cds-spacing-03, 0.5rem); }

.bx--tooltip__heading {
  font-size: var(--cds-productive-heading-01-font-size, 0.875rem);
  font-weight: var(--cds-productive-heading-01-font-weight, 600);
  line-height: var(--cds-productive-heading-01-line-height, 1.28572);
  letter-spacing: var(--cds-productive-heading-01-letter-spacing, 0.16px);
  margin-bottom: var(--cds-spacing-03, 0.5rem); }

.bx--tooltip--shown {
  display: block;
  margin-top: 0; }

/* begin legacy definition tooltip TODO: deprecate */
.bx--tooltip--definition {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  position: relative; }
  .bx--tooltip--definition *,
  .bx--tooltip--definition *::before,
  .bx--tooltip--definition *::after {
    box-sizing: inherit; }
  .bx--tooltip--definition .bx--tooltip__trigger {
    font-size: var(--cds-label-01-font-size, 0.75rem);
    font-weight: var(--cds-label-01-font-weight, 400);
    line-height: var(--cds-label-01-line-height, 1.33333);
    letter-spacing: var(--cds-label-01-letter-spacing, 0.32px);
    position: relative;
    display: inline-flex;
    border-bottom: 1px dotted var(--cds-interactive-01, #0f62fe);
    color: var(--cds-text-01, #161616); }
    .bx--tooltip--definition .bx--tooltip__trigger:hover + .bx--tooltip--definition__top,
    .bx--tooltip--definition .bx--tooltip__trigger:hover + .bx--tooltip--definition__bottom {
      display: block; }
    .bx--tooltip--definition .bx--tooltip__trigger:focus {
      outline: 1px solid var(--cds-focus, #0f62fe); }
      @media screen and (prefers-contrast) {
        .bx--tooltip--definition .bx--tooltip__trigger:focus {
          outline-style: dotted; } }
      .bx--tooltip--definition .bx--tooltip__trigger:focus + .bx--tooltip--definition__top,
      .bx--tooltip--definition .bx--tooltip__trigger:focus + .bx--tooltip--definition__bottom {
        display: block; }

.bx--tooltip--definition__bottom,
.bx--tooltip--definition__top {
  box-shadow: 0 2px 6px var(--cds-shadow, rgba(0, 0, 0, 0.3));
  position: absolute;
  z-index: 1;
  display: none;
  width: 13rem;
  padding: 0.5rem 1rem;
  margin-top: 0.75rem;
  background: var(--cds-inverse-02, #393939);
  border-radius: 0.125rem;
  pointer-events: none; }
  .bx--tooltip--definition__bottom p,
  .bx--tooltip--definition__top p {
    font-size: var(--cds-body-short-01-font-size, 0.875rem);
    font-weight: var(--cds-body-short-01-font-weight, 400);
    line-height: var(--cds-body-short-01-line-height, 1.28572);
    letter-spacing: var(--cds-body-short-01-letter-spacing, 0.16px);
    color: var(--cds-inverse-01, #ffffff); }
  .bx--tooltip--definition__bottom .bx--tooltip__caret,
  .bx--tooltip--definition__top .bx--tooltip__caret {
    position: absolute;
    right: 0;
    left: 0;
    width: 0.6rem;
    height: 0.6rem;
    margin-left: 1rem;
    background: var(--cds-inverse-02, #393939); }

.bx--tooltip--definition__bottom .bx--tooltip__caret {
  top: -0.2rem;
  transform: rotate(-135deg); }

.bx--tooltip--definition__top {
  margin-top: -2rem;
  transform: translateY(-100%); }
  .bx--tooltip--definition__top .bx--tooltip__caret {
    bottom: -0.2rem;
    transform: rotate(45deg); }

.bx--tooltip--definition__align-end {
  right: 0; }

.bx--tooltip--definition__align-center {
  margin-left: 50%;
  transform: translateX(-50%); }

.bx--tooltip--definition__top.bx--tooltip--definition__align-center {
  margin-left: 50%;
  transform: translate(-50%, -100%); }

.bx--tooltip--definition__align-center .bx--tooltip__caret {
  left: auto;
  margin-right: calc(50% - 6px);
  margin-left: auto; }

.bx--tooltip--definition__align-end .bx--tooltip__caret {
  left: auto;
  margin-right: 1rem;
  margin-left: auto; }

/* end legacy definition tooltip */
.bx--tooltip--definition.bx--tooltip--a11y {
  display: inline-flex; }

.bx--tooltip--definition button.bx--tooltip--a11y {
  margin: 0; }

.bx--tooltip__trigger.bx--tooltip__trigger--definition {
  font-size: var(--cds-label-01-font-size, 0.75rem);
  font-weight: var(--cds-label-01-font-weight, 400);
  line-height: var(--cds-label-01-line-height, 1.33333);
  letter-spacing: var(--cds-label-01-letter-spacing, 0.32px);
  border-bottom: 0.0625rem dotted var(--cds-text-02, #525252);
  transition: border-color 110ms; }

.bx--tooltip__trigger.bx--tooltip__trigger--definition:hover,
.bx--tooltip__trigger.bx--tooltip__trigger--definition:focus {
  border-bottom-color: var(--cds-interactive-04, #0f62fe); }

.bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top {
  position: relative;
  display: inline-flex;
  overflow: visible;
  align-items: center;
  cursor: default; }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top:focus {
    outline: 1px solid var(--cds-focus, #0f62fe); }
    @media screen and (prefers-contrast) {
      .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top:focus {
        outline-style: dotted; } }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top::before, .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top::after,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top + .bx--assistive-text {
    position: absolute;
    z-index: 6000;
    display: flex;
    align-items: center;
    opacity: 0;
    pointer-events: none; }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top::before, .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top::after,
      .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top .bx--assistive-text,
      .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top + .bx--assistive-text {
        display: inline-block; } }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top::before, .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top::after {
    transition: opacity 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top.bx--tooltip--a11y::before, .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top.bx--tooltip--a11y::after {
    transition: none; }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top::before {
    width: 0;
    height: 0;
    border-style: solid;
    content: ''; }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top + .bx--assistive-text {
    box-sizing: content-box;
    color: inherit;
    opacity: 1;
    white-space: normal;
    word-break: break-word; }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top::after,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top + .bx--assistive-text {
    box-shadow: 0 2px 6px var(--cds-shadow, rgba(0, 0, 0, 0.3));
    z-index: 6000;
    width: max-content;
    min-width: 1.5rem;
    max-width: 13rem;
    height: auto;
    padding: 0.5rem 1rem;
    background-color: var(--cds-inverse-02, #393939);
    border-radius: 0.125rem;
    color: var(--cds-inverse-01, #ffffff);
    font-weight: 400;
    text-align: left;
    transform: translateX(-50%);
    font-size: var(--cds-body-short-01-font-size, 0.875rem);
    font-weight: var(--cds-body-short-01-font-weight, 400);
    line-height: var(--cds-body-short-01-line-height, 1.28572);
    letter-spacing: var(--cds-body-short-01-letter-spacing, 0.16px); }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top::after,
      .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top .bx--assistive-text,
      .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top + .bx--assistive-text {
        width: auto; } }
    @supports (-ms-accelerator: true) {
      .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top::after,
      .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top .bx--assistive-text,
      .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top + .bx--assistive-text {
        width: auto; } }
    @supports (-ms-ime-align: auto) {
      .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top::after,
      .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top .bx--assistive-text,
      .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top + .bx--assistive-text {
        width: auto; } }
    @media screen and (-ms-high-contrast: active), screen and (prefers-contrast) {
      .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top::after,
      .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top .bx--assistive-text,
      .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top + .bx--assistive-text {
        border: 1px solid transparent; } }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top::after {
    content: attr(aria-label); }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top.bx--tooltip--a11y::after {
    content: none; }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top.bx--tooltip--visible::before, .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top.bx--tooltip--visible::after, .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top:hover::before, .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top:hover::after, .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top:focus::before, .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top:focus::after {
    opacity: 1; }

@keyframes tooltip-fade {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top.bx--tooltip--visible .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top.bx--tooltip--visible + .bx--assistive-text, .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top:hover .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top:hover + .bx--assistive-text, .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top:focus .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top:focus + .bx--assistive-text {
    overflow: visible;
    margin: auto;
    clip: auto; }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top.bx--tooltip--visible .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top.bx--tooltip--visible + .bx--assistive-text, .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top.bx--tooltip--visible.bx--tooltip--a11y::before, .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top:hover .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top:hover + .bx--assistive-text, .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top:hover.bx--tooltip--a11y::before, .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top:focus .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top:focus + .bx--assistive-text, .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top:focus.bx--tooltip--a11y::before {
    animation: tooltip-fade 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top.bx--tooltip--hidden .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top.bx--tooltip--hidden + .bx--assistive-text {
    overflow: hidden;
    margin: -1px;
    clip: rect(0, 0, 0, 0); }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top.bx--tooltip--hidden.bx--tooltip--a11y::before {
    animation: none;
    opacity: 0; }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top .bx--assistive-text::after {
    position: absolute;
    display: block;
    content: '';
    left: 0;
    width: 100%;
    height: 0.75rem;
    bottom: -0.75rem; }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top::before, .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top::after,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top + .bx--assistive-text {
    top: 0;
    left: 50%; }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top::before {
    top: -0.25rem;
    border-width: 0.3125rem 0.25rem 0 0.25rem;
    border-color: var(--cds-inverse-02, #393939) transparent transparent transparent;
    transform: translate(-50%, -100%); }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top::after,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top + .bx--assistive-text {
    top: -0.5625rem;
    left: 0;
    transform: translate(0, -100%); }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top.bx--tooltip--align-start .bx--assistive-text::after {
    position: absolute;
    display: block;
    content: '';
    left: 0;
    width: 100%;
    height: 0.75rem;
    bottom: -0.75rem; }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top.bx--tooltip--align-start::before, .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top.bx--tooltip--align-start::after,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top.bx--tooltip--align-start .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top.bx--tooltip--align-start + .bx--assistive-text {
    top: 0;
    left: 50%; }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top.bx--tooltip--align-start::before {
    top: -0.25rem;
    border-width: 0.3125rem 0.25rem 0 0.25rem;
    border-color: var(--cds-inverse-02, #393939) transparent transparent transparent;
    transform: translate(-50%, -100%); }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top.bx--tooltip--align-start::after,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top.bx--tooltip--align-start .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top.bx--tooltip--align-start + .bx--assistive-text {
    top: -0.5625rem;
    left: 0;
    transform: translate(0, -100%); }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top.bx--tooltip--align-center .bx--assistive-text::after {
    position: absolute;
    display: block;
    content: '';
    left: 0;
    width: 100%;
    height: 0.75rem;
    bottom: -0.75rem; }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top.bx--tooltip--align-center::before, .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top.bx--tooltip--align-center::after,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top.bx--tooltip--align-center .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top.bx--tooltip--align-center + .bx--assistive-text {
    top: 0;
    left: 50%; }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top.bx--tooltip--align-center::before {
    top: -0.25rem;
    border-width: 0.3125rem 0.25rem 0 0.25rem;
    border-color: var(--cds-inverse-02, #393939) transparent transparent transparent;
    transform: translate(-50%, -100%); }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top.bx--tooltip--align-center::after,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top.bx--tooltip--align-center .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top.bx--tooltip--align-center + .bx--assistive-text {
    top: -0.5625rem;
    left: 50%;
    transform: translate(-50%, -100%); }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top.bx--tooltip--align-end .bx--assistive-text::after {
    position: absolute;
    display: block;
    content: '';
    left: 0;
    width: 100%;
    height: 0.75rem;
    bottom: -0.75rem; }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top.bx--tooltip--align-end::before, .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top.bx--tooltip--align-end::after,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top.bx--tooltip--align-end .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top.bx--tooltip--align-end + .bx--assistive-text {
    top: 0;
    left: 50%; }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top.bx--tooltip--align-end::before {
    top: -0.25rem;
    border-width: 0.3125rem 0.25rem 0 0.25rem;
    border-color: var(--cds-inverse-02, #393939) transparent transparent transparent;
    transform: translate(-50%, -100%); }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top.bx--tooltip--align-end::after,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top.bx--tooltip--align-end .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top.bx--tooltip--align-end + .bx--assistive-text {
    top: -0.5625rem;
    right: 0;
    left: auto;
    transform: translate(0, -100%); }

.bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom {
  position: relative;
  display: inline-flex;
  overflow: visible;
  align-items: center;
  cursor: default; }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom:focus {
    outline: 1px solid var(--cds-focus, #0f62fe); }
    @media screen and (prefers-contrast) {
      .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom:focus {
        outline-style: dotted; } }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom::before, .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom::after,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom + .bx--assistive-text {
    position: absolute;
    z-index: 6000;
    display: flex;
    align-items: center;
    opacity: 0;
    pointer-events: none; }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom::before, .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom::after,
      .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom .bx--assistive-text,
      .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom + .bx--assistive-text {
        display: inline-block; } }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom::before, .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom::after {
    transition: opacity 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom.bx--tooltip--a11y::before, .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom.bx--tooltip--a11y::after {
    transition: none; }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom::before {
    width: 0;
    height: 0;
    border-style: solid;
    content: ''; }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom + .bx--assistive-text {
    box-sizing: content-box;
    color: inherit;
    opacity: 1;
    white-space: normal;
    word-break: break-word; }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom::after,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom + .bx--assistive-text {
    box-shadow: 0 2px 6px var(--cds-shadow, rgba(0, 0, 0, 0.3));
    z-index: 6000;
    width: max-content;
    min-width: 1.5rem;
    max-width: 13rem;
    height: auto;
    padding: 0.5rem 1rem;
    background-color: var(--cds-inverse-02, #393939);
    border-radius: 0.125rem;
    color: var(--cds-inverse-01, #ffffff);
    font-weight: 400;
    text-align: left;
    transform: translateX(-50%);
    font-size: var(--cds-body-short-01-font-size, 0.875rem);
    font-weight: var(--cds-body-short-01-font-weight, 400);
    line-height: var(--cds-body-short-01-line-height, 1.28572);
    letter-spacing: var(--cds-body-short-01-letter-spacing, 0.16px); }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom::after,
      .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom .bx--assistive-text,
      .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom + .bx--assistive-text {
        width: auto; } }
    @supports (-ms-accelerator: true) {
      .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom::after,
      .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom .bx--assistive-text,
      .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom + .bx--assistive-text {
        width: auto; } }
    @supports (-ms-ime-align: auto) {
      .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom::after,
      .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom .bx--assistive-text,
      .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom + .bx--assistive-text {
        width: auto; } }
    @media screen and (-ms-high-contrast: active), screen and (prefers-contrast) {
      .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom::after,
      .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom .bx--assistive-text,
      .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom + .bx--assistive-text {
        border: 1px solid transparent; } }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom::after {
    content: attr(aria-label); }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom.bx--tooltip--a11y::after {
    content: none; }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom.bx--tooltip--visible::before, .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom.bx--tooltip--visible::after, .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom:hover::before, .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom:hover::after, .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom:focus::before, .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom:focus::after {
    opacity: 1; }

@keyframes tooltip-fade {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom.bx--tooltip--visible .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom.bx--tooltip--visible + .bx--assistive-text, .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom:hover .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom:hover + .bx--assistive-text, .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom:focus .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom:focus + .bx--assistive-text {
    overflow: visible;
    margin: auto;
    clip: auto; }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom.bx--tooltip--visible .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom.bx--tooltip--visible + .bx--assistive-text, .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom.bx--tooltip--visible.bx--tooltip--a11y::before, .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom:hover .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom:hover + .bx--assistive-text, .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom:hover.bx--tooltip--a11y::before, .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom:focus .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom:focus + .bx--assistive-text, .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom:focus.bx--tooltip--a11y::before {
    animation: tooltip-fade 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom.bx--tooltip--hidden .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom.bx--tooltip--hidden + .bx--assistive-text {
    overflow: hidden;
    margin: -1px;
    clip: rect(0, 0, 0, 0); }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom.bx--tooltip--hidden.bx--tooltip--a11y::before {
    animation: none;
    opacity: 0; }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom .bx--assistive-text::after {
    position: absolute;
    display: block;
    content: '';
    left: 0;
    width: 100%;
    height: 0.75rem;
    top: -0.75rem; }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom::before, .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom::after,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom + .bx--assistive-text {
    bottom: 0;
    left: 50%; }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom::before {
    bottom: -0.25rem;
    border-width: 0 0.25rem 0.3125rem 0.25rem;
    border-color: transparent transparent var(--cds-inverse-02, #393939) transparent;
    transform: translate(-50%, 100%); }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom::after,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom + .bx--assistive-text {
    bottom: -0.5625rem;
    left: 0;
    transform: translate(0, 100%); }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom.bx--tooltip--a11y + .bx--assistive-text {
    bottom: -0.5rem;
    transform: translate(0, 100%); }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom.bx--tooltip--align-start .bx--assistive-text::after {
    position: absolute;
    display: block;
    content: '';
    left: 0;
    width: 100%;
    height: 0.75rem;
    top: -0.75rem; }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom.bx--tooltip--align-start::before, .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom.bx--tooltip--align-start::after,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom.bx--tooltip--align-start .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom.bx--tooltip--align-start + .bx--assistive-text {
    bottom: 0;
    left: 50%; }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom.bx--tooltip--align-start::before {
    bottom: -0.25rem;
    border-width: 0 0.25rem 0.3125rem 0.25rem;
    border-color: transparent transparent var(--cds-inverse-02, #393939) transparent;
    transform: translate(-50%, 100%); }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom.bx--tooltip--align-start::after,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom.bx--tooltip--align-start .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom.bx--tooltip--align-start + .bx--assistive-text {
    bottom: -0.5625rem;
    left: 0;
    transform: translate(0, 100%); }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom.bx--tooltip--align-start.bx--tooltip--a11y + .bx--assistive-text {
    bottom: -0.5rem;
    transform: translate(0, 100%); }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom.bx--tooltip--align-center .bx--assistive-text::after {
    position: absolute;
    display: block;
    content: '';
    left: 0;
    width: 100%;
    height: 0.75rem;
    top: -0.75rem; }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom.bx--tooltip--align-center::before, .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom.bx--tooltip--align-center::after,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom.bx--tooltip--align-center .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom.bx--tooltip--align-center + .bx--assistive-text {
    bottom: 0;
    left: 50%; }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom.bx--tooltip--align-center::before {
    bottom: -0.25rem;
    border-width: 0 0.25rem 0.3125rem 0.25rem;
    border-color: transparent transparent var(--cds-inverse-02, #393939) transparent;
    transform: translate(-50%, 100%); }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom.bx--tooltip--align-center::after,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom.bx--tooltip--align-center .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom.bx--tooltip--align-center + .bx--assistive-text {
    bottom: -0.5625rem;
    transform: translate(-50%, 100%); }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom.bx--tooltip--align-center.bx--tooltip--a11y + .bx--assistive-text {
    bottom: -0.5rem;
    transform: translate(-50%, 100%); }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom.bx--tooltip--align-end .bx--assistive-text::after {
    position: absolute;
    display: block;
    content: '';
    left: 0;
    width: 100%;
    height: 0.75rem;
    top: -0.75rem; }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom.bx--tooltip--align-end::before, .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom.bx--tooltip--align-end::after,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom.bx--tooltip--align-end .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom.bx--tooltip--align-end + .bx--assistive-text {
    bottom: 0;
    left: 50%; }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom.bx--tooltip--align-end::before {
    bottom: -0.25rem;
    border-width: 0 0.25rem 0.3125rem 0.25rem;
    border-color: transparent transparent var(--cds-inverse-02, #393939) transparent;
    transform: translate(-50%, 100%); }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom.bx--tooltip--align-end::after,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom.bx--tooltip--align-end .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom.bx--tooltip--align-end + .bx--assistive-text {
    bottom: -0.5625rem;
    right: 0;
    left: auto;
    transform: translate(0, 100%); }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom.bx--tooltip--align-end.bx--tooltip--a11y + .bx--assistive-text {
    bottom: -0.5rem;
    transform: translate(0, 100%); }

/* begin tooltip icon (TODO: deprecate) */
.bx--tooltip--icon {
  display: inline-flex;
  align-items: center; }

.bx--tooltip--icon__top,
.bx--tooltip--icon__bottom {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  position: relative;
  display: inline-flex;
  overflow: visible;
  align-items: center;
  cursor: pointer; }
  .bx--tooltip--icon__top *,
  .bx--tooltip--icon__top *::before,
  .bx--tooltip--icon__top *::after,
  .bx--tooltip--icon__bottom *,
  .bx--tooltip--icon__bottom *::before,
  .bx--tooltip--icon__bottom *::after {
    box-sizing: inherit; }
  .bx--tooltip--icon__top::before, .bx--tooltip--icon__top::after,
  .bx--tooltip--icon__bottom::before,
  .bx--tooltip--icon__bottom::after {
    font-size: var(--cds-body-short-01-font-size, 0.875rem);
    font-weight: var(--cds-body-short-01-font-weight, 400);
    line-height: var(--cds-body-short-01-line-height, 1.28572);
    letter-spacing: var(--cds-body-short-01-letter-spacing, 0.16px);
    position: absolute;
    display: flex;
    align-items: center;
    opacity: 0;
    pointer-events: none;
    transition: opacity 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--tooltip--icon__top::before,
  .bx--tooltip--icon__bottom::before {
    right: 0;
    left: 0;
    width: 0;
    height: 0;
    border-width: 0 0.25rem 0.3125rem 0.25rem;
    border-style: solid;
    border-color: transparent transparent var(--cds-inverse-02, #393939) transparent;
    margin: 0 auto;
    margin-top: 1px;
    margin-left: 50%;
    content: ''; }
  .bx--tooltip--icon__top::after,
  .bx--tooltip--icon__bottom::after {
    box-shadow: 0 2px 6px var(--cds-shadow, rgba(0, 0, 0, 0.3));
    min-width: 1.5rem;
    max-width: 13rem;
    height: 1.5rem;
    padding: 0 1rem;
    margin-left: 50%;
    background-color: var(--cds-inverse-02, #393939);
    border-radius: 0.125rem;
    color: var(--cds-inverse-01, #ffffff);
    content: attr(aria-label);
    font-weight: 400;
    pointer-events: none;
    transform: translateX(-50%);
    white-space: nowrap; }
  .bx--tooltip--icon__top:hover::before, .bx--tooltip--icon__top:hover::after, .bx--tooltip--icon__top:focus::before, .bx--tooltip--icon__top:focus::after,
  .bx--tooltip--icon__bottom:hover::before,
  .bx--tooltip--icon__bottom:hover::after,
  .bx--tooltip--icon__bottom:focus::before,
  .bx--tooltip--icon__bottom:focus::after {
    opacity: 1; }
  .bx--tooltip--icon__top:hover svg, .bx--tooltip--icon__top:focus svg,
  .bx--tooltip--icon__bottom:hover svg,
  .bx--tooltip--icon__bottom:focus svg {
    fill: var(--cds-icon-02, #525252); }
  .bx--tooltip--icon__top:focus,
  .bx--tooltip--icon__bottom:focus {
    outline: 1px solid transparent; }
    .bx--tooltip--icon__top:focus svg,
    .bx--tooltip--icon__bottom:focus svg {
      outline: 1px solid var(--cds-focus, #0f62fe); }
      @media screen and (prefers-contrast) {
        .bx--tooltip--icon__top:focus svg,
        .bx--tooltip--icon__bottom:focus svg {
          outline-style: dotted; } }

.bx--tooltip--icon__top::before {
  transform: translate(-50%, calc(-100% - 9px)) rotate(180deg);
  top: 1px; }

.bx--tooltip--icon__top::after {
  transform: translate(-50%, calc(-100% - 12px));
  top: 0; }

.bx--tooltip--icon__bottom::before {
  transform: translate(-50%, 10px) rotate(0);
  bottom: 0; }

.bx--tooltip--icon__bottom::after {
  transform: translate(-50%, calc(100% + 10px));
  bottom: 0; }

.bx--tooltip--icon__top.bx--tooltip--icon__align-start::before {
  transform: translate(0, calc(-100% - 9px)) rotate(180deg);
  top: 1px;
  margin-left: 4px; }

.bx--tooltip--icon__top.bx--tooltip--icon__align-start::after {
  transform: translate(0, calc(-100% - 12px));
  top: 0;
  margin-left: 0; }

.bx--tooltip--icon__top.bx--tooltip--icon__align-end::before {
  transform: translate(0, calc(-100% - 9px)) rotate(180deg);
  top: 1px;
  right: 0;
  left: auto;
  margin-right: 4px; }

.bx--tooltip--icon__top.bx--tooltip--icon__align-end::after {
  transform: translate(0, calc(-100% - 12px));
  top: 0;
  margin-left: 0;
  right: 0; }

.bx--tooltip--icon__bottom.bx--tooltip--icon__align-start::before {
  transform: translate(0, 10px) rotate(0);
  bottom: 0;
  margin-left: 4px; }

.bx--tooltip--icon__bottom.bx--tooltip--icon__align-start::after {
  transform: translate(0, calc(100% + 10px));
  bottom: 0;
  margin-left: 0; }

.bx--tooltip--icon__bottom.bx--tooltip--icon__align-end::before {
  transform: translate(0, 10px) rotate(0);
  bottom: 0;
  right: 0;
  left: auto;
  margin-right: 4px; }

.bx--tooltip--icon__bottom.bx--tooltip--icon__align-end::after {
  transform: translate(0, calc(100% + 10px));
  bottom: 0;
  margin-left: 0;
  right: 0; }

.bx--tooltip--icon .bx--tooltip__trigger svg {
  margin-left: 0; }

/* end legacy tooltip icon */
.bx--tooltip__trigger:hover svg, .bx--tooltip__trigger:focus svg {
  fill: var(--cds-icon-02, #525252); }

.bx--tooltip__trigger.bx--tooltip--top {
  position: relative;
  display: inline-flex;
  overflow: visible;
  align-items: center;
  cursor: pointer; }
  .bx--tooltip__trigger.bx--tooltip--top:focus {
    outline: 1px solid var(--cds-focus, #0f62fe); }
    @media screen and (prefers-contrast) {
      .bx--tooltip__trigger.bx--tooltip--top:focus {
        outline-style: dotted; } }
  .bx--tooltip__trigger.bx--tooltip--top:focus {
    outline: 1px solid transparent; }
    .bx--tooltip__trigger.bx--tooltip--top:focus svg {
      outline: 1px solid var(--cds-focus, #0f62fe); }
      @media screen and (prefers-contrast) {
        .bx--tooltip__trigger.bx--tooltip--top:focus svg {
          outline-style: dotted; } }
  .bx--tooltip__trigger.bx--tooltip--top::before, .bx--tooltip__trigger.bx--tooltip--top::after,
  .bx--tooltip__trigger.bx--tooltip--top .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--top + .bx--assistive-text {
    position: absolute;
    z-index: 6000;
    display: flex;
    align-items: center;
    opacity: 0;
    pointer-events: none; }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .bx--tooltip__trigger.bx--tooltip--top::before, .bx--tooltip__trigger.bx--tooltip--top::after,
      .bx--tooltip__trigger.bx--tooltip--top .bx--assistive-text,
      .bx--tooltip__trigger.bx--tooltip--top + .bx--assistive-text {
        display: inline-block; } }
  .bx--tooltip__trigger.bx--tooltip--top::before, .bx--tooltip__trigger.bx--tooltip--top::after {
    transition: opacity 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--tooltip__trigger.bx--tooltip--top.bx--tooltip--a11y::before, .bx--tooltip__trigger.bx--tooltip--top.bx--tooltip--a11y::after {
    transition: none; }
  .bx--tooltip__trigger.bx--tooltip--top::before {
    width: 0;
    height: 0;
    border-style: solid;
    content: ''; }
  .bx--tooltip__trigger.bx--tooltip--top .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--top + .bx--assistive-text {
    box-sizing: content-box;
    color: inherit;
    opacity: 1;
    white-space: normal;
    word-break: break-word; }
  .bx--tooltip__trigger.bx--tooltip--top::after,
  .bx--tooltip__trigger.bx--tooltip--top .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--top + .bx--assistive-text {
    box-shadow: 0 2px 6px var(--cds-shadow, rgba(0, 0, 0, 0.3));
    z-index: 6000;
    width: max-content;
    min-width: 1.5rem;
    max-width: 13rem;
    height: auto;
    padding: 0.1875rem 1rem;
    background-color: var(--cds-inverse-02, #393939);
    border-radius: 0.125rem;
    color: var(--cds-inverse-01, #ffffff);
    font-weight: 400;
    text-align: left;
    transform: translateX(-50%);
    font-size: var(--cds-body-short-01-font-size, 0.875rem);
    font-weight: var(--cds-body-short-01-font-weight, 400);
    line-height: var(--cds-body-short-01-line-height, 1.28572);
    letter-spacing: var(--cds-body-short-01-letter-spacing, 0.16px); }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .bx--tooltip__trigger.bx--tooltip--top::after,
      .bx--tooltip__trigger.bx--tooltip--top .bx--assistive-text,
      .bx--tooltip__trigger.bx--tooltip--top + .bx--assistive-text {
        width: auto; } }
    @supports (-ms-accelerator: true) {
      .bx--tooltip__trigger.bx--tooltip--top::after,
      .bx--tooltip__trigger.bx--tooltip--top .bx--assistive-text,
      .bx--tooltip__trigger.bx--tooltip--top + .bx--assistive-text {
        width: auto; } }
    @supports (-ms-ime-align: auto) {
      .bx--tooltip__trigger.bx--tooltip--top::after,
      .bx--tooltip__trigger.bx--tooltip--top .bx--assistive-text,
      .bx--tooltip__trigger.bx--tooltip--top + .bx--assistive-text {
        width: auto; } }
    @media screen and (-ms-high-contrast: active), screen and (prefers-contrast) {
      .bx--tooltip__trigger.bx--tooltip--top::after,
      .bx--tooltip__trigger.bx--tooltip--top .bx--assistive-text,
      .bx--tooltip__trigger.bx--tooltip--top + .bx--assistive-text {
        border: 1px solid transparent; } }
  .bx--tooltip__trigger.bx--tooltip--top::after {
    content: attr(aria-label); }
  .bx--tooltip__trigger.bx--tooltip--top.bx--tooltip--a11y::after {
    content: none; }
  .bx--tooltip__trigger.bx--tooltip--top.bx--tooltip--visible::before, .bx--tooltip__trigger.bx--tooltip--top.bx--tooltip--visible::after, .bx--tooltip__trigger.bx--tooltip--top:hover::before, .bx--tooltip__trigger.bx--tooltip--top:hover::after, .bx--tooltip__trigger.bx--tooltip--top:focus::before, .bx--tooltip__trigger.bx--tooltip--top:focus::after {
    opacity: 1; }

@keyframes tooltip-fade {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }
  .bx--tooltip__trigger.bx--tooltip--top.bx--tooltip--visible .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--top.bx--tooltip--visible + .bx--assistive-text, .bx--tooltip__trigger.bx--tooltip--top:hover .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--top:hover + .bx--assistive-text, .bx--tooltip__trigger.bx--tooltip--top:focus .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--top:focus + .bx--assistive-text {
    overflow: visible;
    margin: auto;
    clip: auto; }
  .bx--tooltip__trigger.bx--tooltip--top.bx--tooltip--visible .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--top.bx--tooltip--visible + .bx--assistive-text, .bx--tooltip__trigger.bx--tooltip--top.bx--tooltip--visible.bx--tooltip--a11y::before, .bx--tooltip__trigger.bx--tooltip--top:hover .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--top:hover + .bx--assistive-text, .bx--tooltip__trigger.bx--tooltip--top:hover.bx--tooltip--a11y::before, .bx--tooltip__trigger.bx--tooltip--top:focus .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--top:focus + .bx--assistive-text, .bx--tooltip__trigger.bx--tooltip--top:focus.bx--tooltip--a11y::before {
    animation: tooltip-fade 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--tooltip__trigger.bx--tooltip--top.bx--tooltip--hidden .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--top.bx--tooltip--hidden + .bx--assistive-text {
    overflow: hidden;
    margin: -1px;
    clip: rect(0, 0, 0, 0); }
  .bx--tooltip__trigger.bx--tooltip--top.bx--tooltip--hidden.bx--tooltip--a11y::before {
    animation: none;
    opacity: 0; }
  .bx--tooltip__trigger.bx--tooltip--top .bx--assistive-text::after {
    position: absolute;
    display: block;
    content: '';
    left: 0;
    width: 100%;
    height: 0.75rem;
    bottom: -0.75rem; }
  .bx--tooltip__trigger.bx--tooltip--top::before, .bx--tooltip__trigger.bx--tooltip--top::after,
  .bx--tooltip__trigger.bx--tooltip--top .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--top + .bx--assistive-text {
    top: 0;
    left: 50%; }
  .bx--tooltip__trigger.bx--tooltip--top::before {
    top: -0.5rem;
    border-width: 0.3125rem 0.25rem 0 0.25rem;
    border-color: var(--cds-inverse-02, #393939) transparent transparent transparent;
    transform: translate(-50%, -100%); }
  .bx--tooltip__trigger.bx--tooltip--top::after,
  .bx--tooltip__trigger.bx--tooltip--top .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--top + .bx--assistive-text {
    top: -0.8125rem;
    left: 50%;
    transform: translate(-50%, -100%); }
  .bx--tooltip__trigger.bx--tooltip--top.bx--tooltip--align-start .bx--assistive-text::after {
    position: absolute;
    display: block;
    content: '';
    left: 0;
    width: 100%;
    height: 0.75rem;
    bottom: -0.75rem; }
  .bx--tooltip__trigger.bx--tooltip--top.bx--tooltip--align-start::before, .bx--tooltip__trigger.bx--tooltip--top.bx--tooltip--align-start::after,
  .bx--tooltip__trigger.bx--tooltip--top.bx--tooltip--align-start .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--top.bx--tooltip--align-start + .bx--assistive-text {
    top: 0;
    left: 50%; }
  .bx--tooltip__trigger.bx--tooltip--top.bx--tooltip--align-start::before {
    top: -0.5rem;
    border-width: 0.3125rem 0.25rem 0 0.25rem;
    border-color: var(--cds-inverse-02, #393939) transparent transparent transparent;
    transform: translate(-50%, -100%); }
  .bx--tooltip__trigger.bx--tooltip--top.bx--tooltip--align-start::after,
  .bx--tooltip__trigger.bx--tooltip--top.bx--tooltip--align-start .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--top.bx--tooltip--align-start + .bx--assistive-text {
    top: -0.8125rem;
    left: 0;
    transform: translate(0, -100%); }
  .bx--tooltip__trigger.bx--tooltip--top.bx--tooltip--align-center .bx--assistive-text::after {
    position: absolute;
    display: block;
    content: '';
    left: 0;
    width: 100%;
    height: 0.75rem;
    bottom: -0.75rem; }
  .bx--tooltip__trigger.bx--tooltip--top.bx--tooltip--align-center::before, .bx--tooltip__trigger.bx--tooltip--top.bx--tooltip--align-center::after,
  .bx--tooltip__trigger.bx--tooltip--top.bx--tooltip--align-center .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--top.bx--tooltip--align-center + .bx--assistive-text {
    top: 0;
    left: 50%; }
  .bx--tooltip__trigger.bx--tooltip--top.bx--tooltip--align-center::before {
    top: -0.5rem;
    border-width: 0.3125rem 0.25rem 0 0.25rem;
    border-color: var(--cds-inverse-02, #393939) transparent transparent transparent;
    transform: translate(-50%, -100%); }
  .bx--tooltip__trigger.bx--tooltip--top.bx--tooltip--align-center::after,
  .bx--tooltip__trigger.bx--tooltip--top.bx--tooltip--align-center .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--top.bx--tooltip--align-center + .bx--assistive-text {
    top: -0.8125rem;
    left: 50%;
    transform: translate(-50%, -100%); }
  .bx--tooltip__trigger.bx--tooltip--top.bx--tooltip--align-end .bx--assistive-text::after {
    position: absolute;
    display: block;
    content: '';
    left: 0;
    width: 100%;
    height: 0.75rem;
    bottom: -0.75rem; }
  .bx--tooltip__trigger.bx--tooltip--top.bx--tooltip--align-end::before, .bx--tooltip__trigger.bx--tooltip--top.bx--tooltip--align-end::after,
  .bx--tooltip__trigger.bx--tooltip--top.bx--tooltip--align-end .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--top.bx--tooltip--align-end + .bx--assistive-text {
    top: 0;
    left: 50%; }
  .bx--tooltip__trigger.bx--tooltip--top.bx--tooltip--align-end::before {
    top: -0.5rem;
    border-width: 0.3125rem 0.25rem 0 0.25rem;
    border-color: var(--cds-inverse-02, #393939) transparent transparent transparent;
    transform: translate(-50%, -100%); }
  .bx--tooltip__trigger.bx--tooltip--top.bx--tooltip--align-end::after,
  .bx--tooltip__trigger.bx--tooltip--top.bx--tooltip--align-end .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--top.bx--tooltip--align-end + .bx--assistive-text {
    top: -0.8125rem;
    right: 0;
    left: auto;
    transform: translate(0, -100%); }

.bx--tooltip__trigger.bx--tooltip--right {
  position: relative;
  display: inline-flex;
  overflow: visible;
  align-items: center;
  cursor: pointer; }
  .bx--tooltip__trigger.bx--tooltip--right:focus {
    outline: 1px solid var(--cds-focus, #0f62fe); }
    @media screen and (prefers-contrast) {
      .bx--tooltip__trigger.bx--tooltip--right:focus {
        outline-style: dotted; } }
  .bx--tooltip__trigger.bx--tooltip--right:focus {
    outline: 1px solid transparent; }
    .bx--tooltip__trigger.bx--tooltip--right:focus svg {
      outline: 1px solid var(--cds-focus, #0f62fe); }
      @media screen and (prefers-contrast) {
        .bx--tooltip__trigger.bx--tooltip--right:focus svg {
          outline-style: dotted; } }
  .bx--tooltip__trigger.bx--tooltip--right::before, .bx--tooltip__trigger.bx--tooltip--right::after,
  .bx--tooltip__trigger.bx--tooltip--right .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--right + .bx--assistive-text {
    position: absolute;
    z-index: 6000;
    display: flex;
    align-items: center;
    opacity: 0;
    pointer-events: none; }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .bx--tooltip__trigger.bx--tooltip--right::before, .bx--tooltip__trigger.bx--tooltip--right::after,
      .bx--tooltip__trigger.bx--tooltip--right .bx--assistive-text,
      .bx--tooltip__trigger.bx--tooltip--right + .bx--assistive-text {
        display: inline-block; } }
  .bx--tooltip__trigger.bx--tooltip--right::before, .bx--tooltip__trigger.bx--tooltip--right::after {
    transition: opacity 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--tooltip__trigger.bx--tooltip--right.bx--tooltip--a11y::before, .bx--tooltip__trigger.bx--tooltip--right.bx--tooltip--a11y::after {
    transition: none; }
  .bx--tooltip__trigger.bx--tooltip--right::before {
    width: 0;
    height: 0;
    border-style: solid;
    content: ''; }
  .bx--tooltip__trigger.bx--tooltip--right .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--right + .bx--assistive-text {
    box-sizing: content-box;
    color: inherit;
    opacity: 1;
    white-space: normal;
    word-break: break-word; }
  .bx--tooltip__trigger.bx--tooltip--right::after,
  .bx--tooltip__trigger.bx--tooltip--right .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--right + .bx--assistive-text {
    box-shadow: 0 2px 6px var(--cds-shadow, rgba(0, 0, 0, 0.3));
    z-index: 6000;
    width: max-content;
    min-width: 1.5rem;
    max-width: 13rem;
    height: auto;
    padding: 0.1875rem 1rem;
    background-color: var(--cds-inverse-02, #393939);
    border-radius: 0.125rem;
    color: var(--cds-inverse-01, #ffffff);
    font-weight: 400;
    text-align: left;
    transform: translateX(-50%);
    font-size: var(--cds-body-short-01-font-size, 0.875rem);
    font-weight: var(--cds-body-short-01-font-weight, 400);
    line-height: var(--cds-body-short-01-line-height, 1.28572);
    letter-spacing: var(--cds-body-short-01-letter-spacing, 0.16px); }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .bx--tooltip__trigger.bx--tooltip--right::after,
      .bx--tooltip__trigger.bx--tooltip--right .bx--assistive-text,
      .bx--tooltip__trigger.bx--tooltip--right + .bx--assistive-text {
        width: auto; } }
    @supports (-ms-accelerator: true) {
      .bx--tooltip__trigger.bx--tooltip--right::after,
      .bx--tooltip__trigger.bx--tooltip--right .bx--assistive-text,
      .bx--tooltip__trigger.bx--tooltip--right + .bx--assistive-text {
        width: auto; } }
    @supports (-ms-ime-align: auto) {
      .bx--tooltip__trigger.bx--tooltip--right::after,
      .bx--tooltip__trigger.bx--tooltip--right .bx--assistive-text,
      .bx--tooltip__trigger.bx--tooltip--right + .bx--assistive-text {
        width: auto; } }
    @media screen and (-ms-high-contrast: active), screen and (prefers-contrast) {
      .bx--tooltip__trigger.bx--tooltip--right::after,
      .bx--tooltip__trigger.bx--tooltip--right .bx--assistive-text,
      .bx--tooltip__trigger.bx--tooltip--right + .bx--assistive-text {
        border: 1px solid transparent; } }
  .bx--tooltip__trigger.bx--tooltip--right::after {
    content: attr(aria-label); }
  .bx--tooltip__trigger.bx--tooltip--right.bx--tooltip--a11y::after {
    content: none; }
  .bx--tooltip__trigger.bx--tooltip--right.bx--tooltip--visible::before, .bx--tooltip__trigger.bx--tooltip--right.bx--tooltip--visible::after, .bx--tooltip__trigger.bx--tooltip--right:hover::before, .bx--tooltip__trigger.bx--tooltip--right:hover::after, .bx--tooltip__trigger.bx--tooltip--right:focus::before, .bx--tooltip__trigger.bx--tooltip--right:focus::after {
    opacity: 1; }

@keyframes tooltip-fade {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }
  .bx--tooltip__trigger.bx--tooltip--right.bx--tooltip--visible .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--right.bx--tooltip--visible + .bx--assistive-text, .bx--tooltip__trigger.bx--tooltip--right:hover .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--right:hover + .bx--assistive-text, .bx--tooltip__trigger.bx--tooltip--right:focus .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--right:focus + .bx--assistive-text {
    overflow: visible;
    margin: auto;
    clip: auto; }
  .bx--tooltip__trigger.bx--tooltip--right.bx--tooltip--visible .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--right.bx--tooltip--visible + .bx--assistive-text, .bx--tooltip__trigger.bx--tooltip--right.bx--tooltip--visible.bx--tooltip--a11y::before, .bx--tooltip__trigger.bx--tooltip--right:hover .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--right:hover + .bx--assistive-text, .bx--tooltip__trigger.bx--tooltip--right:hover.bx--tooltip--a11y::before, .bx--tooltip__trigger.bx--tooltip--right:focus .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--right:focus + .bx--assistive-text, .bx--tooltip__trigger.bx--tooltip--right:focus.bx--tooltip--a11y::before {
    animation: tooltip-fade 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--tooltip__trigger.bx--tooltip--right.bx--tooltip--hidden .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--right.bx--tooltip--hidden + .bx--assistive-text {
    overflow: hidden;
    margin: -1px;
    clip: rect(0, 0, 0, 0); }
  .bx--tooltip__trigger.bx--tooltip--right.bx--tooltip--hidden.bx--tooltip--a11y::before {
    animation: none;
    opacity: 0; }
  .bx--tooltip__trigger.bx--tooltip--right .bx--assistive-text::after {
    position: absolute;
    display: block;
    content: '';
    top: 0;
    width: 0.75rem;
    height: 100%;
    left: -0.75rem; }
  .bx--tooltip__trigger.bx--tooltip--right::before, .bx--tooltip__trigger.bx--tooltip--right::after,
  .bx--tooltip__trigger.bx--tooltip--right .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--right + .bx--assistive-text {
    top: 50%;
    right: 0; }
  .bx--tooltip__trigger.bx--tooltip--right::before {
    right: -0.5rem;
    border-width: 0.25rem 0.3125rem 0.25rem 0;
    border-color: transparent var(--cds-inverse-02, #393939) transparent transparent;
    transform: translate(100%, -50%); }
  .bx--tooltip__trigger.bx--tooltip--right::after,
  .bx--tooltip__trigger.bx--tooltip--right .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--right + .bx--assistive-text {
    right: -0.8125rem;
    transform: translate(100%, -50%); }
  .bx--tooltip__trigger.bx--tooltip--right.bx--tooltip--align-start .bx--assistive-text::after {
    position: absolute;
    display: block;
    content: '';
    top: 0;
    width: 0.75rem;
    height: 100%;
    left: -0.75rem; }
  .bx--tooltip__trigger.bx--tooltip--right.bx--tooltip--align-start::before, .bx--tooltip__trigger.bx--tooltip--right.bx--tooltip--align-start::after,
  .bx--tooltip__trigger.bx--tooltip--right.bx--tooltip--align-start .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--right.bx--tooltip--align-start + .bx--assistive-text {
    top: 50%;
    right: 0; }
  .bx--tooltip__trigger.bx--tooltip--right.bx--tooltip--align-start::before {
    right: -0.5rem;
    border-width: 0.25rem 0.3125rem 0.25rem 0;
    border-color: transparent var(--cds-inverse-02, #393939) transparent transparent;
    transform: translate(100%, -50%); }
  .bx--tooltip__trigger.bx--tooltip--right.bx--tooltip--align-start::after,
  .bx--tooltip__trigger.bx--tooltip--right.bx--tooltip--align-start .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--right.bx--tooltip--align-start + .bx--assistive-text {
    right: -0.8125rem;
    transform: translate(100%, -50%); }
  .bx--tooltip__trigger.bx--tooltip--right.bx--tooltip--align-center .bx--assistive-text::after {
    position: absolute;
    display: block;
    content: '';
    top: 0;
    width: 0.75rem;
    height: 100%;
    left: -0.75rem; }
  .bx--tooltip__trigger.bx--tooltip--right.bx--tooltip--align-center::before, .bx--tooltip__trigger.bx--tooltip--right.bx--tooltip--align-center::after,
  .bx--tooltip__trigger.bx--tooltip--right.bx--tooltip--align-center .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--right.bx--tooltip--align-center + .bx--assistive-text {
    top: 50%;
    right: 0; }
  .bx--tooltip__trigger.bx--tooltip--right.bx--tooltip--align-center::before {
    right: -0.5rem;
    border-width: 0.25rem 0.3125rem 0.25rem 0;
    border-color: transparent var(--cds-inverse-02, #393939) transparent transparent;
    transform: translate(100%, -50%); }
  .bx--tooltip__trigger.bx--tooltip--right.bx--tooltip--align-center::after,
  .bx--tooltip__trigger.bx--tooltip--right.bx--tooltip--align-center .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--right.bx--tooltip--align-center + .bx--assistive-text {
    right: -0.8125rem;
    transform: translate(100%, -50%); }
  .bx--tooltip__trigger.bx--tooltip--right.bx--tooltip--align-end .bx--assistive-text::after {
    position: absolute;
    display: block;
    content: '';
    top: 0;
    width: 0.75rem;
    height: 100%;
    left: -0.75rem; }
  .bx--tooltip__trigger.bx--tooltip--right.bx--tooltip--align-end::before, .bx--tooltip__trigger.bx--tooltip--right.bx--tooltip--align-end::after,
  .bx--tooltip__trigger.bx--tooltip--right.bx--tooltip--align-end .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--right.bx--tooltip--align-end + .bx--assistive-text {
    top: 50%;
    right: 0; }
  .bx--tooltip__trigger.bx--tooltip--right.bx--tooltip--align-end::before {
    right: -0.5rem;
    border-width: 0.25rem 0.3125rem 0.25rem 0;
    border-color: transparent var(--cds-inverse-02, #393939) transparent transparent;
    transform: translate(100%, -50%); }
  .bx--tooltip__trigger.bx--tooltip--right.bx--tooltip--align-end::after,
  .bx--tooltip__trigger.bx--tooltip--right.bx--tooltip--align-end .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--right.bx--tooltip--align-end + .bx--assistive-text {
    right: -0.8125rem;
    transform: translate(100%, -50%); }

.bx--tooltip__trigger.bx--tooltip--bottom {
  position: relative;
  display: inline-flex;
  overflow: visible;
  align-items: center;
  cursor: pointer; }
  .bx--tooltip__trigger.bx--tooltip--bottom:focus {
    outline: 1px solid var(--cds-focus, #0f62fe); }
    @media screen and (prefers-contrast) {
      .bx--tooltip__trigger.bx--tooltip--bottom:focus {
        outline-style: dotted; } }
  .bx--tooltip__trigger.bx--tooltip--bottom:focus {
    outline: 1px solid transparent; }
    .bx--tooltip__trigger.bx--tooltip--bottom:focus svg {
      outline: 1px solid var(--cds-focus, #0f62fe); }
      @media screen and (prefers-contrast) {
        .bx--tooltip__trigger.bx--tooltip--bottom:focus svg {
          outline-style: dotted; } }
  .bx--tooltip__trigger.bx--tooltip--bottom::before, .bx--tooltip__trigger.bx--tooltip--bottom::after,
  .bx--tooltip__trigger.bx--tooltip--bottom .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--bottom + .bx--assistive-text {
    position: absolute;
    z-index: 6000;
    display: flex;
    align-items: center;
    opacity: 0;
    pointer-events: none; }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .bx--tooltip__trigger.bx--tooltip--bottom::before, .bx--tooltip__trigger.bx--tooltip--bottom::after,
      .bx--tooltip__trigger.bx--tooltip--bottom .bx--assistive-text,
      .bx--tooltip__trigger.bx--tooltip--bottom + .bx--assistive-text {
        display: inline-block; } }
  .bx--tooltip__trigger.bx--tooltip--bottom::before, .bx--tooltip__trigger.bx--tooltip--bottom::after {
    transition: opacity 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--tooltip__trigger.bx--tooltip--bottom.bx--tooltip--a11y::before, .bx--tooltip__trigger.bx--tooltip--bottom.bx--tooltip--a11y::after {
    transition: none; }
  .bx--tooltip__trigger.bx--tooltip--bottom::before {
    width: 0;
    height: 0;
    border-style: solid;
    content: ''; }
  .bx--tooltip__trigger.bx--tooltip--bottom .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--bottom + .bx--assistive-text {
    box-sizing: content-box;
    color: inherit;
    opacity: 1;
    white-space: normal;
    word-break: break-word; }
  .bx--tooltip__trigger.bx--tooltip--bottom::after,
  .bx--tooltip__trigger.bx--tooltip--bottom .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--bottom + .bx--assistive-text {
    box-shadow: 0 2px 6px var(--cds-shadow, rgba(0, 0, 0, 0.3));
    z-index: 6000;
    width: max-content;
    min-width: 1.5rem;
    max-width: 13rem;
    height: auto;
    padding: 0.1875rem 1rem;
    background-color: var(--cds-inverse-02, #393939);
    border-radius: 0.125rem;
    color: var(--cds-inverse-01, #ffffff);
    font-weight: 400;
    text-align: left;
    transform: translateX(-50%);
    font-size: var(--cds-body-short-01-font-size, 0.875rem);
    font-weight: var(--cds-body-short-01-font-weight, 400);
    line-height: var(--cds-body-short-01-line-height, 1.28572);
    letter-spacing: var(--cds-body-short-01-letter-spacing, 0.16px); }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .bx--tooltip__trigger.bx--tooltip--bottom::after,
      .bx--tooltip__trigger.bx--tooltip--bottom .bx--assistive-text,
      .bx--tooltip__trigger.bx--tooltip--bottom + .bx--assistive-text {
        width: auto; } }
    @supports (-ms-accelerator: true) {
      .bx--tooltip__trigger.bx--tooltip--bottom::after,
      .bx--tooltip__trigger.bx--tooltip--bottom .bx--assistive-text,
      .bx--tooltip__trigger.bx--tooltip--bottom + .bx--assistive-text {
        width: auto; } }
    @supports (-ms-ime-align: auto) {
      .bx--tooltip__trigger.bx--tooltip--bottom::after,
      .bx--tooltip__trigger.bx--tooltip--bottom .bx--assistive-text,
      .bx--tooltip__trigger.bx--tooltip--bottom + .bx--assistive-text {
        width: auto; } }
    @media screen and (-ms-high-contrast: active), screen and (prefers-contrast) {
      .bx--tooltip__trigger.bx--tooltip--bottom::after,
      .bx--tooltip__trigger.bx--tooltip--bottom .bx--assistive-text,
      .bx--tooltip__trigger.bx--tooltip--bottom + .bx--assistive-text {
        border: 1px solid transparent; } }
  .bx--tooltip__trigger.bx--tooltip--bottom::after {
    content: attr(aria-label); }
  .bx--tooltip__trigger.bx--tooltip--bottom.bx--tooltip--a11y::after {
    content: none; }
  .bx--tooltip__trigger.bx--tooltip--bottom.bx--tooltip--visible::before, .bx--tooltip__trigger.bx--tooltip--bottom.bx--tooltip--visible::after, .bx--tooltip__trigger.bx--tooltip--bottom:hover::before, .bx--tooltip__trigger.bx--tooltip--bottom:hover::after, .bx--tooltip__trigger.bx--tooltip--bottom:focus::before, .bx--tooltip__trigger.bx--tooltip--bottom:focus::after {
    opacity: 1; }

@keyframes tooltip-fade {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }
  .bx--tooltip__trigger.bx--tooltip--bottom.bx--tooltip--visible .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--bottom.bx--tooltip--visible + .bx--assistive-text, .bx--tooltip__trigger.bx--tooltip--bottom:hover .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--bottom:hover + .bx--assistive-text, .bx--tooltip__trigger.bx--tooltip--bottom:focus .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--bottom:focus + .bx--assistive-text {
    overflow: visible;
    margin: auto;
    clip: auto; }
  .bx--tooltip__trigger.bx--tooltip--bottom.bx--tooltip--visible .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--bottom.bx--tooltip--visible + .bx--assistive-text, .bx--tooltip__trigger.bx--tooltip--bottom.bx--tooltip--visible.bx--tooltip--a11y::before, .bx--tooltip__trigger.bx--tooltip--bottom:hover .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--bottom:hover + .bx--assistive-text, .bx--tooltip__trigger.bx--tooltip--bottom:hover.bx--tooltip--a11y::before, .bx--tooltip__trigger.bx--tooltip--bottom:focus .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--bottom:focus + .bx--assistive-text, .bx--tooltip__trigger.bx--tooltip--bottom:focus.bx--tooltip--a11y::before {
    animation: tooltip-fade 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--tooltip__trigger.bx--tooltip--bottom.bx--tooltip--hidden .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--bottom.bx--tooltip--hidden + .bx--assistive-text {
    overflow: hidden;
    margin: -1px;
    clip: rect(0, 0, 0, 0); }
  .bx--tooltip__trigger.bx--tooltip--bottom.bx--tooltip--hidden.bx--tooltip--a11y::before {
    animation: none;
    opacity: 0; }
  .bx--tooltip__trigger.bx--tooltip--bottom .bx--assistive-text::after {
    position: absolute;
    display: block;
    content: '';
    left: 0;
    width: 100%;
    height: 0.75rem;
    top: -0.75rem; }
  .bx--tooltip__trigger.bx--tooltip--bottom::before, .bx--tooltip__trigger.bx--tooltip--bottom::after,
  .bx--tooltip__trigger.bx--tooltip--bottom .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--bottom + .bx--assistive-text {
    bottom: 0;
    left: 50%; }
  .bx--tooltip__trigger.bx--tooltip--bottom::before {
    bottom: -0.5rem;
    border-width: 0 0.25rem 0.3125rem 0.25rem;
    border-color: transparent transparent var(--cds-inverse-02, #393939) transparent;
    transform: translate(-50%, 100%); }
  .bx--tooltip__trigger.bx--tooltip--bottom::after,
  .bx--tooltip__trigger.bx--tooltip--bottom .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--bottom + .bx--assistive-text {
    bottom: -0.8125rem;
    transform: translate(-50%, 100%); }
  .bx--tooltip__trigger.bx--tooltip--bottom.bx--tooltip--align-start .bx--assistive-text::after {
    position: absolute;
    display: block;
    content: '';
    left: 0;
    width: 100%;
    height: 0.75rem;
    top: -0.75rem; }
  .bx--tooltip__trigger.bx--tooltip--bottom.bx--tooltip--align-start::before, .bx--tooltip__trigger.bx--tooltip--bottom.bx--tooltip--align-start::after,
  .bx--tooltip__trigger.bx--tooltip--bottom.bx--tooltip--align-start .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--bottom.bx--tooltip--align-start + .bx--assistive-text {
    bottom: 0;
    left: 50%; }
  .bx--tooltip__trigger.bx--tooltip--bottom.bx--tooltip--align-start::before {
    bottom: -0.5rem;
    border-width: 0 0.25rem 0.3125rem 0.25rem;
    border-color: transparent transparent var(--cds-inverse-02, #393939) transparent;
    transform: translate(-50%, 100%); }
  .bx--tooltip__trigger.bx--tooltip--bottom.bx--tooltip--align-start::after,
  .bx--tooltip__trigger.bx--tooltip--bottom.bx--tooltip--align-start .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--bottom.bx--tooltip--align-start + .bx--assistive-text {
    bottom: -0.8125rem;
    left: 0;
    transform: translate(0, 100%); }
  .bx--tooltip__trigger.bx--tooltip--bottom.bx--tooltip--align-center .bx--assistive-text::after {
    position: absolute;
    display: block;
    content: '';
    left: 0;
    width: 100%;
    height: 0.75rem;
    top: -0.75rem; }
  .bx--tooltip__trigger.bx--tooltip--bottom.bx--tooltip--align-center::before, .bx--tooltip__trigger.bx--tooltip--bottom.bx--tooltip--align-center::after,
  .bx--tooltip__trigger.bx--tooltip--bottom.bx--tooltip--align-center .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--bottom.bx--tooltip--align-center + .bx--assistive-text {
    bottom: 0;
    left: 50%; }
  .bx--tooltip__trigger.bx--tooltip--bottom.bx--tooltip--align-center::before {
    bottom: -0.5rem;
    border-width: 0 0.25rem 0.3125rem 0.25rem;
    border-color: transparent transparent var(--cds-inverse-02, #393939) transparent;
    transform: translate(-50%, 100%); }
  .bx--tooltip__trigger.bx--tooltip--bottom.bx--tooltip--align-center::after,
  .bx--tooltip__trigger.bx--tooltip--bottom.bx--tooltip--align-center .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--bottom.bx--tooltip--align-center + .bx--assistive-text {
    bottom: -0.8125rem;
    transform: translate(-50%, 100%); }
  .bx--tooltip__trigger.bx--tooltip--bottom.bx--tooltip--align-end .bx--assistive-text::after {
    position: absolute;
    display: block;
    content: '';
    left: 0;
    width: 100%;
    height: 0.75rem;
    top: -0.75rem; }
  .bx--tooltip__trigger.bx--tooltip--bottom.bx--tooltip--align-end::before, .bx--tooltip__trigger.bx--tooltip--bottom.bx--tooltip--align-end::after,
  .bx--tooltip__trigger.bx--tooltip--bottom.bx--tooltip--align-end .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--bottom.bx--tooltip--align-end + .bx--assistive-text {
    bottom: 0;
    left: 50%; }
  .bx--tooltip__trigger.bx--tooltip--bottom.bx--tooltip--align-end::before {
    bottom: -0.5rem;
    border-width: 0 0.25rem 0.3125rem 0.25rem;
    border-color: transparent transparent var(--cds-inverse-02, #393939) transparent;
    transform: translate(-50%, 100%); }
  .bx--tooltip__trigger.bx--tooltip--bottom.bx--tooltip--align-end::after,
  .bx--tooltip__trigger.bx--tooltip--bottom.bx--tooltip--align-end .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--bottom.bx--tooltip--align-end + .bx--assistive-text {
    bottom: -0.8125rem;
    right: 0;
    left: auto;
    transform: translate(0, 100%); }

.bx--tooltip__trigger.bx--tooltip--left {
  position: relative;
  display: inline-flex;
  overflow: visible;
  align-items: center;
  cursor: pointer; }
  .bx--tooltip__trigger.bx--tooltip--left:focus {
    outline: 1px solid var(--cds-focus, #0f62fe); }
    @media screen and (prefers-contrast) {
      .bx--tooltip__trigger.bx--tooltip--left:focus {
        outline-style: dotted; } }
  .bx--tooltip__trigger.bx--tooltip--left:focus {
    outline: 1px solid transparent; }
    .bx--tooltip__trigger.bx--tooltip--left:focus svg {
      outline: 1px solid var(--cds-focus, #0f62fe); }
      @media screen and (prefers-contrast) {
        .bx--tooltip__trigger.bx--tooltip--left:focus svg {
          outline-style: dotted; } }
  .bx--tooltip__trigger.bx--tooltip--left::before, .bx--tooltip__trigger.bx--tooltip--left::after,
  .bx--tooltip__trigger.bx--tooltip--left .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--left + .bx--assistive-text {
    position: absolute;
    z-index: 6000;
    display: flex;
    align-items: center;
    opacity: 0;
    pointer-events: none; }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .bx--tooltip__trigger.bx--tooltip--left::before, .bx--tooltip__trigger.bx--tooltip--left::after,
      .bx--tooltip__trigger.bx--tooltip--left .bx--assistive-text,
      .bx--tooltip__trigger.bx--tooltip--left + .bx--assistive-text {
        display: inline-block; } }
  .bx--tooltip__trigger.bx--tooltip--left::before, .bx--tooltip__trigger.bx--tooltip--left::after {
    transition: opacity 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--tooltip__trigger.bx--tooltip--left.bx--tooltip--a11y::before, .bx--tooltip__trigger.bx--tooltip--left.bx--tooltip--a11y::after {
    transition: none; }
  .bx--tooltip__trigger.bx--tooltip--left::before {
    width: 0;
    height: 0;
    border-style: solid;
    content: ''; }
  .bx--tooltip__trigger.bx--tooltip--left .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--left + .bx--assistive-text {
    box-sizing: content-box;
    color: inherit;
    opacity: 1;
    white-space: normal;
    word-break: break-word; }
  .bx--tooltip__trigger.bx--tooltip--left::after,
  .bx--tooltip__trigger.bx--tooltip--left .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--left + .bx--assistive-text {
    box-shadow: 0 2px 6px var(--cds-shadow, rgba(0, 0, 0, 0.3));
    z-index: 6000;
    width: max-content;
    min-width: 1.5rem;
    max-width: 13rem;
    height: auto;
    padding: 0.1875rem 1rem;
    background-color: var(--cds-inverse-02, #393939);
    border-radius: 0.125rem;
    color: var(--cds-inverse-01, #ffffff);
    font-weight: 400;
    text-align: left;
    transform: translateX(-50%);
    font-size: var(--cds-body-short-01-font-size, 0.875rem);
    font-weight: var(--cds-body-short-01-font-weight, 400);
    line-height: var(--cds-body-short-01-line-height, 1.28572);
    letter-spacing: var(--cds-body-short-01-letter-spacing, 0.16px); }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .bx--tooltip__trigger.bx--tooltip--left::after,
      .bx--tooltip__trigger.bx--tooltip--left .bx--assistive-text,
      .bx--tooltip__trigger.bx--tooltip--left + .bx--assistive-text {
        width: auto; } }
    @supports (-ms-accelerator: true) {
      .bx--tooltip__trigger.bx--tooltip--left::after,
      .bx--tooltip__trigger.bx--tooltip--left .bx--assistive-text,
      .bx--tooltip__trigger.bx--tooltip--left + .bx--assistive-text {
        width: auto; } }
    @supports (-ms-ime-align: auto) {
      .bx--tooltip__trigger.bx--tooltip--left::after,
      .bx--tooltip__trigger.bx--tooltip--left .bx--assistive-text,
      .bx--tooltip__trigger.bx--tooltip--left + .bx--assistive-text {
        width: auto; } }
    @media screen and (-ms-high-contrast: active), screen and (prefers-contrast) {
      .bx--tooltip__trigger.bx--tooltip--left::after,
      .bx--tooltip__trigger.bx--tooltip--left .bx--assistive-text,
      .bx--tooltip__trigger.bx--tooltip--left + .bx--assistive-text {
        border: 1px solid transparent; } }
  .bx--tooltip__trigger.bx--tooltip--left::after {
    content: attr(aria-label); }
  .bx--tooltip__trigger.bx--tooltip--left.bx--tooltip--a11y::after {
    content: none; }
  .bx--tooltip__trigger.bx--tooltip--left.bx--tooltip--visible::before, .bx--tooltip__trigger.bx--tooltip--left.bx--tooltip--visible::after, .bx--tooltip__trigger.bx--tooltip--left:hover::before, .bx--tooltip__trigger.bx--tooltip--left:hover::after, .bx--tooltip__trigger.bx--tooltip--left:focus::before, .bx--tooltip__trigger.bx--tooltip--left:focus::after {
    opacity: 1; }

@keyframes tooltip-fade {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }
  .bx--tooltip__trigger.bx--tooltip--left.bx--tooltip--visible .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--left.bx--tooltip--visible + .bx--assistive-text, .bx--tooltip__trigger.bx--tooltip--left:hover .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--left:hover + .bx--assistive-text, .bx--tooltip__trigger.bx--tooltip--left:focus .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--left:focus + .bx--assistive-text {
    overflow: visible;
    margin: auto;
    clip: auto; }
  .bx--tooltip__trigger.bx--tooltip--left.bx--tooltip--visible .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--left.bx--tooltip--visible + .bx--assistive-text, .bx--tooltip__trigger.bx--tooltip--left.bx--tooltip--visible.bx--tooltip--a11y::before, .bx--tooltip__trigger.bx--tooltip--left:hover .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--left:hover + .bx--assistive-text, .bx--tooltip__trigger.bx--tooltip--left:hover.bx--tooltip--a11y::before, .bx--tooltip__trigger.bx--tooltip--left:focus .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--left:focus + .bx--assistive-text, .bx--tooltip__trigger.bx--tooltip--left:focus.bx--tooltip--a11y::before {
    animation: tooltip-fade 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--tooltip__trigger.bx--tooltip--left.bx--tooltip--hidden .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--left.bx--tooltip--hidden + .bx--assistive-text {
    overflow: hidden;
    margin: -1px;
    clip: rect(0, 0, 0, 0); }
  .bx--tooltip__trigger.bx--tooltip--left.bx--tooltip--hidden.bx--tooltip--a11y::before {
    animation: none;
    opacity: 0; }
  .bx--tooltip__trigger.bx--tooltip--left .bx--assistive-text::after {
    position: absolute;
    display: block;
    content: '';
    top: 0;
    width: 0.75rem;
    height: 100%;
    right: -0.75rem; }
  .bx--tooltip__trigger.bx--tooltip--left::before, .bx--tooltip__trigger.bx--tooltip--left::after,
  .bx--tooltip__trigger.bx--tooltip--left .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--left + .bx--assistive-text {
    top: 50%;
    left: 0; }
  .bx--tooltip__trigger.bx--tooltip--left::before {
    left: -0.5rem;
    border-width: 0.25rem 0 0.25rem 0.3125rem;
    border-color: transparent transparent transparent var(--cds-inverse-02, #393939);
    transform: translate(-100%, -50%); }
  .bx--tooltip__trigger.bx--tooltip--left::after,
  .bx--tooltip__trigger.bx--tooltip--left .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--left + .bx--assistive-text {
    left: -0.8125rem;
    transform: translate(-100%, -50%); }
  .bx--tooltip__trigger.bx--tooltip--left.bx--tooltip--align-start .bx--assistive-text::after {
    position: absolute;
    display: block;
    content: '';
    top: 0;
    width: 0.75rem;
    height: 100%;
    right: -0.75rem; }
  .bx--tooltip__trigger.bx--tooltip--left.bx--tooltip--align-start::before, .bx--tooltip__trigger.bx--tooltip--left.bx--tooltip--align-start::after,
  .bx--tooltip__trigger.bx--tooltip--left.bx--tooltip--align-start .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--left.bx--tooltip--align-start + .bx--assistive-text {
    top: 50%;
    left: 0; }
  .bx--tooltip__trigger.bx--tooltip--left.bx--tooltip--align-start::before {
    left: -0.5rem;
    border-width: 0.25rem 0 0.25rem 0.3125rem;
    border-color: transparent transparent transparent var(--cds-inverse-02, #393939);
    transform: translate(-100%, -50%); }
  .bx--tooltip__trigger.bx--tooltip--left.bx--tooltip--align-start::after,
  .bx--tooltip__trigger.bx--tooltip--left.bx--tooltip--align-start .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--left.bx--tooltip--align-start + .bx--assistive-text {
    left: -0.8125rem;
    transform: translate(-100%, -50%); }
  .bx--tooltip__trigger.bx--tooltip--left.bx--tooltip--align-center .bx--assistive-text::after {
    position: absolute;
    display: block;
    content: '';
    top: 0;
    width: 0.75rem;
    height: 100%;
    right: -0.75rem; }
  .bx--tooltip__trigger.bx--tooltip--left.bx--tooltip--align-center::before, .bx--tooltip__trigger.bx--tooltip--left.bx--tooltip--align-center::after,
  .bx--tooltip__trigger.bx--tooltip--left.bx--tooltip--align-center .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--left.bx--tooltip--align-center + .bx--assistive-text {
    top: 50%;
    left: 0; }
  .bx--tooltip__trigger.bx--tooltip--left.bx--tooltip--align-center::before {
    left: -0.5rem;
    border-width: 0.25rem 0 0.25rem 0.3125rem;
    border-color: transparent transparent transparent var(--cds-inverse-02, #393939);
    transform: translate(-100%, -50%); }
  .bx--tooltip__trigger.bx--tooltip--left.bx--tooltip--align-center::after,
  .bx--tooltip__trigger.bx--tooltip--left.bx--tooltip--align-center .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--left.bx--tooltip--align-center + .bx--assistive-text {
    left: -0.8125rem;
    transform: translate(-100%, -50%); }
  .bx--tooltip__trigger.bx--tooltip--left.bx--tooltip--align-end .bx--assistive-text::after {
    position: absolute;
    display: block;
    content: '';
    top: 0;
    width: 0.75rem;
    height: 100%;
    right: -0.75rem; }
  .bx--tooltip__trigger.bx--tooltip--left.bx--tooltip--align-end::before, .bx--tooltip__trigger.bx--tooltip--left.bx--tooltip--align-end::after,
  .bx--tooltip__trigger.bx--tooltip--left.bx--tooltip--align-end .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--left.bx--tooltip--align-end + .bx--assistive-text {
    top: 50%;
    left: 0; }
  .bx--tooltip__trigger.bx--tooltip--left.bx--tooltip--align-end::before {
    left: -0.5rem;
    border-width: 0.25rem 0 0.25rem 0.3125rem;
    border-color: transparent transparent transparent var(--cds-inverse-02, #393939);
    transform: translate(-100%, -50%); }
  .bx--tooltip__trigger.bx--tooltip--left.bx--tooltip--align-end::after,
  .bx--tooltip__trigger.bx--tooltip--left.bx--tooltip--align-end .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--left.bx--tooltip--align-end + .bx--assistive-text {
    left: -0.8125rem;
    transform: translate(-100%, -50%); }

.bx--tooltip__trigger:not(.bx--tooltip--hidden) .bx--assistive-text {
  pointer-events: all; }

/* stylelint-disable */
@media screen and (-ms-high-contrast: active), (forced-colors: active), (prefers-contrast) {
  .bx--tooltip__trigger svg,
  .bx--tooltip__trigger:hover svg,
  .bx--tooltip__trigger:focus svg {
    fill: ButtonText; } }

@media screen and (-ms-high-contrast: active), (forced-colors: active), (prefers-contrast) {
  .bx--tooltip__trigger:focus svg {
    color: Highlight;
    outline: 1px solid Highlight; } }

@media screen and (-ms-high-contrast: active), (forced-colors: active), (prefers-contrast) {
  .bx--tooltip {
    outline: 1px solid transparent; } }

@media screen and (-ms-high-contrast: active), (forced-colors: active), (prefers-contrast) {
  .bx--assistive-text {
    outline: 1px solid transparent; } }

.bx--progress {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  display: flex;
  list-style: none; }
  .bx--progress *,
  .bx--progress *::before,
  .bx--progress *::after {
    box-sizing: inherit; }

.bx--progress-step {
  position: relative;
  display: inline-flex;
  overflow: visible;
  width: 8rem;
  min-width: 7rem;
  flex-direction: row; }
  .bx--progress-step .bx--tooltip__label {
    display: block; }

.bx--progress--space-equal .bx--progress-step {
  min-width: 8rem;
  flex-grow: 1; }

.bx--progress-line {
  position: absolute;
  left: 0;
  width: 8rem;
  height: 1px;
  border: 1px inset transparent; }

.bx--progress--space-equal .bx--progress-line {
  width: 100%;
  min-width: 8rem; }

.bx--progress-step svg {
  position: relative;
  z-index: 1;
  width: 1rem;
  height: 1rem;
  flex-shrink: 0;
  margin: 0.625rem 0.5rem 0 0;
  border-radius: 50%;
  fill: var(--cds-interactive-04, #0f62fe); }

.bx--progress-label {
  font-size: var(--cds-body-short-01-font-size, 0.875rem);
  font-weight: var(--cds-body-short-01-font-weight, 400);
  line-height: var(--cds-body-short-01-line-height, 1.28572);
  letter-spacing: var(--cds-body-short-01-letter-spacing, 0.16px);
  overflow: hidden;
  max-width: 5.5rem;
  margin: 0.5rem 0 0 0;
  color: var(--cds-text-01, #161616);
  line-height: 1.45;
  text-overflow: ellipsis;
  transition: box-shadow 110ms cubic-bezier(0.2, 0, 0.38, 0.9), color 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  white-space: nowrap; }
  .bx--progress-label::before {
    display: block;
    content: ''; }

.bx--progress-label:hover {
  box-shadow: 0 0.0625rem var(--cds-link-01, #0f62fe);
  color: var(--cds-link-01, #0f62fe);
  cursor: pointer; }

.bx--progress-label:focus {
  box-shadow: 0 0.1875rem 0 0 var(--cds-link-01, #0f62fe);
  color: var(--cds-link-01, #0f62fe);
  outline: none; }

.bx--progress--space-equal .bx--progress-label {
  max-width: 100%;
  margin-right: 0.75rem; }

.bx--progress-step-button:not(.bx--progress-step-button--unclickable) .bx--progress-label:active {
  box-shadow: 0 0.1875rem 0 0 var(--cds-interactive, #0f62fe);
  color: var(--cds-interactive, #0f62fe); }

.bx--progress-label-overflow:hover ~ .bx--tooltip,
.bx--progress-label-overflow:focus ~ .bx--tooltip {
  visibility: inherit; }

.bx--progress-step .bx--tooltip .bx--tooltip__caret {
  margin-left: 0.625rem; }

.bx--tooltip__text {
  padding: 0;
  margin: 0;
  font-weight: normal; }

.bx--progress-step .bx--tooltip {
  font-size: var(--cds-body-long-01-font-size, 0.875rem);
  font-weight: var(--cds-body-long-01-font-weight, 400);
  line-height: var(--cds-body-long-01-line-height, 1.42857);
  letter-spacing: var(--cds-body-long-01-letter-spacing, 0.16px);
  display: block;
  width: 7.8125rem;
  min-width: 7.1875rem;
  min-height: 1.5rem;
  padding: 0.5rem 1rem;
  margin-top: 2.5rem;
  margin-left: 1.375rem;
  color: var(--cds-inverse-01, #ffffff);
  visibility: hidden; }

.bx--progress-step .bx--tooltip_multi {
  font-size: var(--cds-body-long-01-font-size, 0.875rem);
  font-weight: var(--cds-body-long-01-font-weight, 400);
  line-height: var(--cds-body-long-01-line-height, 1.42857);
  letter-spacing: var(--cds-body-long-01-letter-spacing, 0.16px);
  width: 9.375rem;
  height: auto;
  color: var(--cds-inverse-01, #ffffff); }

.bx--progress-optional {
  font-size: var(--cds-label-01-font-size, 0.75rem);
  font-weight: var(--cds-label-01-font-weight, 400);
  line-height: var(--cds-label-01-line-height, 1.33333);
  letter-spacing: var(--cds-label-01-letter-spacing, 0.32px);
  position: absolute;
  left: 0;
  margin-top: 1.75rem;
  margin-left: 1.5rem;
  color: var(--cds-text-02, #525252);
  text-align: start; }

.bx--progress-step--current .bx--progress-line {
  background-color: var(--cds-interactive-04, #0f62fe); }

.bx--progress-step--incomplete svg {
  fill: var(--cds-ui-05, #161616); }

.bx--progress-step--incomplete .bx--progress-line {
  background-color: var(--cds-ui-03, #e0e0e0); }

.bx--progress-step--complete .bx--progress-line {
  background-color: var(--cds-interactive-04, #0f62fe); }

.bx--progress-step-button {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  display: inline-block;
  padding: 0;
  border: 0;
  appearance: none;
  background: none;
  cursor: pointer;
  width: 100%;
  display: flex;
  text-align: left; }
  .bx--progress-step-button *,
  .bx--progress-step-button *::before,
  .bx--progress-step-button *::after {
    box-sizing: inherit; }
  .bx--progress-step-button::-moz-focus-inner {
    border: 0; }

.bx--progress-step-button--unclickable {
  cursor: default;
  outline: none; }

.bx--progress-step-button--unclickable .bx--progress-label:hover {
  box-shadow: none;
  color: var(--cds-text-01, #161616);
  cursor: default; }

.bx--progress-step-button--unclickable .bx--tooltip__label:hover {
  box-shadow: 0 0.0625rem var(--cds-link-01, #0f62fe);
  color: var(--cds-link-01, #0f62fe);
  cursor: pointer; }

.bx--progress-step--disabled {
  cursor: not-allowed;
  pointer-events: none; }
  .bx--progress-step--disabled svg {
    cursor: not-allowed;
    fill: #c6c6c6; }
  .bx--progress-step--disabled .bx--progress-label,
  .bx--progress-step--disabled .bx--progress-label:hover {
    box-shadow: none;
    color: #c6c6c6;
    cursor: not-allowed; }
  .bx--progress-step--disabled .bx--progress-label:focus,
  .bx--progress-step--disabled .bx--progress-label:active {
    box-shadow: none;
    outline: none; }
  .bx--progress-step--disabled .bx--progress-line {
    cursor: not-allowed; }
  .bx--progress-step--disabled .bx--progress-label-overflow:hover ~ .bx--tooltip--definition .bx--tooltip--definition__bottom {
    display: none; }

.bx--progress__warning > * {
  fill: var(--cds-support-01, #da1e28); }

.bx--progress.bx--skeleton .bx--progress-label {
  position: relative;
  padding: 0;
  border: none;
  background: var(--cds-skeleton-01, #e5e5e5);
  box-shadow: none;
  pointer-events: none;
  width: 2.5rem;
  height: 0.875rem;
  margin-top: 0.625rem; }
  .bx--progress.bx--skeleton .bx--progress-label:hover, .bx--progress.bx--skeleton .bx--progress-label:focus, .bx--progress.bx--skeleton .bx--progress-label:active {
    border: none;
    cursor: default;
    outline: none; }
  .bx--progress.bx--skeleton .bx--progress-label::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    animation: 3000ms ease-in-out skeleton infinite;
    background: var(--cds-skeleton-02, #c6c6c6);
    content: '';
    will-change: transform-origin, transform, opacity; }
    @media (prefers-reduced-motion: reduce) {
      .bx--progress.bx--skeleton .bx--progress-label::before {
        animation: none; } }

.bx--progress--vertical {
  display: flex;
  flex-direction: column; }

.bx--progress--vertical,
.bx--progress-text {
  display: flex;
  flex-direction: column; }

.bx--progress--vertical .bx--progress-step,
.bx--progress--vertical .bx--progress-step-button {
  width: initial;
  min-width: initial;
  min-height: 3.625rem;
  align-content: flex-start; }

.bx--progress--vertical .bx--progress-step svg,
.bx--progress--vertical .bx--progress-step-button svg {
  display: inline-block;
  margin: 0.0625rem 0.5rem 0; }

.bx--progress--vertical .bx--progress-label {
  display: inline-block;
  width: initial;
  max-width: 10rem;
  margin: 0;
  vertical-align: top;
  white-space: initial; }

.bx--progress--vertical .bx--progress-step .bx--tooltip {
  margin-top: 0.5rem; }

.bx--progress--vertical .bx--progress-optional {
  position: static;
  width: 100%;
  margin: auto 0; }

.bx--progress--vertical .bx--progress-line {
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 100%; }

.security--button--ghost-danger {
  color: var(--cds-text-01, #161616); }
  .security--button--ghost-danger:focus, .security--button--ghost-danger:hover {
    background-color: var(--cds-hover-danger, #b81921);
    color: var(--cds-text-04, #ffffff); }
  .security--button--ghost-danger:hover > .bx--btn__icon > path {
    fill: currentColor; }

.security--button--loading > .bx--inline-loading {
  width: auto;
  margin-top: -0.5rem;
  margin-bottom: -0.5rem; }

.bx--fieldset {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  margin-bottom: 2rem; }
  .bx--fieldset *,
  .bx--fieldset *::before,
  .bx--fieldset *::after {
    box-sizing: inherit; }

.bx--fieldset--no-margin {
  margin-bottom: 0; }

.bx--form-item {
  font-size: var(--cds-body-short-01-font-size, 0.875rem);
  font-weight: var(--cds-body-short-01-font-weight, 400);
  line-height: var(--cds-body-short-01-line-height, 1.28572);
  letter-spacing: var(--cds-body-short-01-letter-spacing, 0.16px);
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-items: flex-start; }

.bx--label {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  font-size: var(--cds-label-01-font-size, 0.75rem);
  font-weight: var(--cds-label-01-font-weight, 400);
  line-height: var(--cds-label-01-line-height, 1.33333);
  letter-spacing: var(--cds-label-01-letter-spacing, 0.32px);
  display: inline-block;
  margin-bottom: 0.5rem;
  color: var(--cds-text-02, #525252);
  font-weight: 400;
  line-height: 1rem;
  vertical-align: baseline; }
  .bx--label *,
  .bx--label *::before,
  .bx--label *::after {
    box-sizing: inherit; }

.bx--label .bx--tooltip__trigger {
  font-size: var(--cds-label-01-font-size, 0.75rem);
  font-weight: var(--cds-label-01-font-weight, 400);
  line-height: var(--cds-label-01-line-height, 1.33333);
  letter-spacing: var(--cds-label-01-letter-spacing, 0.32px); }

.bx--label.bx--skeleton {
  position: relative;
  padding: 0;
  border: none;
  background: var(--cds-skeleton-01, #e5e5e5);
  box-shadow: none;
  pointer-events: none;
  width: 4.6875rem;
  height: 0.875rem; }
  .bx--label.bx--skeleton:hover, .bx--label.bx--skeleton:focus, .bx--label.bx--skeleton:active {
    border: none;
    cursor: default;
    outline: none; }
  .bx--label.bx--skeleton::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    animation: 3000ms ease-in-out skeleton infinite;
    background: var(--cds-skeleton-02, #c6c6c6);
    content: '';
    will-change: transform-origin, transform, opacity; }
    @media (prefers-reduced-motion: reduce) {
      .bx--label.bx--skeleton::before {
        animation: none; } }

input[type='number'] {
  font-family: 'IBM Plex Mono', 'Menlo', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', Courier, monospace; }

input[data-invalid]:not(:focus),
.bx--number[data-invalid] input[type='number']:not(:focus),
.bx--text-input__field-wrapper[data-invalid] > .bx--text-input--invalid:not(:focus),
.bx--text-area__wrapper[data-invalid] > .bx--text-area--invalid:not(:focus),
.bx--select-input__wrapper[data-invalid] .bx--select-input:not(:focus),
.bx--list-box[data-invalid]:not(:focus),
.bx--combo-box[data-invalid] .bx--text-input:not(:focus) {
  outline: 2px solid var(--cds-support-01, #da1e28);
  outline-offset: -2px; }
  @media screen and (prefers-contrast) {
    input[data-invalid]:not(:focus),
    .bx--number[data-invalid] input[type='number']:not(:focus),
    .bx--text-input__field-wrapper[data-invalid] > .bx--text-input--invalid:not(:focus),
    .bx--text-area__wrapper[data-invalid] > .bx--text-area--invalid:not(:focus),
    .bx--select-input__wrapper[data-invalid] .bx--select-input:not(:focus),
    .bx--list-box[data-invalid]:not(:focus),
    .bx--combo-box[data-invalid] .bx--text-input:not(:focus) {
      outline-style: dotted; } }

input[data-invalid] ~ .bx--form-requirement,
.bx--number[data-invalid] .bx--number__input-wrapper ~ .bx--form-requirement,
.bx--number__input-wrapper--warning ~ .bx--form-requirement,
.bx--date-picker-input__wrapper ~ .bx--form-requirement,
.bx--date-picker-input__wrapper--warn ~ .bx--form-requirement,
.bx--date-picker-input__wrapper--invalid ~ .bx--form-requirement,
.bx--time-picker--invalid ~ .bx--form-requirement,
.bx--text-input__field-wrapper[data-invalid] ~ .bx--form-requirement,
.bx--text-input__field-wrapper--warning ~ .bx--form-requirement,
.bx--text-input__field-wrapper--warning > .bx--text-input ~ .bx--form-requirement,
.bx--text-area__wrapper[data-invalid] ~ .bx--form-requirement,
.bx--select-input__wrapper[data-invalid] ~ .bx--form-requirement,
.bx--select--warning .bx--select-input__wrapper ~ .bx--form-requirement,
.bx--time-picker[data-invalid] ~ .bx--form-requirement,
.bx--list-box[data-invalid] ~ .bx--form-requirement,
.bx--list-box--warning ~ .bx--form-requirement {
  display: block;
  overflow: visible;
  max-height: 12.5rem;
  font-weight: 400; }

input[data-invalid] ~ .bx--form-requirement,
.bx--number[data-invalid] .bx--number__input-wrapper ~ .bx--form-requirement,
.bx--date-picker-input__wrapper ~ .bx--form-requirement,
.bx--date-picker-input__wrapper--invalid ~ .bx--form-requirement,
.bx--time-picker--invalid ~ .bx--form-requirement,
.bx--text-input__field-wrapper[data-invalid] ~ .bx--form-requirement,
.bx--text-area__wrapper[data-invalid] ~ .bx--form-requirement,
.bx--select-input__wrapper[data-invalid] ~ .bx--form-requirement,
.bx--time-picker[data-invalid] ~ .bx--form-requirement,
.bx--list-box[data-invalid] ~ .bx--form-requirement {
  color: var(--cds-text-error, #da1e28); }

.bx--form--fluid .bx--text-input__field-wrapper[data-invalid],
.bx--form--fluid .bx--text-input__field-wrapper--warning {
  display: block; }

.bx--form--fluid .bx--fieldset {
  margin: 0; }

.bx--form--fluid input[data-invalid] {
  outline: none; }

.bx--form--fluid .bx--form-requirement {
  padding: 0.5rem 2.5rem 0.5rem 1rem;
  margin: 0; }

input:not(output):not([data-invalid]):-moz-ui-invalid {
  box-shadow: none; }

.bx--form-requirement {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  font-size: var(--cds-caption-01-font-size, 0.75rem);
  font-weight: var(--cds-caption-01-font-weight, 400);
  line-height: var(--cds-caption-01-line-height, 1.33333);
  letter-spacing: var(--cds-caption-01-letter-spacing, 0.32px);
  display: none;
  overflow: hidden;
  max-height: 0;
  margin: 0.25rem 0 0; }
  .bx--form-requirement *,
  .bx--form-requirement *::before,
  .bx--form-requirement *::after {
    box-sizing: inherit; }

.bx--select--inline .bx--form__helper-text {
  margin-top: 0; }

.bx--form__helper-text {
  font-size: var(--cds-helper-text-01-font-size, 0.75rem);
  line-height: var(--cds-helper-text-01-line-height, 1.33333);
  letter-spacing: var(--cds-helper-text-01-letter-spacing, 0.32px);
  z-index: 0;
  width: 100%;
  margin-top: 0.25rem;
  color: var(--cds-text-02, #525252);
  opacity: 1; }

.bx--label--disabled,
.bx--form__helper-text--disabled {
  color: var(--cds-disabled-02, #c6c6c6); }

fieldset[disabled] .bx--label,
fieldset[disabled] .bx--form__helper-text {
  color: var(--cds-disabled-02, #c6c6c6); }

@keyframes rotate {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes rotate-end-p1 {
  100% {
    transform: rotate(360deg); } }

@keyframes rotate-end-p2 {
  100% {
    transform: rotate(-360deg); } }

/* Stroke animations */
@keyframes init-stroke {
  0% {
    stroke-dashoffset: 276.4608; }
  100% {
    stroke-dashoffset: 52.52755; } }

@keyframes stroke-end {
  0% {
    stroke-dashoffset: 52.52755; }
  100% {
    stroke-dashoffset: 276.4608; } }

.bx--loading {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  animation-duration: 690ms;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: rotate;
  animation-timing-function: linear;
  width: 5.5rem;
  height: 5.5rem; }
  .bx--loading *,
  .bx--loading *::before,
  .bx--loading *::after {
    box-sizing: inherit; }
  .bx--loading svg circle {
    animation-duration: 10ms;
    animation-name: init-stroke;
    animation-timing-function: cubic-bezier(0.2, 0, 0.38, 0.9); }

.bx--loading__svg {
  fill: transparent; }

.bx--loading__svg circle {
  stroke-dasharray: 276.4608 276.4608;
  stroke-linecap: butt;
  stroke-width: 10; }

.bx--loading__stroke {
  stroke: var(--cds-interactive-04, #0f62fe);
  stroke-dashoffset: 52.52755; }

.bx--loading--small .bx--loading__stroke {
  stroke-dashoffset: 143.75962; }

.bx--loading--stop {
  animation: rotate-end-p1 700ms cubic-bezier(0.2, 0, 1, 0.9) forwards, rotate-end-p2 700ms cubic-bezier(0.2, 0, 1, 0.9) 700ms forwards; }
  .bx--loading--stop svg circle {
    animation-delay: 700ms;
    animation-duration: 700ms;
    animation-fill-mode: forwards;
    animation-name: stroke-end;
    animation-timing-function: cubic-bezier(0.2, 0, 1, 0.9); }

.bx--loading--small {
  width: 1rem;
  height: 1rem; }
  .bx--loading--small circle {
    stroke-width: 16; }

.bx--loading--small .bx--loading__svg {
  stroke: var(--cds-interactive-04, #0f62fe); }

.bx--loading__background {
  stroke: var(--cds-ui-03, #e0e0e0);
  stroke-dashoffset: -22; }

@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) and (stroke-color: transparent) {
    circle.bx--loading__background {
      stroke-dasharray: 265;
      stroke-dashoffset: 0; } } }

.bx--loading-overlay {
  position: fixed;
  z-index: 6000;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background-color: var(--cds-overlay-01, rgba(22, 22, 22, 0.5));
  transition: background-color 720ms cubic-bezier(0.4, 0.14, 0.3, 1); }

.bx--loading-overlay--stop {
  display: none; }

.bx--file {
  width: 100%; }

.bx--file--invalid {
  margin-right: 0.5rem;
  fill: var(--cds-support-01, #da1e28); }

.bx--file--label {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  font-size: var(--cds-productive-heading-01-font-size, 0.875rem);
  font-weight: var(--cds-productive-heading-01-font-weight, 600);
  line-height: var(--cds-productive-heading-01-line-height, 1.28572);
  letter-spacing: var(--cds-productive-heading-01-letter-spacing, 0.16px);
  margin-bottom: 0.5rem;
  color: var(--cds-text-01, #161616); }
  .bx--file--label *,
  .bx--file--label *::before,
  .bx--file--label *::after {
    box-sizing: inherit; }

.bx--file--label--disabled {
  color: var(--cds-disabled-02, #c6c6c6); }

.bx--file-input {
  position: absolute;
  overflow: hidden;
  width: 1px;
  height: 1px;
  padding: 0;
  border: 0;
  margin: -1px;
  clip: rect(0, 0, 0, 0);
  visibility: inherit;
  white-space: nowrap; }

.bx--file-btn {
  display: inline-flex;
  padding-right: 4rem;
  margin: 0; }

.bx--file-browse-btn {
  display: inline-block;
  width: 100%;
  max-width: 20rem;
  margin-bottom: 0.5rem;
  color: var(--cds-link-01, #0f62fe);
  cursor: pointer;
  outline: 2px solid transparent;
  outline-offset: -2px;
  transition: 110ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--file-browse-btn:focus, .bx--file-browse-btn:hover {
    outline: 2px solid var(--cds-interactive-03, #0f62fe); }
  .bx--file-browse-btn:hover, .bx--file-browse-btn:focus, .bx--file-browse-btn:active, .bx--file-browse-btn:active:visited {
    text-decoration: underline; }
  .bx--file-browse-btn:active {
    color: var(--cds-text-01, #161616); }

.bx--file-browse-btn--disabled {
  color: var(--cds-disabled-02, #c6c6c6);
  cursor: no-drop;
  text-decoration: none; }
  .bx--file-browse-btn--disabled:hover, .bx--file-browse-btn--disabled:focus {
    color: var(--cds-disabled-02, #c6c6c6);
    outline: none;
    text-decoration: none; }

.bx--file-browse-btn--disabled .bx--file__drop-container {
  border: 1px dashed var(--cds-disabled-02, #c6c6c6); }

.bx--label-description {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  font-size: var(--cds-body-short-01-font-size, 0.875rem);
  font-weight: var(--cds-body-short-01-font-weight, 400);
  line-height: var(--cds-body-short-01-line-height, 1.28572);
  letter-spacing: var(--cds-body-short-01-letter-spacing, 0.16px);
  margin-bottom: 1rem;
  color: var(--cds-text-02, #525252); }
  .bx--label-description *,
  .bx--label-description *::before,
  .bx--label-description *::after {
    box-sizing: inherit; }

.bx--label-description--disabled {
  color: var(--cds-disabled-02, #c6c6c6); }

.bx--file-btn ~ .bx--file-container {
  margin-top: 1.5rem; }

.bx--btn ~ .bx--file-container {
  margin-top: 1rem; }

.bx--file .bx--file-container,
.bx--file ~ .bx--file-container {
  margin-top: 0.5rem; }

.bx--file__selected-file {
  display: grid;
  max-width: 20rem;
  min-height: 3rem;
  align-items: center;
  margin-bottom: 0.5rem;
  background-color: var(--cds-field-01, #f4f4f4);
  gap: 0.75rem 1rem;
  grid-auto-rows: auto;
  grid-template-columns: 1fr auto;
  word-break: break-word; }
  .bx--file__selected-file:last-child {
    margin-bottom: 0; }
  .bx--file__selected-file .bx--form-requirement {
    display: block;
    max-height: none;
    margin: 0;
    grid-column: 1 / -1; }
  .bx--file__selected-file .bx--inline-loading__animation .bx--loading {
    margin-right: 0; }
  .bx--file__selected-file .bx--file-filename {
    font-size: var(--cds-body-short-01-font-size, 0.875rem);
    font-weight: var(--cds-body-short-01-font-weight, 400);
    line-height: var(--cds-body-short-01-line-height, 1.28572);
    letter-spacing: var(--cds-body-short-01-letter-spacing, 0.16px);
    overflow: hidden;
    margin-left: 1rem;
    text-overflow: ellipsis;
    white-space: nowrap; }

.bx--file__selected-file--field,
.bx--file__selected-file--md {
  min-height: 2.5rem;
  gap: 0.5rem 1rem; }

.bx--file__selected-file--sm {
  min-height: 2rem;
  gap: 0.25rem 1rem; }

.bx--file__selected-file--invalid__wrapper {
  outline: 2px solid var(--cds-support-01, #da1e28);
  outline-offset: -2px;
  max-width: 20rem;
  margin-bottom: 0.5rem;
  background-color: var(--cds-field-01, #f4f4f4);
  outline-width: 1px; }
  @media screen and (prefers-contrast) {
    .bx--file__selected-file--invalid__wrapper {
      outline-style: dotted; } }

.bx--file__selected-file--invalid {
  outline: 2px solid var(--cds-support-01, #da1e28);
  outline-offset: -2px;
  padding: 0.75rem 0; }
  @media screen and (prefers-contrast) {
    .bx--file__selected-file--invalid {
      outline-style: dotted; } }

.bx--file__selected-file--invalid.bx--file__selected-file--sm {
  padding: 0.25rem 0; }

.bx--file__selected-file--invalid.bx--file__selected-file--field,
.bx--file__selected-file--invalid.bx--file__selected-file--md {
  padding: 0.5rem 0; }

.bx--file__selected-file--invalid .bx--form-requirement {
  padding-top: 1rem;
  border-top: 1px solid var(--cds-ui-03, #e0e0e0); }

.bx--file__selected-file--invalid.bx--file__selected-file--sm .bx--form-requirement {
  padding-top: 0.4375rem; }

.bx--file__selected-file--invalid.bx--file__selected-file--field .bx--form-requirement,
.bx--file__selected-file--invalid.bx--file__selected-file--md .bx--form-requirement {
  padding-top: 0.6875rem; }

.bx--file__selected-file--invalid .bx--form-requirement__title,
.bx--file__selected-file--invalid .bx--form-requirement__supplement {
  font-size: var(--cds-label-01-font-size, 0.75rem);
  font-weight: var(--cds-label-01-font-weight, 400);
  line-height: var(--cds-label-01-line-height, 1.33333);
  letter-spacing: var(--cds-label-01-letter-spacing, 0.32px);
  padding: 0 1rem; }

.bx--file__selected-file--invalid .bx--form-requirement__title {
  color: var(--cds-text-error, #da1e28); }

.bx--file__selected-file--invalid .bx--form-requirement__supplement {
  color: var(--cds-text-01, #161616); }

.bx--file__selected-file--invalid + .bx--form-requirement {
  font-size: var(--cds-caption-01-font-size, 0.75rem);
  font-weight: var(--cds-caption-01-font-weight, 400);
  line-height: var(--cds-caption-01-line-height, 1.33333);
  letter-spacing: var(--cds-caption-01-letter-spacing, 0.32px);
  display: block;
  overflow: visible;
  max-height: 12.5rem;
  padding: 0.5rem 1rem;
  color: var(--cds-text-error, #da1e28);
  font-weight: 400; }

.bx--file__selected-file--invalid + .bx--form-requirement .bx--form-requirement__supplement {
  padding-bottom: 0.5rem;
  color: var(--cds-text-01, #161616); }

.bx--file__state-container {
  display: flex;
  min-width: 1.5rem;
  align-items: center;
  justify-content: center;
  padding-right: 1rem; }
  .bx--file__state-container .bx--loading__svg {
    stroke: var(--cds-ui-05, #161616); }

.bx--file__state-container .bx--file-complete {
  cursor: pointer;
  fill: var(--cds-interactive-04, #0f62fe); }
  .bx--file__state-container .bx--file-complete:focus {
    outline: 1px solid var(--cds-focus, #0f62fe); }
    @media screen and (prefers-contrast) {
      .bx--file__state-container .bx--file-complete:focus {
        outline-style: dotted; } }
  .bx--file__state-container .bx--file-complete [data-icon-path='inner-path'] {
    fill: var(--cds-icon-03, #ffffff);
    opacity: 1; }

.bx--file__state-container .bx--file-invalid {
  width: 1rem;
  height: 1rem;
  fill: var(--cds-support-01, #da1e28); }

.bx--file__state-container .bx--file-close {
  display: flex;
  width: 1.5rem;
  height: 1.5rem;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
  fill: var(--cds-icon-01, #161616); }
  .bx--file__state-container .bx--file-close:focus {
    outline: 2px solid var(--cds-focus, #0f62fe);
    outline-offset: -2px; }
    @media screen and (prefers-contrast) {
      .bx--file__state-container .bx--file-close:focus {
        outline-style: dotted; } }

.bx--file__state-container .bx--file-close svg path {
  fill: var(--cds-icon-01, #161616); }

.bx--file__state-container .bx--inline-loading__animation {
  margin-right: -0.5rem; }

.bx--file__drop-container {
  display: flex;
  overflow: hidden;
  height: 6rem;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
  border: 1px dashed var(--cds-ui-04, #8d8d8d); }

.bx--file__drop-container--drag-over {
  background: none;
  outline: 2px solid var(--cds-interactive-03, #0f62fe);
  outline-offset: -2px; }

/* stylelint-disable */
@media screen and (-ms-high-contrast: active), (forced-colors: active), (prefers-contrast) {
  .bx--file__selected-file {
    outline: 1px solid transparent; } }

@media screen and (-ms-high-contrast: active), (forced-colors: active), (prefers-contrast) {
  .bx--file__state-container .bx--file-close svg path {
    fill: ButtonText; } }

/* stylelint-enable */
.bx--link {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  font-size: var(--cds-body-short-01-font-size, 0.875rem);
  font-weight: var(--cds-body-short-01-font-weight, 400);
  line-height: var(--cds-body-short-01-line-height, 1.28572);
  letter-spacing: var(--cds-body-short-01-letter-spacing, 0.16px);
  display: inline-flex;
  color: var(--cds-link-01, #0f62fe);
  outline: none;
  text-decoration: none;
  transition: color 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--link *,
  .bx--link *::before,
  .bx--link *::after {
    box-sizing: inherit; }
  .bx--link:hover {
    color: var(--cds-hover-primary-text, #0043ce);
    text-decoration: underline; }
  .bx--link:active, .bx--link:active:visited, .bx--link:active:visited:hover {
    color: var(--cds-text-01, #161616);
    text-decoration: underline; }
  .bx--link:focus {
    outline: 1px solid var(--cds-focus, #0f62fe); }
    @media screen and (prefers-contrast) {
      .bx--link:focus {
        outline-style: dotted; } }
  .bx--link:visited {
    color: var(--cds-link-01, #0f62fe); }
  .bx--link:visited:hover {
    color: var(--cds-hover-primary-text, #0043ce); }

.bx--link--disabled,
.bx--link--disabled:hover {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  font-size: var(--cds-body-short-01-font-size, 0.875rem);
  font-weight: var(--cds-body-short-01-font-weight, 400);
  line-height: var(--cds-body-short-01-line-height, 1.28572);
  letter-spacing: var(--cds-body-short-01-letter-spacing, 0.16px);
  color: var(--cds-disabled-02, #c6c6c6);
  cursor: not-allowed;
  font-weight: 400;
  text-decoration: none; }
  .bx--link--disabled *,
  .bx--link--disabled *::before,
  .bx--link--disabled *::after,
  .bx--link--disabled:hover *,
  .bx--link--disabled:hover *::before,
  .bx--link--disabled:hover *::after {
    box-sizing: inherit; }

.bx--link.bx--link--visited:visited {
  color: var(--cds-visited-link, #8a3ffc); }

.bx--link.bx--link--visited:visited:hover {
  color: var(--cds-hover-primary-text, #0043ce); }

.bx--link.bx--link--inline {
  text-decoration: underline; }
  .bx--link.bx--link--inline:focus, .bx--link.bx--link--inline:visited {
    text-decoration: none; }

.bx--link--disabled.bx--link--inline {
  text-decoration: underline; }

.bx--link--sm {
  font-size: var(--cds-helper-text-01-font-size, 0.75rem);
  line-height: var(--cds-helper-text-01-line-height, 1.33333);
  letter-spacing: var(--cds-helper-text-01-letter-spacing, 0.32px); }

.bx--link--lg {
  font-size: var(--cds-body-short-02-font-size, 1rem);
  font-weight: var(--cds-body-short-02-font-weight, 400);
  line-height: var(--cds-body-short-02-line-height, 1.375);
  letter-spacing: var(--cds-body-short-02-letter-spacing, 0); }

.bx--link__icon {
  display: inline-flex;
  align-self: center;
  margin-left: var(--cds-spacing-03, 0.5rem); }

.bx--breadcrumb {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  font-size: var(--cds-body-short-01-font-size, 0.875rem);
  font-weight: var(--cds-body-short-01-font-weight, 400);
  line-height: var(--cds-body-short-01-line-height, 1.28572);
  letter-spacing: var(--cds-body-short-01-letter-spacing, 0.16px);
  display: inline; }
  .bx--breadcrumb *,
  .bx--breadcrumb *::before,
  .bx--breadcrumb *::after {
    box-sizing: inherit; }
  @media (min-width: 42rem) {
    .bx--breadcrumb {
      display: flex;
      flex-wrap: wrap; } }

.bx--breadcrumb-item {
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 0.5rem; }

.bx--breadcrumb-item .bx--link:visited {
  color: var(--cds-link-01, #0f62fe); }
  .bx--breadcrumb-item .bx--link:visited:hover {
    color: var(--cds-hover-primary-text, #0043ce); }

.bx--breadcrumb-item::after {
  margin-left: 0.5rem;
  color: var(--cds-text-01, #161616);
  content: '/'; }

.bx--breadcrumb--no-trailing-slash .bx--breadcrumb-item:last-child::after {
  content: ''; }

.bx--breadcrumb-item:last-child,
.bx--breadcrumb-item:last-child::after {
  margin-right: 0; }

.bx--breadcrumb .bx--link {
  white-space: nowrap; }

.bx--breadcrumb-item [aria-current='page'],
.bx--breadcrumb-item.bx--breadcrumb-item--current .bx--link {
  color: var(--cds-text-01, #161616);
  cursor: auto; }
  .bx--breadcrumb-item [aria-current='page']:hover,
  .bx--breadcrumb-item.bx--breadcrumb-item--current .bx--link:hover {
    text-decoration: none; }

.bx--breadcrumb-item .bx--overflow-menu {
  position: relative;
  width: 1.25rem;
  height: 1.125rem; }
  .bx--breadcrumb-item .bx--overflow-menu:focus {
    outline: 1px solid var(--cds-focus, #0f62fe); }
  .bx--breadcrumb-item .bx--overflow-menu:hover {
    background: transparent; }
  .bx--breadcrumb-item .bx--overflow-menu::after {
    position: absolute;
    bottom: 2px;
    width: 0.75rem;
    height: 1px;
    background: var(--cds-hover-primary-text, #0043ce);
    content: '';
    opacity: 0;
    transition: opacity 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }

.bx--breadcrumb-item .bx--overflow-menu:hover::after {
  opacity: 1; }

.bx--breadcrumb-item .bx--overflow-menu.bx--overflow-menu--open {
  background: transparent;
  box-shadow: none; }

.bx--breadcrumb-item .bx--overflow-menu__icon {
  position: relative;
  fill: var(--cds-link-01, #0f62fe);
  transform: translateY(4px); }

.bx--breadcrumb-item .bx--overflow-menu:hover .bx--overflow-menu__icon {
  fill: var(--cds-hover-primary-text, #0043ce); }

.bx--breadcrumb-menu-options:focus {
  outline: none; }

.bx--breadcrumb-menu-options.bx--overflow-menu-options[data-floating-menu-direction='bottom']::after {
  top: -0.4375rem;
  left: 0.875rem;
  width: 0;
  height: 0;
  border-right: 0.4375rem solid transparent;
  border-bottom: 0.4375rem solid var(--cds-field-01, #f4f4f4);
  border-left: 0.4375rem solid transparent;
  margin: 0 auto;
  background: transparent; }

.bx--breadcrumb.bx--skeleton .bx--link {
  position: relative;
  padding: 0;
  border: none;
  background: var(--cds-skeleton-01, #e5e5e5);
  box-shadow: none;
  pointer-events: none;
  width: 6.25rem;
  height: 1rem; }
  .bx--breadcrumb.bx--skeleton .bx--link:hover, .bx--breadcrumb.bx--skeleton .bx--link:focus, .bx--breadcrumb.bx--skeleton .bx--link:active {
    border: none;
    cursor: default;
    outline: none; }
  .bx--breadcrumb.bx--skeleton .bx--link::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    animation: 3000ms ease-in-out skeleton infinite;
    background: var(--cds-skeleton-02, #c6c6c6);
    content: '';
    will-change: transform-origin, transform, opacity; }
    @media (prefers-reduced-motion: reduce) {
      .bx--breadcrumb.bx--skeleton .bx--link::before {
        animation: none; } }

.bx--form-item.bx--checkbox-wrapper {
  position: relative;
  margin-bottom: 0.25rem; }

.bx--form-item.bx--checkbox-wrapper:first-of-type {
  margin-top: 0.1875rem; }

.bx--label + .bx--form-item.bx--checkbox-wrapper {
  margin-top: -0.125rem; }

.bx--form-item.bx--checkbox-wrapper:last-of-type {
  margin-bottom: 0.1875rem; }

.bx--checkbox {
  position: absolute;
  overflow: hidden;
  width: 1px;
  height: 1px;
  padding: 0;
  border: 0;
  margin: -1px;
  clip: rect(0, 0, 0, 0);
  visibility: inherit;
  white-space: nowrap; }

.bx--checkbox-label {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  font-size: var(--cds-body-short-01-font-size, 0.875rem);
  font-weight: var(--cds-body-short-01-font-weight, 400);
  line-height: var(--cds-body-short-01-line-height, 1.28572);
  letter-spacing: var(--cds-body-short-01-letter-spacing, 0.16px);
  position: relative;
  display: flex;
  min-height: 1.5rem;
  padding-top: 0.1875rem;
  padding-left: 1.25rem;
  cursor: pointer;
  user-select: none; }
  .bx--checkbox-label *,
  .bx--checkbox-label *::before,
  .bx--checkbox-label *::after {
    box-sizing: inherit; }

.bx--checkbox-label-text {
  padding-left: 0.375rem; }

.bx--checkbox-label::before,
.bx--checkbox-label::after {
  box-sizing: border-box; }

.bx--checkbox-label::before {
  position: absolute;
  top: 0.125rem;
  left: 0;
  width: 1rem;
  height: 1rem;
  border: 1px solid var(--cds-icon-01, #161616);
  margin: 0.125rem 0.125rem 0.125rem 0.1875rem;
  background-color: transparent;
  border-radius: 1px;
  content: ''; }

.bx--checkbox-label::after {
  position: absolute;
  top: 0.5rem;
  left: 0.4375rem;
  width: 0.5625rem;
  height: 0.3125rem;
  border-bottom: 2px solid var(--cds-inverse-01, #ffffff);
  border-left: 2px solid var(--cds-inverse-01, #ffffff);
  margin-top: -0.1875rem /*rtl:0rem*/;
  background: none;
  content: '';
  transform: scale(0) rotate(-45deg);
  transform-origin: bottom right /*rtl:center*/; }

.bx--checkbox:checked + .bx--checkbox-label::before,
.bx--checkbox:indeterminate + .bx--checkbox-label::before,
.bx--checkbox-label[data-contained-checkbox-state='true']::before,
.bx--checkbox-label[data-contained-checkbox-state='mixed']::before {
  border-width: 1px;
  border-color: var(--cds-icon-01, #161616);
  background-color: var(--cds-icon-01, #161616); }

.bx--checkbox:checked + .bx--checkbox-label::after,
.bx--checkbox-label[data-contained-checkbox-state='true']::after {
  transform: scale(1) rotate(-45deg) /*rtl:scale(1.2) rotate3d(.5, 1, 0, 158deg)*/; }

.bx--checkbox:indeterminate + .bx--checkbox-label::after,
.bx--checkbox-label[data-contained-checkbox-state='mixed']::after {
  top: 0.6875rem;
  width: 0.5rem;
  border-bottom: 2px solid var(--cds-inverse-01, #ffffff);
  border-left: 0 solid var(--cds-inverse-01, #ffffff);
  transform: scale(1) rotate(0deg); }

.bx--checkbox:focus + .bx--checkbox-label::before,
.bx--checkbox-label__focus::before,
.bx--checkbox:checked:focus + .bx--checkbox-label::before,
.bx--checkbox-label[data-contained-checkbox-state='true'].bx--checkbox-label__focus::before,
.bx--checkbox:indeterminate:focus + .bx--checkbox-label::before,
.bx--checkbox-label[data-contained-checkbox-state='mixed'].bx--checkbox-label__focus::before {
  outline: 2px solid var(--cds-focus, #0f62fe);
  outline-offset: 1px; }

.bx--checkbox:disabled + .bx--checkbox-label,
.bx--checkbox-label[data-contained-checkbox-disabled='true'] {
  color: var(--cds-disabled-02, #c6c6c6);
  cursor: not-allowed; }

.bx--checkbox:disabled + .bx--checkbox-label::before,
.bx--checkbox-label[data-contained-checkbox-disabled='true']::before {
  border-color: var(--cds-disabled-02, #c6c6c6); }

.bx--checkbox:checked:disabled + .bx--checkbox-label::before,
.bx--checkbox:indeterminate:disabled + .bx--checkbox-label::before,
.bx--checkbox-label[data-contained-checkbox-state='true'][data-contained-checkbox-disabled='true']::before,
.bx--checkbox-label[data-contained-checkbox-state='mixed'][data-contained-checkbox-disabled='true']::before {
  background-color: var(--cds-disabled-02, #c6c6c6); }

.bx--checkbox-label-text.bx--skeleton {
  position: relative;
  padding: 0;
  border: none;
  background: var(--cds-skeleton-01, #e5e5e5);
  box-shadow: none;
  pointer-events: none;
  width: 6.25rem;
  height: var(--cds-spacing-05, 1rem);
  margin: 0.0625rem 0 0 0.375rem; }
  .bx--checkbox-label-text.bx--skeleton:hover, .bx--checkbox-label-text.bx--skeleton:focus, .bx--checkbox-label-text.bx--skeleton:active {
    border: none;
    cursor: default;
    outline: none; }
  .bx--checkbox-label-text.bx--skeleton::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    animation: 3000ms ease-in-out skeleton infinite;
    background: var(--cds-skeleton-02, #c6c6c6);
    content: '';
    will-change: transform-origin, transform, opacity; }
    @media (prefers-reduced-motion: reduce) {
      .bx--checkbox-label-text.bx--skeleton::before {
        animation: none; } }

.security--button--icon {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  display: inline-block;
  padding: 0;
  border: 0;
  appearance: none;
  background: none;
  cursor: pointer;
  width: 100%;
  width: 2.5rem;
  height: 2.5rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 0;
  padding-right: 0;
  padding-left: 0;
  border: 0;
  background-color: inherit;
  color: var(--cds-icon-01, #161616);
  fill: currentColor;
  transition: 240ms cubic-bezier(0.2, 0, 0.38, 0.9) background-color; }
  .security--button--icon *,
  .security--button--icon *::before,
  .security--button--icon *::after {
    box-sizing: inherit; }
  .security--button--icon::-moz-focus-inner {
    border: 0; }
  .security--button--icon + .security--button--icon__tooltip::before {
    left: 1.25rem; }
  .security--button--icon:hover, .security--button--icon:focus {
    background-color: var(--cds-hover-ui, #e5e5e5); }
  .security--button--icon:focus {
    outline: 2px solid var(--cds-focus, #0f62fe);
    outline-offset: -2px;
    border-width: 0; }
    @media screen and (prefers-contrast) {
      .security--button--icon:focus {
        outline-style: dotted; } }
  .security--button--icon:disabled {
    cursor: not-allowed;
    fill: var(--cds-disabled-03, #8d8d8d); }
    .security--button--icon:disabled, .security--button--icon:disabled:hover, .security--button--icon:disabled:focus {
      background-color: var(--cds-disabled-01, #f4f4f4); }
    .security--button--icon:disabled:focus {
      outline-width: 0; }
  .security--button--icon--lg {
    width: 3rem;
    height: 3rem; }
    .security--button--icon--lg + .security--button--icon__tooltip::before {
      left: 1.5rem; }
  .security--button--icon--sm {
    width: 2rem;
    height: 2rem; }
    .security--button--icon--sm + .security--button--icon__tooltip::before {
      left: 1rem; }
  .security--button--icon--xl {
    width: 4rem;
    height: 4rem; }
    .security--button--icon--xl + .security--button--icon__tooltip::before {
      left: 2rem; }
  .security--button--icon--tooltip::before, .security--button--icon--tooltip::after {
    z-index: 6000; }
  .security--button--icon--tooltip::before {
    font-size: var(--cds-body-short-01-font-size, 0.875rem);
    font-weight: var(--cds-body-short-01-font-weight, 400);
    line-height: var(--cds-body-short-01-line-height, 1.28572);
    letter-spacing: var(--cds-body-short-01-letter-spacing, 0.16px);
    position: absolute;
    display: flex;
    align-items: center;
    padding: 0.125rem 1rem;
    background-color: var(--cds-inverse-02, #393939);
    border-radius: 0.125rem;
    color: var(--cds-inverse-01, #ffffff);
    opacity: 0;
    pointer-events: none;
    transition-duration: 110ms;
    transition-property: opacity;
    white-space: nowrap;
    content: attr(aria-label); }
  .security--button--icon--tooltip::after {
    position: absolute;
    display: inline-block;
    border-width: 0.25rem;
    border-style: solid;
    border-color: transparent;
    content: '';
    opacity: 1;
    transition-duration: 110ms;
    transition-property: opacity;
    opacity: 0; }
  .security--button--icon--tooltip:focus::before, .security--button--icon--tooltip:focus::after, .security--button--icon--tooltip:hover::before, .security--button--icon--tooltip:hover::after {
    opacity: 1; }
  .security--button--icon--tooltip--top::before, .security--button--icon--tooltip--top::after, .security--button--icon--tooltip--bottom::before, .security--button--icon--tooltip--bottom::after {
    left: 50%; }
  .security--button--icon--tooltip--left::before, .security--button--icon--tooltip--left::after, .security--button--icon--tooltip--right::before, .security--button--icon--tooltip--right::after {
    top: 50%; }
  .security--button--icon--tooltip--top::before, .security--button--icon--tooltip--top::after {
    bottom: calc( 100% + 0.25rem); }
  .security--button--icon--tooltip--top::before {
    transform: translate(-50%, -0.25rem); }
  .security--button--icon--tooltip--top::after {
    border-top-color: var(--cds-inverse-02, #393939);
    transform: translate(-50%, 0.25rem); }
  .security--button--icon--tooltip--right::before, .security--button--icon--tooltip--right::after {
    left: calc( 100% + 0.25rem); }
  .security--button--icon--tooltip--right::before {
    transform: translate(0.25rem, -50%); }
  .security--button--icon--tooltip--right::after {
    border-right-color: var(--cds-inverse-02, #393939);
    transform: translate(-0.25rem, -50%); }
  .security--button--icon--tooltip--bottom::before, .security--button--icon--tooltip--bottom::after {
    top: calc( 100% + 0.25rem); }
  .security--button--icon--tooltip--bottom::before {
    transform: translate(-50%, 0.25rem); }
  .security--button--icon--tooltip--bottom::after {
    border-bottom-color: var(--cds-inverse-02, #393939);
    transform: translate(-50%, -0.25rem); }
  .security--button--icon--tooltip--left::before, .security--button--icon--tooltip--left::after {
    right: calc( 100% + 0.25rem); }
  .security--button--icon--tooltip--left::before {
    transform: translate(-0.25rem, -50%); }
  .security--button--icon--tooltip--left::after {
    border-left-color: var(--cds-inverse-02, #393939);
    transform: translate(0.25rem, -50%); }

.security--scroll-gradient {
  position: relative;
  display: flex;
  width: inherit;
  height: inherit;
  align-items: stretch;
  justify-content: stretch; }
  .security--scroll-gradient__content {
    overflow: auto;
    width: 100%;
    height: inherit; }
  .security--scroll-gradient__before, .security--scroll-gradient__after {
    position: absolute;
    z-index: 1;
    display: block;
    opacity: 0;
    pointer-events: none;
    transition-duration: 240ms;
    transition-property: opacity;
    transition-timing-function: cubic-bezier(0.2, 0, 0.38, 0.9); }
  .security--scroll-gradient--x > .security--scroll-gradient__before,
  .security--scroll-gradient--x > .security--scroll-gradient__after {
    top: 0;
    width: 3rem;
    height: 100%; }
  .security--scroll-gradient--x > .security--scroll-gradient__before {
    left: 0; }
  .security--scroll-gradient--x > .security--scroll-gradient__after {
    right: 0; }
  .security--scroll-gradient--y > .security--scroll-gradient__before,
  .security--scroll-gradient--y > .security--scroll-gradient__after {
    left: 0;
    width: 100%;
    height: 3rem; }
  .security--scroll-gradient--y > .security--scroll-gradient__before {
    top: 0; }
  .security--scroll-gradient--y > .security--scroll-gradient__after {
    bottom: 0; }
  .security--scroll-gradient--initial > .security--scroll-gradient__after, .security--scroll-gradient--started > .security--scroll-gradient__after {
    opacity: 1; }
  .security--scroll-gradient--end > .security--scroll-gradient__before, .security--scroll-gradient--started > .security--scroll-gradient__before {
    opacity: 1; }
  .security--scroll-gradient--initial > .security--scroll-gradient__before {
    transition-duration: 110ms; }
  .security--scroll-gradient--end > .security--scroll-gradient__after {
    transition-duration: 110ms; }

.bx--search {
  position: relative;
  display: flex;
  width: 100%;
  align-items: center; }

.bx--search .bx--label {
  position: absolute;
  overflow: hidden;
  width: 1px;
  height: 1px;
  padding: 0;
  border: 0;
  margin: -1px;
  clip: rect(0, 0, 0, 0);
  visibility: inherit;
  white-space: nowrap; }

.bx--search-input {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  font-size: var(--cds-body-short-01-font-size, 0.875rem);
  font-weight: var(--cds-body-short-01-font-weight, 400);
  line-height: var(--cds-body-short-01-line-height, 1.28572);
  letter-spacing: var(--cds-body-short-01-letter-spacing, 0.16px);
  outline: 2px solid transparent;
  outline-offset: -2px;
  width: 100%;
  order: 1;
  padding: 0 2.5rem;
  border: none;
  border-bottom: 1px solid var(--cds-ui-04, #8d8d8d);
  appearance: none;
  background-color: var(--cds-field-01, #f4f4f4);
  color: var(--cds-text-01, #161616);
  text-overflow: ellipsis;
  transition: background-color 110ms cubic-bezier(0.2, 0, 0.38, 0.9), outline 110ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--search-input *,
  .bx--search-input *::before,
  .bx--search-input *::after {
    box-sizing: inherit; }
  .bx--search-input:focus {
    outline: 2px solid var(--cds-focus, #0f62fe);
    outline-offset: -2px; }
    @media screen and (prefers-contrast) {
      .bx--search-input:focus {
        outline-style: dotted; } }
  .bx--search-input::placeholder {
    color: var(--cds-text-05, #6f6f6f);
    opacity: 1; }
  .bx--search-input::-ms-clear {
    display: none; }

.bx--search-input[disabled] {
  border-bottom: 1px solid transparent;
  background-color: var(--cds-field-disabled, #f4f4f4);
  color: #c6c6c6;
  cursor: not-allowed; }
  .bx--search-input[disabled]::placeholder {
    color: #c6c6c6; }

.bx--search--light .bx--search-input {
  background: var(--cds-field-02, #ffffff); }

.bx--search--light .bx--search-close::before {
  background: var(--cds-field-02, #ffffff); }

.bx--search--sm .bx--search-input,
.bx--search--sm.bx--search--expandable.bx--search--expanded .bx--search-input {
  height: 2rem;
  padding: 0 var(--cds-spacing-07, 2rem); }

.bx--search--sm .bx--search-magnifier-icon {
  left: 0.5rem; }

.bx--search--lg .bx--search-input,
.bx--search--lg.bx--search--expandable.bx--search--expanded .bx--search-input {
  height: 2.5rem;
  padding: 0 var(--cds-spacing-08, 2.5rem); }

.bx--search--lg .bx--search-magnifier-icon {
  left: 0.75rem; }

.bx--search--xl .bx--search-input,
.bx--search--xl.bx--search--expandable.bx--search--expanded .bx--search-input {
  height: 3rem;
  padding: 0 var(--cds-spacing-09, 3rem); }

.bx--search-magnifier-icon {
  position: absolute;
  z-index: 2;
  top: 50%;
  left: var(--cds-spacing-05, 1rem);
  width: 1rem;
  height: 1rem;
  fill: var(--cds-icon-02, #525252);
  pointer-events: none;
  transform: translateY(-50%); }

.bx--search-close {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  display: inline-block;
  padding: 0;
  border: 0;
  appearance: none;
  background: none;
  cursor: pointer;
  outline: 2px solid transparent;
  outline-offset: -2px;
  position: absolute;
  top: 0;
  right: 0; }
  .bx--search-close *,
  .bx--search-close *::before,
  .bx--search-close *::after {
    box-sizing: inherit; }
  .bx--search-close::-moz-focus-inner {
    border: 0; }
  .bx--search-close::before {
    position: absolute;
    top: 0.0625rem;
    left: 0;
    display: block;
    width: 2px;
    height: calc(100% - 2px);
    background-color: var(--cds-field-01, #f4f4f4);
    content: '';
    transition: background-color 110ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--search-close:hover {
    border-bottom: 1px solid var(--cds-ui-04, #8d8d8d); }
    .bx--search-close:hover::before {
      background-color: var(--cds-hover-field, #e5e5e5); }

.bx--search-button {
  flex-shrink: 0;
  margin-left: 0.125rem;
  background-color: var(--cds-field-01, #f4f4f4); }
  .bx--search-button svg {
    fill: currentColor;
    vertical-align: middle; }

.bx--search-close svg {
  fill: inherit; }

.bx--search-close,
.bx--search-button {
  display: flex;
  width: 2.5rem;
  height: 2.5rem;
  align-items: center;
  justify-content: center;
  border-width: 1px 0;
  border-style: solid;
  border-color: transparent;
  cursor: pointer;
  fill: var(--cds-icon-01, #161616);
  opacity: 1;
  transition: opacity 110ms cubic-bezier(0.2, 0, 0.38, 0.9), background-color 110ms cubic-bezier(0.2, 0, 0.38, 0.9), outline 110ms cubic-bezier(0.2, 0, 0.38, 0.9), border 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  visibility: inherit; }
  .bx--search-close:hover,
  .bx--search-button:hover {
    background-color: var(--cds-hover-field, #e5e5e5); }
  .bx--search-close:focus,
  .bx--search-button:focus {
    outline: 2px solid var(--cds-focus, #0f62fe);
    outline-offset: -2px; }
    @media screen and (prefers-contrast) {
      .bx--search-close:focus,
      .bx--search-button:focus {
        outline-style: dotted; } }
  .bx--search-close:active,
  .bx--search-button:active {
    outline: 2px solid var(--cds-focus, #0f62fe);
    outline-offset: -2px;
    background-color: var(--cds-selected-ui, #e0e0e0); }
    @media screen and (prefers-contrast) {
      .bx--search-close:active,
      .bx--search-button:active {
        outline-style: dotted; } }

.bx--search--disabled .bx--search-close,
.bx--search--disabled.bx--search--expandable .bx--search-magnifier {
  cursor: not-allowed;
  outline: none; }
  .bx--search--disabled .bx--search-close:hover,
  .bx--search--disabled.bx--search--expandable .bx--search-magnifier:hover {
    border-bottom-color: transparent;
    background-color: transparent; }
  .bx--search--disabled .bx--search-close:hover::before,
  .bx--search--disabled.bx--search--expandable .bx--search-magnifier:hover::before {
    background-color: transparent; }

.bx--search--disabled svg {
  fill: #c6c6c6; }

.bx--search-close:focus::before,
.bx--search-close:active::before {
  background-color: var(--cds-focus, #0f62fe); }

.bx--search-input:focus ~ .bx--search-close:hover {
  outline: 2px solid var(--cds-focus, #0f62fe);
  outline-offset: -2px; }
  @media screen and (prefers-contrast) {
    .bx--search-input:focus ~ .bx--search-close:hover {
      outline-style: dotted; } }

.bx--search--sm .bx--search-close,
.bx--search--sm ~ .bx--search-button, .bx--search--sm.bx--search--expandable,
.bx--search--sm.bx--search--expandable .bx--search-magnifier {
  width: 2rem;
  height: 2rem; }

.bx--search--sm.bx--search--expandable .bx--search-input::placeholder {
  padding: 0 2rem; }

.bx--search--lg .bx--search-close,
.bx--search--lg ~ .bx--search-button, .bx--search--lg.bx--search--expandable,
.bx--search--lg.bx--search--expandable .bx--search-magnifier {
  width: 2.5rem;
  height: 2.5rem; }

.bx--search--lg.bx--search--expandable .bx--search-input::placeholder {
  padding: 0 2.5rem; }

.bx--search--xl .bx--search-close,
.bx--search--xl ~ .bx--search-button, .bx--search--xl.bx--search--expandable,
.bx--search--xl.bx--search--expandable .bx--search-magnifier {
  width: 3rem;
  height: 3rem; }

.bx--search--xl.bx--search--expandable .bx--search-input::placeholder {
  padding: 0 3rem; }

.bx--search-close--hidden {
  opacity: 0;
  visibility: hidden; }

.bx--search--xl.bx--skeleton .bx--search-input,
.bx--search--lg.bx--skeleton .bx--search-input,
.bx--search--sm.bx--skeleton .bx--search-input {
  position: relative;
  padding: 0;
  border: none;
  background: var(--cds-skeleton-01, #e5e5e5);
  box-shadow: none;
  pointer-events: none;
  width: 100%; }
  .bx--search--xl.bx--skeleton .bx--search-input:hover, .bx--search--xl.bx--skeleton .bx--search-input:focus, .bx--search--xl.bx--skeleton .bx--search-input:active,
  .bx--search--lg.bx--skeleton .bx--search-input:hover,
  .bx--search--lg.bx--skeleton .bx--search-input:focus,
  .bx--search--lg.bx--skeleton .bx--search-input:active,
  .bx--search--sm.bx--skeleton .bx--search-input:hover,
  .bx--search--sm.bx--skeleton .bx--search-input:focus,
  .bx--search--sm.bx--skeleton .bx--search-input:active {
    border: none;
    cursor: default;
    outline: none; }
  .bx--search--xl.bx--skeleton .bx--search-input::before,
  .bx--search--lg.bx--skeleton .bx--search-input::before,
  .bx--search--sm.bx--skeleton .bx--search-input::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    animation: 3000ms ease-in-out skeleton infinite;
    background: var(--cds-skeleton-02, #c6c6c6);
    content: '';
    will-change: transform-origin, transform, opacity; }
    @media (prefers-reduced-motion: reduce) {
      .bx--search--xl.bx--skeleton .bx--search-input::before,
      .bx--search--lg.bx--skeleton .bx--search-input::before,
      .bx--search--sm.bx--skeleton .bx--search-input::before {
        animation: none; } }
  .bx--search--xl.bx--skeleton .bx--search-input::placeholder,
  .bx--search--lg.bx--skeleton .bx--search-input::placeholder,
  .bx--search--sm.bx--skeleton .bx--search-input::placeholder {
    color: transparent; }

.bx--search--expandable {
  transition: width 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }

.bx--search--expandable.bx--search--expanded {
  width: 100%; }

.bx--search--expandable .bx--search-input {
  width: 0;
  padding: 0;
  transition: padding 70ms cubic-bezier(0.2, 0, 0.38, 0.9), width 0s linear 70ms; }
  .bx--search--expandable .bx--search-input::placeholder {
    position: relative;
    opacity: 0;
    transition-duration: 70ms;
    transition-property: padding, opacity;
    transition-timing-function: cubic-bezier(0.2, 0, 0.38, 0.9); }

.bx--search--expandable.bx--search--expanded .bx--search-input {
  width: 100%;
  transition: padding 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--search--expandable.bx--search--expanded .bx--search-input::placeholder {
    position: relative;
    padding: 0;
    opacity: 1; }

.bx--search--expandable .bx--search-magnifier {
  position: absolute;
  cursor: pointer; }

.bx--search--expandable .bx--search-magnifier:hover {
  background-color: var(--cds-hover-ui, #e5e5e5); }

.bx--search--expandable.bx--search--expanded .bx--search-magnifier {
  pointer-events: none; }

.bx--search--expandable .bx--search-magnifier-icon {
  fill: var(--cds-icon-01, #161616); }

.bx--search--expandable.bx--search--expanded .bx--search-magnifier-icon {
  fill: var(--cds-icon-02, #525252); }

@media screen and (-ms-high-contrast: active), (forced-colors: active), (prefers-contrast) {
  .bx--search-close svg,
  .bx--search-magnifier-icon {
    fill: ButtonText; } }

.bx--table-toolbar {
  position: relative;
  display: flex;
  overflow: hidden;
  width: 100%;
  height: var(--cds-spacing-09, 3rem);
  background-color: var(--cds-layer, #f4f4f4); }

.bx--toolbar-content {
  display: flex;
  width: 100%;
  height: var(--cds-spacing-09, 3rem);
  justify-content: flex-end;
  transform: translate3d(0, 0, 0);
  transition: transform 110ms cubic-bezier(0.2, 0, 0.38, 0.9), clip-path 110ms cubic-bezier(0.2, 0, 0.38, 0.9); }

.bx--batch-actions ~ .bx--toolbar-content {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%); }

.bx--toolbar-content .bx--search .bx--search-input {
  background-color: transparent; }

.bx--batch-actions ~ .bx--toolbar-search-container {
  display: flex;
  align-items: center;
  opacity: 1;
  transition: opacity 110ms; }

.bx--toolbar-content .bx--toolbar-search-container-expandable {
  position: relative;
  width: var(--cds-spacing-09, 3rem);
  height: var(--cds-spacing-09, 3rem);
  box-shadow: none;
  cursor: pointer;
  transition: width 240ms cubic-bezier(0.2, 0, 0.38, 0.9), background-color 110ms cubic-bezier(0, 0, 0.38, 0.9); }
  .bx--toolbar-content .bx--toolbar-search-container-expandable:hover {
    background-color: var(--cds-field-hover, #e5e5e5); }

.bx--toolbar-search-container-expandable .bx--search-input {
  height: 100%;
  padding: 0;
  cursor: pointer;
  opacity: 0; }

.bx--toolbar-search-container-expandable .bx--search-magnifier-icon {
  left: 0;
  width: var(--cds-spacing-09, 3rem);
  height: var(--cds-spacing-09, 3rem);
  padding: var(--cds-spacing-05, 1rem);
  fill: var(--cds-icon-primary, #161616); }

.bx--toolbar-search-container-expandable.bx--search--disabled .bx--search-magnifier-icon {
  background-color: var(--cds-layer-disabled, #f4f4f4);
  cursor: not-allowed;
  transition: background-color none; }

.bx--toolbar-search-container-disabled .bx--search-input {
  cursor: not-allowed; }

.bx--toolbar-search-container-expandable.bx--search .bx--label {
  visibility: hidden; }

.bx--toolbar-search-container-expandable.bx--search .bx--search-close {
  width: var(--cds-spacing-09, 3rem);
  height: var(--cds-spacing-09, 3rem); }
  .bx--toolbar-search-container-expandable.bx--search .bx--search-close::before {
    top: 0.125rem;
    height: calc(100% - 0.25rem);
    background-color: var(--cds-field-hover, #e5e5e5); }

.bx--toolbar-search-container-expandable.bx--search .bx--search-close:focus::before {
  background-color: var(--cds-focus, #0f62fe); }

.bx--toolbar-search-container-active.bx--search {
  width: 100%; }

.bx--toolbar-search-container-active .bx--search-input {
  opacity: 1; }

.bx--toolbar-search-container-active .bx--label,
.bx--toolbar-search-container-active .bx--search-input {
  padding: 0 var(--cds-spacing-09, 3rem);
  cursor: text; }

.bx--toolbar-search-container-active .bx--search-input:focus + .bx--search-close {
  border: none;
  box-shadow: none;
  outline: none; }

.bx--toolbar-search-container-active .bx--search-input:not(:placeholder-shown) {
  border: none;
  background-color: var(--cds-field-hover, #e5e5e5); }

.bx--toolbar-search-container-active .bx--search-magnifier-icon:focus,
.bx--toolbar-search-container-active .bx--search-magnifier-icon:active,
.bx--toolbar-search-container-active .bx--search-magnifier-icon:hover {
  border: none;
  background-color: transparent;
  outline: none; }

.bx--toolbar-search-container-persistent .bx--search-close,
.bx--toolbar-search-container-persistent .bx--search-close:hover,
.bx--toolbar-search-container-active .bx--search-close,
.bx--toolbar-search-container-active .bx--search-close:hover {
  border: none;
  background-color: transparent; }

.bx--toolbar-search-container-persistent .bx--search-close::before {
  display: none; }

.bx--overflow-menu.bx--toolbar-action {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  display: inline-block;
  padding: 0;
  border: 0;
  appearance: none;
  background: none;
  cursor: pointer;
  width: 100%;
  display: flex;
  width: var(--cds-spacing-09, 3rem);
  height: var(--cds-spacing-09, 3rem);
  padding: var(--cds-spacing-05, 1rem);
  cursor: pointer;
  transition: background 110ms cubic-bezier(0, 0, 0.38, 0.9); }
  .bx--overflow-menu.bx--toolbar-action *,
  .bx--overflow-menu.bx--toolbar-action *::before,
  .bx--overflow-menu.bx--toolbar-action *::after {
    box-sizing: inherit; }
  .bx--overflow-menu.bx--toolbar-action::-moz-focus-inner {
    border: 0; }

.bx--toolbar-action {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  display: inline-block;
  padding: 0;
  border: 0;
  appearance: none;
  background: none;
  cursor: pointer;
  width: 100%;
  display: flex;
  width: var(--cds-spacing-09, 3rem);
  height: var(--cds-spacing-09, 3rem);
  cursor: pointer;
  transition: background 110ms cubic-bezier(0, 0, 0.38, 0.9); }
  .bx--toolbar-action *,
  .bx--toolbar-action *::before,
  .bx--toolbar-action *::after {
    box-sizing: inherit; }
  .bx--toolbar-action::-moz-focus-inner {
    border: 0; }

.bx--toolbar-action:hover:not([disabled]) {
  background-color: var(--cds-field-hover, #e5e5e5); }

.bx--toolbar-action:hover[aria-expanded='true'] {
  background-color: var(--cds-layer, #f4f4f4); }

.bx--toolbar-action[disabled] {
  cursor: not-allowed; }

.bx--toolbar-action[disabled] .bx--toolbar-action__icon {
  cursor: not-allowed;
  fill: var(--cds-icon-disabled, #c6c6c6); }

.bx--toolbar-action:focus:not([disabled]),
.bx--toolbar-action:active:not([disabled]) {
  outline: 2px solid var(--cds-focus, #0f62fe);
  outline-offset: -2px; }
  @media screen and (prefers-contrast) {
    .bx--toolbar-action:focus:not([disabled]),
    .bx--toolbar-action:active:not([disabled]) {
      outline-style: dotted; } }
  .bx--toolbar-action:focus:not([disabled]).bx--toolbar-search-container-expandable,
  .bx--toolbar-action:active:not([disabled]).bx--toolbar-search-container-expandable {
    outline: none; }

.bx--toolbar-action ~ .bx--btn {
  max-width: none;
  margin: 0;
  white-space: nowrap; }

.bx--overflow-menu--data-table {
  height: var(--cds-spacing-09, 3rem); }

.bx--toolbar-action__icon {
  width: auto;
  max-width: var(--cds-spacing-05, 1rem);
  height: var(--cds-spacing-05, 1rem);
  fill: var(--cds-icon-primary, #161616); }

.bx--toolbar-search-container-persistent {
  position: relative;
  width: 100%;
  height: var(--cds-spacing-09, 3rem);
  opacity: 1; }

.bx--toolbar-search-container-persistent + .bx--toolbar-content {
  position: relative;
  width: auto; }

.bx--toolbar-search-container-persistent .bx--search {
  position: initial; }

.bx--toolbar-search-container-persistent .bx--search-magnifier-icon {
  left: var(--cds-spacing-05, 1rem); }

.bx--toolbar-search-container-persistent .bx--search-input {
  height: var(--cds-spacing-09, 3rem);
  padding: 0 var(--cds-spacing-09, 3rem);
  border: none; }

.bx--toolbar-search-container-persistent .bx--search-input:focus:not([disabled]) {
  outline: 2px solid var(--cds-focus, #0f62fe);
  outline-offset: -2px; }
  @media screen and (prefers-contrast) {
    .bx--toolbar-search-container-persistent .bx--search-input:focus:not([disabled]) {
      outline-style: dotted; } }

.bx--toolbar-search-container-persistent .bx--search-input:hover:not([disabled]) {
  background-color: var(--cds-field-hover, #e5e5e5); }

.bx--toolbar-search-container-persistent .bx--search-input:active:not([disabled]),
.bx--toolbar-search-container-persistent .bx--search-input:not(:placeholder-shown) {
  background-color: var(--cds-field-hover, #e5e5e5); }

.bx--toolbar-search-container-persistent .bx--search-close {
  width: var(--cds-spacing-09, 3rem);
  height: var(--cds-spacing-09, 3rem); }

.bx--batch-actions--active ~ .bx--toolbar-search-container,
.bx--batch-actions--active ~ .bx--toolbar-content {
  clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
  transform: translate3d(0, 48px, 0);
  transition: transform 110ms cubic-bezier(0.2, 0, 0.38, 0.9), clip-path 110ms cubic-bezier(0.2, 0, 0.38, 0.9); }

.bx--batch-actions {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  padding-right: var(--cds-spacing-06, 1.5rem);
  padding-left: var(--cds-spacing-06, 1.5rem);
  background-color: var(--cds-background-brand, #0f62fe);
  clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
  overflow-x: auto;
  pointer-events: none;
  transform: translate3d(0, 48px, 0);
  transition: transform 110ms cubic-bezier(0.2, 0, 0.38, 0.9), clip-path 110ms cubic-bezier(0.2, 0, 0.38, 0.9), opacity 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  will-change: transform; }

.bx--batch-actions:focus {
  outline: 1px solid var(--cds-focus, #0f62fe); }
  @media screen and (prefers-contrast) {
    .bx--batch-actions:focus {
      outline-style: dotted; } }

.bx--batch-actions--active {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  pointer-events: all;
  transform: translate3d(0, 0, 0); }

.bx--action-list {
  position: absolute;
  right: 0;
  display: flex; }

.bx--action-list .bx--btn {
  min-width: 0;
  padding: calc(0.875rem - 3px) 16px;
  color: var(--cds-text-on-color, #ffffff); }

.bx--action-list .bx--btn:disabled {
  color: var(--cds-text-on-color-disabled, #8d8d8d); }

.bx--action-list .bx--btn .bx--btn__icon {
  position: static;
  margin-left: var(--cds-spacing-03, 0.5rem);
  fill: var(--cds-icon-on-color, #ffffff); }

.bx--action-list .bx--btn .bx--btn__icon .st0 {
  fill: none; }

.bx--batch-download {
  padding: 0.0625rem; }

.bx--action-list .bx--btn--primary:focus::before,
.bx--action-list .bx--btn--primary::before,
.bx--action-list .bx--btn--primary:focus::after,
.bx--action-list .bx--btn--primary::after {
  display: none; }

.bx--action-list .bx--btn--primary:focus {
  outline: 2px solid var(--cds-layer, #f4f4f4);
  outline-offset: -0.125rem; }

.bx--action-list .bx--btn--primary:nth-child(3):hover + .bx--btn--primary.bx--batch-summary__cancel::before,
.bx--action-list .bx--btn--primary:nth-child(3):focus + .bx--btn--primary.bx--batch-summary__cancel::before {
  opacity: 0; }

.bx--btn--primary.bx--batch-summary__cancel::before {
  position: absolute;
  top: 0.9375rem;
  left: 0;
  display: block;
  width: 0.0625rem;
  height: var(--cds-spacing-05, 1rem);
  border: none;
  background-color: var(--cds-text-on-color, #ffffff);
  content: '';
  opacity: 1;
  transition: opacity 110ms cubic-bezier(0.2, 0, 0.38, 0.9); }

.bx--btn--primary.bx--batch-summary__cancel:hover::before {
  opacity: 0;
  transition: opacity 110ms cubic-bezier(0.2, 0, 0.38, 0.9); }

.bx--batch-summary__cancel {
  position: relative;
  padding-right: var(--cds-spacing-05, 1rem); }

.bx--batch-summary {
  position: absolute;
  left: 0;
  display: flex;
  align-items: center;
  margin-left: var(--cds-spacing-05, 1rem);
  color: var(--cds-text-on-color, #ffffff); }

.bx--batch-summary__para {
  font-size: var(--cds-body-short-01-font-size, 0.875rem);
  font-weight: var(--cds-body-short-01-font-weight, 400);
  line-height: var(--cds-body-short-01-line-height, 1.28572);
  letter-spacing: var(--cds-body-short-01-letter-spacing, 0.16px); }

.bx--table-toolbar--small,
.bx--table-toolbar--sm {
  height: 2rem; }
  .bx--table-toolbar--small .bx--toolbar-search-container-expandable,
  .bx--table-toolbar--small .bx--toolbar-search-container-persistent,
  .bx--table-toolbar--sm .bx--toolbar-search-container-expandable,
  .bx--table-toolbar--sm .bx--toolbar-search-container-persistent {
    height: 2rem; }
  .bx--table-toolbar--small .bx--toolbar-search-container-expandable .bx--search-input,
  .bx--table-toolbar--small .bx--toolbar-search-container-persistent .bx--search-input,
  .bx--table-toolbar--sm .bx--toolbar-search-container-expandable .bx--search-input,
  .bx--table-toolbar--sm .bx--toolbar-search-container-persistent .bx--search-input {
    height: 2rem; }
  .bx--table-toolbar--small .bx--toolbar-search-container-expandable .bx--search-close,
  .bx--table-toolbar--small .bx--toolbar-search-container-persistent .bx--search-close,
  .bx--table-toolbar--sm .bx--toolbar-search-container-expandable .bx--search-close,
  .bx--table-toolbar--sm .bx--toolbar-search-container-persistent .bx--search-close {
    width: 2rem;
    height: 2rem; }
  .bx--table-toolbar--small .bx--toolbar-search-container-expandable .bx--search-magnifier-icon,
  .bx--table-toolbar--small .bx--toolbar-search-container-persistent .bx--search-magnifier-icon,
  .bx--table-toolbar--sm .bx--toolbar-search-container-expandable .bx--search-magnifier-icon,
  .bx--table-toolbar--sm .bx--toolbar-search-container-persistent .bx--search-magnifier-icon {
    width: 2rem;
    height: 2rem;
    padding: var(--cds-spacing-03, 0.5rem); }
  .bx--table-toolbar--small .bx--toolbar-action.bx--toolbar-search-container-persistent,
  .bx--table-toolbar--sm .bx--toolbar-action.bx--toolbar-search-container-persistent {
    width: 100%; }
  .bx--table-toolbar--small .bx--toolbar-search-container-active .bx--search-magnifier-icon,
  .bx--table-toolbar--small .bx--toolbar-search-container-persistent .bx--search-magnifier-icon,
  .bx--table-toolbar--sm .bx--toolbar-search-container-active .bx--search-magnifier-icon,
  .bx--table-toolbar--sm .bx--toolbar-search-container-persistent .bx--search-magnifier-icon {
    left: var(--cds-spacing-03, 0.5rem); }
  .bx--table-toolbar--small .bx--toolbar-search-container-expandable,
  .bx--table-toolbar--sm .bx--toolbar-search-container-expandable {
    width: 2rem; }
  .bx--table-toolbar--small .bx--toolbar-search-container-expandable .bx--search .bx--search-input,
  .bx--table-toolbar--sm .bx--toolbar-search-container-expandable .bx--search .bx--search-input {
    padding: 0 var(--cds-spacing-09, 3rem); }
  .bx--table-toolbar--small .bx--toolbar-search-container-active,
  .bx--table-toolbar--sm .bx--toolbar-search-container-active {
    flex: auto;
    transition: flex 175ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--table-toolbar--small .bx--toolbar-search-container-active .bx--search-input,
  .bx--table-toolbar--sm .bx--toolbar-search-container-active .bx--search-input {
    visibility: inherit; }
  .bx--table-toolbar--small .bx--toolbar-search-container-active .bx--search-input:focus,
  .bx--table-toolbar--sm .bx--toolbar-search-container-active .bx--search-input:focus {
    outline: 2px solid var(--cds-focus, #0f62fe);
    outline-offset: -2px;
    background-color: var(--cds-field-hover, #e5e5e5); }
    @media screen and (prefers-contrast) {
      .bx--table-toolbar--small .bx--toolbar-search-container-active .bx--search-input:focus,
      .bx--table-toolbar--sm .bx--toolbar-search-container-active .bx--search-input:focus {
        outline-style: dotted; } }
  .bx--table-toolbar--small .bx--toolbar-search-container-active .bx--search-input:active,
  .bx--table-toolbar--small .bx--toolbar-search-container-active .bx--search-input:not(:placeholder-shown),
  .bx--table-toolbar--sm .bx--toolbar-search-container-active .bx--search-input:active,
  .bx--table-toolbar--sm .bx--toolbar-search-container-active .bx--search-input:not(:placeholder-shown) {
    background-color: var(--cds-field-hover, #e5e5e5); }
  .bx--table-toolbar--small .bx--toolbar-search-container-active .bx--search-magnifier-icon:focus,
  .bx--table-toolbar--small .bx--toolbar-search-container-active .bx--search-magnifier-icon:active,
  .bx--table-toolbar--small .bx--toolbar-search-container-active .bx--search-magnifier-icon:hover,
  .bx--table-toolbar--sm .bx--toolbar-search-container-active .bx--search-magnifier-icon:focus,
  .bx--table-toolbar--sm .bx--toolbar-search-container-active .bx--search-magnifier-icon:active,
  .bx--table-toolbar--sm .bx--toolbar-search-container-active .bx--search-magnifier-icon:hover {
    outline: 2px solid transparent;
    outline-offset: -2px;
    background-color: transparent; }

.bx--search--disabled .bx--search-magnifier-icon:hover {
  background-color: transparent; }

.bx--table-toolbar--small .bx--batch-actions .bx--action-list,
.bx--table-toolbar--sm .bx--batch-actions .bx--action-list {
  height: 2rem; }

.bx--table-toolbar--small .bx--toolbar-action,
.bx--table-toolbar--sm .bx--toolbar-action {
  width: 2rem;
  height: 2rem;
  padding: var(--cds-spacing-03, 0.5rem) 0; }

.bx--table-toolbar--small .bx--btn--primary,
.bx--table-toolbar--sm .bx--btn--primary {
  height: 2rem;
  min-height: auto;
  padding-top: calc(0.375rem - 3px);
  padding-bottom: calc(0.375rem - 3px); }

.bx--table-toolbar--small .bx--btn--primary.bx--batch-summary__cancel::before,
.bx--table-toolbar--sm .bx--btn--primary.bx--batch-summary__cancel::before {
  top: 0.5rem; }

.bx--table-toolbar--small .bx--toolbar-action ~ .bx--btn,
.bx--table-toolbar--sm .bx--toolbar-action ~ .bx--btn {
  overflow: hidden;
  height: 2rem; }

.bx--data-table-container {
  position: relative;
  padding-top: var(--cds-spacing-01, 0.125rem); }

.bx--data-table-content {
  overflow-x: auto; }

.bx--data-table-header {
  padding: var(--cds-spacing-05, 1rem) 0 var(--cds-spacing-06, 1.5rem) var(--cds-spacing-05, 1rem);
  background: var(--cds-layer, #f4f4f4); }

.bx--data-table-header__title {
  font-size: var(--cds-productive-heading-03-font-size, 1.25rem);
  font-weight: var(--cds-productive-heading-03-font-weight, 400);
  line-height: var(--cds-productive-heading-03-line-height, 1.4);
  letter-spacing: var(--cds-productive-heading-03-letter-spacing, 0);
  color: var(--cds-text-primary, #161616); }

.bx--data-table-header__description {
  font-size: var(--cds-body-short-01-font-size, 0.875rem);
  font-weight: var(--cds-body-short-01-font-weight, 400);
  line-height: var(--cds-body-short-01-line-height, 1.28572);
  letter-spacing: var(--cds-body-short-01-letter-spacing, 0.16px);
  color: var(--cds-text-secondary, #525252); }

.bx--data-table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0; }

.bx--data-table thead {
  font-size: var(--cds-productive-heading-01-font-size, 0.875rem);
  font-weight: var(--cds-productive-heading-01-font-weight, 600);
  line-height: var(--cds-productive-heading-01-line-height, 1.28572);
  letter-spacing: var(--cds-productive-heading-01-letter-spacing, 0.16px);
  background-color: var(--cds-layer-accent, #e0e0e0); }

.bx--data-table tbody {
  font-size: var(--cds-body-short-01-font-size, 0.875rem);
  font-weight: var(--cds-body-short-01-font-weight, 400);
  line-height: var(--cds-body-short-01-line-height, 1.28572);
  letter-spacing: var(--cds-body-short-01-letter-spacing, 0.16px);
  width: 100%;
  background-color: var(--cds-layer, #f4f4f4); }

.bx--data-table tr {
  width: 100%;
  height: var(--cds-spacing-09, 3rem);
  border: none; }

.bx--data-table tbody tr,
.bx--data-table tbody tr td,
.bx--data-table tbody tr th {
  transition: background-color 70ms cubic-bezier(0, 0, 0.38, 0.9); }

.bx--data-table tbody tr:hover {
  background: var(--cds-layer-hover, #e5e5e5); }

.bx--data-table tbody tr:hover td,
.bx--data-table tbody tr:hover th {
  border-top: 1px solid var(--cds-layer-hover, #e5e5e5);
  border-bottom: 1px solid var(--cds-layer-hover, #e5e5e5);
  background: var(--cds-layer-hover, #e5e5e5);
  color: var(--cds-text-primary, #161616); }

.bx--data-table tbody tr:hover td .bx--link,
.bx--data-table tbody tr:hover th .bx--link {
  color: var(--cds-link-secondary, #0043ce); }

.bx--data-table tbody tr:hover td .bx--link--disabled,
.bx--data-table tbody tr:hover th .bx--link--disabled {
  color: var(--cds-disabled-02, #c6c6c6); }

.bx--data-table th,
.bx--data-table td {
  text-align: left;
  vertical-align: middle; }

.bx--data-table th[align='right'],
.bx--data-table td[align='right'] {
  text-align: right; }

.bx--data-table th[align='center'],
.bx--data-table td[align='center'] {
  text-align: center; }

.bx--data-table th {
  padding-right: var(--cds-spacing-05, 1rem);
  padding-left: var(--cds-spacing-05, 1rem);
  background-color: var(--cds-layer-accent, #e0e0e0);
  color: var(--cds-text-primary, #161616); }

.bx--data-table th:last-of-type {
  position: static;
  width: auto; }

.bx--data-table td,
.bx--data-table tbody th {
  padding-right: var(--cds-spacing-05, 1rem);
  padding-left: var(--cds-spacing-05, 1rem);
  border-top: 1px solid var(--cds-layer, #f4f4f4);
  border-bottom: 1px solid var(--cds-border-subtle, #e0e0e0);
  background: var(--cds-layer, #f4f4f4);
  color: var(--cds-text-secondary, #525252); }
  .bx--data-table td + td:first-of-type,
  .bx--data-table tbody th + td:first-of-type {
    padding-left: var(--cds-spacing-04, 0.75rem); }

@supports (-moz-appearance: none) {
  .bx--data-table td {
    background-clip: padding-box; } }

.bx--data-table .bx--list-box input[role='combobox'],
.bx--data-table .bx--list-box input[type='text'],
.bx--data-table .bx--dropdown,
.bx--data-table .bx--list-box,
.bx--data-table .bx--number input[type='number'],
.bx--data-table .bx--number__control-btn::before,
.bx--data-table .bx--number__control-btn::after,
.bx--data-table .bx--text-input,
.bx--data-table .bx--select-input {
  background-color: var(--cds-field-02, #ffffff); }

.bx--data-table td.bx--table-column-menu .bx--overflow-menu[aria-expanded='false']:focus {
  outline: 2px solid var(--cds-focus, #0f62fe);
  outline-offset: -2px; }
  @media screen and (prefers-contrast) {
    .bx--data-table td.bx--table-column-menu .bx--overflow-menu[aria-expanded='false']:focus {
      outline-style: dotted; } }

.bx--data-table td.bx--table-column-menu .bx--overflow-menu[aria-expanded='true']:focus {
  outline: none; }

@media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .bx--data-table td.bx--table-column-menu .bx--overflow-menu .bx--overflow-menu__icon {
    opacity: 0; } }

.bx--data-table td.bx--table-column-menu .bx--overflow-menu.bx--overflow-menu--open .bx--overflow-menu__icon {
  opacity: 1; }

.bx--data-table.bx--data-table--visible-overflow-menu td.bx--table-column-menu .bx--overflow-menu .bx--overflow-menu__icon,
.bx--data-table td.bx--table-column-menu .bx--overflow-menu:hover .bx--overflow-menu__icon,
.bx--data-table td.bx--table-column-menu .bx--overflow-menu:focus .bx--overflow-menu__icon,
.bx--data-table tr:hover td.bx--table-column-menu .bx--overflow-menu .bx--overflow-menu__icon {
  opacity: 1; }

.bx--table-row--menu-option .bx--overflow-menu-options__btn .bx--overflow-menu-options__option-content svg {
  position: relative;
  top: 0.1875rem;
  margin-right: var(--cds-spacing-03, 0.5rem); }

.bx--data-table .bx--overflow-menu:hover,
.bx--data-table .bx--overflow-menu__trigger:hover {
  background-color: var(--cds-layer-selected-hover, #cacaca); }

.bx--data-table--selected .bx--overflow-menu:hover,
.bx--data-table--selected .bx--overflow-menu__trigger:hover {
  background-color: var(--cds-layer-hover, #e5e5e5); }

.bx--data-table--selected .bx--link {
  color: var(--cds-link-secondary, #0043ce); }

.bx--data-table--compact td.bx--table-column-menu,
.bx--data-table--xs td.bx--table-column-menu,
.bx--data-table--short td.bx--table-column-menu,
.bx--data-table--sm td.bx--table-column-menu {
  height: 1.5rem;
  padding-top: 0;
  padding-bottom: 0; }

.bx--data-table--short td.bx--table-column-menu,
.bx--data-table--sm td.bx--table-column-menu {
  height: 2rem; }

.bx--data-table--md td.bx--table-column-menu {
  height: 2.5rem; }

.bx--data-table--tall .bx--table-column-menu,
.bx--data-table--xl .bx--table-column-menu {
  padding-top: var(--cds-spacing-03, 0.5rem); }

.bx--data-table--zebra tbody tr:not(.bx--parent-row):nth-child(odd) td {
  border-bottom: 1px solid var(--cds-layer, #f4f4f4); }

.bx--data-table--zebra tbody tr:not(.bx--parent-row):nth-child(even) td {
  border-top: 1px solid #ffffff;
  border-bottom: 1px solid #ffffff;
  background-color: #ffffff; }

.bx--data-table--zebra tbody tr:not(.bx--parent-row):hover td {
  border-top: 1px solid var(--cds-layer-hover, #e5e5e5);
  border-bottom: 1px solid var(--cds-layer-hover, #e5e5e5);
  background-color: var(--cds-layer-hover, #e5e5e5); }

.bx--table-column-checkbox .bx--checkbox-label {
  padding-left: 0; }

.bx--data-table th.bx--table-column-checkbox {
  position: static;
  width: 2rem;
  background: var(--cds-layer-accent, #e0e0e0);
  transition: background-color 70ms cubic-bezier(0, 0, 0.38, 0.9); }

.bx--data-table .bx--table-column-checkbox .bx--checkbox:focus + .bx--checkbox-label::before {
  outline-offset: 0.375rem; }

.bx--data-table--compact .bx--table-column-checkbox .bx--checkbox:focus + .bx--checkbox-label::before,
.bx--data-table--xs .bx--table-column-checkbox .bx--checkbox:focus + .bx--checkbox-label::before {
  outline-offset: 0.125rem; }

.bx--data-table thead th.bx--table-column-checkbox,
.bx--data-table tbody td.bx--table-column-checkbox,
.bx--data-table thead th.bx--table-expand,
.bx--data-table tbody td.bx--table-expand {
  min-width: 0; }

.bx--data-table thead th.bx--table-column-checkbox,
.bx--data-table tbody td.bx--table-column-checkbox {
  padding-right: 0.25rem;
  padding-left: 1rem; }

.bx--data-table thead th.bx--table-expand,
.bx--data-table tbody td.bx--table-expand {
  width: 2rem;
  height: 2rem; }

.bx--data-table--compact thead th.bx--table-expand,
.bx--data-table--compact tbody td.bx--table-expand,
.bx--data-table--xs thead th.bx--table-expand,
.bx--data-table--xs tbody td.bx--table-expand {
  width: 1.5rem;
  height: 1.5rem;
  padding: 0 0 0 0.5rem; }

.bx--data-table--short thead th.bx--table-expand,
.bx--data-table--short tbody td.bx--table-expand,
.bx--data-table--sm thead th.bx--table-expand,
.bx--data-table--sm tbody td.bx--table-expand {
  width: 2rem;
  height: 2rem;
  padding: 0;
  padding-left: 0.5rem; }

.bx--data-table--md thead th.bx--table-expand,
.bx--data-table--md tbody td.bx--table-expand {
  width: 2.5rem;
  height: 2.5rem;
  padding: 0.25rem 0 0.25rem 0.5rem; }

.bx--data-table--tall thead th.bx--table-expand,
.bx--data-table--tall tbody td.bx--table-expand,
.bx--data-table--xl thead th.bx--table-expand,
.bx--data-table--xl tbody td.bx--table-expand {
  height: 4rem;
  padding-top: 0.625rem;
  padding-bottom: 1.375rem; }

.bx--data-table--tall .bx--table-column-checkbox,
.bx--data-table--xl .bx--table-column-checkbox {
  padding-top: 0.8125rem; }

.bx--data-table--tall .bx--table-column-radio,
.bx--data-table--xl .bx--table-column-radio {
  padding-top: var(--cds-spacing-05, 1rem); }

.bx--table-column-radio {
  width: 48px; }

.bx--table-column-radio .bx--radio-button__appearance {
  margin-right: -0.125rem; }

.bx--data-table--zebra tbody tr:nth-child(odd).bx--data-table--selected td,
tr.bx--data-table--selected td {
  border-top: 1px solid var(--cds-layer-selected, #e0e0e0);
  border-bottom: 1px solid var(--cds-layer-active, #c6c6c6);
  background-color: var(--cds-layer-selected, #e0e0e0);
  color: var(--cds-text-primary, #161616); }

.bx--data-table--zebra tbody tr:first-of-type:nth-child(odd).bx--data-table--selected td,
tr.bx--data-table--selected:first-of-type td {
  border-top: 1px solid var(--cds-layer-active, #c6c6c6); }

.bx--data-table--zebra tbody tr:last-of-type:nth-child(odd).bx--data-table--selected td,
.bx--data-table--zebra tbody tr:last-of-type:nth-child(even).bx--data-table--selected td,
tr.bx--data-table--selected:last-of-type td {
  border-top: 1px solid var(--cds-layer-selected, #e0e0e0);
  border-bottom: 1px solid var(--cds-layer-selected, #e0e0e0); }

.bx--data-table--zebra tbody tr:nth-child(even).bx--data-table--selected td {
  border-bottom: 1px solid var(--cds-layer-active, #c6c6c6); }

.bx--data-table--zebra tbody tr:nth-child(even).bx--data-table--selected:hover td {
  border-bottom: 1px solid #cacaca; }

.bx--data-table--zebra tbody tr:nth-child(odd).bx--data-table--selected:hover td,
.bx--data-table tbody .bx--data-table--selected:hover td {
  border-top: 1px solid #cacaca;
  border-bottom: 1px solid #cacaca;
  background: #cacaca;
  color: var(--cds-text-primary, #161616); }

.bx--data-table--selected .bx--overflow-menu .bx--overflow-menu__icon {
  opacity: 1; }

.bx--data-table--compact thead tr,
.bx--data-table--compact tbody tr,
.bx--data-table--compact tbody tr th {
  height: 1.5rem; }

.bx--data-table--compact .bx--table-header-label {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem; }

.bx--data-table--compact td,
.bx--data-table--compact tbody tr th {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem; }

.bx--data-table--compact .bx--overflow-menu {
  width: 2rem;
  height: 100%; }

.bx--data-table.bx--data-table--compact .bx--table-column-checkbox {
  padding-top: 0;
  padding-bottom: 0; }

.bx--data-table.bx--data-table--compact .bx--table-column-checkbox .bx--checkbox-label {
  height: 1.4375rem;
  min-height: 1.4375rem; }

.bx--data-table--xs thead tr,
.bx--data-table--xs tbody tr,
.bx--data-table--xs tbody tr th {
  height: 1.5rem; }

.bx--data-table--xs .bx--table-header-label {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem; }

.bx--data-table--xs td,
.bx--data-table--xs tbody tr th {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem; }

.bx--data-table--xs .bx--overflow-menu {
  width: 2rem;
  height: calc(100% + 1px); }

.bx--data-table.bx--data-table--xs .bx--table-column-checkbox {
  padding-top: 0;
  padding-bottom: 0; }

.bx--data-table.bx--data-table--xs .bx--table-column-checkbox .bx--checkbox-label {
  height: 1.4375rem;
  min-height: 1.4375rem; }

.bx--data-table--short thead tr,
.bx--data-table--short tbody tr,
.bx--data-table--short tbody tr th {
  height: 2rem; }

.bx--data-table--short .bx--table-header-label {
  padding-top: 0.4375rem;
  padding-bottom: 0.4375rem; }

.bx--data-table--short td,
.bx--data-table--short tbody tr th {
  padding-top: 0.4375rem;
  padding-bottom: 0.375rem; }

.bx--data-table.bx--data-table--short .bx--table-column-checkbox {
  padding-top: 0.1875rem;
  padding-bottom: 0.1875rem; }

.bx--data-table--short .bx--overflow-menu {
  height: 100%; }

.bx--data-table--sm thead tr,
.bx--data-table--sm tbody tr,
.bx--data-table--sm tbody tr th {
  height: 2rem; }

.bx--data-table--sm .bx--table-header-label {
  padding-top: 0.4375rem;
  padding-bottom: 0.4375rem; }

.bx--data-table--sm td,
.bx--data-table--sm tbody tr th {
  padding-top: 0.4375rem;
  padding-bottom: 0.375rem; }

.bx--data-table.bx--data-table--sm .bx--table-column-checkbox {
  padding-top: 0.1875rem;
  padding-bottom: 0.1875rem; }

.bx--data-table--sm .bx--overflow-menu {
  height: calc(100% + 1px); }

.bx--data-table--md thead tr,
.bx--data-table--md tbody tr,
.bx--data-table--md tbody tr th {
  height: 2.5rem; }

.bx--data-table--md .bx--table-header-label {
  padding-top: 0.4375rem;
  padding-bottom: 0.4375rem; }

.bx--data-table--md td,
.bx--data-table--md tbody tr th {
  padding-top: 0.4375rem;
  padding-bottom: 0.375rem; }

.bx--data-table.bx--data-table--md .bx--table-column-checkbox {
  padding-top: 0.1875rem;
  padding-bottom: 0.1875rem; }

.bx--data-table--md .bx--table-column-menu {
  padding-top: 0.1875rem;
  padding-bottom: 0.1875rem; }

.bx--data-table--tall thead tr,
.bx--data-table--tall tbody tr,
.bx--data-table--tall tbody tr th {
  height: 4rem; }

.bx--data-table--tall .bx--table-header-label {
  padding-top: var(--cds-spacing-05, 1rem);
  padding-bottom: var(--cds-spacing-05, 1rem); }

.bx--data-table--tall td,
.bx--data-table--tall tbody tr th {
  padding-top: 1rem; }

.bx--data-table--tall th,
.bx--data-table--tall td {
  vertical-align: top; }

.bx--data-table--tall .bx--data-table--cell-secondary-text {
  font-size: var(--cds-label-01-font-size, 0.75rem);
  font-weight: var(--cds-label-01-font-weight, 400);
  line-height: var(--cds-label-01-line-height, 1.33333);
  letter-spacing: var(--cds-label-01-letter-spacing, 0.32px); }

.bx--data-table--xl thead tr,
.bx--data-table--xl tbody tr,
.bx--data-table--xl tbody tr th {
  height: 4rem; }

.bx--data-table--xl .bx--table-header-label {
  padding-top: var(--cds-spacing-05, 1rem);
  padding-bottom: var(--cds-spacing-05, 1rem); }

.bx--data-table--xl td,
.bx--data-table--xl tbody tr th {
  padding-top: 1rem; }

.bx--data-table--xl th,
.bx--data-table--xl td {
  vertical-align: top; }

.bx--data-table--xl .bx--data-table--cell-secondary-text {
  font-size: var(--cds-label-01-font-size, 0.75rem);
  font-weight: var(--cds-label-01-font-weight, 400);
  line-height: var(--cds-label-01-line-height, 1.33333);
  letter-spacing: var(--cds-label-01-letter-spacing, 0.32px); }

.bx--data-table--static {
  width: auto; }

.bx--data-table-container--static {
  width: fit-content; }

.bx--data-table_inner-container {
  background-color: var(--cds-layer-accent, #e0e0e0);
  transform: translateZ(0); }

.bx--data-table--sticky-header {
  display: block;
  overflow-y: scroll;
  /* When using sticky header, with a selection element in the first column, we need to set the last item to a fixed width to match the table body. We only want this to happen when the last table header does not have any text */ }
  .bx--data-table--sticky-header thead,
  .bx--data-table--sticky-header tbody,
  .bx--data-table--sticky-header tr,
  .bx--data-table--sticky-header th,
  .bx--data-table--sticky-header td {
    display: flex; }
  .bx--data-table--sticky-header thead {
    position: sticky;
    z-index: 1;
    top: 0;
    overflow: scroll;
    width: 100%;
    -ms-overflow-style: none;
    will-change: transform; }
  .bx--data-table--sticky-header thead tr th {
    border-bottom: 1px solid var(--cds-layer-active, #c6c6c6); }
  .bx--data-table--sticky-header tbody {
    flex-direction: column;
    -ms-overflow-style: none;
    overflow-x: scroll;
    will-change: transform; }
  .bx--data-table--sticky-header tr.bx--parent-row.bx--expandable-row {
    height: auto;
    min-height: 3rem; }
  .bx--data-table--sticky-header tr.bx--expandable-row:not(.bx--parent-row) {
    height: auto; }
  .bx--data-table--sticky-header .bx--table-expand {
    max-width: 3rem; }
  .bx--data-table--sticky-header thead .bx--table-expand {
    align-items: center; }
  .bx--data-table--sticky-header .bx--parent-row {
    min-height: 3rem; }
  .bx--data-table--sticky-header:not(.bx--data-table--compact):not(.bx--data-table--xs):not(.bx--data-table--tall):not(.bx--data-table--xl):not(.bx--data-table--short):not(.bx--data-table--sm) td:not(.bx--table-column-menu):not(.bx--table-column-checkbox) {
    padding-top: 0.875rem; }
  .bx--data-table--sticky-header tr.bx--parent-row.bx--expandable-row:hover + tr[data-child-row] td {
    border-top: 1px solid var(--cds-layer-hover, #e5e5e5); }
  .bx--data-table--sticky-header tr.bx--expandable-row:last-of-type {
    overflow: hidden; }
  .bx--data-table--sticky-header tr.bx--data-table--selected:first-of-type td {
    border-top: none; }
  .bx--data-table--sticky-header thead th.bx--table-column-checkbox,
  .bx--data-table--sticky-header tbody tr td.bx--table-column-checkbox {
    width: 2.25rem;
    min-width: 2.25rem;
    align-items: center; }
  .bx--data-table--sticky-header.bx--data-table--tall thead th.bx--table-column-checkbox,
  .bx--data-table--sticky-header.bx--data-table--xl thead th.bx--table-column-checkbox,
  .bx--data-table--sticky-header.bx--data-table--tall td.bx--table-column-checkbox,
  .bx--data-table--sticky-header.bx--data-table--xl td.bx--table-column-checkbox {
    align-items: flex-start; }
  .bx--data-table--sticky-header th.bx--table-column-checkbox ~ th:last-of-type:empty {
    max-width: 4rem; }
  .bx--data-table--sticky-header th:empty:not(.bx--table-expand) {
    max-width: 2.25rem; }
  .bx--data-table--sticky-header td.bx--table-column-menu {
    height: auto;
    align-items: center;
    padding-top: 0; }
  .bx--data-table--sticky-header thead::-webkit-scrollbar,
  .bx--data-table--sticky-header tbody::-webkit-scrollbar {
    display: none; }

@-moz-document url-prefix() {
  .bx--data-table--sticky-header thead,
  .bx--data-table--sticky-header tbody {
    scrollbar-width: none; } }
  .bx--data-table--sticky-header tbody tr:last-of-type {
    border-bottom: 0; }
  .bx--data-table--sticky-header th:not(.bx--table-column-checkbox):not(.bx--table-column-menu):not(.bx--table-expand-v2):not(.bx--table-column-icon),
  .bx--data-table--sticky-header td:not(.bx--table-column-checkbox):not(.bx--table-column-menu):not(.bx--table-expand-v2):not(.bx--table-column-icon) {
    width: 100%;
    min-width: 0; }
  .bx--data-table--sticky-header.bx--data-table--compact tr:not(.bx--expandable-row),
  .bx--data-table--sticky-header.bx--data-table--xs tr:not(.bx--expandable-row),
  .bx--data-table--sticky-header.bx--data-table--short tr:not(.bx--expandable-row),
  .bx--data-table--sticky-header.bx--data-table--sm tr:not(.bx--expandable-row),
  .bx--data-table--sticky-header.bx--data-table--tall tr:not(.bx--expandable-row),
  .bx--data-table--sticky-header.bx--data-table--xl tr:not(.bx--expandable-row) {
    height: auto; }
  .bx--data-table--sticky-header.bx--data-table--compact tr:not(.bx--expandable-row),
  .bx--data-table--sticky-header.bx--data-table--xs tr:not(.bx--expandable-row) {
    min-height: 1.5rem; }
  .bx--data-table--sticky-header.bx--data-table--short tr:not(.bx--expandable-row),
  .bx--data-table--sticky-header.bx--data-table--sm tr:not(.bx--expandable-row) {
    min-height: 2rem; }
  .bx--data-table--sticky-header.bx--data-table--tall tr:not(.bx--expandable-row),
  .bx--data-table--sticky-header.bx--data-table--xl tr:not(.bx--expandable-row) {
    min-height: 4rem; }
  .bx--data-table--sticky-header.bx--data-table--compact tr td.bx--table-expand,
  .bx--data-table--sticky-header.bx--data-table--xs tr td.bx--table-expand {
    padding-top: 0.25rem; }
  .bx--data-table--sticky-header.bx--data-table--short tr td.bx--table-expand,
  .bx--data-table--sticky-header.bx--data-table--sm tr td.bx--table-expand {
    padding-top: 0.5rem; }
  .bx--data-table--sticky-header .bx--table-header-label {
    display: block;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: calc(100% - 10px);
    padding-top: 0.9375rem;
    padding-bottom: 1rem;
    overflow-y: hidden; }
  .bx--data-table--sticky-header.bx--data-table--compact th .bx--table-header-label,
  .bx--data-table--sticky-header.bx--data-table--xs th .bx--table-header-label {
    padding-top: 0.1875rem;
    padding-bottom: 0; }
  .bx--data-table--sticky-header.bx--data-table--short th .bx--table-header-label,
  .bx--data-table--sticky-header.bx--data-table--sm th .bx--table-header-label {
    padding-top: 0.5rem;
    padding-bottom: 0; }
  .bx--data-table--sticky-header.bx--data-table--tall th .bx--table-header-label,
  .bx--data-table--sticky-header.bx--data-table--xl th .bx--table-header-label {
    padding-top: 1rem; }
  .bx--data-table--sticky-header.bx--data-table--tall th.bx--table-expand,
  .bx--data-table--sticky-header.bx--data-table--xl th.bx--table-expand {
    display: flex;
    align-items: flex-start; }
  .bx--data-table--sticky-header.bx--data-table--compact tr.bx--parent-row .bx--table-column-checkbox,
  .bx--data-table--sticky-header.bx--data-table--short tr.bx--parent-row .bx--table-column-checkbox,
  .bx--data-table--sticky-header.bx--data-table--xs tr.bx--parent-row .bx--table-column-checkbox,
  .bx--data-table--sticky-header.bx--data-table--sm tr.bx--parent-row .bx--table-column-checkbox {
    align-items: flex-start; }

.bx--data-table--max-width {
  max-width: 100%; }

.bx--data-table--sticky-header {
  max-height: 18.75rem; }

.bx--data-table .bx--form-item.bx--checkbox-wrapper:last-of-type {
  margin: 0; }

.bx--data-table--short .bx--form-item.bx--checkbox-wrapper:last-of-type,
.bx--data-table--compact .bx--form-item.bx--checkbox-wrapper:last-of-type,
.bx--data-table--xs .bx--form-item.bx--checkbox-wrapper:last-of-type,
.bx--data-table--sm .bx--form-item.bx--checkbox-wrapper:last-of-type {
  margin: -0.1875rem 0; }

@media screen and (-ms-high-contrast: active), (forced-colors: active), (prefers-contrast) {
  .bx--data-table-content {
    outline: 1px solid transparent; } }

.bx--data-table tr.bx--parent-row:first-of-type td {
  border-top: 1px solid var(--cds-border-subtle, #e0e0e0); }

.bx--expandable-row--hidden td {
  width: auto;
  padding: var(--cds-spacing-05, 1rem);
  border-top: 0; }

tr.bx--parent-row:not(.bx--expandable-row) + tr[data-child-row] {
  height: 0;
  transition: height 150ms cubic-bezier(0.2, 0, 0.38, 0.9); }

tr.bx--parent-row:not(.bx--expandable-row) + tr[data-child-row] td {
  padding-top: 0;
  padding-bottom: 0;
  border: 0;
  background-color: var(--cds-layer-hover, #e5e5e5);
  transition: padding 150ms cubic-bezier(0.2, 0, 0.38, 0.9), background-color 150ms cubic-bezier(0.2, 0, 0.38, 0.9); }

tr.bx--parent-row:not(.bx--expandable-row) + tr[data-child-row] td .bx--child-row-inner-container {
  overflow: hidden;
  max-height: 0; }

tr.bx--parent-row.bx--expandable-row + tr[data-child-row] {
  transition: height 150ms cubic-bezier(0.2, 0, 0.38, 0.9); }

tr.bx--parent-row.bx--expandable-row + tr[data-child-row] td {
  padding-left: 4rem;
  border-bottom: 1px solid var(--cds-border-subtle, #e0e0e0);
  transition: padding-bottom 110ms cubic-bezier(0.2, 0, 0.38, 0.9), transform 110ms cubic-bezier(0.2, 0, 0.38, 0.9), background-color 110ms cubic-bezier(0.2, 0, 0.38, 0.9); }

tr.bx--parent-row.bx--expandable-row + tr[data-child-row] td .bx--child-row-inner-container {
  max-height: 100%; }

.bx--parent-row.bx--expandable-row > td,
.bx--parent-row.bx--expandable-row + tr[data-child-row] > td {
  border-bottom: 1px solid var(--cds-border-subtle, #e0e0e0);
  box-shadow: 0 1px var(--cds-border-subtle, #e0e0e0); }

.bx--parent-row:not(.bx--expandable-row) + tr[data-child-row] > td {
  box-shadow: none; }

.bx--parent-row.bx--expandable-row > td:first-of-type {
  box-shadow: none; }

tr.bx--parent-row:not(.bx--expandable-row) td,
tr.bx--parent-row.bx--expandable-row td,
tr.bx--parent-row.bx--expandable-row {
  transition: height 240ms cubic-bezier(0.2, 0, 0.38, 0.9), background-color 110ms cubic-bezier(0.2, 0, 0.38, 0.9); }

tr.bx--parent-row:not(.bx--expandable-row):first-of-type:hover td {
  border-top: 1px solid var(--cds-border-subtle, #e0e0e0);
  border-bottom: 1px solid var(--cds-border-subtle, #e0e0e0); }

tr.bx--parent-row.bx--expandable-row:hover td {
  border-top: 1px solid var(--cds-border-subtle, #e0e0e0);
  border-bottom: 1px solid var(--cds-border-subtle, #e0e0e0);
  background-color: var(--cds-layer-hover, #e5e5e5);
  color: var(--cds-text-primary, #161616); }

tr.bx--parent-row.bx--expandable-row:hover td:first-of-type {
  border-bottom: 1px solid var(--cds-layer-hover, #e5e5e5); }

tr.bx--parent-row.bx--expandable-row:hover + tr[data-child-row] td {
  border-bottom: 1px solid var(--cds-border-subtle, #e0e0e0);
  background-color: var(--cds-layer-hover, #e5e5e5);
  color: var(--cds-text-primary, #161616); }

tr.bx--expandable-row--hover + tr[data-child-row] td {
  border-bottom: 1px solid var(--cds-border-subtle, #e0e0e0); }

tr.bx--expandable-row--hover {
  background-color: var(--cds-layer-hover, #e5e5e5); }

tr.bx--expandable-row--hover td {
  border-top: 1px solid var(--cds-border-subtle, #e0e0e0);
  border-bottom: 1px solid var(--cds-border-subtle, #e0e0e0);
  background-color: var(--cds-layer-hover, #e5e5e5);
  color: var(--cds-text-primary, #161616); }

tr.bx--parent-row.bx--expandable-row.bx--expandable-row--hover td:first-of-type {
  border-bottom: 1px solid transparent; }

.bx--data-table td.bx--table-expand {
  border-bottom: 1px solid var(--cds-border-subtle, #e0e0e0); }

.bx--data-table th.bx--table-expand + .bx--table-column-checkbox,
.bx--data-table td.bx--table-expand + .bx--table-column-checkbox {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.bx--data-table th.bx--table-expand + .bx--table-column-checkbox + th,
.bx--data-table td.bx--table-expand + .bx--table-column-checkbox + td {
  padding-left: 0.5rem; }

.bx--data-table td.bx--table-expand,
.bx--data-table th.bx--table-expand {
  padding: 0.5rem;
  padding-right: 0; }

.bx--data-table td.bx--table-expand[data-previous-value='collapsed'] {
  border-bottom: 1px solid transparent; }

.bx--table-expand[data-previous-value='collapsed'] .bx--table-expand__svg {
  transform: rotate(270deg);
  transition: transform 150ms cubic-bezier(0.2, 0, 0.38, 0.9); }

.bx--table-expand__button {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  display: inline-block;
  padding: 0;
  border: 0;
  appearance: none;
  background: none;
  cursor: pointer;
  display: inline-flex;
  width: 100%;
  height: calc(100% + 1px);
  align-items: center;
  justify-content: center;
  padding: 0 0.5rem;
  vertical-align: inherit; }
  .bx--table-expand__button *,
  .bx--table-expand__button *::before,
  .bx--table-expand__button *::after {
    box-sizing: inherit; }
  .bx--table-expand__button::-moz-focus-inner {
    border: 0; }

.bx--table-expand__button:focus {
  box-shadow: inset 0 0 0 2px var(--cds-focus, #0f62fe);
  outline: none; }

.bx--table-expand__svg {
  fill: var(--cds-ui-05, #161616);
  transform: rotate(90deg);
  transition: transform 150ms cubic-bezier(0.2, 0, 0.38, 0.9); }

.bx--data-table--tall .bx--table-expand__button,
.bx--data-table--xl .bx--table-expand__button {
  width: 2rem;
  padding: 0; }

tr.bx--parent-row.bx--expandable-row td.bx--table-expand + td::after {
  position: absolute;
  bottom: -0.0625rem;
  left: 0;
  width: 0.5rem;
  height: 0.0625rem;
  background: var(--cds-layer-accent, #e0e0e0);
  content: ''; }

tr.bx--parent-row.bx--expandable-row:hover td.bx--table-expand + td::after,
tr.bx--parent-row.bx--expandable-row.bx--expandable-row--hover td.bx--table-expand + td::after {
  background: var(--cds-layer-hover, #e5e5e5); }

tr.bx--parent-row.bx--data-table--selected td.bx--table-expand + td::after {
  display: none; }

.bx--data-table--zebra tbody tr[data-parent-row]:nth-child(4n + 3) td,
.bx--data-table--zebra tbody tr[data-child-row]:nth-child(4n + 4) td {
  border-bottom: 1px solid var(--cds-layer, #f4f4f4); }

.bx--data-table--zebra tbody tr[data-parent-row]:nth-child(4n + 1) td,
.bx--data-table--zebra tbody tr[data-child-row]:nth-child(4n + 2) td {
  border-top: 1px solid #ffffff;
  border-bottom: 1px solid #ffffff;
  background-color: #ffffff; }

.bx--data-table--zebra tr.bx--parent-row td,
.bx--data-table--zebra tr.bx--parent-row.bx--expandable-row + tr[data-child-row] td {
  transition: transform 150ms cubic-bezier(0.2, 0, 0.38, 0.9), border-bottom 150ms cubic-bezier(0.2, 0, 0.38, 0.9), border-top 150ms cubic-bezier(0.2, 0, 0.38, 0.9); }

.bx--data-table--zebra tbody tr[data-parent-row]:hover td,
.bx--data-table--zebra tbody tr[data-parent-row]:hover + tr[data-child-row] td,
.bx--data-table--zebra tbody tr[data-child-row]:hover td {
  border-top: 1px solid var(--cds-layer-hover, #e5e5e5);
  border-bottom: 1px solid var(--cds-layer-hover, #e5e5e5);
  background-color: var(--cds-layer-hover, #e5e5e5); }

.bx--data-table--zebra tr.bx--parent-row.bx--expandable-row.bx--expandable-row--hover td {
  border-top: 1px solid var(--cds-layer-hover, #e5e5e5);
  border-bottom: 1px solid var(--cds-layer-hover, #e5e5e5);
  background: var(--cds-layer-hover, #e5e5e5); }

tr.bx--parent-row.bx--data-table--selected:first-of-type td {
  border-top: 1px solid var(--cds-layer-active, #c6c6c6);
  border-bottom: 1px solid var(--cds-border-subtle, #e0e0e0);
  background: var(--cds-layer-selected, #e0e0e0);
  box-shadow: 0 1px var(--cds-layer-active, #c6c6c6); }

tr.bx--parent-row.bx--data-table--selected td {
  border-bottom: 1px solid transparent;
  background: var(--cds-layer-selected, #e0e0e0);
  box-shadow: 0 1px var(--cds-layer-active, #c6c6c6);
  color: var(--cds-text-primary, #161616); }

tr.bx--parent-row.bx--data-table--selected:last-of-type td {
  border-bottom: 1px solid transparent;
  background: var(--cds-layer-selected, #e0e0e0);
  box-shadow: 0 1px var(--cds-border-subtle, #e0e0e0); }

tr.bx--parent-row.bx--data-table--selected:not(.bx--expandable-row):hover td {
  border-top: 1px solid var(--cds-layer-selected-hover, #cacaca);
  border-bottom: 1px solid var(--cds-border-subtle, #e0e0e0);
  background: var(--cds-layer-selected-hover, #cacaca);
  box-shadow: 0 1px var(--cds-layer-selected-hover, #cacaca); }

tr.bx--parent-row.bx--data-table--selected.bx--expandable-row td,
tr.bx--parent-row.bx--data-table--selected.bx--expandable-row td:first-of-type {
  border-bottom: 1px solid transparent;
  box-shadow: 0 1px var(--cds-layer-selected, #e0e0e0); }

tr.bx--parent-row.bx--data-table--selected.bx--expandable-row:hover td,
tr.bx--parent-row.bx--data-table--selected.bx--expandable-row:hover td:first-of-type,
tr.bx--parent-row.bx--data-table--selected.bx--expandable-row--hover td,
tr.bx--parent-row.bx--data-table--selected.bx--expandable-row--hover td:first-of-type {
  border-top: 1px solid var(--cds-layer-selected-hover, #cacaca);
  border-bottom: 1px solid transparent;
  background: var(--cds-layer-selected-hover, #cacaca);
  box-shadow: 0 1px var(--cds-layer-selected-hover, #cacaca); }

tr.bx--parent-row.bx--data-table--selected.bx--expandable-row + tr[data-child-row] td {
  border-top: 1px solid var(--cds-layer-active, #c6c6c6);
  border-bottom: 1px solid var(--cds-border-subtle, #e0e0e0);
  background-color: var(--cds-layer-hover, #e5e5e5);
  box-shadow: 0 1px var(--cds-layer-active, #c6c6c6);
  color: var(--cds-text-primary, #161616); }

tr.bx--parent-row.bx--data-table--selected.bx--expandable-row + tr[data-child-row]:last-of-type td {
  padding-bottom: 1.5rem;
  box-shadow: inset 0 -1px var(--cds-layer-active, #c6c6c6); }

tr.bx--parent-row.bx--data-table--selected.bx--expandable-row:hover + tr[data-child-row] td,
tr.bx--parent-row.bx--data-table--selected.bx--expandable-row--hover + tr[data-child-row] td {
  background: var(--cds-layer-selected, #e0e0e0); }

@media screen and (-ms-high-contrast: active), (forced-colors: active), (prefers-contrast) {
  .bx--table-expand__button:focus .bx--table-expand__svg {
    color: Highlight;
    outline: 1px solid Highlight; } }

@media screen and (-ms-high-contrast: active), (forced-colors: active), (prefers-contrast) {
  .bx--table-expand__svg {
    fill: ButtonText; } }

.bx--data-table--sort th,
.bx--data-table th[aria-sort] {
  height: var(--cds-spacing-09, 3rem);
  padding: 0;
  border-top: none;
  border-bottom: none; }

.bx--table-sort {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  display: inline-block;
  padding: 0;
  border: 0;
  appearance: none;
  background: none;
  cursor: pointer;
  display: flex;
  width: 100%;
  min-height: 100%;
  align-items: center;
  justify-content: space-between;
  padding-left: var(--cds-spacing-05, 1rem);
  background-color: var(--cds-layer-accent, #e0e0e0);
  color: var(--cds-text-primary, #161616);
  font: inherit;
  line-height: 1;
  text-align: left;
  transition: background-color 70ms cubic-bezier(0, 0, 0.38, 0.9), outline 70ms cubic-bezier(0, 0, 0.38, 0.9); }
  .bx--table-sort *,
  .bx--table-sort *::before,
  .bx--table-sort *::after {
    box-sizing: inherit; }
  .bx--table-sort::-moz-focus-inner {
    border: 0; }

.bx--table-sort:focus {
  outline: 2px solid var(--cds-focus, #0f62fe);
  outline-offset: -2px; }
  @media screen and (prefers-contrast) {
    .bx--table-sort:focus {
      outline-style: dotted; } }

.bx--table-sort:hover {
  background: #cacaca; }

.bx--table-sort:focus svg,
.bx--table-sort:hover svg {
  opacity: 1; }

.bx--data-table.bx--data-table--sort th > .bx--table-header-label {
  padding-right: var(--cds-spacing-05, 1rem);
  padding-left: var(--cds-spacing-05, 1rem); }

th .bx--table-sort__flex {
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 3rem;
  align-items: center;
  justify-content: space-between; }

.bx--data-table--sort:not(.bx--data-table--compact):not(.bx--data-table--short):not(.bx--data-table--tall):not(.bx--data-table--xs):not(.bx--data-table--sm):not(.bx--data-table--md):not(.bx--data-table--xl) th .bx--table-sort__flex {
  /* IE11 workaround for align-items: center and min-height
        https://github.com/philipwalton/flexbugs/issues/231 */ }
  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    .bx--data-table--sort:not(.bx--data-table--compact):not(.bx--data-table--short):not(.bx--data-table--tall):not(.bx--data-table--xs):not(.bx--data-table--sm):not(.bx--data-table--md):not(.bx--data-table--xl) th .bx--table-sort__flex {
      height: 2.99rem; } }

.bx--data-table--compact.bx--data-table--sort th .bx--table-sort__flex,
.bx--data-table--xs.bx--data-table--sort th .bx--table-sort__flex {
  min-height: 1.5rem; }

.bx--data-table--short.bx--data-table--sort th .bx--table-sort__flex,
.bx--data-table--sm.bx--data-table--sort th .bx--table-sort__flex {
  min-height: 2rem; }

.bx--data-table--md.bx--data-table--sort th .bx--table-sort__flex {
  min-height: 2.5rem; }

.bx--data-table--tall.bx--data-table--sort th .bx--table-sort__flex,
.bx--data-table--xl.bx--data-table--sort th .bx--table-sort__flex {
  min-height: 4rem;
  align-items: flex-start; }

.bx--table-sort .bx--table-sort__icon-inactive {
  display: block; }

.bx--table-sort .bx--table-sort__icon {
  display: none; }

.bx--table-sort__icon-unsorted {
  width: 1.25rem;
  min-width: var(--cds-spacing-05, 1rem);
  margin-right: var(--cds-spacing-03, 0.5rem);
  margin-left: var(--cds-spacing-03, 0.5rem);
  fill: var(--cds-icon-primary, #161616);
  opacity: 0; }

.bx--table-sort.bx--table-sort--active {
  background: #cacaca; }

.bx--table-sort.bx--table-sort--active .bx--table-sort__icon-unsorted {
  display: none; }

.bx--table-sort.bx--table-sort--active .bx--table-sort__icon {
  display: block;
  opacity: 1; }

.bx--table-sort--ascending .bx--table-sort__icon {
  transform: rotate(180deg); }

.bx--table-sort__icon {
  width: 1.25rem;
  min-width: var(--cds-spacing-05, 1rem);
  margin-right: var(--cds-spacing-03, 0.5rem);
  margin-left: var(--cds-spacing-03, 0.5rem);
  fill: var(--cds-icon-primary, #161616);
  opacity: 1;
  transform: rotate(0);
  transition: transform 110ms cubic-bezier(0.2, 0, 0.38, 0.9); }

.bx--data-table--compact.bx--data-table--sort th,
.bx--data-table--xs.bx--data-table--sort th {
  height: 1.5rem; }

.bx--data-table--short.bx--data-table--sort th,
.bx--data-table--sm.bx--data-table--sort th {
  height: 2rem; }

.bx--data-table--md.bx--data-table--sort th {
  height: 2.5rem; }

.bx--data-table--tall.bx--data-table--sort th,
.bx--data-table--xl.bx--data-table--sort th {
  height: 4rem; }

.bx--data-table--tall.bx--data-table--sort th .bx--table-sort,
.bx--data-table--xl.bx--data-table--sort th .bx--table-sort {
  display: inline-block;
  height: 4rem; }

.bx--data-table--tall .bx--table-sort__icon-unsorted,
.bx--data-table--tall .bx--table-sort__icon,
.bx--data-table--xl .bx--table-sort__icon-unsorted,
.bx--data-table--xl .bx--table-sort__icon {
  margin-top: 0.8125rem; }

@media screen and (-ms-high-contrast: active), (forced-colors: active), (prefers-contrast) {
  .bx--table-sort__icon,
  .bx--table-sort__icon-unsorted {
    fill: ButtonText; } }

.bx--inline-edit-label {
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .bx--inline-edit-label:hover .bx--inline-edit-label__icon {
    opacity: 1; }

.bx--inline-edit-label--inactive {
  display: none; }

.bx--inline-edit-label__action {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  display: inline-block;
  padding: 0;
  border: 0;
  appearance: none;
  background: none;
  cursor: pointer; }
  .bx--inline-edit-label__action *,
  .bx--inline-edit-label__action *::before,
  .bx--inline-edit-label__action *::after {
    box-sizing: inherit; }
  .bx--inline-edit-label__action::-moz-focus-inner {
    border: 0; }
  .bx--inline-edit-label__action:hover {
    cursor: pointer; }
  .bx--inline-edit-label__action:focus {
    outline: 1px solid var(--cds-focus, #0f62fe);
    padding: var(--cds-spacing-01, 0.125rem); }
    @media screen and (prefers-contrast) {
      .bx--inline-edit-label__action:focus {
        outline-style: dotted; } }
    .bx--inline-edit-label__action:focus .bx--inline-edit-label__icon {
      width: auto;
      opacity: 1; }

.bx--inline-edit-label__icon {
  fill: var(--cds-icon-primary, #161616);
  opacity: 0; }

.bx--inline-edit-input {
  display: none; }

.bx--inline-edit-input--active {
  display: block;
  margin-left: -0.75rem; }
  .bx--inline-edit-input--active input {
    padding-left: var(--cds-spacing-04, 0.75rem); }

.bx--data-table.bx--skeleton th {
  padding-left: 1rem;
  vertical-align: middle; }

.bx--data-table.bx--skeleton th span,
.bx--data-table.bx--skeleton td span {
  position: relative;
  padding: 0;
  border: none;
  background: var(--cds-skeleton-01, #e5e5e5);
  box-shadow: none;
  pointer-events: none;
  display: block;
  width: 4rem;
  height: 1rem; }
  .bx--data-table.bx--skeleton th span:hover, .bx--data-table.bx--skeleton th span:focus, .bx--data-table.bx--skeleton th span:active,
  .bx--data-table.bx--skeleton td span:hover,
  .bx--data-table.bx--skeleton td span:focus,
  .bx--data-table.bx--skeleton td span:active {
    border: none;
    cursor: default;
    outline: none; }
  .bx--data-table.bx--skeleton th span::before,
  .bx--data-table.bx--skeleton td span::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    animation: 3000ms ease-in-out skeleton infinite;
    background: var(--cds-skeleton-02, #c6c6c6);
    content: '';
    will-change: transform-origin, transform, opacity; }
    @media (prefers-reduced-motion: reduce) {
      .bx--data-table.bx--skeleton th span::before,
      .bx--data-table.bx--skeleton td span::before {
        animation: none; } }

.bx--data-table.bx--skeleton tr:hover td {
  border-color: var(--cds-border-subtle, #e0e0e0);
  background: transparent; }
  .bx--data-table.bx--skeleton tr:hover td:first-of-type, .bx--data-table.bx--skeleton tr:hover td:last-of-type {
    border-color: var(--cds-border-subtle, #e0e0e0); }

.bx--data-table.bx--skeleton .bx--table-sort-v2 {
  pointer-events: none; }

.bx--data-table.bx--skeleton th span {
  background: var(--cds-skeleton-element, #c6c6c6); }

.bx--data-table.bx--skeleton th span::before {
  background: var(--cds-skeleton-background, #e5e5e5); }

.bx--data-table-container.bx--skeleton .bx--data-table-header__title {
  position: relative;
  padding: 0;
  border: none;
  background: var(--cds-skeleton-01, #e5e5e5);
  box-shadow: none;
  pointer-events: none;
  width: 7.5rem;
  height: 1.5rem; }
  .bx--data-table-container.bx--skeleton .bx--data-table-header__title:hover, .bx--data-table-container.bx--skeleton .bx--data-table-header__title:focus, .bx--data-table-container.bx--skeleton .bx--data-table-header__title:active {
    border: none;
    cursor: default;
    outline: none; }
  .bx--data-table-container.bx--skeleton .bx--data-table-header__title::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    animation: 3000ms ease-in-out skeleton infinite;
    background: var(--cds-skeleton-02, #c6c6c6);
    content: '';
    will-change: transform-origin, transform, opacity; }
    @media (prefers-reduced-motion: reduce) {
      .bx--data-table-container.bx--skeleton .bx--data-table-header__title::before {
        animation: none; } }

.bx--data-table-container.bx--skeleton .bx--data-table-header__description {
  position: relative;
  padding: 0;
  border: none;
  background: var(--cds-skeleton-01, #e5e5e5);
  box-shadow: none;
  pointer-events: none;
  width: 10rem;
  height: 1rem;
  margin-top: var(--cds-spacing-03, 0.5rem); }
  .bx--data-table-container.bx--skeleton .bx--data-table-header__description:hover, .bx--data-table-container.bx--skeleton .bx--data-table-header__description:focus, .bx--data-table-container.bx--skeleton .bx--data-table-header__description:active {
    border: none;
    cursor: default;
    outline: none; }
  .bx--data-table-container.bx--skeleton .bx--data-table-header__description::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    animation: 3000ms ease-in-out skeleton infinite;
    background: var(--cds-skeleton-02, #c6c6c6);
    content: '';
    will-change: transform-origin, transform, opacity; }
    @media (prefers-reduced-motion: reduce) {
      .bx--data-table-container.bx--skeleton .bx--data-table-header__description::before {
        animation: none; } }

.security--data-table__table-wrapper {
  z-index: 0; }

.security--data-table__overflow-cell .bx--overflow-menu {
  z-index: 6000; }

.security--data-table .bx--data-table {
  background-color: var(--cds-ui-03, #e0e0e0); }
  .security--data-table .bx--data-table-content {
    overflow: unset; }
  .security--data-table .bx--data-table.bx--skeleton th {
    border-bottom-width: 0; }

@keyframes fpFadeInDown {
  from {
    transform: translate3d(0, -20px, 0);
    opacity: 0; }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1; } }

@keyframes fpSlideLeft {
  from {
    transform: translate3d(0, 0, 0); }
  to {
    transform: translate3d(-100%, 0, 0); } }

@keyframes fpSlideLeftNew {
  from {
    transform: translate3d(100%, 0, 0); }
  to {
    transform: translate3d(0, 0, 0); } }

@keyframes fpSlideRight {
  from {
    transform: translate3d(0, 0, 0); }
  to {
    transform: translate3d(100%, 0, 0); } }

@keyframes fpSlideRightNew {
  from {
    transform: translate3d(-100%, 0, 0); }
  to {
    transform: translate3d(0, 0, 0); } }

@keyframes fpFadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes fpFadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.flatpickr-calendar {
  position: absolute;
  box-sizing: border-box;
  width: 19.6875rem;
  max-height: 0;
  padding: 0;
  overflow: hidden;
  direction: ltr;
  text-align: center;
  border: 0;
  border-radius: 0;
  visibility: hidden;
  opacity: 0;
  animation: none;
  touch-action: manipulation; }

.flatpickr-calendar.open,
.flatpickr-calendar.inline {
  max-height: 40rem;
  overflow: visible;
  visibility: inherit;
  opacity: 1; }

.flatpickr-calendar.open {
  box-shadow: 0 2px 6px var(--cds-shadow, rgba(0, 0, 0, 0.3));
  z-index: 99999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 18rem;
  height: 21rem;
  margin-top: -0.125rem;
  padding: 0.25rem 0.25rem 0.5rem 0.25rem;
  overflow: hidden;
  background-color: var(--cds-ui-01, #f4f4f4);
  border: none; }
  .flatpickr-calendar.open:focus {
    outline: 1px solid var(--cds-focus, #0f62fe); }
    @media screen and (prefers-contrast) {
      .flatpickr-calendar.open:focus {
        outline-style: dotted; } }

.flatpickr-calendar.animate.open {
  animation: fpFadeInDown 110ms cubic-bezier(0, 0, 0.38, 0.9); }

.flatpickr-calendar.inline {
  position: relative;
  top: 0.125rem;
  display: block; }

.flatpickr-calendar.static {
  position: absolute;
  top: calc(100% + 2px); }

.flatpickr-calendar.static.open {
  z-index: 999;
  display: block; }

.flatpickr-calendar.hasWeeks {
  width: auto; }

.dayContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  height: 15.375rem;
  padding: 0;
  outline: 0; }

.flatpickr-calendar .hasWeeks .dayContainer,
.flatpickr-calendar .hasTime .dayContainer {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

.flatpickr-calendar .hasWeeks .dayContainer {
  border-left: 0; }

.flatpickr-calendar.showTimeInput.hasTime .flatpickr-time {
  height: 2.5rem;
  border-top: 1px solid #e6e6e6; }

.flatpickr-calendar.noCalendar.hasTime .flatpickr-time {
  height: auto; }

.flatpickr-calendar:focus {
  outline: 0; }

.flatpickr-months {
  display: flex;
  justify-content: space-between;
  width: 100%; }

.flatpickr-month {
  font-size: var(--cds-productive-heading-01-font-size, 0.875rem);
  font-weight: var(--cds-productive-heading-01-font-weight, 600);
  line-height: var(--cds-productive-heading-01-line-height, 1.28572);
  letter-spacing: var(--cds-productive-heading-01-letter-spacing, 0.16px);
  display: flex;
  align-items: center;
  height: 2.5rem;
  color: var(--cds-text-01, #161616);
  line-height: 1;
  text-align: center;
  background-color: transparent; }

.flatpickr-prev-month,
.flatpickr-next-month {
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  padding: 0;
  line-height: 16px;
  text-decoration: none;
  transform: scale(1, 1) /*rtl: scale(-1,1)*/;
  cursor: pointer;
  transition: background-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
  fill: var(--cds-icon-01, #161616); }
  .flatpickr-prev-month:hover,
  .flatpickr-next-month:hover {
    background-color: var(--cds-hover-ui, #e5e5e5); }

.flatpickr-next-month.disabled svg,
.flatpickr-prev-month.disabled svg {
  cursor: not-allowed;
  fill: var(--cds-ui-05, #161616); }

.flatpickr-next-month.disabled:hover svg,
.flatpickr-prev-month.disabled:hover svg {
  fill: var(--cds-ui-05, #161616); }

.flatpickr-current-month {
  font-size: var(--cds-productive-heading-01-font-size, 0.875rem);
  font-weight: var(--cds-productive-heading-01-font-weight, 600);
  line-height: var(--cds-productive-heading-01-line-height, 1.28572);
  letter-spacing: var(--cds-productive-heading-01-letter-spacing, 0.16px);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.75rem;
  text-align: center; }

.flatpickr-current-month .cur-month {
  margin-right: 0.25rem;
  margin-left: 0.25rem; }
  .flatpickr-current-month .cur-month:hover {
    background-color: var(--cds-hover-ui, #e5e5e5); }

.numInputWrapper {
  position: relative;
  width: 3.75rem; }
  .numInputWrapper:hover {
    background-color: var(--cds-hover-ui, #e5e5e5); }

.numInputWrapper .numInput {
  display: inline-block;
  width: 100%;
  margin: 0;
  padding: 0.25rem;
  color: var(--cds-text-01, #161616);
  font-weight: 600;
  font-size: inherit;
  font-family: inherit;
  background-color: var(--cds-field-01, #f4f4f4);
  border: none;
  cursor: default;
  -moz-appearance: textfield;
  /* Firefox */ }
  .numInputWrapper .numInput::-webkit-outer-spin-button, .numInputWrapper .numInput::-webkit-inner-spin-button {
    margin: 0;
    /* <-- Apparently some margin are still there even though it's hidden */
    -webkit-appearance: none; }
  .numInputWrapper .numInput:focus {
    outline: 1px solid var(--cds-focus, #0f62fe); }
    @media screen and (prefers-contrast) {
      .numInputWrapper .numInput:focus {
        outline-style: dotted; } }
  .numInputWrapper .numInput[disabled], .numInputWrapper .numInput[disabled]:hover {
    color: var(--cds-disabled-02, #c6c6c6);
    background-color: var(--cds-ui-01, #f4f4f4);
    pointer-events: none; }

.numInputWrapper .arrowUp {
  top: 0.25rem;
  border-bottom: 0; }
  .numInputWrapper .arrowUp::after {
    border-bottom: 0.25rem solid var(--cds-icon-01, #161616); }

.numInputWrapper .arrowDown {
  top: 0.6875rem; }
  .numInputWrapper .arrowDown::after {
    border-top: 0.25rem solid var(--cds-icon-01, #161616); }

.numInputWrapper .arrowUp,
.numInputWrapper .arrowDown {
  position: absolute;
  left: 2.6rem;
  width: 0.75rem;
  height: 50%;
  padding: 0 0.25rem 0 0.125rem;
  line-height: 50%;
  border: none;
  cursor: pointer;
  opacity: 0; }
  .numInputWrapper .arrowUp::after,
  .numInputWrapper .arrowDown::after {
    position: absolute;
    top: 33%;
    display: block;
    border-right: 0.25rem solid transparent;
    border-left: 0.25rem solid transparent;
    content: ''; }
  .numInputWrapper .arrowUp:hover::after,
  .numInputWrapper .arrowDown:hover::after {
    border-top-color: var(--cds-interactive-01, #0f62fe);
    border-bottom-color: var(--cds-interactive-01, #0f62fe); }
  .numInputWrapper .arrowUp:active::after,
  .numInputWrapper .arrowDown:active::after {
    border-top-color: var(--cds-interactive-04, #0f62fe);
    border-bottom-color: var(--cds-interactive-04, #0f62fe); }

.numInput[disabled] ~ .arrowUp::after {
  border-bottom-color: var(--cds-disabled-02, #c6c6c6); }

.numInput[disabled] ~ .arrowDown::after {
  border-top-color: var(--cds-disabled-02, #c6c6c6); }

.numInputWrapper:hover .arrowUp,
.numInputWrapper:hover .arrowDown {
  opacity: 1; }

.numInputWrapper:hover .numInput[disabled] ~ .arrowUp,
.numInputWrapper:hover .numInput[disabled] ~ .arrowDown {
  opacity: 0; }

.flatpickr-weekdays {
  display: flex;
  align-items: center;
  height: 2.5rem; }

.flatpickr-weekdaycontainer {
  display: flex;
  width: 100%; }

.flatpickr-weekday {
  font-size: var(--cds-body-short-01-font-size, 0.875rem);
  font-weight: var(--cds-body-short-01-font-weight, 400);
  line-height: var(--cds-body-short-01-line-height, 1.28572);
  letter-spacing: var(--cds-body-short-01-letter-spacing, 0.16px);
  flex: 1;
  color: var(--cds-text-01, #161616);
  cursor: default; }

.flatpickr-days:focus {
  outline: 0; }

.flatpickr-calendar.animate .dayContainer.slideLeft {
  animation: fpFadeOut 400ms cubic-bezier(0.23, 1, 0.32, 1), fpSlideLeft 400ms cubic-bezier(0.23, 1, 0.32, 1); }

.flatpickr-calendar.animate .dayContainer.slideLeft,
.flatpickr-calendar.animate .dayContainer.slideLeftNew {
  transform: translate3d(-100%, 0, 0); }

.flatpickr-calendar.animate .dayContainer.slideLeftNew {
  animation: fpFadeIn 400ms cubic-bezier(0.23, 1, 0.32, 1), fpSlideLeft 400ms cubic-bezier(0.23, 1, 0.32, 1); }

.flatpickr-calendar.animate .dayContainer.slideRight {
  transform: translate3d(100%, 0, 0);
  animation: fpFadeOut 400ms cubic-bezier(0.23, 1, 0.32, 1), fpSlideRight 400ms cubic-bezier(0.23, 1, 0.32, 1); }

.flatpickr-calendar.animate .dayContainer.slideRightNew {
  animation: fpFadeIn 400ms cubic-bezier(0.23, 1, 0.32, 1), fpSlideRightNew 400ms cubic-bezier(0.23, 1, 0.32, 1); }

.flatpickr-day {
  font-size: var(--cds-body-short-01-font-size, 0.875rem);
  font-weight: var(--cds-body-short-01-font-weight, 400);
  line-height: var(--cds-body-short-01-line-height, 1.28572);
  letter-spacing: var(--cds-body-short-01-letter-spacing, 0.16px);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  color: var(--cds-text-01, #161616);
  cursor: pointer;
  transition: all 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .flatpickr-day:hover {
    background: var(--cds-hover-ui, #e5e5e5); }
  .flatpickr-day:focus {
    outline: 2px solid var(--cds-focus, #0f62fe);
    outline-offset: -2px;
    outline-color: var(--cds-interactive-01, #0f62fe); }
    @media screen and (prefers-contrast) {
      .flatpickr-day:focus {
        outline-style: dotted; } }

.nextMonthDay,
.prevMonthDay {
  color: var(--cds-text-05, #6f6f6f); }

.flatpickr-day.today {
  position: relative;
  color: var(--cds-link-01, #0f62fe);
  font-weight: 600; }
  .flatpickr-day.today::after {
    position: absolute;
    bottom: 0.4375rem;
    left: 50%;
    display: block;
    width: 0.25rem;
    height: 0.25rem;
    background-color: var(--cds-link-01, #0f62fe);
    transform: translateX(-50%);
    content: ''; }

.flatpickr-day.today.no-border {
  border: none; }

.flatpickr-day.today.selected {
  outline: 2px solid var(--cds-focus, #0f62fe);
  outline-offset: -2px; }
  @media screen and (prefers-contrast) {
    .flatpickr-day.today.selected {
      outline-style: dotted; } }
  .flatpickr-day.today.selected::after {
    display: none; }

.flatpickr-day.inRange {
  color: var(--cds-text-01, #161616);
  background-color: var(--cds-highlight, #d0e2ff); }

.flatpickr-day.selected {
  color: var(--cds-text-04, #ffffff);
  background-color: var(--cds-interactive-01, #0f62fe); }
  .flatpickr-day.selected:focus {
    outline: 0.0625rem solid var(--cds-ui-02, #ffffff);
    outline-offset: -0.1875rem; }

.flatpickr-day.startRange.selected {
  z-index: 2;
  box-shadow: none; }

.flatpickr-day.startRange.inRange:not(.selected),
.flatpickr-day.endRange.inRange {
  outline: 2px solid var(--cds-focus, #0f62fe);
  outline-offset: -2px;
  z-index: 3;
  background: var(--cds-ui-01, #f4f4f4); }
  @media screen and (prefers-contrast) {
    .flatpickr-day.startRange.inRange:not(.selected),
    .flatpickr-day.endRange.inRange {
      outline-style: dotted; } }

.flatpickr-day.endRange:hover {
  outline: 2px solid var(--cds-focus, #0f62fe);
  outline-offset: -2px;
  color: var(--cds-text-01, #161616);
  background: var(--cds-ui-01, #f4f4f4); }
  @media screen and (prefers-contrast) {
    .flatpickr-day.endRange:hover {
      outline-style: dotted; } }

.flatpickr-day.endRange.inRange.selected {
  color: var(--cds-text-04, #ffffff);
  background: var(--cds-interactive-01, #0f62fe); }

.flatpickr-day.flatpickr-disabled {
  color: var(--cds-disabled-02, #c6c6c6);
  cursor: not-allowed; }
  .flatpickr-day.flatpickr-disabled:hover {
    background-color: transparent; }

.flatpickr-input[readonly] {
  cursor: pointer; }

@media screen and (-ms-high-contrast: active), (forced-colors: active), (prefers-contrast) {
  .flatpickr-prev-month,
  .flatpickr-next-month {
    fill: ButtonText; } }

@media screen and (-ms-high-contrast: active), (forced-colors: active), (prefers-contrast) {
  .flatpickr-day.selected {
    color: Highlight;
    outline: 1px solid Highlight;
    outline-style: dotted; } }

@media screen and (-ms-high-contrast: active), (forced-colors: active), (prefers-contrast) {
  .flatpickr-day.today,
  .flatpickr-day.inRange {
    color: Highlight; } }

@media screen and (-ms-high-contrast: active), (forced-colors: active), (prefers-contrast) {
  .flatpickr-calendar {
    outline: 1px solid transparent; } }

.bx--date-picker {
  display: flex; }

.bx--date-picker--light .bx--date-picker__input {
  background: var(--cds-field-02, #ffffff); }

.bx--date-picker ~ .bx--label {
  order: 1; }

.bx--date-picker-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }

.bx--date-picker-input__wrapper {
  position: relative;
  display: flex;
  align-items: center; }

.bx--date-picker.bx--date-picker--simple .bx--date-picker__input,
.bx--date-picker.bx--date-picker--simple .bx--label {
  width: 7.5rem; }

.bx--date-picker.bx--date-picker--simple .bx--date-picker-input__wrapper--invalid .bx--date-picker__input,
.bx--date-picker.bx--date-picker--simple .bx--date-picker-input__wrapper--invalid ~ .bx--form-requirement,
.bx--date-picker.bx--date-picker--simple .bx--date-picker-input__wrapper--warn .bx--date-picker__input,
.bx--date-picker.bx--date-picker--simple .bx--date-picker-input__wrapper--warn ~ .bx--form-requirement {
  width: 9.5rem; }

.bx--date-picker.bx--date-picker--simple.bx--date-picker--short .bx--date-picker__input {
  width: 5.7rem; }

.bx--date-picker.bx--date-picker--single .bx--date-picker__input {
  width: 18rem; }

.bx--date-picker .bx--date-picker-input__wrapper--warn ~ .bx--form-requirement {
  color: var(--cds-text-primary, #161616); }

.bx--date-picker__input {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  font-family: var(--cds-code-02-font-family, 'IBM Plex Mono', 'Menlo', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', Courier, monospace);
  font-size: var(--cds-code-02-font-size, 0.875rem);
  font-weight: var(--cds-code-02-font-weight, 400);
  line-height: var(--cds-code-02-line-height, 1.42857);
  letter-spacing: var(--cds-code-02-letter-spacing, 0.32px);
  outline: 2px solid transparent;
  outline-offset: -2px;
  position: relative;
  display: block;
  height: 2.5rem;
  padding: 0 1rem;
  border: none;
  border-bottom: 1px solid var(--cds-border-strong, #8d8d8d);
  background-color: var(--cds-field, #f4f4f4);
  color: var(--cds-text-primary, #161616);
  transition: 70ms cubic-bezier(0.2, 0, 0.38, 0.9) all; }
  .bx--date-picker__input *,
  .bx--date-picker__input *::before,
  .bx--date-picker__input *::after {
    box-sizing: inherit; }
  .bx--date-picker__input:focus, .bx--date-picker__input.bx--focused {
    outline: 2px solid var(--cds-focus, #0f62fe);
    outline-offset: -2px; }
    @media screen and (prefers-contrast) {
      .bx--date-picker__input:focus, .bx--date-picker__input.bx--focused {
        outline-style: dotted; } }
  .bx--date-picker__input:disabled {
    border-bottom: 1px solid transparent;
    background-color: var(--cds-field-disabled, #f4f4f4);
    color: var(--cds-text-disabled, #c6c6c6);
    cursor: not-allowed; }
  .bx--date-picker__input:disabled::placeholder {
    color: var(--cds-text-disabled, #c6c6c6); }
  .bx--date-picker__input:disabled:hover {
    border-bottom: 1px solid transparent; }
  .bx--date-picker__input::placeholder {
    color: var(--cds-text-05, #6f6f6f);
    opacity: 1; }

.bx--date-picker__input--xl,
.bx--date-picker__input--lg {
  height: 3rem; }

.bx--date-picker__input--sm {
  height: 2rem; }

.bx--date-picker__icon {
  position: absolute;
  z-index: 1;
  top: 50%;
  right: 1rem;
  cursor: pointer;
  fill: var(--cds-icon-primary, #161616);
  transform: translateY(-50%); }

.bx--date-picker__icon--invalid,
.bx--date-picker__icon--warn {
  cursor: auto; }

.bx--date-picker__icon--warn {
  fill: var(--cds-support-warning, #f1c21b); }

.bx--date-picker__icon--warn path:first-of-type {
  fill: #000000;
  opacity: 1; }

.bx--date-picker__icon--invalid {
  fill: var(--cds-support-error, #da1e28); }

.bx--date-picker__icon ~ .bx--date-picker__input {
  padding-right: 3rem; }

.bx--date-picker__input:disabled ~ .bx--date-picker__icon {
  cursor: not-allowed;
  fill: var(--cds-icon-disabled, #c6c6c6); }

.bx--date-picker--range > .bx--date-picker-container:first-child {
  margin-right: 0.0625rem; }

.bx--date-picker--range .bx--date-picker-container,
.bx--date-picker--range .bx--date-picker__input {
  width: 8.96875rem; }

.bx--date-picker.bx--skeleton input,
.bx--date-picker__input.bx--skeleton {
  position: relative;
  padding: 0;
  border: none;
  background: var(--cds-skeleton-01, #e5e5e5);
  box-shadow: none;
  pointer-events: none;
  width: 100%; }
  .bx--date-picker.bx--skeleton input:hover, .bx--date-picker.bx--skeleton input:focus, .bx--date-picker.bx--skeleton input:active,
  .bx--date-picker__input.bx--skeleton:hover,
  .bx--date-picker__input.bx--skeleton:focus,
  .bx--date-picker__input.bx--skeleton:active {
    border: none;
    cursor: default;
    outline: none; }
  .bx--date-picker.bx--skeleton input::before,
  .bx--date-picker__input.bx--skeleton::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    animation: 3000ms ease-in-out skeleton infinite;
    background: var(--cds-skeleton-02, #c6c6c6);
    content: '';
    will-change: transform-origin, transform, opacity; }
    @media (prefers-reduced-motion: reduce) {
      .bx--date-picker.bx--skeleton input::before,
      .bx--date-picker__input.bx--skeleton::before {
        animation: none; } }
  .bx--date-picker.bx--skeleton input::placeholder,
  .bx--date-picker__input.bx--skeleton::placeholder {
    color: transparent; }

.bx--date-picker.bx--skeleton .bx--label {
  position: relative;
  padding: 0;
  border: none;
  background: var(--cds-skeleton-01, #e5e5e5);
  box-shadow: none;
  pointer-events: none;
  width: 4.6875rem;
  height: 0.875rem; }
  .bx--date-picker.bx--skeleton .bx--label:hover, .bx--date-picker.bx--skeleton .bx--label:focus, .bx--date-picker.bx--skeleton .bx--label:active {
    border: none;
    cursor: default;
    outline: none; }
  .bx--date-picker.bx--skeleton .bx--label::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    animation: 3000ms ease-in-out skeleton infinite;
    background: var(--cds-skeleton-02, #c6c6c6);
    content: '';
    will-change: transform-origin, transform, opacity; }
    @media (prefers-reduced-motion: reduce) {
      .bx--date-picker.bx--skeleton .bx--label::before {
        animation: none; } }

@media screen and (-ms-high-contrast: active), (forced-colors: active), (prefers-contrast) {
  .bx--date-picker__icon {
    fill: ButtonText; } }

.bx--list-box__wrapper--inline {
  display: inline-grid;
  align-items: center;
  grid-gap: 0.25rem;
  grid-template: auto auto / auto auto; }
  .bx--list-box__wrapper--inline .bx--label {
    font-size: var(--cds-body-short-01-font-size, 0.875rem);
    font-weight: var(--cds-body-short-01-font-weight, 400);
    line-height: var(--cds-body-short-01-line-height, 1.28572);
    letter-spacing: var(--cds-body-short-01-letter-spacing, 0.16px); }
  .bx--list-box__wrapper--inline .bx--label,
  .bx--list-box__wrapper--inline .bx--form__helper-text,
  .bx--list-box__wrapper--inline .bx--form-requirement {
    margin: 0; }
  .bx--list-box__wrapper--inline .bx--form__helper-text {
    max-width: none; }
  .bx--list-box__wrapper--inline .bx--form-requirement {
    grid-column: 2; }

.bx--list-box {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  position: relative;
  width: 100%;
  height: 2.5rem;
  max-height: 2.5rem;
  border: none;
  border-bottom: 1px solid var(--cds-ui-04, #8d8d8d);
  background-color: var(--cds-field-01, #f4f4f4);
  color: var(--cds-text-01, #161616);
  cursor: pointer;
  transition: all 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--list-box *,
  .bx--list-box *::before,
  .bx--list-box *::after {
    box-sizing: inherit; }
  .bx--list-box:hover {
    background-color: var(--cds-hover-ui, #e5e5e5); }

.bx--list-box--xl,
.bx--list-box--lg {
  height: 3rem;
  max-height: 3rem; }

.bx--list-box--sm {
  height: 2rem;
  max-height: 2rem; }

.bx--list-box--expanded {
  border-bottom-color: var(--cds-ui-03, #e0e0e0); }

.bx--list-box--expanded:hover {
  background-color: var(--cds-field-01, #f4f4f4); }

.bx--list-box--expanded:hover.bx--list-box--light:hover {
  background-color: var(--cds-field-02, #ffffff); }

.bx--list-box .bx--text-input {
  min-width: 0;
  height: 100%; }

.bx--list-box__invalid-icon {
  position: absolute;
  top: 50%;
  right: 2.5rem;
  fill: var(--cds-support-01, #da1e28);
  transform: translateY(-50%); }

.bx--list-box__invalid-icon--warning {
  fill: var(--cds-support-03, #f1c21b); }

.bx--list-box__invalid-icon--warning path[fill] {
  fill: #000000;
  opacity: 1; }

.bx--list-box[data-invalid] .bx--list-box__field,
.bx--list-box.bx--list-box--warning .bx--list-box__field {
  padding-right: 4rem;
  border-bottom: 0; }

.bx--list-box[data-invalid].bx--list-box--inline .bx--list-box__field {
  padding-right: 3.5rem; }

.bx--list-box--light {
  background-color: var(--cds-field-02, #ffffff); }
  .bx--list-box--light:hover {
    background-color: var(--cds-hover-light-ui, #e5e5e5); }

.bx--list-box--light .bx--list-box__menu {
  background: var(--cds-field-02, #ffffff); }

.bx--list-box--light .bx--list-box__menu-item__option {
  border-top-color: var(--cds-decorative-01, #e0e0e0); }

.bx--list-box--light.bx--list-box--expanded {
  border-bottom-color: transparent; }

.bx--list-box--disabled:hover {
  background-color: var(--cds-field-01, #f4f4f4); }

.bx--list-box--light.bx--list-box--disabled {
  background-color: var(--cds-field-02, #ffffff); }

.bx--list-box--disabled,
.bx--list-box--disabled .bx--list-box__field,
.bx--list-box--disabled .bx--list-box__field:focus {
  border-bottom-color: transparent;
  outline: none; }

.bx--list-box--disabled .bx--list-box__label,
.bx--list-box--disabled.bx--list-box--inline .bx--list-box__label {
  color: var(--cds-disabled-02, #c6c6c6); }

.bx--list-box--disabled .bx--list-box__menu-icon > svg,
.bx--list-box--disabled .bx--list-box__selection > svg {
  fill: var(--cds-disabled-02, #c6c6c6); }

.bx--list-box--disabled,
.bx--list-box--disabled .bx--list-box__field,
.bx--list-box--disabled .bx--list-box__menu-icon {
  cursor: not-allowed; }

.bx--list-box--disabled .bx--list-box__menu-item,
.bx--list-box--disabled .bx--list-box__menu-item:hover,
.bx--list-box--disabled .bx--list-box__menu-item--highlighted {
  color: var(--cds-disabled-02, #c6c6c6);
  text-decoration: none; }

.bx--list-box--disabled .bx--list-box__selection:hover {
  cursor: not-allowed; }

.bx--list-box--disabled.bx--list-box[data-invalid] .bx--list-box__field {
  padding-right: 3rem; }

.bx--list-box--disabled.bx--list-box[data-invalid].bx--list-box--inline .bx--list-box__field {
  padding-right: 2rem; }

.bx--list-box.bx--list-box--inline {
  border-width: 0;
  background-color: transparent; }
  .bx--list-box.bx--list-box--inline:hover {
    background-color: var(--cds-hover-ui, #e5e5e5); }

.bx--list-box.bx--list-box--inline.bx--list-box--expanded {
  border-bottom-width: 0; }

.bx--list-box.bx--list-box--inline.bx--list-box--expanded .bx--list-box__field[aria-expanded='true'] {
  border-width: 0; }

.bx--list-box.bx--list-box--inline.bx--list-box--disabled:hover {
  background-color: transparent; }

.bx--list-box.bx--list-box--inline.bx--list-box--expanded:hover {
  background-color: var(--cds-field-02, #ffffff); }

.bx--list-box.bx--list-box--inline .bx--list-box__field {
  padding: 0 2rem 0 0.5rem; }

.bx--list-box.bx--list-box--inline .bx--list-box__menu-icon {
  right: 0.5rem; }

.bx--list-box.bx--list-box--inline .bx--list-box__invalid-icon {
  right: 2rem; }

.bx--list-box--inline .bx--list-box__label {
  color: var(--cds-text-01, #161616); }

.bx--list-box--inline .bx--list-box__field {
  height: 100%; }

.bx--dropdown--inline .bx--list-box__field {
  max-width: 30rem; }

.bx--dropdown--inline .bx--list-box__menu {
  min-width: 18rem;
  max-width: 30rem; }

.bx--list-box__field {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  display: inline-block;
  padding: 0;
  border: 0;
  appearance: none;
  background: none;
  cursor: pointer;
  width: 100%;
  position: relative;
  display: inline-flex;
  overflow: hidden;
  height: calc(100% + 1px);
  align-items: center;
  padding: 0 3rem 0 1rem;
  cursor: pointer;
  outline: none;
  text-overflow: ellipsis;
  vertical-align: top;
  white-space: nowrap; }
  .bx--list-box__field *,
  .bx--list-box__field *::before,
  .bx--list-box__field *::after {
    box-sizing: inherit; }
  .bx--list-box__field::-moz-focus-inner {
    border: 0; }

.bx--list-box__field:focus {
  outline: 2px solid var(--cds-focus, #0f62fe);
  outline-offset: -2px; }
  @media screen and (prefers-contrast) {
    .bx--list-box__field:focus {
      outline-style: dotted; } }

.bx--list-box__field[disabled] {
  color: var(--cds-disabled-02, #c6c6c6);
  outline: none; }

.bx--list-box__field .bx--text-input {
  padding-right: 4.5rem; }

.bx--list-box[data-invalid] .bx--list-box__field .bx--text-input,
.bx--list-box--warning .bx--list-box__field .bx--text-input {
  padding-right: 6.125rem; }

.bx--list-box[data-invalid] .bx--list-box__field .bx--text-input + .bx--list-box__invalid-icon,
.bx--list-box--warning .bx--list-box__field .bx--text-input + .bx--list-box__invalid-icon {
  right: 4.125rem; }

.bx--list-box__field .bx--text-input--empty {
  padding-right: 3rem; }

.bx--list-box[data-invalid] .bx--list-box__field .bx--text-input--empty,
.bx--list-box--warning .bx--list-box__field .bx--text-input--empty {
  padding-right: 4.5rem; }

.bx--list-box[data-invalid] .bx--list-box__field .bx--text-input--empty + .bx--list-box__invalid-icon,
.bx--list-box--warning .bx--list-box__field .bx--text-input--empty + .bx--list-box__invalid-icon {
  right: 2.5rem; }

.bx--list-box__label {
  font-size: var(--cds-body-short-01-font-size, 0.875rem);
  font-weight: var(--cds-body-short-01-font-weight, 400);
  line-height: var(--cds-body-short-01-line-height, 1.28572);
  letter-spacing: var(--cds-body-short-01-letter-spacing, 0.16px);
  overflow: hidden;
  color: var(--cds-text-01, #161616);
  text-overflow: ellipsis;
  user-select: none;
  white-space: nowrap; }

.bx--list-box__menu-icon {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  display: inline-block;
  padding: 0;
  border: 0;
  appearance: none;
  background: none;
  cursor: pointer;
  position: absolute;
  right: 1rem;
  display: flex;
  width: 1.5rem;
  height: 1.5rem;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  outline: none;
  transition: transform 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--list-box__menu-icon *,
  .bx--list-box__menu-icon *::before,
  .bx--list-box__menu-icon *::after {
    box-sizing: inherit; }
  .bx--list-box__menu-icon::-moz-focus-inner {
    border: 0; }

.bx--list-box__menu-icon > svg {
  fill: var(--cds-icon-01, #161616); }

.bx--list-box__menu-icon--open {
  width: 1.5rem;
  justify-content: center;
  transform: rotate(180deg); }

.bx--list-box__selection {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  display: inline-block;
  padding: 0;
  border: 0;
  appearance: none;
  background: none;
  cursor: pointer;
  position: absolute;
  top: 50%;
  /* to preserve .5rem space between icons according to spec top/transform used to center the combobox clear selection icon in IE11 */
  right: 2.5rem;
  display: flex;
  width: 1.5rem;
  height: 1.5rem;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transform: translateY(-50%);
  transition: background-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
  user-select: none; }
  .bx--list-box__selection *,
  .bx--list-box__selection *::before,
  .bx--list-box__selection *::after {
    box-sizing: inherit; }
  .bx--list-box__selection::-moz-focus-inner {
    border: 0; }
  .bx--list-box__selection:focus {
    outline: 2px solid var(--cds-focus, #0f62fe);
    outline-offset: -2px; }
    @media screen and (prefers-contrast) {
      .bx--list-box__selection:focus {
        outline-style: dotted; } }
    .bx--list-box__selection:focus:hover {
      outline: 2px solid var(--cds-focus, #0f62fe);
      outline-offset: -2px; }
      @media screen and (prefers-contrast) {
        .bx--list-box__selection:focus:hover {
          outline-style: dotted; } }

.bx--list-box__selection > svg {
  fill: var(--cds-icon-01, #161616); }

.bx--list-box--disabled .bx--list-box__selection:focus {
  outline: none; }

.bx--list-box__selection--multi {
  font-size: var(--cds-label-01-font-size, 0.75rem);
  font-weight: var(--cds-label-01-font-weight, 400);
  line-height: var(--cds-label-01-line-height, 1.33333);
  letter-spacing: var(--cds-label-01-letter-spacing, 0.32px);
  position: static;
  top: auto;
  display: flex;
  width: auto;
  height: 1.5rem;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
  padding-right: 0.125rem;
  margin-right: 0.625rem;
  background-color: var(--cds-inverse-02, #393939);
  border-radius: 0.75rem;
  color: var(--cds-inverse-01, #ffffff);
  line-height: 0;
  transform: none; }

.bx--list-box__selection--multi > svg {
  width: 1.25rem;
  height: 1.25rem;
  padding: 0.125rem;
  margin-left: 0.25rem;
  fill: var(--cds-inverse-01, #ffffff); }
  .bx--list-box__selection--multi > svg:hover {
    background-color: var(--cds-hover-secondary, #4c4c4c);
    border-radius: 50%; }

.bx--list-box--disabled .bx--list-box__selection--multi {
  background-color: var(--cds-disabled-02, #c6c6c6);
  color: var(--cds-disabled-01, #f4f4f4); }
  .bx--list-box--disabled .bx--list-box__selection--multi.bx--tag--interactive:hover,
  .bx--list-box--disabled .bx--list-box__selection--multi .bx--tag__close-icon:hover {
    background-color: var(--cds-disabled-02, #c6c6c6); }
  .bx--list-box--disabled .bx--list-box__selection--multi > svg {
    fill: var(--cds-disabled-01, #f4f4f4); }
    .bx--list-box--disabled .bx--list-box__selection--multi > svg:hover {
      background-color: initial; }

.bx--list-box__selection--multi:hover {
  outline: none; }

.bx--list-box__menu {
  box-shadow: 0 2px 6px var(--cds-shadow, rgba(0, 0, 0, 0.3));
  position: absolute;
  z-index: 9100;
  right: 0;
  left: 0;
  width: 100%;
  background-color: var(--cds-ui-01, #f4f4f4);
  overflow-y: auto;
  transition: max-height 110ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--list-box__menu:focus {
    outline: 1px solid var(--cds-focus, #0f62fe); }
    @media screen and (prefers-contrast) {
      .bx--list-box__menu:focus {
        outline-style: dotted; } }

.bx--list-box .bx--list-box__field[aria-expanded='false'] + .bx--list-box__menu {
  max-height: 0; }

.bx--list-box--expanded .bx--list-box__menu {
  max-height: 13.75rem; }

.bx--list-box--expanded.bx--list-box--xl .bx--list-box__menu,
.bx--list-box--expanded.bx--list-box--lg .bx--list-box__menu {
  max-height: 16.5rem; }

.bx--list-box--expanded.bx--list-box--sm .bx--list-box__menu {
  max-height: 11rem; }

.bx--list-box__menu-item {
  font-size: var(--cds-body-short-01-font-size, 0.875rem);
  font-weight: var(--cds-body-short-01-font-weight, 400);
  line-height: var(--cds-body-short-01-line-height, 1.28572);
  letter-spacing: var(--cds-body-short-01-letter-spacing, 0.16px);
  position: relative;
  height: 2.5rem;
  color: var(--cds-text-02, #525252);
  cursor: pointer;
  transition: background 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
  user-select: none; }
  .bx--list-box__menu-item:hover {
    background-color: var(--cds-hover-ui, #e5e5e5); }
  .bx--list-box__menu-item:active {
    background-color: var(--cds-selected-ui, #e0e0e0); }

.bx--list-box--light .bx--list-box__menu-item:hover {
  background-color: var(--cds-hover-light-ui, #e5e5e5); }

.bx--list-box--sm .bx--list-box__menu-item {
  height: 2rem; }

.bx--list-box--xl .bx--list-box__menu-item,
.bx--list-box--lg .bx--list-box__menu-item {
  height: 3rem; }

.bx--list-box--disabled .bx--list-box__menu-item:hover {
  background-color: transparent; }

.bx--list-box--light .bx--list-box__menu-item:active {
  background-color: var(--cds-selected-light-ui, #e0e0e0); }

.bx--list-box--disabled .bx--list-box__menu-item__option:hover {
  border-top-color: var(--cds-ui-03, #e0e0e0); }

.bx--list-box__menu-item:first-of-type .bx--list-box__menu-item__option {
  border-top-color: transparent; }

.bx--list-box__menu-item:hover .bx--list-box__menu-item__option {
  color: var(--cds-text-01, #161616); }

.bx--list-box__menu-item:hover + .bx--list-box__menu-item .bx--list-box__menu-item__option {
  border-top-color: transparent; }

.bx--list-box--disabled .bx--list-box__menu-item:hover + .bx--list-box__menu-item .bx--list-box__menu-item__option {
  border-top-color: var(--cds-ui-03, #e0e0e0); }

.bx--list-box__menu-item__option {
  outline: 2px solid transparent;
  outline-offset: -2px;
  display: block;
  overflow: hidden;
  height: 2.5rem;
  padding: 0.6875rem 0;
  padding-right: 1.5rem;
  border-top: 1px solid transparent;
  border-top-color: var(--cds-ui-03, #e0e0e0);
  border-bottom: 1px solid transparent;
  margin: 0 1rem;
  color: var(--cds-text-02, #525252);
  font-weight: normal;
  line-height: 1rem;
  text-decoration: none;
  text-overflow: ellipsis;
  transition: border-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9), color 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
  white-space: nowrap; }
  .bx--list-box__menu-item__option:focus {
    outline: 2px solid var(--cds-focus, #0f62fe);
    outline-offset: -2px;
    padding: 0.6875rem 1rem;
    border-color: transparent;
    margin: 0; }
    @media screen and (prefers-contrast) {
      .bx--list-box__menu-item__option:focus {
        outline-style: dotted; } }
  .bx--list-box__menu-item__option:hover {
    border-color: transparent;
    color: var(--cds-text-01, #161616); }

.bx--list-box--sm .bx--list-box__menu-item__option {
  height: 2rem;
  padding-top: 0.4375rem;
  padding-bottom: 0.4375rem; }

.bx--list-box--xl .bx--list-box__menu-item__option,
.bx--list-box--lg .bx--list-box__menu-item__option {
  height: 3rem;
  padding-top: 0.9375rem;
  padding-bottom: 0.9375rem; }

.bx--list-box--disabled .bx--list-box__menu-item:hover .bx--list-box__menu-item__option,
.bx--list-box--disabled .bx--list-box__menu-item__option {
  color: var(--cds-disabled-02, #c6c6c6); }

.bx--list-box__menu-item[disabled],
.bx--list-box__menu-item[disabled] *,
.bx--list-box__menu-item[disabled] .bx--list-box__menu-item__option,
.bx--list-box__menu-item[disabled]:hover {
  color: var(--cds-disabled-02, #c6c6c6);
  cursor: not-allowed;
  outline: none; }

.bx--list-box__menu-item[disabled]:hover {
  background-color: revert; }

.bx--list-box__menu-item[disabled] .bx--checkbox-label::before {
  border-color: var(--cds-disabled-02, #c6c6c6); }

.bx--list-box__menu-item[disabled] .bx--list-box__menu-item__option {
  border-top-color: var(--cds-ui-03, #e0e0e0); }

.bx--list-box__menu-item[disabled]:hover + .bx--list-box__menu-item .bx--list-box__menu-item__option {
  border-top-color: var(--cds-ui-03, #e0e0e0); }

.bx--list-box.bx--list-box--inline .bx--list-box__menu-item__option {
  margin: 0 0.5rem; }
  .bx--list-box.bx--list-box--inline .bx--list-box__menu-item__option:focus {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin: 0; }

.bx--list-box__menu-item--highlighted {
  border-color: transparent;
  background-color: var(--cds-hover-ui, #e5e5e5);
  color: var(--cds-text-01, #161616); }

.bx--list-box__menu-item--highlighted .bx--list-box__menu-item__option,
.bx--list-box__menu-item--highlighted + .bx--list-box__menu-item .bx--list-box__menu-item__option {
  border-top-color: transparent; }

.bx--list-box__menu-item--highlighted .bx--list-box__menu-item__option {
  color: var(--cds-text-01, #161616); }

.bx--list-box__menu-item--active {
  border-bottom-color: var(--cds-selected-ui, #e0e0e0);
  background-color: var(--cds-selected-ui, #e0e0e0);
  color: var(--cds-text-01, #161616); }

.bx--list-box--light .bx--list-box__menu-item--active {
  border-bottom-color: var(--cds-selected-light-ui, #e0e0e0);
  background-color: var(--cds-selected-light-ui, #e0e0e0); }

.bx--list-box__menu-item--active:hover,
.bx--list-box__menu-item--active.bx--list-box__menu-item--highlighted {
  border-bottom-color: var(--cds-hover-selected-ui, #cacaca);
  background-color: var(--cds-hover-selected-ui, #cacaca); }

.bx--list-box__menu-item--active .bx--list-box__menu-item__option {
  color: var(--cds-text-01, #161616); }

.bx--list-box__menu-item--active + .bx--list-box__menu-item > .bx--list-box__menu-item__option {
  border-top-color: transparent; }

.bx--list-box__menu-item__selected-icon {
  position: absolute;
  top: 50%;
  right: 1rem;
  display: none;
  fill: var(--cds-icon-01, #161616);
  transform: translateY(-50%); }

.bx--list-box--inline .bx--list-box__menu-item__selected-icon {
  right: 0.5rem; }

.bx--list-box__menu-item--active .bx--list-box__menu-item__selected-icon {
  display: block; }

.bx--list-box__menu-item .bx--checkbox-label {
  width: 100%; }

.bx--list-box__menu-item .bx--checkbox-label-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.bx--list-box--up .bx--list-box__menu {
  bottom: 2.5rem; }

.bx--list-box--up.bx--dropdown--sm .bx--list-box__menu,
.bx--list-box--up.bx--list-box--sm .bx--list-box__menu,
.bx--list-box--up .bx--list-box--sm .bx--list-box__menu {
  bottom: 2rem; }

.bx--list-box--up.bx--dropdown--xl .bx--list-box__menu,
.bx--list-box--up.bx--list-box--xl .bx--list-box__menu,
.bx--list-box--up.bx--dropdown--lg .bx--list-box__menu,
.bx--list-box--up.bx--list-box--lg .bx--list-box__menu,
.bx--list-box--up .bx--list-box--lg .bx--list-box__menu {
  bottom: 3rem; }

.bx--list-box input[role='combobox'],
.bx--list-box input[type='text'] {
  min-width: 0;
  background-color: inherit; }

@media screen and (-ms-high-contrast: active), (forced-colors: active), (prefers-contrast) {
  .bx--list-box__field,
  .bx--list-box__menu,
  .bx--multi-select .bx--tag--filter {
    outline: 1px solid transparent; } }

@media screen and (-ms-high-contrast: active), (forced-colors: active), (prefers-contrast) {
  .bx--list-box__field:focus,
  .bx--multi-select .bx--tag__close-icon:focus,
  .bx--list-box__menu-item--highlighted .bx--list-box__menu-item__option {
    color: Highlight;
    outline: 1px solid Highlight; } }

@media screen and (-ms-high-contrast: active), (forced-colors: active), (prefers-contrast) {
  .bx--list-box__menu-icon > svg,
  .bx--list-box__selection > svg,
  .bx--list-box__selection--multi > svg {
    fill: ButtonText; } }

.bx--dropdown__wrapper--inline {
  display: inline-grid;
  align-items: center;
  grid-gap: 0 1.5rem;
  grid-template: auto auto / auto min-content; }
  .bx--dropdown__wrapper--inline .bx--label {
    font-size: var(--cds-body-short-01-font-size, 0.875rem);
    font-weight: var(--cds-body-short-01-font-weight, 400);
    line-height: var(--cds-body-short-01-line-height, 1.28572);
    letter-spacing: var(--cds-body-short-01-letter-spacing, 0.16px); }
  .bx--dropdown__wrapper--inline .bx--label,
  .bx--dropdown__wrapper--inline .bx--form__helper-text,
  .bx--dropdown__wrapper--inline .bx--form-requirement {
    margin: 0; }
  .bx--dropdown__wrapper--inline .bx--form-requirement {
    grid-column: 2; }

.bx--dropdown {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  outline: 2px solid transparent;
  outline-offset: -2px;
  position: relative;
  display: block;
  width: 100%;
  height: 2.5rem;
  border: none;
  border-bottom: 1px solid var(--cds-border-strong, #8d8d8d);
  background-color: var(--cds-field, #f4f4f4);
  color: var(--cds-text-primary, #161616);
  cursor: pointer;
  list-style: none;
  outline: 2px solid transparent;
  transition: background-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--dropdown *,
  .bx--dropdown *::before,
  .bx--dropdown *::after {
    box-sizing: inherit; }
  .bx--dropdown:hover {
    background-color: var(--cds-field-hover, #e5e5e5); }

.bx--dropdown .bx--list-box__field {
  text-align: left; }

.bx--dropdown--xl,
.bx--dropdown--lg {
  height: 3rem;
  max-height: 3rem; }

.bx--dropdown--xl .bx--dropdown__arrow,
.bx--dropdown--lg .bx--dropdown__arrow {
  top: 1rem; }

.bx--dropdown--sm {
  height: 2rem;
  max-height: 2rem; }

.bx--dropdown--sm .bx--dropdown__arrow {
  top: 0.5rem; }

.bx--dropdown--open {
  border-bottom-color: var(--cds-border-subtle, #e0e0e0); }

.bx--dropdown--invalid {
  outline: 2px solid var(--cds-support-01, #da1e28);
  outline-offset: -2px; }
  @media screen and (prefers-contrast) {
    .bx--dropdown--invalid {
      outline-style: dotted; } }
  .bx--dropdown--invalid .bx--dropdown-text {
    padding-right: 3.5rem; }
  .bx--dropdown--invalid + .bx--form-requirement {
    display: inline-block;
    max-height: 12.5rem;
    color: var(--cds-text-error, #da1e28); }

.bx--dropdown__invalid-icon {
  position: absolute;
  top: 50%;
  right: var(--cds-spacing-08, 2.5rem);
  fill: var(--cds-support-error, #da1e28);
  transform: translateY(-50%); }

.bx--dropdown--open:hover {
  background-color: var(--cds-field, #f4f4f4); }

.bx--dropdown--open:focus {
  outline: 1px solid transparent; }

.bx--dropdown--open .bx--dropdown-list {
  box-shadow: 0 2px 6px var(--cds-shadow, rgba(0, 0, 0, 0.3));
  max-height: 13.75rem;
  transition: max-height 110ms cubic-bezier(0, 0, 0.38, 0.9); }

.bx--dropdown--light {
  background-color: var(--cds-field-02, #ffffff); }
  .bx--dropdown--light:hover {
    background-color: var(--cds-hover-light-ui, #e5e5e5); }

.bx--dropdown--up .bx--dropdown-list {
  bottom: 2rem; }

.bx--dropdown__arrow {
  position: absolute;
  top: 0.8125rem;
  right: 1rem;
  fill: var(--cds-icon-primary, #161616);
  pointer-events: none;
  transform-origin: 50% 45%;
  transition: transform 110ms cubic-bezier(0.2, 0, 0.38, 0.9); }

button.bx--dropdown-text {
  width: 100%;
  border: none;
  background: none;
  color: var(--cds-text-primary, #161616);
  text-align: left; }
  button.bx--dropdown-text:focus {
    outline: 2px solid var(--cds-focus, #0f62fe);
    outline-offset: -2px; }
    @media screen and (prefers-contrast) {
      button.bx--dropdown-text:focus {
        outline-style: dotted; } }

.bx--dropdown-text {
  font-size: var(--cds-body-short-01-font-size, 0.875rem);
  font-weight: var(--cds-body-short-01-font-weight, 400);
  line-height: var(--cds-body-short-01-line-height, 1.28572);
  letter-spacing: var(--cds-body-short-01-letter-spacing, 0.16px);
  display: block;
  overflow: hidden;
  height: calc(100% + 1px);
  padding-right: 2.625rem;
  padding-left: 1rem;
  text-overflow: ellipsis;
  white-space: nowrap; }

.bx--dropdown-list {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  outline: 2px solid transparent;
  outline-offset: -2px;
  box-shadow: 0 2px 6px var(--cds-shadow, rgba(0, 0, 0, 0.3));
  font-size: var(--cds-body-short-01-font-size, 0.875rem);
  font-weight: var(--cds-body-short-01-font-weight, 400);
  line-height: var(--cds-body-short-01-line-height, 1.28572);
  letter-spacing: var(--cds-body-short-01-letter-spacing, 0.16px);
  position: absolute;
  z-index: 9100;
  display: flex;
  width: 100%;
  max-height: 0;
  flex-direction: column;
  background-color: var(--cds-layer, #f4f4f4);
  list-style: none;
  overflow-x: hidden;
  overflow-y: auto;
  transition: max-height 110ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--dropdown-list *,
  .bx--dropdown-list *::before,
  .bx--dropdown-list *::after {
    box-sizing: inherit; }

.bx--dropdown--light .bx--dropdown-list {
  background-color: var(--cds-field-02, #ffffff); }

.bx--dropdown:not(.bx--dropdown--open) .bx--dropdown-item {
  visibility: hidden; }

.bx--dropdown-item {
  position: relative;
  opacity: 0;
  transition: visibility 70ms cubic-bezier(0.2, 0, 0.38, 0.9), opacity 70ms cubic-bezier(0.2, 0, 0.38, 0.9), background-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
  visibility: inherit; }
  .bx--dropdown-item:hover {
    background-color: var(--cds-layer-hover, #e5e5e5); }
    .bx--dropdown-item:hover + .bx--dropdown-item .bx--dropdown-link {
      border-color: transparent; }
  .bx--dropdown-item:active {
    background-color: var(--cds-layer-selected, #e0e0e0); }
  .bx--dropdown-item:first-of-type .bx--dropdown-link {
    border-top-color: transparent; }

.bx--dropdown-item:last-of-type .bx--dropdown-link {
  border-bottom: none; }

.bx--dropdown-link {
  outline: 2px solid transparent;
  outline-offset: -2px;
  display: block;
  overflow: hidden;
  height: 2.5rem;
  padding: 0.6875rem 0;
  border: 1px solid transparent;
  border-top-color: var(--cds-border-subtle, #e0e0e0);
  margin: 0 1rem;
  color: var(--cds-text-secondary, #525252);
  font-weight: normal;
  line-height: 1rem;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap; }
  .bx--dropdown-link:hover {
    border-color: transparent;
    color: var(--cds-text-01, #161616); }

.bx--dropdown--light .bx--dropdown-link {
  border-top-color: var(--cds-decorative-01, #e0e0e0); }

.bx--dropdown--sm .bx--dropdown-link {
  height: 2rem;
  padding-top: 0.4375rem;
  padding-bottom: 0.4375rem; }

.bx--dropdown--xl .bx--dropdown-link {
  height: 3rem;
  padding-top: 0.9375rem;
  padding-bottom: 0.9375rem; }

.bx--dropdown--focused,
.bx--dropdown-link:focus {
  outline: 2px solid var(--cds-focus, #0f62fe);
  outline-offset: -2px;
  padding: 0.6875rem 1rem;
  margin: 0; }
  @media screen and (prefers-contrast) {
    .bx--dropdown--focused,
    .bx--dropdown-link:focus {
      outline-style: dotted; } }

.bx--dropdown-list[aria-activedescendant] .bx--dropdown-link:focus {
  padding: 0.6875rem 0;
  margin: 0 1rem;
  outline: none; }

.bx--dropdown-list[aria-activedescendant] .bx--dropdown--focused:focus {
  outline: 2px solid var(--cds-focus, #0f62fe);
  outline-offset: -2px;
  padding: 0.6875rem 1rem;
  margin: 0; }
  @media screen and (prefers-contrast) {
    .bx--dropdown-list[aria-activedescendant] .bx--dropdown--focused:focus {
      outline-style: dotted; } }

.bx--dropdown-list[aria-activedescendant] .bx--dropdown-item:active {
  background-color: inherit; }

.bx--dropdown-item:hover .bx--dropdown-link {
  border-bottom-color: var(--cds-layer-hover, #e5e5e5); }

.bx--dropdown--open .bx--dropdown__arrow {
  transform: rotate(-180deg); }

.bx--dropdown--open.bx--dropdown--xl .bx--dropdown-list {
  max-height: 16.5rem; }

.bx--dropdown--open.bx--dropdown--sm .bx--dropdown-list {
  max-height: 11rem; }

.bx--dropdown--open .bx--dropdown-item {
  opacity: 1; }

.bx--dropdown--disabled {
  border-bottom-color: transparent; }
  .bx--dropdown--disabled:hover {
    background-color: var(--cds-field, #f4f4f4); }
  .bx--dropdown--disabled:focus {
    outline: none; }
  .bx--dropdown--disabled .bx--dropdown-text,
  .bx--dropdown--disabled .bx--list-box__label {
    color: var(--cds-text-disabled, #c6c6c6); }
  .bx--dropdown--disabled .bx--dropdown__arrow,
  .bx--dropdown--disabled .bx--list-box__menu-icon svg {
    fill: var(--cds-icon-disabled, #c6c6c6); }
  .bx--dropdown--disabled.bx--dropdown--light:hover {
    background-color: var(--cds-field-02, #ffffff); }

.bx--dropdown--disabled .bx--list-box__field,
.bx--dropdown--disabled .bx--list-box__menu-icon {
  cursor: not-allowed; }

.bx--dropdown--auto-width {
  width: auto;
  max-width: 25rem; }

.bx--dropdown--inline {
  display: inline-block;
  width: auto;
  border-bottom-color: transparent;
  background-color: transparent;
  justify-self: start;
  transition: background 70ms cubic-bezier(0, 0, 0.38, 0.9); }
  .bx--dropdown--inline:hover {
    background-color: var(--cds-field-hover, #e5e5e5); }
  .bx--dropdown--inline.bx--dropdown--disabled {
    background-color: transparent; }
  .bx--dropdown--inline .bx--dropdown__arrow {
    top: 0.5rem;
    right: 0.5rem; }

.bx--dropdown--inline.bx--dropdown--open {
  background-color: transparent; }

.bx--dropdown--inline .bx--dropdown-text {
  display: inline-block;
  overflow: visible;
  height: 2rem;
  padding: 0.4375rem 2rem 0.4375rem 0.75rem;
  color: var(--cds-text-primary, #161616); }

.bx--dropdown--inline.bx--dropdown--disabled .bx--dropdown-text {
  color: var(--cds-text-disabled, #c6c6c6); }

.bx--dropdown--inline.bx--dropdown--disabled:focus .bx--dropdown-text {
  outline: 0; }

.bx--dropdown--inline.bx--dropdown--invalid .bx--dropdown__invalid-icon {
  right: 2rem; }

.bx--dropdown--inline.bx--dropdown--invalid .bx--dropdown-text {
  padding-right: 3.5rem; }

.bx--dropdown--inline.bx--dropdown--open:focus .bx--dropdown-list {
  box-shadow: 0 2px 6px var(--cds-shadow, rgba(0, 0, 0, 0.3)); }

.bx--dropdown--inline .bx--dropdown-link {
  font-weight: normal; }

.bx--dropdown--show-selected .bx--dropdown--selected {
  display: block;
  background-color: var(--cds-layer-hover, #e5e5e5);
  color: var(--cds-text-primary, #161616); }
  .bx--dropdown--show-selected .bx--dropdown--selected:hover {
    background-color: var(--cds-layer-selected, #e0e0e0); }
  .bx--dropdown--show-selected .bx--dropdown--selected .bx--dropdown-link {
    border-top-color: transparent; }
  .bx--dropdown--show-selected .bx--dropdown--selected + .bx--dropdown-item .bx--dropdown-link {
    border-top-color: transparent; }
  .bx--dropdown--show-selected .bx--dropdown--selected .bx--list-box__menu-item__selected-icon {
    display: block; }

.bx--dropdown-v2.bx--skeleton,
.bx--dropdown.bx--skeleton {
  position: relative;
  padding: 0;
  border: none;
  background: var(--cds-skeleton-01, #e5e5e5);
  box-shadow: none;
  pointer-events: none; }
  .bx--dropdown-v2.bx--skeleton:hover, .bx--dropdown-v2.bx--skeleton:focus, .bx--dropdown-v2.bx--skeleton:active,
  .bx--dropdown.bx--skeleton:hover,
  .bx--dropdown.bx--skeleton:focus,
  .bx--dropdown.bx--skeleton:active {
    border: none;
    cursor: default;
    outline: none; }
  .bx--dropdown-v2.bx--skeleton::before,
  .bx--dropdown.bx--skeleton::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    animation: 3000ms ease-in-out skeleton infinite;
    background: var(--cds-skeleton-02, #c6c6c6);
    content: '';
    will-change: transform-origin, transform, opacity; }
    @media (prefers-reduced-motion: reduce) {
      .bx--dropdown-v2.bx--skeleton::before,
      .bx--dropdown.bx--skeleton::before {
        animation: none; } }

@media screen and (-ms-high-contrast: active), (forced-colors: active), (prefers-contrast) {
  .bx--dropdown .bx--list-box__field {
    outline: 1px solid transparent; } }

@media screen and (-ms-high-contrast: active), (forced-colors: active), (prefers-contrast) {
  .bx--list-box__menu-item__option {
    outline: none; } }

@media screen and (-ms-high-contrast: active), (forced-colors: active), (prefers-contrast) {
  .bx--list-box__menu-item__selected-icon {
    fill: ButtonText; } }

@keyframes collapse-accordion {
  0% {
    height: 100%;
    opacity: 1;
    visibility: inherit; }
  100% {
    height: 0;
    opacity: 0;
    visibility: hidden; } }

@keyframes expand-accordion {
  0% {
    height: 0;
    opacity: 0;
    visibility: hidden; }
  100% {
    height: 100%;
    opacity: 1;
    visibility: inherit; } }

.bx--accordion {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  width: 100%;
  list-style: none; }
  .bx--accordion *,
  .bx--accordion *::before,
  .bx--accordion *::after {
    box-sizing: inherit; }

.bx--accordion__item {
  overflow: visible;
  border-top: 1px solid var(--cds-ui-03, #e0e0e0);
  transition: all 110ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--accordion__item:last-child {
    border-bottom: 1px solid var(--cds-ui-03, #e0e0e0); }

.bx--accordion__heading {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  display: inline-block;
  padding: 0;
  border: 0;
  appearance: none;
  background: none;
  cursor: pointer;
  width: 100%;
  position: relative;
  display: flex;
  width: 100%;
  min-height: 2.5rem;
  flex-direction: row-reverse;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0.625rem 0;
  margin: 0;
  color: var(--cds-text-01, #161616);
  cursor: pointer;
  transition: background-color cubic-bezier(0.2, 0, 0.38, 0.9) 110ms; }
  .bx--accordion__heading *,
  .bx--accordion__heading *::before,
  .bx--accordion__heading *::after {
    box-sizing: inherit; }
  .bx--accordion__heading::-moz-focus-inner {
    border: 0; }
  .bx--accordion__heading:hover::before, .bx--accordion__heading:focus::before {
    position: absolute;
    top: -1px;
    left: 0;
    width: 100%;
    height: calc(100% + 2px);
    content: ''; }
  .bx--accordion__heading:hover::before {
    background-color: var(--cds-hover-ui, #e5e5e5); }
  .bx--accordion__heading:focus {
    outline: none; }
  .bx--accordion__heading:focus::before {
    box-sizing: border-box;
    border: 2px solid var(--cds-focus, #0f62fe); }
    @media screen and (prefers-contrast) {
      .bx--accordion__heading:focus::before {
        border-style: dotted; } }

.bx--accordion--xl .bx--accordion__heading,
.bx--accordion--lg .bx--accordion__heading {
  min-height: 3rem; }

.bx--accordion--sm .bx--accordion__heading {
  min-height: 2rem;
  padding: 0.3125rem 0; }

.bx--accordion__heading[disabled] {
  color: var(--cds-disabled-02, #c6c6c6);
  cursor: not-allowed; }

.bx--accordion__heading[disabled] .bx--accordion__arrow {
  fill: var(--cds-disabled-02, #c6c6c6); }

.bx--accordion__heading[disabled]:hover::before {
  background-color: transparent; }

.bx--accordion__item--disabled,
.bx--accordion__item--disabled + .bx--accordion__item {
  border-top: 1px solid var(--cds-disabled-01, #f4f4f4); }

li.bx--accordion__item--disabled:last-of-type {
  border-bottom: 1px solid var(--cds-disabled-01, #f4f4f4); }

.bx--accordion__arrow {
  outline: 2px solid transparent;
  outline-offset: -2px;
  width: 1rem;
  height: 1rem;
  flex: 0 0 1rem;
  margin: 2px 1rem 0 0;
  fill: var(--cds-ui-05, #161616);
  transform: rotate(-270deg) /*rtl:ignore*/;
  transition: all 110ms cubic-bezier(0.2, 0, 0.38, 0.9); }

.bx--accordion__title {
  font-size: var(--cds-body-long-01-font-size, 0.875rem);
  font-weight: var(--cds-body-long-01-font-weight, 400);
  line-height: var(--cds-body-long-01-line-height, 1.42857);
  letter-spacing: var(--cds-body-long-01-letter-spacing, 0.16px);
  z-index: 1;
  width: 100%;
  margin: 0 0 0 1rem;
  text-align: left; }

.bx--accordion__content {
  display: none;
  padding-right: 1rem;
  padding-left: 1rem;
  transition: padding cubic-bezier(0.2, 0, 0.38, 0.9) 110ms; }
  @media (min-width: 480px) {
    .bx--accordion__content {
      padding-right: 3rem; } }
  @media (min-width: 640px) {
    .bx--accordion__content {
      padding-right: 25%; } }
  .bx--accordion__content > p {
    font-size: var(--cds-body-long-01-font-size, 0.875rem);
    font-weight: var(--cds-body-long-01-font-weight, 400);
    line-height: var(--cds-body-long-01-line-height, 1.42857);
    letter-spacing: var(--cds-body-long-01-letter-spacing, 0.16px); }

.bx--accordion--start .bx--accordion__heading {
  flex-direction: row; }

.bx--accordion--start .bx--accordion__arrow {
  margin: 2px 0 0 1rem; }

.bx--accordion--start .bx--accordion__title {
  margin-right: 1rem; }

.bx--accordion--start .bx--accordion__content {
  margin-left: 2rem; }

.bx--accordion__item--collapsing .bx--accordion__content,
.bx--accordion__item--expanding .bx--accordion__content {
  display: block; }

.bx--accordion__item--collapsing .bx--accordion__content {
  animation: 110ms cubic-bezier(0.2, 0, 0.38, 0.9) collapse-accordion; }

.bx--accordion__item--expanding .bx--accordion__content {
  animation: 110ms cubic-bezier(0.2, 0, 0.38, 0.9) expand-accordion; }

.bx--accordion__item--active {
  overflow: visible; }
  .bx--accordion__item--active .bx--accordion__content {
    display: block;
    padding-top: var(--cds-spacing-03, 0.5rem);
    padding-bottom: 1.5rem;
    transition: padding-top cubic-bezier(0, 0, 0.38, 0.9) 110ms, padding-bottom cubic-bezier(0, 0, 0.38, 0.9) 110ms; }
  .bx--accordion__item--active .bx--accordion__arrow {
    fill: var(--cds-ui-05, #161616);
    transform: rotate(-90deg) /*rtl:ignore*/; }

.bx--accordion.bx--skeleton .bx--accordion__heading,
.bx--accordion.bx--skeleton .bx--accordion__button {
  cursor: default; }

.bx--accordion.bx--skeleton .bx--accordion__arrow {
  cursor: default;
  fill: var(--cds-ui-05, #161616);
  pointer-events: none; }
  .bx--accordion.bx--skeleton .bx--accordion__arrow:hover, .bx--accordion.bx--skeleton .bx--accordion__arrow:focus, .bx--accordion.bx--skeleton .bx--accordion__arrow:active {
    border: none;
    cursor: default;
    outline: none; }

.bx--accordion.bx--skeleton .bx--accordion__heading:hover::before {
  background-color: transparent; }

.bx--accordion--end.bx--skeleton .bx--accordion__arrow {
  margin-left: var(--cds-spacing-05, 1rem); }

.bx--skeleton .bx--accordion__heading:focus .bx--accordion__arrow {
  border: none;
  cursor: default;
  outline: none; }

.bx--accordion__title.bx--skeleton__text {
  margin-bottom: 0; }

@media screen and (-ms-high-contrast: active), (forced-colors: active), (prefers-contrast) {
  .bx--accordion__arrow,
  .bx--accordion__item--active .bx--accordion__arrow {
    fill: ButtonText; } }

.security--profile-image {
  font-size: var(--cds-body-short-01-font-size, 0.875rem);
  font-weight: var(--cds-body-short-01-font-weight, 400);
  line-height: var(--cds-body-short-01-line-height, 1.28572);
  letter-spacing: var(--cds-body-short-01-letter-spacing, 0.16px);
  display: inline-flex;
  width: 1.75rem;
  height: 1.75rem;
  align-items: center;
  justify-content: center;
  background-color: var(--cds-icon-02, #525252);
  border-radius: 50%;
  color: var(--cds-inverse-01, #ffffff);
  object-fit: cover;
  text-transform: uppercase; }
  .security--profile-image--large {
    font-size: var(--cds-productive-heading-03-font-size, 1.25rem);
    font-weight: var(--cds-productive-heading-03-font-weight, 400);
    line-height: var(--cds-productive-heading-03-line-height, 1.4);
    letter-spacing: var(--cds-productive-heading-03-letter-spacing, 0);
    width: 3.5rem;
    height: 3.5rem; }

.security--header__list__item {
  height: inherit; }
  .security--header__list__item--active {
    margin-right: 0.5rem; }
    .security--header__list__item--active:last-of-type {
      margin-right: 0; }
  .security--header__list__item > .bx--btn {
    height: inherit;
    min-height: inherit; }

.security--string-formatter {
  display: inline-block;
  text-align: left; }
  .security--string-formatter--truncate {
    display: -webkit-box;
    overflow: hidden;
    max-width: 80ch;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1; }
  .security--string-formatter__tooltip > .bx--tooltip__trigger {
    border-width: 0;
    color: currentColor;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit; }

.security--header__notification {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  justify-content: space-between; }
  .security--header__notification__content {
    flex-grow: 1;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 0.5rem;
    color: inherit;
    text-decoration: none; }
    .security--header__notification__content:focus {
      outline: 2px solid var(--cds-focus, #0f62fe);
      outline-offset: -2px;
      outline-color: var(--cds-inverse-focus-ui, #ffffff);
      opacity: 1;
      transform: translate3d(0, 0, 0); }
      @media screen and (prefers-contrast) {
        .security--header__notification__content:focus {
          outline-style: dotted; } }
    .security--header__notification__content > .bx--tooltip--definition > .bx--tooltip__trigger {
      color: currentColor; }
      .security--header__notification__content > .bx--tooltip--definition > .bx--tooltip__trigger:focus {
        outline-color: var(--cds-inverse-focus-ui, #ffffff); }
      .security--header__notification__content > .bx--tooltip--definition > .bx--tooltip__trigger.bx--tooltip--bottom + .bx--assistive-text, .security--header__notification__content > .bx--tooltip--definition > .bx--tooltip__trigger.bx--tooltip--top + .bx--assistive-text {
        background-color: var(--cds-inverse-01, #ffffff);
        color: var(--cds-inverse-02, #393939); }
      .security--header__notification__content > .bx--tooltip--definition > .bx--tooltip__trigger.bx--tooltip--bottom::before {
        border-bottom-color: var(--cds-inverse-01, #ffffff); }
      .security--header__notification__content > .bx--tooltip--definition > .bx--tooltip__trigger.bx--tooltip--top::before {
        border-top-color: var(--cds-inverse-01, #ffffff); }
  .security--header__notification__details {
    font-size: var(--cds-caption-01-font-size, 0.75rem);
    font-weight: var(--cds-caption-01-font-weight, 400);
    line-height: var(--cds-caption-01-line-height, 1.33333);
    letter-spacing: var(--cds-caption-01-letter-spacing, 0.32px);
    display: block;
    margin-bottom: 0.5rem; }
  .security--header__notification__time {
    font-weight: 600; }
  .security--header__notification__button {
    box-sizing: border-box;
    padding: 0;
    border: 0;
    margin: 0;
    font-family: inherit;
    font-size: 100%;
    vertical-align: baseline;
    display: inline-block;
    padding: 0;
    border: 0;
    appearance: none;
    background: none;
    cursor: pointer;
    width: 100%;
    width: 2.5rem;
    height: 2.5rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 0;
    padding-right: 0;
    padding-left: 0;
    border: 0;
    background-color: inherit;
    color: var(--cds-icon-01, #161616);
    fill: currentColor;
    transition: 240ms cubic-bezier(0.2, 0, 0.38, 0.9) background-color;
    margin-left: auto;
    color: var(--cds-inverse-01, #ffffff);
    transition-duration: 110ms;
    transition-property: background-color;
    transition-timing-function: cubic-bezier(0.2, 0, 0.38, 0.9);
    flex-shrink: 0;
    cursor: inherit;
    opacity: 0;
    transform: translate3d(0.5rem, 0, 0);
    transition-duration: 110ms;
    transition-property: opacity, transform;
    transition-timing-function: cubic-bezier(0.2, 0, 0.38, 0.9); }
    .security--header__notification__button *,
    .security--header__notification__button *::before,
    .security--header__notification__button *::after {
      box-sizing: inherit; }
    .security--header__notification__button::-moz-focus-inner {
      border: 0; }
    .security--header__notification__button + .security--button--icon__tooltip::before {
      left: 1.25rem; }
    .security--header__notification__button:hover, .security--header__notification__button:focus {
      background-color: var(--cds-hover-ui, #e5e5e5); }
    .security--header__notification__button:focus {
      outline: 2px solid var(--cds-focus, #0f62fe);
      outline-offset: -2px;
      border-width: 0; }
      @media screen and (prefers-contrast) {
        .security--header__notification__button:focus {
          outline-style: dotted; } }
    .security--header__notification__button:disabled {
      cursor: not-allowed;
      fill: var(--cds-disabled-03, #8d8d8d); }
      .security--header__notification__button:disabled, .security--header__notification__button:disabled:hover, .security--header__notification__button:disabled:focus {
        background-color: var(--cds-disabled-01, #f4f4f4); }
      .security--header__notification__button:disabled:focus {
        outline-width: 0; }
    .security--header__notification__button--lg {
      width: 3rem;
      height: 3rem; }
      .security--header__notification__button--lg + .security--button--icon__tooltip::before {
        left: 1.5rem; }
    .security--header__notification__button--sm {
      width: 2rem;
      height: 2rem; }
      .security--header__notification__button--sm + .security--button--icon__tooltip::before {
        left: 1rem; }
    .security--header__notification__button--xl {
      width: 4rem;
      height: 4rem; }
      .security--header__notification__button--xl + .security--button--icon__tooltip::before {
        left: 2rem; }
    .security--header__notification__button--tooltip::before, .security--header__notification__button--tooltip::after {
      z-index: 6000; }
    .security--header__notification__button--tooltip::before {
      font-size: var(--cds-body-short-01-font-size, 0.875rem);
      font-weight: var(--cds-body-short-01-font-weight, 400);
      line-height: var(--cds-body-short-01-line-height, 1.28572);
      letter-spacing: var(--cds-body-short-01-letter-spacing, 0.16px);
      position: absolute;
      display: flex;
      align-items: center;
      padding: 0.125rem 1rem;
      background-color: var(--cds-inverse-02, #393939);
      border-radius: 0.125rem;
      color: var(--cds-inverse-01, #ffffff);
      opacity: 0;
      pointer-events: none;
      transition-duration: 110ms;
      transition-property: opacity;
      white-space: nowrap;
      content: attr(aria-label); }
    .security--header__notification__button--tooltip::after {
      position: absolute;
      display: inline-block;
      border-width: 0.25rem;
      border-style: solid;
      border-color: transparent;
      content: '';
      opacity: 1;
      transition-duration: 110ms;
      transition-property: opacity;
      opacity: 0; }
    .security--header__notification__button--tooltip:focus::before, .security--header__notification__button--tooltip:focus::after, .security--header__notification__button--tooltip:hover::before, .security--header__notification__button--tooltip:hover::after {
      opacity: 1; }
    .security--header__notification__button--tooltip--top::before, .security--header__notification__button--tooltip--top::after, .security--header__notification__button--tooltip--bottom::before, .security--header__notification__button--tooltip--bottom::after {
      left: 50%; }
    .security--header__notification__button--tooltip--left::before, .security--header__notification__button--tooltip--left::after, .security--header__notification__button--tooltip--right::before, .security--header__notification__button--tooltip--right::after {
      top: 50%; }
    .security--header__notification__button--tooltip--top::before, .security--header__notification__button--tooltip--top::after {
      bottom: calc( 100% + 0.25rem); }
    .security--header__notification__button--tooltip--top::before {
      transform: translate(-50%, -0.25rem); }
    .security--header__notification__button--tooltip--top::after {
      border-top-color: var(--cds-inverse-02, #393939);
      transform: translate(-50%, 0.25rem); }
    .security--header__notification__button--tooltip--right::before, .security--header__notification__button--tooltip--right::after {
      left: calc( 100% + 0.25rem); }
    .security--header__notification__button--tooltip--right::before {
      transform: translate(0.25rem, -50%); }
    .security--header__notification__button--tooltip--right::after {
      border-right-color: var(--cds-inverse-02, #393939);
      transform: translate(-0.25rem, -50%); }
    .security--header__notification__button--tooltip--bottom::before, .security--header__notification__button--tooltip--bottom::after {
      top: calc( 100% + 0.25rem); }
    .security--header__notification__button--tooltip--bottom::before {
      transform: translate(-50%, 0.25rem); }
    .security--header__notification__button--tooltip--bottom::after {
      border-bottom-color: var(--cds-inverse-02, #393939);
      transform: translate(-50%, -0.25rem); }
    .security--header__notification__button--tooltip--left::before, .security--header__notification__button--tooltip--left::after {
      right: calc( 100% + 0.25rem); }
    .security--header__notification__button--tooltip--left::before {
      transform: translate(-0.25rem, -50%); }
    .security--header__notification__button--tooltip--left::after {
      border-left-color: var(--cds-inverse-02, #393939);
      transform: translate(0.25rem, -50%); }
    .security--header__notification__button:focus, .security--header__notification__button:hover {
      background-color: var(--cds-inverse-hover-ui, #4c4c4c);
      outline-color: var(--cds-inverse-focus-ui, #ffffff); }
    .security--header__notification__button:focus {
      outline: 2px solid var(--cds-focus, #0f62fe);
      outline-offset: -2px;
      outline-color: var(--cds-inverse-focus-ui, #ffffff);
      opacity: 1;
      transform: translate3d(0, 0, 0); }
      @media screen and (prefers-contrast) {
        .security--header__notification__button:focus {
          outline-style: dotted; } }
    .security--header__notification__button:active, .security--header__notification__button:focus, .security--header__notification__button:hover {
      background-color: var(--cds-active-tertiary, #002d9c);
      cursor: pointer; }
  .security--header__notification__transition--enter--active, .security--header__notification__transition--leave--active {
    opacity: 1;
    transition-timing-function: cubic-bezier(0.2, 0, 0.38, 0.9); }
  .security--header__notification__transition--enter {
    opacity: 0; }
    .security--header__notification__transition--enter--active {
      opacity: 1; }
  .security--header__notification__transition--leave {
    opacity: 1; }
    .security--header__notification__transition--leave--active {
      opacity: 0; }
  .security--header__notification__transition--leave {
    max-height: 6rem;
    transition-duration: 110ms;
    transition-property: max-height, opacity; }
    .security--header__notification__transition--leave--active {
      max-height: 0; }

.security--header__popover__header {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  padding: 1.5rem; }
  .security--header__popover__header__title {
    font-size: var(--cds-heading-02-font-size, 1rem);
    font-weight: var(--cds-heading-02-font-weight, 600);
    line-height: var(--cds-heading-02-line-height, 1.5);
    letter-spacing: var(--cds-heading-02-letter-spacing, 0); }

.security--header__popover__link--secondary, .security--header__popover__link--secondary:focus, .security--header__popover__link--secondary:hover, .security--header__popover__link--secondary:visited {
  color: var(--cds-inverse-link, #78a9ff); }

.security--header__popover__link--secondary:focus {
  outline-color: var(--cds-inverse-link, #78a9ff); }

.security--header {
  position: fixed;
  z-index: 8000;
  top: 0;
  display: flex;
  width: inherit;
  height: inherit;
  align-items: center;
  padding-top: inherit;
  background-color: inherit;
  color: inherit; }
  .security--header__container {
    width: 100%;
    height: 3rem;
    padding-top: 0.5rem;
    background-color: var(--cds-ui-background, #ffffff); }
  .security--header__link {
    font-size: var(--cds-body-short-01-font-size, 0.875rem);
    font-weight: var(--cds-body-short-01-font-weight, 400);
    line-height: var(--cds-body-short-01-line-height, 1.28572);
    letter-spacing: var(--cds-body-short-01-letter-spacing, 0.16px);
    display: inline;
    margin-left: 2rem; }
    .security--header__link, .security--header__link:visited {
      color: inherit; }
    .security--header__link:hover, .security--header__link:focus {
      color: var(--cds-text-02, #525252); }
    .security--header__link__title--domain {
      font-size: var(--cds-heading-01-font-size, 0.875rem);
      font-weight: var(--cds-heading-01-font-weight, 600);
      line-height: var(--cds-heading-01-line-height, 1.42857);
      letter-spacing: var(--cds-heading-01-letter-spacing, 0.16px); }
  .security--header__group {
    display: flex;
    padding-left: unset;
    margin-top: unset;
    margin-bottom: unset;
    margin-left: auto;
    list-style: none; }
    .security--header__group--active {
      margin-right: 2rem; }
  .security--header__button::after {
    height: 100%;
    content: ''; }
  .security--header__button--active::after {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 0.25rem;
    background-color: var(--cds-interactive-01, #0f62fe);
    transition-duration: 240ms;
    transition-property: height;
    transition-timing-function: cubic-bezier(0.2, 0, 0.38, 0.9); }
  .security--header__button--notifications::before {
    position: absolute;
    z-index: 6000;
    top: 0.75rem;
    right: 0.5rem;
    display: inline-block;
    width: 0.5rem;
    height: 0.5rem;
    background-color: var(--cds-support-01, #da1e28);
    border-radius: 50%;
    content: ''; }
  .security--header__popover {
    font-size: var(--cds-body-short-01-font-size, 0.875rem);
    font-weight: var(--cds-body-short-01-font-weight, 400);
    line-height: var(--cds-body-short-01-line-height, 1.28572);
    letter-spacing: var(--cds-body-short-01-letter-spacing, 0.16px);
    position: absolute;
    right: 0;
    width: 100%;
    max-width: 100%;
    background-color: var(--cds-inverse-02, #393939);
    color: var(--cds-inverse-01, #ffffff); }
    @media (min-width: 42rem) {
      .security--header__popover {
        right: 0.5rem;
        width: 17rem; } }
    .security--header__popover--focus:focus {
      outline: none; }
    .security--header__popover__container {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      padding: 1.5rem; }
    .security--header__popover__title {
      font-size: var(--cds-productive-heading-03-font-size, 1.25rem);
      font-weight: var(--cds-productive-heading-03-font-weight, 400);
      line-height: var(--cds-productive-heading-03-line-height, 1.4);
      letter-spacing: var(--cds-productive-heading-03-letter-spacing, 0); }
    .security--header__popover__content {
      display: flex;
      align-items: center;
      padding-right: 0.25rem;
      padding-left: 1.5rem; }
    .security--header__popover__button {
      box-sizing: border-box;
      padding: 0;
      border: 0;
      margin: 0;
      font-family: inherit;
      font-size: 100%;
      vertical-align: baseline;
      display: inline-block;
      padding: 0;
      border: 0;
      appearance: none;
      background: none;
      cursor: pointer;
      width: 100%;
      width: 2.5rem;
      height: 2.5rem;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 0;
      padding-right: 0;
      padding-left: 0;
      border: 0;
      background-color: inherit;
      color: var(--cds-icon-01, #161616);
      fill: currentColor;
      transition: 240ms cubic-bezier(0.2, 0, 0.38, 0.9) background-color;
      margin-left: auto;
      color: var(--cds-inverse-01, #ffffff);
      transition-duration: 110ms;
      transition-property: background-color;
      transition-timing-function: cubic-bezier(0.2, 0, 0.38, 0.9); }
      .security--header__popover__button *,
      .security--header__popover__button *::before,
      .security--header__popover__button *::after {
        box-sizing: inherit; }
      .security--header__popover__button::-moz-focus-inner {
        border: 0; }
      .security--header__popover__button + .security--button--icon__tooltip::before {
        left: 1.25rem; }
      .security--header__popover__button:hover, .security--header__popover__button:focus {
        background-color: var(--cds-hover-ui, #e5e5e5); }
      .security--header__popover__button:focus {
        outline: 2px solid var(--cds-focus, #0f62fe);
        outline-offset: -2px;
        border-width: 0; }
        @media screen and (prefers-contrast) {
          .security--header__popover__button:focus {
            outline-style: dotted; } }
      .security--header__popover__button:disabled {
        cursor: not-allowed;
        fill: var(--cds-disabled-03, #8d8d8d); }
        .security--header__popover__button:disabled, .security--header__popover__button:disabled:hover, .security--header__popover__button:disabled:focus {
          background-color: var(--cds-disabled-01, #f4f4f4); }
        .security--header__popover__button:disabled:focus {
          outline-width: 0; }
      .security--header__popover__button--lg {
        width: 3rem;
        height: 3rem; }
        .security--header__popover__button--lg + .security--button--icon__tooltip::before {
          left: 1.5rem; }
      .security--header__popover__button--sm {
        width: 2rem;
        height: 2rem; }
        .security--header__popover__button--sm + .security--button--icon__tooltip::before {
          left: 1rem; }
      .security--header__popover__button--xl {
        width: 4rem;
        height: 4rem; }
        .security--header__popover__button--xl + .security--button--icon__tooltip::before {
          left: 2rem; }
      .security--header__popover__button--tooltip::before, .security--header__popover__button--tooltip::after {
        z-index: 6000; }
      .security--header__popover__button--tooltip::before {
        font-size: var(--cds-body-short-01-font-size, 0.875rem);
        font-weight: var(--cds-body-short-01-font-weight, 400);
        line-height: var(--cds-body-short-01-line-height, 1.28572);
        letter-spacing: var(--cds-body-short-01-letter-spacing, 0.16px);
        position: absolute;
        display: flex;
        align-items: center;
        padding: 0.125rem 1rem;
        background-color: var(--cds-inverse-02, #393939);
        border-radius: 0.125rem;
        color: var(--cds-inverse-01, #ffffff);
        opacity: 0;
        pointer-events: none;
        transition-duration: 110ms;
        transition-property: opacity;
        white-space: nowrap;
        content: attr(aria-label); }
      .security--header__popover__button--tooltip::after {
        position: absolute;
        display: inline-block;
        border-width: 0.25rem;
        border-style: solid;
        border-color: transparent;
        content: '';
        opacity: 1;
        transition-duration: 110ms;
        transition-property: opacity;
        opacity: 0; }
      .security--header__popover__button--tooltip:focus::before, .security--header__popover__button--tooltip:focus::after, .security--header__popover__button--tooltip:hover::before, .security--header__popover__button--tooltip:hover::after {
        opacity: 1; }
      .security--header__popover__button--tooltip--top::before, .security--header__popover__button--tooltip--top::after, .security--header__popover__button--tooltip--bottom::before, .security--header__popover__button--tooltip--bottom::after {
        left: 50%; }
      .security--header__popover__button--tooltip--left::before, .security--header__popover__button--tooltip--left::after, .security--header__popover__button--tooltip--right::before, .security--header__popover__button--tooltip--right::after {
        top: 50%; }
      .security--header__popover__button--tooltip--top::before, .security--header__popover__button--tooltip--top::after {
        bottom: calc( 100% + 0.25rem); }
      .security--header__popover__button--tooltip--top::before {
        transform: translate(-50%, -0.25rem); }
      .security--header__popover__button--tooltip--top::after {
        border-top-color: var(--cds-inverse-02, #393939);
        transform: translate(-50%, 0.25rem); }
      .security--header__popover__button--tooltip--right::before, .security--header__popover__button--tooltip--right::after {
        left: calc( 100% + 0.25rem); }
      .security--header__popover__button--tooltip--right::before {
        transform: translate(0.25rem, -50%); }
      .security--header__popover__button--tooltip--right::after {
        border-right-color: var(--cds-inverse-02, #393939);
        transform: translate(-0.25rem, -50%); }
      .security--header__popover__button--tooltip--bottom::before, .security--header__popover__button--tooltip--bottom::after {
        top: calc( 100% + 0.25rem); }
      .security--header__popover__button--tooltip--bottom::before {
        transform: translate(-50%, 0.25rem); }
      .security--header__popover__button--tooltip--bottom::after {
        border-bottom-color: var(--cds-inverse-02, #393939);
        transform: translate(-50%, -0.25rem); }
      .security--header__popover__button--tooltip--left::before, .security--header__popover__button--tooltip--left::after {
        right: calc( 100% + 0.25rem); }
      .security--header__popover__button--tooltip--left::before {
        transform: translate(-0.25rem, -50%); }
      .security--header__popover__button--tooltip--left::after {
        border-left-color: var(--cds-inverse-02, #393939);
        transform: translate(0.25rem, -50%); }
      .security--header__popover__button:focus, .security--header__popover__button:hover {
        background-color: var(--cds-inverse-hover-ui, #4c4c4c);
        outline-color: var(--cds-inverse-focus-ui, #ffffff); }
    .security--header__popover__label {
      font-size: var(--cds-heading-01-font-size, 0.875rem);
      font-weight: var(--cds-heading-01-font-weight, 600);
      line-height: var(--cds-heading-01-line-height, 1.42857);
      letter-spacing: var(--cds-heading-01-letter-spacing, 0.16px); }
    .security--header__popover__list {
      max-height: 35rem;
      padding-left: unset;
      list-style: none; }
    .security--header__popover__list-item {
      position: relative;
      display: block;
      padding-right: 1.5rem;
      padding-left: 1rem;
      color: unset;
      text-decoration: initial;
      transition-duration: 110ms;
      transition-property: background-color;
      transition-timing-function: cubic-bezier(0.2, 0, 0.38, 0.9); }
      .security--header__popover__list-item .security--header__notification {
        box-shadow: inset 0 -0.0625rem 0 0 var(--cds-inverse-hover-ui, #4c4c4c); }
        .security--header__popover__list-item .security--header__notification__wrapper {
          margin-right: -1.5rem; }
        .security--header__popover__list-item .security--header__notification__button {
          display: flex;
          width: 3rem;
          height: 100%;
          flex-direction: column;
          justify-content: center; }
      .security--header__popover__list-item:focus, .security--header__popover__list-item:hover {
        background-color: var(--cds-inverse-hover-ui, #4c4c4c); }
        .security--header__popover__list-item:focus .security--header__notification__button, .security--header__popover__list-item:hover .security--header__notification__button {
          opacity: 1;
          transform: translate3d(0, 0, 0); }
      .security--header__popover__list-item:focus {
        outline: 2px solid var(--cds-focus, #0f62fe);
        outline-offset: -2px; }
        @media screen and (prefers-contrast) {
          .security--header__popover__list-item:focus {
            outline-style: dotted; } }
      .security--header__popover__list-item:last-of-type .security--header__notification {
        box-shadow: none; }
    .security--header__popover__icon {
      fill: currentColor; }
    .security--header__popover__profile--large {
      margin-right: 1rem; }
    .security--header__popover__profile__header__icon {
      flex: none;
      margin-right: 1rem;
      margin-bottom: auto; }
    .security--header__popover__profile__header__title, .security--header__popover__profile__header__email {
      display: block;
      word-wrap: anywhere; }
    .security--header__popover__profile__header__title {
      font-size: var(--cds-productive-heading-03-font-size, 1.25rem);
      font-weight: var(--cds-productive-heading-03-font-weight, 400);
      line-height: var(--cds-productive-heading-03-line-height, 1.4);
      letter-spacing: var(--cds-productive-heading-03-letter-spacing, 0);
      hyphens: auto;
      word-break: break-word; }
    .security--header__popover__profile__header__email {
      font-size: var(--cds-caption-01-font-size, 0.75rem);
      font-weight: var(--cds-caption-01-font-weight, 400);
      line-height: var(--cds-caption-01-line-height, 1.33333);
      letter-spacing: var(--cds-caption-01-letter-spacing, 0.32px);
      word-break: break-all; }
    .security--header__popover__profile__description {
      padding-right: 1.5rem;
      padding-bottom: 1rem;
      padding-left: 1.5rem; }
    .security--header__popover__profile__body--account {
      padding-right: 1.5rem;
      padding-bottom: 1rem;
      padding-left: 1.5rem; }
    .security--header__popover__profile__body__label {
      font-size: var(--cds-caption-01-font-size, 0.75rem);
      font-weight: var(--cds-caption-01-font-weight, 400);
      line-height: var(--cds-caption-01-line-height, 1.33333);
      letter-spacing: var(--cds-caption-01-letter-spacing, 0.32px); }
    .security--header__popover__profile__body__name {
      font-size: var(--cds-body-short-02-font-size, 1rem);
      font-weight: var(--cds-body-short-02-font-weight, 400);
      line-height: var(--cds-body-short-02-line-height, 1.375);
      letter-spacing: var(--cds-body-short-02-letter-spacing, 0);
      overflow: hidden;
      width: 13rem;
      text-align: left;
      text-overflow: ellipsis;
      white-space: nowrap; }
    .security--header__popover__profile__body__accordion > .bx--accordion__item--active .bx--accordion__arrow {
      transform: rotate3d(0, 0, 1, 270deg); }
    .security--header__popover__profile__body__accordion__item:last-child {
      border-width: 0; }
    .security--header__popover__profile__body__accordion__item .bx--accordion__arrow, .security--header__popover__profile__body__accordion__item .bx--accordion__heading {
      color: inherit; }
    .security--header__popover__profile__body__accordion__item .bx--accordion__arrow {
      margin-right: 1rem;
      margin-left: unset;
      fill: currentColor;
      transform: rotate3d(0, 0, 1, 90deg); }
    .security--header__popover__profile__body__accordion__item .bx--accordion__heading {
      flex-direction: row-reverse; }
      .security--header__popover__profile__body__accordion__item .bx--accordion__heading:focus::before, .security--header__popover__profile__body__accordion__item .bx--accordion__heading:hover::before {
        background-color: var(--cds-inverse-hover-ui, #4c4c4c); }
      .security--header__popover__profile__body__accordion__item .bx--accordion__heading:focus::before {
        outline: 2px solid var(--cds-focus, #0f62fe);
        outline-offset: -2px;
        outline-color: var(--cds-inverse-focus-ui, #ffffff); }
        @media screen and (prefers-contrast) {
          .security--header__popover__profile__body__accordion__item .bx--accordion__heading:focus::before {
            outline-style: dotted; } }
    .security--header__popover__profile__body__accordion__item .bx--accordion__title {
      margin-left: 1.5rem; }
    .security--header__popover__profile__body__accordion__item .bx--accordion__content {
      padding: unset;
      margin-left: unset; }
    .security--header__popover__profile__body__list {
      padding-left: unset;
      list-style: none; }
    .security--header__popover__profile__body__account {
      position: relative; }
      .security--header__popover__profile__body__account__button {
        box-sizing: border-box;
        padding: 0;
        border: 0;
        margin: 0;
        font-family: inherit;
        font-size: 100%;
        vertical-align: baseline;
        display: inline-block;
        padding: 0;
        border: 0;
        appearance: none;
        background: none;
        cursor: pointer;
        width: 100%;
        font-size: var(--cds-body-short-01-font-size, 0.875rem);
        font-weight: var(--cds-body-short-01-font-weight, 400);
        line-height: var(--cds-body-short-01-line-height, 1.28572);
        letter-spacing: var(--cds-body-short-01-letter-spacing, 0.16px);
        padding: 1rem 1.5rem;
        color: inherit;
        text-align: left; }
        .security--header__popover__profile__body__account__button *,
        .security--header__popover__profile__body__account__button *::before,
        .security--header__popover__profile__body__account__button *::after {
          box-sizing: inherit; }
        .security--header__popover__profile__body__account__button::-moz-focus-inner {
          border: 0; }
        .security--header__popover__profile__body__account__button:focus, .security--header__popover__profile__body__account__button:hover {
          background-color: var(--cds-inverse-hover-ui, #4c4c4c); }
        .security--header__popover__profile__body__account__button:focus {
          outline: 2px solid var(--cds-focus, #0f62fe);
          outline-offset: -2px;
          outline-color: var(--cds-inverse-focus-ui, #ffffff); }
          @media screen and (prefers-contrast) {
            .security--header__popover__profile__body__account__button:focus {
              outline-style: dotted; } }
        .security--header__popover__profile__body__account__button::before {
          position: absolute;
          right: 1.5rem;
          bottom: 0;
          left: 1.5rem;
          display: inline-block;
          height: 0.0625rem;
          background-color: var(--cds-inverse-hover-ui, #4c4c4c);
          content: '';
          transform: translate3d(0, -0.125rem, 0); }
      .security--header__popover__profile__body__account:last-of-type .security--header__popover__profile__body__account__button::before {
        height: 0; }
    .security--header__popover__profile__footer__logout {
      margin-left: auto; }
    .security--header__popover__footer {
      font-size: var(--cds-body-short-01-font-size, 0.875rem);
      font-weight: var(--cds-body-short-01-font-weight, 400);
      line-height: var(--cds-body-short-01-line-height, 1.28572);
      letter-spacing: var(--cds-body-short-01-letter-spacing, 0.16px);
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.75rem 1.5rem;
      border-top: 0.0625rem solid var(--cds-inverse-hover-ui, #4c4c4c); }
  .security--header__transition--enter--active, .security--header__transition--leave--active {
    opacity: 1;
    transition-timing-function: cubic-bezier(0.2, 0, 0.38, 0.9); }
  .security--header__transition--enter {
    opacity: 0; }
    .security--header__transition--enter--active {
      opacity: 1; }
  .security--header__transition--leave {
    opacity: 1; }
    .security--header__transition--leave--active {
      opacity: 0; }
  .security--header__transition--enter--active, .security--header__transition--leave--active {
    transition-delay: 0.025s;
    transition-duration: 110ms;
    transition-property: opacity, transform; }
  .security--header__transition--enter {
    transform: translate3d(0, -3rem, 0); }
    .security--header__transition--enter--active {
      transform: translate3d(0, 0, 0); }
  .security--header .selectable-text {
    -moz-user-select: text;
    -webkit-user-select: text;
    -ms-user-select: text;
    user-select: text; }

@keyframes rotate {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes rotate-end-p1 {
  100% {
    transform: rotate(360deg); } }

@keyframes rotate-end-p2 {
  100% {
    transform: rotate(-360deg); } }

/* Stroke animations */
@keyframes init-stroke {
  0% {
    stroke-dashoffset: 276.4608; }
  100% {
    stroke-dashoffset: 52.52755; } }

@keyframes stroke-end {
  0% {
    stroke-dashoffset: 52.52755; }
  100% {
    stroke-dashoffset: 276.4608; } }

.security--loading-message {
  display: flex;
  align-items: center; }
  .security--loading-message__loading {
    margin-right: 0.5rem; }

.bx--multi-select .bx--tag {
  min-width: auto;
  margin: 0 0.5rem 0 0; }

.bx--multi-select--filterable .bx--tag {
  margin: 0 0.5rem 0 1rem; }

.bx--multi-select .bx--list-box__menu {
  min-width: auto; }

.bx--multi-select .bx--list-box__menu-item__option .bx--checkbox-wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center; }

.bx--multi-select .bx--list-box__menu-item__option .bx--checkbox-label {
  display: inline-block;
  overflow: hidden;
  width: 100%;
  padding-left: 1.75rem;
  text-overflow: ellipsis;
  white-space: nowrap; }

.bx--multi-select .bx--list-box__menu-item__option > .bx--form-item {
  flex-direction: row;
  margin: 0; }

.bx--multi-select .bx--list-box__menu-item .bx--checkbox:checked ~ .bx--checkbox-label-text {
  color: var(--cds-text-01, #161616); }

.bx--multi-select--filterable {
  transition: outline-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }

.bx--multi-select--filterable.bx--combo-box .bx--text-input {
  border: 0.125rem solid transparent;
  background-clip: padding-box;
  outline: none; }

.bx--multi-select--filterable--input-focused {
  outline: 2px solid var(--cds-focus, #0f62fe);
  outline-offset: -2px; }
  @media screen and (prefers-contrast) {
    .bx--multi-select--filterable--input-focused {
      outline-style: dotted; } }

.bx--multi-select--filterable.bx--multi-select--selected .bx--text-input {
  padding-left: 0; }

.bx--multi-select--filterable.bx--list-box--disabled:hover .bx--text-input {
  background-color: var(--cds-field-01, #f4f4f4); }

.bx--multi-select--filterable .bx--list-box__selection--multi {
  margin: 0 0 0 var(--cds-spacing-05, 1rem); }

.bx--multi-select--filterable.bx--multi-select--inline,
.bx--multi-select--filterable.bx--multi-select--inline .bx--text-input {
  border-bottom: 0;
  background-color: transparent; }

.bx--inline-notification {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  position: relative;
  display: flex;
  width: 100%;
  min-width: 18rem;
  max-width: 18rem;
  height: auto;
  min-height: 3rem;
  flex-wrap: wrap;
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: var(--cds-inverse-01, #ffffff); }
  .bx--inline-notification *,
  .bx--inline-notification *::before,
  .bx--inline-notification *::after {
    box-sizing: inherit; }
  @media (min-width: 42rem) {
    .bx--inline-notification {
      max-width: 38rem;
      flex-wrap: nowrap; } }
  @media (min-width: 66rem) {
    .bx--inline-notification {
      max-width: 46rem; } }
  @media (min-width: 99rem) {
    .bx--inline-notification {
      max-width: 52rem; } }

.bx--inline-notification:not(.bx--inline-notification--low-contrast) a {
  color: var(--cds-inverse-link, #78a9ff); }

.bx--inline-notification a {
  text-decoration: none; }

.bx--inline-notification a:hover {
  text-decoration: underline; }

.bx--inline-notification a:focus {
  outline: 1px solid var(--cds-inverse-link, #78a9ff); }

.bx--inline-notification.bx--inline-notification--low-contrast a:focus {
  outline: 1px solid var(--cds-focus, #0f62fe); }
  @media screen and (prefers-contrast) {
    .bx--inline-notification.bx--inline-notification--low-contrast a:focus {
      outline-style: dotted; } }

.bx--inline-notification--low-contrast {
  color: var(--cds-text-01, #161616); }
  .bx--inline-notification--low-contrast::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border-width: 1px 1px 1px 0;
    border-style: solid;
    content: '';
    filter: opacity(0.4);
    pointer-events: none; }

.bx--inline-notification--error {
  border-left: 3px solid var(--cds-inverse-support-01, #fa4d56);
  background: var(--cds-inverse-02, #393939); }
  .bx--inline-notification--error .bx--inline-notification__icon,
  .bx--inline-notification--error .bx--toast-notification__icon,
  .bx--inline-notification--error .bx--actionable-notification__icon {
    fill: var(--cds-inverse-support-01, #fa4d56); }

.bx--inline-notification--low-contrast.bx--inline-notification--error {
  border-left: 3px solid var(--cds-support-01, #da1e28);
  background: var(--cds-notification-background-error, #fff1f1); }
  .bx--inline-notification--low-contrast.bx--inline-notification--error .bx--inline-notification__icon,
  .bx--inline-notification--low-contrast.bx--inline-notification--error .bx--toast-notification__icon,
  .bx--inline-notification--low-contrast.bx--inline-notification--error .bx--actionable-notification__icon {
    fill: var(--cds-support-01, #da1e28); }
  .bx--inline-notification--low-contrast.bx--inline-notification--error::before {
    border-color: var(--cds-support-01, #da1e28); }

.bx--inline-notification--success {
  border-left: 3px solid var(--cds-inverse-support-02, #42be65);
  background: var(--cds-inverse-02, #393939); }
  .bx--inline-notification--success .bx--inline-notification__icon,
  .bx--inline-notification--success .bx--toast-notification__icon,
  .bx--inline-notification--success .bx--actionable-notification__icon {
    fill: var(--cds-inverse-support-02, #42be65); }

.bx--inline-notification--low-contrast.bx--inline-notification--success {
  border-left: 3px solid var(--cds-support-02, #198038);
  background: var(--cds-notification-background-success, #defbe6); }
  .bx--inline-notification--low-contrast.bx--inline-notification--success .bx--inline-notification__icon,
  .bx--inline-notification--low-contrast.bx--inline-notification--success .bx--toast-notification__icon,
  .bx--inline-notification--low-contrast.bx--inline-notification--success .bx--actionable-notification__icon {
    fill: var(--cds-support-02, #198038); }
  .bx--inline-notification--low-contrast.bx--inline-notification--success::before {
    border-color: var(--cds-support-02, #198038); }

.bx--inline-notification--info,
.bx--inline-notification--info-square {
  border-left: 3px solid var(--cds-inverse-support-04, #4589ff);
  background: var(--cds-inverse-02, #393939); }
  .bx--inline-notification--info .bx--inline-notification__icon,
  .bx--inline-notification--info .bx--toast-notification__icon,
  .bx--inline-notification--info .bx--actionable-notification__icon,
  .bx--inline-notification--info-square .bx--inline-notification__icon,
  .bx--inline-notification--info-square .bx--toast-notification__icon,
  .bx--inline-notification--info-square .bx--actionable-notification__icon {
    fill: var(--cds-inverse-support-04, #4589ff); }

.bx--inline-notification--low-contrast.bx--inline-notification--info,
.bx--inline-notification--low-contrast.bx--inline-notification--info-square {
  border-left: 3px solid var(--cds-support-04, #0043ce);
  background: var(--cds-notification-background-info, #edf5ff); }
  .bx--inline-notification--low-contrast.bx--inline-notification--info .bx--inline-notification__icon,
  .bx--inline-notification--low-contrast.bx--inline-notification--info .bx--toast-notification__icon,
  .bx--inline-notification--low-contrast.bx--inline-notification--info .bx--actionable-notification__icon,
  .bx--inline-notification--low-contrast.bx--inline-notification--info-square .bx--inline-notification__icon,
  .bx--inline-notification--low-contrast.bx--inline-notification--info-square .bx--toast-notification__icon,
  .bx--inline-notification--low-contrast.bx--inline-notification--info-square .bx--actionable-notification__icon {
    fill: var(--cds-support-04, #0043ce); }
  .bx--inline-notification--low-contrast.bx--inline-notification--info::before,
  .bx--inline-notification--low-contrast.bx--inline-notification--info-square::before {
    border-color: var(--cds-support-04, #0043ce); }

.bx--inline-notification--warning,
.bx--inline-notification--warning-alt {
  border-left: 3px solid var(--cds-inverse-support-03, #f1c21b);
  background: var(--cds-inverse-02, #393939); }
  .bx--inline-notification--warning .bx--inline-notification__icon,
  .bx--inline-notification--warning .bx--toast-notification__icon,
  .bx--inline-notification--warning .bx--actionable-notification__icon,
  .bx--inline-notification--warning-alt .bx--inline-notification__icon,
  .bx--inline-notification--warning-alt .bx--toast-notification__icon,
  .bx--inline-notification--warning-alt .bx--actionable-notification__icon {
    fill: var(--cds-inverse-support-03, #f1c21b); }

.bx--inline-notification--low-contrast.bx--inline-notification--warning,
.bx--inline-notification--low-contrast.bx--inline-notification--warning-alt {
  border-left: 3px solid var(--cds-support-03, #f1c21b);
  background: var(--cds-notification-background-warning, #fdf6dd); }
  .bx--inline-notification--low-contrast.bx--inline-notification--warning .bx--inline-notification__icon,
  .bx--inline-notification--low-contrast.bx--inline-notification--warning .bx--toast-notification__icon,
  .bx--inline-notification--low-contrast.bx--inline-notification--warning .bx--actionable-notification__icon,
  .bx--inline-notification--low-contrast.bx--inline-notification--warning-alt .bx--inline-notification__icon,
  .bx--inline-notification--low-contrast.bx--inline-notification--warning-alt .bx--toast-notification__icon,
  .bx--inline-notification--low-contrast.bx--inline-notification--warning-alt .bx--actionable-notification__icon {
    fill: var(--cds-support-03, #f1c21b); }
  .bx--inline-notification--low-contrast.bx--inline-notification--warning::before,
  .bx--inline-notification--low-contrast.bx--inline-notification--warning-alt::before {
    border-color: var(--cds-support-03, #f1c21b); }

.bx--inline-notification--warning .bx--inline-notification__icon path[opacity='0'] {
  fill: #000000;
  opacity: 1; }

.bx--inline-notification__details {
  display: flex;
  flex-grow: 1;
  margin: 0 3rem 0 1rem; }
  @media (min-width: 42rem) {
    .bx--inline-notification__details {
      margin: 0 1rem; } }

.bx--inline-notification__icon {
  flex-shrink: 0;
  margin-top: 0.875rem;
  margin-right: 1rem; }

.bx--inline-notification__text-wrapper {
  display: flex;
  flex-wrap: wrap;
  padding: 0.9375rem 0; }

.bx--inline-notification__title {
  font-size: var(--cds-productive-heading-01-font-size, 0.875rem);
  font-weight: var(--cds-productive-heading-01-font-weight, 600);
  line-height: var(--cds-productive-heading-01-line-height, 1.28572);
  letter-spacing: var(--cds-productive-heading-01-letter-spacing, 0.16px);
  margin: 0 0.25rem 0 0; }

.bx--inline-notification__subtitle {
  font-size: var(--cds-body-short-01-font-size, 0.875rem);
  font-weight: var(--cds-body-short-01-font-weight, 400);
  line-height: var(--cds-body-short-01-line-height, 1.28572);
  letter-spacing: var(--cds-body-short-01-letter-spacing, 0.16px);
  word-break: break-word; }

.bx--inline-notification__action-button.bx--btn--ghost {
  height: 2rem;
  margin-bottom: 0.5rem;
  margin-left: 2.5rem; }
  @media (min-width: 42rem) {
    .bx--inline-notification__action-button.bx--btn--ghost {
      margin: 0.5rem 0; } }

.bx--inline-notification:not(.bx--inline-notification--low-contrast) .bx--inline-notification__action-button.bx--btn--ghost {
  color: var(--cds-inverse-link, #78a9ff); }

.bx--inline-notification__action-button.bx--btn--ghost:active,
.bx--inline-notification__action-button.bx--btn--ghost:hover {
  background-color: var(--cds-inverse-hover-ui, #4c4c4c); }

.bx--inline-notification--low-contrast .bx--inline-notification__action-button.bx--btn--ghost:active,
.bx--inline-notification--low-contrast .bx--inline-notification__action-button.bx--btn--ghost:hover {
  background-color: var(--cds-notification-action-hover, #ffffff); }

.bx--inline-notification__action-button.bx--btn--ghost:focus {
  border-color: transparent;
  box-shadow: none;
  outline: 2px solid var(--cds-inverse-focus-ui, #ffffff);
  outline-offset: -2px; }

.bx--inline-notification--low-contrast .bx--inline-notification__action-button.bx--btn--ghost:focus {
  outline-color: var(--cds-focus, #0f62fe); }

.bx--inline-notification--hide-close-button .bx--inline-notification__action-button.bx--btn--ghost {
  margin-right: 0.5rem; }

.bx--inline-notification__close-button {
  outline: 2px solid transparent;
  outline-offset: -2px;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  width: 3rem;
  min-width: 3rem;
  max-width: 3rem;
  height: 3rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: none;
  background: transparent;
  cursor: pointer;
  transition: outline 110ms cubic-bezier(0.2, 0, 0.38, 0.9), background-color 110ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--inline-notification__close-button:focus {
    outline: 2px solid var(--cds-inverse-focus-ui, #ffffff);
    outline-offset: -2px; }
  .bx--inline-notification__close-button .bx--inline-notification__close-icon {
    fill: var(--cds-inverse-01, #ffffff); }
  @media (min-width: 42rem) {
    .bx--inline-notification__close-button {
      position: static; } }

.bx--inline-notification--low-contrast .bx--inline-notification__close-button:focus {
  outline: 2px solid var(--cds-focus, #0f62fe);
  outline-offset: -2px; }
  @media screen and (prefers-contrast) {
    .bx--inline-notification--low-contrast .bx--inline-notification__close-button:focus {
      outline-style: dotted; } }

.bx--inline-notification--low-contrast .bx--inline-notification__close-button .bx--inline-notification__close-icon {
  fill: var(--cds-icon-01, #161616); }

/* stylelint-disable */
@media screen and (-ms-high-contrast: active), (forced-colors: active), (prefers-contrast) {
  .bx--inline-notification {
    outline: 1px solid transparent; } }

@media screen and (-ms-high-contrast: active), (forced-colors: active), (prefers-contrast) {
  .bx--inline-notification__close-button:focus,
  .bx--btn.bx--btn--ghost.bx--inline-notification__action-button:focus {
    color: Highlight;
    outline: 1px solid Highlight; } }

@media screen and (-ms-high-contrast: active), (forced-colors: active), (prefers-contrast) {
  .bx--inline-notification__icon {
    fill: ButtonText; } }

/* stylelint-enable */
.bx--toast-notification {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  display: flex;
  width: 18rem;
  height: auto;
  padding-left: 1rem;
  margin-top: 0.5rem;
  margin-right: 1rem;
  margin-bottom: 0.5rem;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
  color: var(--cds-inverse-01, #ffffff); }
  .bx--toast-notification *,
  .bx--toast-notification *::before,
  .bx--toast-notification *::after {
    box-sizing: inherit; }
  .bx--toast-notification:first-child {
    margin-top: 1rem; }
  @media (min-width: 99rem) {
    .bx--toast-notification {
      width: 22rem; } }

.bx--toast-notification:not(.bx--toast-notification--low-contrast) a {
  color: var(--cds-inverse-link, #78a9ff); }

.bx--toast-notification a {
  text-decoration: none; }

.bx--toast-notification a:hover {
  text-decoration: underline; }

.bx--toast-notification a:focus {
  outline: 1px solid var(--cds-inverse-link, #78a9ff); }

.bx--toast-notification.bx--toast-notification--low-contrast a:focus {
  outline: 1px solid var(--cds-focus, #0f62fe); }
  @media screen and (prefers-contrast) {
    .bx--toast-notification.bx--toast-notification--low-contrast a:focus {
      outline-style: dotted; } }

.bx--toast-notification--low-contrast {
  color: var(--cds-text-01, #161616); }

.bx--toast-notification--error {
  border-left: 3px solid var(--cds-inverse-support-01, #fa4d56);
  background: var(--cds-inverse-02, #393939); }
  .bx--toast-notification--error .bx--inline-notification__icon,
  .bx--toast-notification--error .bx--toast-notification__icon,
  .bx--toast-notification--error .bx--actionable-notification__icon {
    fill: var(--cds-inverse-support-01, #fa4d56); }

.bx--toast-notification--low-contrast.bx--toast-notification--error {
  border-left: 3px solid var(--cds-support-01, #da1e28);
  background: var(--cds-notification-background-error, #fff1f1); }
  .bx--toast-notification--low-contrast.bx--toast-notification--error .bx--inline-notification__icon,
  .bx--toast-notification--low-contrast.bx--toast-notification--error .bx--toast-notification__icon,
  .bx--toast-notification--low-contrast.bx--toast-notification--error .bx--actionable-notification__icon {
    fill: var(--cds-support-01, #da1e28); }

.bx--toast-notification--success {
  border-left: 3px solid var(--cds-inverse-support-02, #42be65);
  background: var(--cds-inverse-02, #393939); }
  .bx--toast-notification--success .bx--inline-notification__icon,
  .bx--toast-notification--success .bx--toast-notification__icon,
  .bx--toast-notification--success .bx--actionable-notification__icon {
    fill: var(--cds-inverse-support-02, #42be65); }

.bx--toast-notification--low-contrast.bx--toast-notification--success {
  border-left: 3px solid var(--cds-support-02, #198038);
  background: var(--cds-notification-background-success, #defbe6); }
  .bx--toast-notification--low-contrast.bx--toast-notification--success .bx--inline-notification__icon,
  .bx--toast-notification--low-contrast.bx--toast-notification--success .bx--toast-notification__icon,
  .bx--toast-notification--low-contrast.bx--toast-notification--success .bx--actionable-notification__icon {
    fill: var(--cds-support-02, #198038); }

.bx--toast-notification--info,
.bx--toast-notification--info-square {
  border-left: 3px solid var(--cds-inverse-support-04, #4589ff);
  background: var(--cds-inverse-02, #393939); }
  .bx--toast-notification--info .bx--inline-notification__icon,
  .bx--toast-notification--info .bx--toast-notification__icon,
  .bx--toast-notification--info .bx--actionable-notification__icon,
  .bx--toast-notification--info-square .bx--inline-notification__icon,
  .bx--toast-notification--info-square .bx--toast-notification__icon,
  .bx--toast-notification--info-square .bx--actionable-notification__icon {
    fill: var(--cds-inverse-support-04, #4589ff); }

.bx--toast-notification--low-contrast.bx--toast-notification--info,
.bx--toast-notification--low-contrast.bx--toast-notification--info-square {
  border-left: 3px solid var(--cds-support-04, #0043ce);
  background: var(--cds-notification-background-info, #edf5ff); }
  .bx--toast-notification--low-contrast.bx--toast-notification--info .bx--inline-notification__icon,
  .bx--toast-notification--low-contrast.bx--toast-notification--info .bx--toast-notification__icon,
  .bx--toast-notification--low-contrast.bx--toast-notification--info .bx--actionable-notification__icon,
  .bx--toast-notification--low-contrast.bx--toast-notification--info-square .bx--inline-notification__icon,
  .bx--toast-notification--low-contrast.bx--toast-notification--info-square .bx--toast-notification__icon,
  .bx--toast-notification--low-contrast.bx--toast-notification--info-square .bx--actionable-notification__icon {
    fill: var(--cds-support-04, #0043ce); }

.bx--toast-notification--warning,
.bx--toast-notification--warning-alt {
  border-left: 3px solid var(--cds-inverse-support-03, #f1c21b);
  background: var(--cds-inverse-02, #393939); }
  .bx--toast-notification--warning .bx--inline-notification__icon,
  .bx--toast-notification--warning .bx--toast-notification__icon,
  .bx--toast-notification--warning .bx--actionable-notification__icon,
  .bx--toast-notification--warning-alt .bx--inline-notification__icon,
  .bx--toast-notification--warning-alt .bx--toast-notification__icon,
  .bx--toast-notification--warning-alt .bx--actionable-notification__icon {
    fill: var(--cds-inverse-support-03, #f1c21b); }

.bx--toast-notification--low-contrast.bx--toast-notification--warning,
.bx--toast-notification--low-contrast.bx--toast-notification--warning-alt {
  border-left: 3px solid var(--cds-support-03, #f1c21b);
  background: var(--cds-notification-background-warning, #fdf6dd); }
  .bx--toast-notification--low-contrast.bx--toast-notification--warning .bx--inline-notification__icon,
  .bx--toast-notification--low-contrast.bx--toast-notification--warning .bx--toast-notification__icon,
  .bx--toast-notification--low-contrast.bx--toast-notification--warning .bx--actionable-notification__icon,
  .bx--toast-notification--low-contrast.bx--toast-notification--warning-alt .bx--inline-notification__icon,
  .bx--toast-notification--low-contrast.bx--toast-notification--warning-alt .bx--toast-notification__icon,
  .bx--toast-notification--low-contrast.bx--toast-notification--warning-alt .bx--actionable-notification__icon {
    fill: var(--cds-support-03, #f1c21b); }

.bx--toast-notification--warning .bx--toast-notification__icon path[opacity='0'] {
  fill: #000000;
  opacity: 1; }

.bx--toast-notification__icon {
  flex-shrink: 0;
  margin-top: 1rem;
  margin-right: 1rem; }

.bx--toast-notification__details {
  margin-right: 1rem; }

.bx--toast-notification__close-button {
  outline: 2px solid transparent;
  outline-offset: -2px;
  display: flex;
  width: 3rem;
  min-width: 3rem;
  height: 3rem;
  min-height: 3rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: none;
  margin-left: auto;
  background-color: transparent;
  cursor: pointer;
  transition: outline 110ms, background-color 110ms; }
  .bx--toast-notification__close-button:focus {
    outline: 2px solid var(--cds-inverse-focus-ui, #ffffff);
    outline-offset: -2px; }
  .bx--toast-notification__close-button .bx--toast-notification__close-icon {
    fill: var(--cds-inverse-01, #ffffff); }

.bx--toast-notification--low-contrast .bx--toast-notification__close-button:focus {
  outline: 2px solid var(--cds-focus, #0f62fe);
  outline-offset: -2px; }
  @media screen and (prefers-contrast) {
    .bx--toast-notification--low-contrast .bx--toast-notification__close-button:focus {
      outline-style: dotted; } }

.bx--toast-notification--low-contrast .bx--toast-notification__close-button .bx--toast-notification__close-icon {
  fill: var(--cds-icon-01, #161616); }

.bx--toast-notification__title {
  font-size: var(--cds-productive-heading-01-font-size, 0.875rem);
  font-weight: var(--cds-productive-heading-01-font-weight, 600);
  line-height: var(--cds-productive-heading-01-line-height, 1.28572);
  letter-spacing: var(--cds-productive-heading-01-letter-spacing, 0.16px);
  margin-top: 1rem;
  font-weight: 600;
  word-break: break-word; }

.bx--toast-notification__subtitle {
  font-size: var(--cds-body-short-01-font-size, 0.875rem);
  font-weight: var(--cds-body-short-01-font-weight, 400);
  line-height: var(--cds-body-short-01-line-height, 1.28572);
  letter-spacing: var(--cds-body-short-01-letter-spacing, 0.16px);
  margin-top: 0;
  margin-bottom: 1rem;
  color: var(--cds-inverse-01, #ffffff);
  word-break: break-word; }

.bx--toast-notification--low-contrast .bx--toast-notification__subtitle {
  color: var(--cds-text-primary, #161616); }

.bx--toast-notification__caption {
  font-size: var(--cds-body-short-01-font-size, 0.875rem);
  font-weight: var(--cds-body-short-01-font-weight, 400);
  line-height: var(--cds-body-short-01-line-height, 1.28572);
  letter-spacing: var(--cds-body-short-01-letter-spacing, 0.16px);
  padding-top: 0.5rem;
  margin-bottom: 1rem;
  color: var(--cds-inverse-01, #ffffff); }

.bx--toast-notification--low-contrast .bx--toast-notification__caption {
  color: var(--cds-text-01, #161616); }

/* stylelint-disable */
@media screen and (-ms-high-contrast: active), (forced-colors: active), (prefers-contrast) {
  .bx--toast-notification {
    outline: 1px solid transparent; } }

@media screen and (-ms-high-contrast: active), (forced-colors: active), (prefers-contrast) {
  .bx--toast-notification__close-button:focus {
    color: Highlight;
    outline: 1px solid Highlight; } }

@media screen and (-ms-high-contrast: active), (forced-colors: active), (prefers-contrast) {
  .bx--toast-notification__icon {
    fill: ButtonText; } }

/* stylelint-enable */
.bx--overflow-menu,
.bx--overflow-menu__trigger {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  display: inline-block;
  padding: 0;
  border: 0;
  appearance: none;
  background: none;
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  outline: 2px solid transparent;
  outline-offset: -2px;
  position: relative;
  display: flex;
  width: 2.5rem;
  height: 2.5rem;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: outline 110ms cubic-bezier(0, 0, 0.38, 0.9), background-color 110ms cubic-bezier(0, 0, 0.38, 0.9); }
  .bx--overflow-menu *,
  .bx--overflow-menu *::before,
  .bx--overflow-menu *::after,
  .bx--overflow-menu__trigger *,
  .bx--overflow-menu__trigger *::before,
  .bx--overflow-menu__trigger *::after {
    box-sizing: inherit; }
  .bx--overflow-menu::-moz-focus-inner,
  .bx--overflow-menu__trigger::-moz-focus-inner {
    border: 0; }
  .bx--overflow-menu *,
  .bx--overflow-menu *::before,
  .bx--overflow-menu *::after,
  .bx--overflow-menu__trigger *,
  .bx--overflow-menu__trigger *::before,
  .bx--overflow-menu__trigger *::after {
    box-sizing: inherit; }
  .bx--overflow-menu:focus,
  .bx--overflow-menu__trigger:focus {
    outline: 2px solid var(--cds-focus, #0f62fe);
    outline-offset: -2px; }
    @media screen and (prefers-contrast) {
      .bx--overflow-menu:focus,
      .bx--overflow-menu__trigger:focus {
        outline-style: dotted; } }
  .bx--overflow-menu:hover,
  .bx--overflow-menu__trigger:hover {
    background-color: var(--cds-hover-ui, #e5e5e5); }

.bx--overflow-menu--sm {
  width: 2rem;
  height: 2rem; }

.bx--overflow-menu--xl,
.bx--overflow-menu--lg {
  width: 3rem;
  height: 3rem; }

.bx--overflow-menu__trigger.bx--tooltip--a11y.bx--tooltip__trigger:focus {
  outline: 2px solid var(--cds-focus, #0f62fe);
  outline-offset: -2px; }
  @media screen and (prefers-contrast) {
    .bx--overflow-menu__trigger.bx--tooltip--a11y.bx--tooltip__trigger:focus {
      outline-style: dotted; } }
  .bx--overflow-menu__trigger.bx--tooltip--a11y.bx--tooltip__trigger:focus svg {
    outline: none; }

.bx--overflow-menu.bx--overflow-menu--open,
.bx--overflow-menu.bx--overflow-menu--open .bx--overflow-menu__trigger {
  box-shadow: 0 2px 6px var(--cds-shadow, rgba(0, 0, 0, 0.3));
  background-color: var(--cds-field-01, #f4f4f4);
  transition: none; }

.bx--overflow-menu--light.bx--overflow-menu--open,
.bx--overflow-menu--light.bx--overflow-menu--open .bx--overflow-menu__trigger {
  background-color: var(--cds-field-02, #ffffff); }

.bx--overflow-menu__icon {
  width: 1rem;
  height: 1rem;
  fill: var(--cds-icon-01, #161616); }

.bx--overflow-menu-options {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  box-shadow: 0 2px 6px var(--cds-shadow, rgba(0, 0, 0, 0.3));
  position: absolute;
  z-index: 6000;
  top: 32px;
  left: 0;
  display: none;
  width: 10rem;
  flex-direction: column;
  align-items: flex-start;
  background-color: var(--cds-field-01, #f4f4f4);
  list-style: none; }
  .bx--overflow-menu-options *,
  .bx--overflow-menu-options *::before,
  .bx--overflow-menu-options *::after {
    box-sizing: inherit; }
  .bx--overflow-menu-options::after {
    position: absolute;
    display: block;
    background-color: var(--cds-field-01, #f4f4f4);
    content: '';
    transition: background-color 110ms cubic-bezier(0, 0, 0.38, 0.9); }

.bx--overflow-menu.bx--overflow-menu--open:hover {
  background-color: var(--cds-field-01, #f4f4f4); }

.bx--overflow-menu-options--light {
  background-color: var(--cds-field-02, #ffffff); }
  .bx--overflow-menu-options--light::after {
    background-color: var(--cds-field-02, #ffffff); }

.bx--overflow-menu.bx--overflow-menu--light.bx--overflow-menu--open:hover {
  background-color: var(--cds-field-02, #ffffff); }

.bx--overflow-menu-options[data-floating-menu-direction='bottom']::after {
  top: -0.1875rem;
  left: 0;
  width: 2.5rem;
  height: 0.1875rem; }

.bx--overflow-menu-options[data-floating-menu-direction='top']::after {
  bottom: -0.5rem;
  left: 0;
  width: 2.5rem;
  height: 0.5rem; }

.bx--overflow-menu-options[data-floating-menu-direction='left']::after {
  top: 0;
  right: -0.375rem;
  width: 0.375rem;
  height: 2.5rem; }

.bx--overflow-menu-options[data-floating-menu-direction='right']::after {
  top: 0;
  left: -0.375rem;
  width: 0.375rem;
  height: 2.5rem; }

.bx--overflow-menu-options--sm.bx--overflow-menu-options[data-floating-menu-direction='bottom']::after, .bx--overflow-menu-options--sm.bx--overflow-menu-options[data-floating-menu-direction='top']::after {
  width: 2rem; }

.bx--overflow-menu-options--sm.bx--overflow-menu-options[data-floating-menu-direction='left']::after, .bx--overflow-menu-options--sm.bx--overflow-menu-options[data-floating-menu-direction='right']::after {
  height: 2rem; }

.bx--overflow-menu-options--xl.bx--overflow-menu-options[data-floating-menu-direction='bottom']::after, .bx--overflow-menu-options--xl.bx--overflow-menu-options[data-floating-menu-direction='top']::after,
.bx--overflow-menu-options--lg.bx--overflow-menu-options[data-floating-menu-direction='bottom']::after,
.bx--overflow-menu-options--lg.bx--overflow-menu-options[data-floating-menu-direction='top']::after {
  width: 3rem; }

.bx--overflow-menu-options--xl.bx--overflow-menu-options[data-floating-menu-direction='left']::after, .bx--overflow-menu-options--xl.bx--overflow-menu-options[data-floating-menu-direction='right']::after,
.bx--overflow-menu-options--lg.bx--overflow-menu-options[data-floating-menu-direction='left']::after,
.bx--overflow-menu-options--lg.bx--overflow-menu-options[data-floating-menu-direction='right']::after {
  height: 3rem; }

.bx--overflow-menu--flip.bx--overflow-menu-options[data-floating-menu-direction='top']::after,
.bx--overflow-menu--flip.bx--overflow-menu-options[data-floating-menu-direction='bottom']::after {
  right: 0;
  left: auto; }

.bx--overflow-menu--flip.bx--overflow-menu-options[data-floating-menu-direction='left']::after,
.bx--overflow-menu--flip.bx--overflow-menu-options[data-floating-menu-direction='right']::after {
  top: auto;
  bottom: 0; }

.bx--overflow-menu-options--open {
  display: flex; }

.bx--overflow-menu-options__content {
  width: 100%; }

.bx--overflow-menu-options__option {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  display: flex;
  width: 100%;
  height: 2.5rem;
  align-items: center;
  padding: 0;
  background-color: transparent;
  transition: background-color 110ms cubic-bezier(0, 0, 0.38, 0.9); }
  .bx--overflow-menu-options__option *,
  .bx--overflow-menu-options__option *::before,
  .bx--overflow-menu-options__option *::after {
    box-sizing: inherit; }

.bx--overflow-menu-options--sm .bx--overflow-menu-options__option {
  height: 2rem; }

.bx--overflow-menu-options--xl .bx--overflow-menu-options__option,
.bx--overflow-menu-options--lg .bx--overflow-menu-options__option {
  height: 3rem; }

.bx--overflow-menu--divider {
  border-top: 1px solid var(--cds-ui-03, #e0e0e0); }

.bx--overflow-menu--light .bx--overflow-menu--divider {
  border-top: 1px solid var(--cds-decorative-01, #e0e0e0); }

a.bx--overflow-menu-options__btn::before {
  display: inline-block;
  height: 100%;
  content: '';
  vertical-align: middle; }

.bx--overflow-menu-options__btn {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  font-size: var(--cds-body-short-01-font-size, 0.875rem);
  font-weight: var(--cds-body-short-01-font-weight, 400);
  line-height: var(--cds-body-short-01-line-height, 1.28572);
  letter-spacing: var(--cds-body-short-01-letter-spacing, 0.16px);
  outline: 2px solid transparent;
  outline-offset: -2px;
  display: inline-flex;
  width: 100%;
  max-width: 11.25rem;
  height: 100%;
  align-items: center;
  padding: 0 1rem;
  border: none;
  background-color: transparent;
  color: var(--cds-text-02, #525252);
  cursor: pointer;
  font-weight: 400;
  text-align: left;
  transition: outline 110ms cubic-bezier(0, 0, 0.38, 0.9), background-color 110ms cubic-bezier(0, 0, 0.38, 0.9), color 110ms cubic-bezier(0, 0, 0.38, 0.9); }
  .bx--overflow-menu-options__btn *,
  .bx--overflow-menu-options__btn *::before,
  .bx--overflow-menu-options__btn *::after {
    box-sizing: inherit; }
  .bx--overflow-menu-options__btn:hover {
    color: var(--cds-text-01, #161616); }
  .bx--overflow-menu-options__btn:focus {
    outline: 2px solid var(--cds-focus, #0f62fe);
    outline-offset: -2px; }
    @media screen and (prefers-contrast) {
      .bx--overflow-menu-options__btn:focus {
        outline-style: dotted; } }
  .bx--overflow-menu-options__btn::-moz-focus-inner {
    border: none; }

.bx--overflow-menu-options__btn svg {
  fill: var(--cds-icon-02, #525252); }

.bx--overflow-menu-options__btn:hover svg {
  fill: var(--cds-icon-01, #161616); }

.bx--overflow-menu-options__option-content {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.bx--overflow-menu-options__option:hover {
  background-color: var(--cds-hover-ui, #e5e5e5); }

.bx--overflow-menu-options__option--danger .bx--overflow-menu-options__btn:hover,
.bx--overflow-menu-options__option--danger .bx--overflow-menu-options__btn:focus {
  background-color: var(--cds-danger-01, #da1e28);
  color: var(--cds-text-04, #ffffff); }
  .bx--overflow-menu-options__option--danger .bx--overflow-menu-options__btn:hover svg,
  .bx--overflow-menu-options__option--danger .bx--overflow-menu-options__btn:focus svg {
    fill: currentColor; }

.bx--overflow-menu-options__option--disabled:hover {
  background-color: var(--cds-ui-01, #f4f4f4);
  cursor: not-allowed; }

.bx--overflow-menu-options__option--disabled .bx--overflow-menu-options__btn {
  color: var(--cds-disabled-02, #c6c6c6);
  pointer-events: none; }
  .bx--overflow-menu-options__option--disabled .bx--overflow-menu-options__btn:hover, .bx--overflow-menu-options__option--disabled .bx--overflow-menu-options__btn:active, .bx--overflow-menu-options__option--disabled .bx--overflow-menu-options__btn:focus {
    outline: 2px solid transparent;
    outline-offset: -2px;
    background-color: var(--cds-ui-01, #f4f4f4); }

.bx--overflow-menu-options__option--disabled .bx--overflow-menu-options__btn svg {
  fill: var(--cds-disabled-02, #c6c6c6); }

.bx--overflow-menu--flip {
  left: -140px; }
  .bx--overflow-menu--flip::before {
    left: 145px; }

.bx--overflow-menu__container {
  display: inline-block; }

/* stylelint-disable */
@media screen and (-ms-high-contrast: active), (forced-colors: active), (prefers-contrast) {
  .bx--overflow-menu:focus,
  .bx--overflow-menu-options__btn:focus {
    color: Highlight;
    outline: 1px solid Highlight; } }

@media screen and (-ms-high-contrast: active), (forced-colors: active), (prefers-contrast) {
  .bx--overflow-menu svg {
    fill: ButtonText; } }

/*stylelint-enable */
.bx--select {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start; }
  .bx--select *,
  .bx--select *::before,
  .bx--select *::after {
    box-sizing: inherit; }

.bx--select-input__wrapper {
  position: relative;
  display: flex;
  width: 100%;
  align-items: center; }

.bx--select-input {
  font-size: var(--cds-body-short-01-font-size, 0.875rem);
  font-weight: var(--cds-body-short-01-font-weight, 400);
  line-height: var(--cds-body-short-01-line-height, 1.28572);
  letter-spacing: var(--cds-body-short-01-letter-spacing, 0.16px);
  outline: 2px solid transparent;
  outline-offset: -2px;
  display: block;
  width: 100%;
  height: 2.5rem;
  padding: 0 var(--cds-spacing-09, 3rem) 0 var(--cds-spacing-05, 1rem);
  border: none;
  border-bottom: 1px solid var(--cds-ui-04, #8d8d8d);
  appearance: none;
  background-color: var(--cds-field-01, #f4f4f4);
  border-radius: 0;
  color: var(--cds-text-01, #161616);
  cursor: pointer;
  opacity: 1;
  transition: outline 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--select-input:hover {
    background-color: var(--cds-hover-ui, #e5e5e5); }
  .bx--select-input::-ms-expand {
    display: none; }

@-moz-document url-prefix() {
  .bx--select-input:-moz-focusring, .bx--select-input::-moz-focus-inner {
    background-image: none;
    color: transparent;
    text-shadow: 0 0 0 #000000; } }
  .bx--select-input:focus {
    outline: 2px solid var(--cds-focus, #0f62fe);
    outline-offset: -2px;
    color: var(--cds-text-01, #161616); }
    @media screen and (prefers-contrast) {
      .bx--select-input:focus {
        outline-style: dotted; } }
  .bx--select-input:disabled, .bx--select-input:hover:disabled {
    border-bottom-color: var(--cds-disabled-01, #f4f4f4);
    background-color: var(--cds-disabled-01, #f4f4f4);
    color: var(--cds-disabled-02, #c6c6c6);
    cursor: not-allowed; }

.bx--select-input--sm {
  height: 2rem;
  max-height: 2rem; }

.bx--select-input--xl,
.bx--select-input--lg {
  height: 3rem;
  max-height: 3rem; }

.bx--select--disabled .bx--label,
.bx--select--disabled .bx--form__helper-text {
  color: var(--cds-disabled-02, #c6c6c6); }

.bx--select-input__wrapper[data-invalid] .bx--select-input,
.bx--select--warning .bx--select-input {
  padding-right: 4.5rem; }

.bx--select-input:disabled ~ .bx--select__arrow {
  fill: var(--cds-disabled-02, #c6c6c6); }

.bx--select--light .bx--select-input {
  background-color: var(--cds-field-02, #ffffff); }
  .bx--select--light .bx--select-input:hover {
    background-color: var(--cds-hover-ui, #e5e5e5); }
  .bx--select--light .bx--select-input:disabled, .bx--select--light .bx--select-input:hover:disabled {
    background-color: var(--cds-field-02, #ffffff);
    color: var(--cds-disabled-02, #c6c6c6);
    cursor: not-allowed; }

.bx--select__arrow {
  position: absolute;
  top: 0;
  right: var(--cds-spacing-05, 1rem);
  height: 100%;
  fill: var(--cds-ui-05, #161616);
  pointer-events: none; }
  @media screen and (-ms-high-contrast: active), screen and (prefers-contrast) {
    .bx--select__arrow path {
      fill: ButtonText; } }

.bx--select__invalid-icon {
  position: absolute;
  right: var(--cds-spacing-08, 2.5rem); }

.bx--select-input__wrapper[data-invalid] .bx--select-input ~ .bx--select__invalid-icon {
  fill: var(--cds-support-01, #da1e28); }

.bx--select__invalid-icon--warning {
  fill: var(--cds-support-03, #f1c21b); }

.bx--select__invalid-icon--warning path[fill] {
  fill: #000000;
  opacity: 1; }

optgroup.bx--select-optgroup,
.bx--select-option {
  background-color: var(--cds-background-hover, #e5e5e5);
  color: var(--cds-text-01, #161616); }
  optgroup.bx--select-optgroup:disabled,
  .bx--select-option:disabled {
    color: var(--cds-text-disabled, #c6c6c6); }

.bx--select--inline {
  display: flex;
  flex-direction: row;
  align-items: center; }

.bx--select--inline.bx--select--invalid .bx--label,
.bx--select--inline.bx--select--invalid .bx--form__helper-text {
  align-self: flex-start;
  margin-top: 0.8125rem; }

.bx--select--inline .bx--form__helper-text {
  margin-bottom: 0;
  margin-left: var(--cds-spacing-03, 0.5rem); }

.bx--select--inline .bx--label {
  margin: 0 0.5rem 0 0;
  white-space: nowrap; }

.bx--select--inline .bx--select-input {
  width: auto;
  padding-right: var(--cds-spacing-07, 2rem);
  padding-left: 0.5rem;
  border-bottom: none;
  background-color: var(--cds-background, #ffffff);
  color: var(--cds-text-01, #161616); }

.bx--select--inline .bx--select-input[disabled],
.bx--select--inline .bx--select-input[disabled]:hover {
  background-color: var(--cds-disabled-01, #f4f4f4); }

.bx--select--inline .bx--select__arrow {
  right: 0.5rem; }

.bx--select--inline.bx--select--invalid .bx--select-input {
  padding-right: 3.5rem; }

.bx--select--inline.bx--select--invalid .bx--select-input ~ .bx--select__invalid-icon {
  right: var(--cds-spacing-07, 2rem); }

.bx--select--inline .bx--select-input:disabled {
  color: #c6c6c6;
  cursor: not-allowed; }
  .bx--select--inline .bx--select-input:disabled ~ * {
    cursor: not-allowed; }

.bx--select.bx--skeleton {
  position: relative;
  padding: 0;
  border: none;
  background: var(--cds-skeleton-01, #e5e5e5);
  box-shadow: none;
  pointer-events: none;
  width: 100%;
  height: 2.5rem; }
  .bx--select.bx--skeleton:hover, .bx--select.bx--skeleton:focus, .bx--select.bx--skeleton:active {
    border: none;
    cursor: default;
    outline: none; }
  .bx--select.bx--skeleton::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    animation: 3000ms ease-in-out skeleton infinite;
    background: var(--cds-skeleton-02, #c6c6c6);
    content: '';
    will-change: transform-origin, transform, opacity; }
    @media (prefers-reduced-motion: reduce) {
      .bx--select.bx--skeleton::before {
        animation: none; } }

.bx--select.bx--skeleton .bx--select-input {
  display: none; }

@media screen and (-ms-high-contrast: active), (forced-colors: active), (prefers-contrast) {
  .bx--select__arrow {
    fill: ButtonText; } }

.bx--text-input {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  font-size: var(--cds-body-short-01-font-size, 0.875rem);
  font-weight: var(--cds-body-short-01-font-weight, 400);
  line-height: var(--cds-body-short-01-line-height, 1.28572);
  letter-spacing: var(--cds-body-short-01-letter-spacing, 0.16px);
  outline: 2px solid transparent;
  outline-offset: -2px;
  width: 100%;
  height: 2.5rem;
  padding: 0 1rem;
  border: none;
  border-bottom: 1px solid var(--cds-ui-04, #8d8d8d);
  background-color: var(--cds-field-01, #f4f4f4);
  color: var(--cds-text-01, #161616);
  transition: background-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9), outline 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--text-input *,
  .bx--text-input *::before,
  .bx--text-input *::after {
    box-sizing: inherit; }
  .bx--text-input:focus, .bx--text-input:active {
    outline: 2px solid var(--cds-focus, #0f62fe);
    outline-offset: -2px; }
    @media screen and (prefers-contrast) {
      .bx--text-input:focus, .bx--text-input:active {
        outline-style: dotted; } }
  .bx--text-input-wrapper svg[hidden] {
    display: none; }

.bx--text-input--xl,
.bx--text-input--lg {
  height: 3rem; }

.bx--text-input--sm {
  height: 2rem; }

.bx--password-input {
  padding-right: 2.5rem; }

.bx--text-input--sm.bx--password-input {
  padding-right: 2rem; }

.bx--text-input--lg.bx--password-input {
  padding-right: 3rem; }

.bx--text-input::placeholder {
  color: var(--cds-text-05, #6f6f6f);
  opacity: 1; }

.bx--text-input--light {
  background-color: var(--cds-field-02, #ffffff); }

.bx--text-input__field-wrapper {
  position: relative;
  display: flex;
  width: 100%; }

.bx--text-input__invalid-icon,
.bx--text-input__readonly-icon {
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%); }

.bx--text-input__invalid-icon {
  fill: var(--cds-support-01, #da1e28); }

.bx--text-input__invalid-icon--warning {
  fill: var(--cds-support-03, #f1c21b); }

.bx--text-input__invalid-icon--warning path:first-of-type {
  fill: #000000;
  opacity: 1; }

.bx--text-input--password__visibility {
  position: relative;
  display: inline-flex;
  overflow: visible;
  align-items: center;
  cursor: pointer; }
  .bx--text-input--password__visibility:focus {
    outline: 1px solid var(--cds-focus, #0f62fe); }
    @media screen and (prefers-contrast) {
      .bx--text-input--password__visibility:focus {
        outline-style: dotted; } }
  .bx--text-input--password__visibility:focus {
    outline: 1px solid transparent; }
    .bx--text-input--password__visibility:focus svg {
      outline: 1px solid var(--cds-focus, #0f62fe); }
      @media screen and (prefers-contrast) {
        .bx--text-input--password__visibility:focus svg {
          outline-style: dotted; } }
  .bx--text-input--password__visibility::before, .bx--text-input--password__visibility::after,
  .bx--text-input--password__visibility .bx--assistive-text,
  .bx--text-input--password__visibility + .bx--assistive-text {
    position: absolute;
    z-index: 6000;
    display: flex;
    align-items: center;
    opacity: 0;
    pointer-events: none; }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .bx--text-input--password__visibility::before, .bx--text-input--password__visibility::after,
      .bx--text-input--password__visibility .bx--assistive-text,
      .bx--text-input--password__visibility + .bx--assistive-text {
        display: inline-block; } }
  .bx--text-input--password__visibility::before, .bx--text-input--password__visibility::after {
    transition: opacity 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--text-input--password__visibility.bx--tooltip--a11y::before, .bx--text-input--password__visibility.bx--tooltip--a11y::after {
    transition: none; }
  .bx--text-input--password__visibility::before {
    width: 0;
    height: 0;
    border-style: solid;
    content: ''; }
  .bx--text-input--password__visibility .bx--assistive-text,
  .bx--text-input--password__visibility + .bx--assistive-text {
    box-sizing: content-box;
    color: inherit;
    opacity: 1;
    white-space: normal;
    word-break: break-word; }
  .bx--text-input--password__visibility::after,
  .bx--text-input--password__visibility .bx--assistive-text,
  .bx--text-input--password__visibility + .bx--assistive-text {
    box-shadow: 0 2px 6px var(--cds-shadow, rgba(0, 0, 0, 0.3));
    z-index: 6000;
    width: max-content;
    min-width: 1.5rem;
    max-width: 13rem;
    height: auto;
    padding: 0.1875rem 1rem;
    background-color: var(--cds-inverse-02, #393939);
    border-radius: 0.125rem;
    color: var(--cds-inverse-01, #ffffff);
    font-weight: 400;
    text-align: left;
    transform: translateX(-50%);
    font-size: var(--cds-body-short-01-font-size, 0.875rem);
    font-weight: var(--cds-body-short-01-font-weight, 400);
    line-height: var(--cds-body-short-01-line-height, 1.28572);
    letter-spacing: var(--cds-body-short-01-letter-spacing, 0.16px); }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .bx--text-input--password__visibility::after,
      .bx--text-input--password__visibility .bx--assistive-text,
      .bx--text-input--password__visibility + .bx--assistive-text {
        width: auto; } }
    @supports (-ms-accelerator: true) {
      .bx--text-input--password__visibility::after,
      .bx--text-input--password__visibility .bx--assistive-text,
      .bx--text-input--password__visibility + .bx--assistive-text {
        width: auto; } }
    @supports (-ms-ime-align: auto) {
      .bx--text-input--password__visibility::after,
      .bx--text-input--password__visibility .bx--assistive-text,
      .bx--text-input--password__visibility + .bx--assistive-text {
        width: auto; } }
    @media screen and (-ms-high-contrast: active), screen and (prefers-contrast) {
      .bx--text-input--password__visibility::after,
      .bx--text-input--password__visibility .bx--assistive-text,
      .bx--text-input--password__visibility + .bx--assistive-text {
        border: 1px solid transparent; } }
  .bx--text-input--password__visibility::after {
    content: attr(aria-label); }
  .bx--text-input--password__visibility.bx--tooltip--a11y::after {
    content: none; }
  .bx--text-input--password__visibility.bx--tooltip--visible::before, .bx--text-input--password__visibility.bx--tooltip--visible::after, .bx--text-input--password__visibility:hover::before, .bx--text-input--password__visibility:hover::after, .bx--text-input--password__visibility:focus::before, .bx--text-input--password__visibility:focus::after {
    opacity: 1; }

@keyframes tooltip-fade {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }
  .bx--text-input--password__visibility.bx--tooltip--visible .bx--assistive-text,
  .bx--text-input--password__visibility.bx--tooltip--visible + .bx--assistive-text, .bx--text-input--password__visibility:hover .bx--assistive-text,
  .bx--text-input--password__visibility:hover + .bx--assistive-text, .bx--text-input--password__visibility:focus .bx--assistive-text,
  .bx--text-input--password__visibility:focus + .bx--assistive-text {
    overflow: visible;
    margin: auto;
    clip: auto; }
  .bx--text-input--password__visibility.bx--tooltip--visible .bx--assistive-text,
  .bx--text-input--password__visibility.bx--tooltip--visible + .bx--assistive-text, .bx--text-input--password__visibility.bx--tooltip--visible.bx--tooltip--a11y::before, .bx--text-input--password__visibility:hover .bx--assistive-text,
  .bx--text-input--password__visibility:hover + .bx--assistive-text, .bx--text-input--password__visibility:hover.bx--tooltip--a11y::before, .bx--text-input--password__visibility:focus .bx--assistive-text,
  .bx--text-input--password__visibility:focus + .bx--assistive-text, .bx--text-input--password__visibility:focus.bx--tooltip--a11y::before {
    animation: tooltip-fade 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--text-input--password__visibility.bx--tooltip--hidden .bx--assistive-text,
  .bx--text-input--password__visibility.bx--tooltip--hidden + .bx--assistive-text {
    overflow: hidden;
    margin: -1px;
    clip: rect(0, 0, 0, 0); }
  .bx--text-input--password__visibility.bx--tooltip--hidden.bx--tooltip--a11y::before {
    animation: none;
    opacity: 0; }
  .bx--text-input--password__visibility .bx--assistive-text::after {
    position: absolute;
    display: block;
    content: '';
    left: 0;
    width: 100%;
    height: 0.75rem;
    top: -0.75rem; }
  .bx--text-input--password__visibility::before, .bx--text-input--password__visibility::after,
  .bx--text-input--password__visibility .bx--assistive-text,
  .bx--text-input--password__visibility + .bx--assistive-text {
    bottom: 0;
    left: 50%; }
  .bx--text-input--password__visibility::before {
    bottom: -0.5rem;
    border-width: 0 0.25rem 0.3125rem 0.25rem;
    border-color: transparent transparent var(--cds-inverse-02, #393939) transparent;
    transform: translate(-50%, 100%); }
  .bx--text-input--password__visibility::after,
  .bx--text-input--password__visibility .bx--assistive-text,
  .bx--text-input--password__visibility + .bx--assistive-text {
    bottom: -0.8125rem;
    transform: translate(-50%, 100%); }

.bx--text-input--password__visibility,
.bx--btn.bx--text-input--password__visibility__toggle.bx--tooltip__trigger {
  outline: 2px solid transparent;
  outline-offset: -2px;
  position: absolute;
  right: 0;
  display: flex;
  width: 2.5rem;
  height: 100%;
  min-height: auto;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: 0;
  background: none;
  cursor: pointer;
  transition: outline 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }

.bx--text-input--sm + .bx--btn.bx--text-input--password__visibility__toggle.bx--tooltip__trigger {
  width: 2rem; }

.bx--text-input--lg + .bx--btn.bx--text-input--password__visibility__toggle.bx--tooltip__trigger {
  width: 3rem; }

.bx--btn.bx--text-input--password__visibility__toggle.bx--tooltip__trigger svg {
  fill: var(--cds-icon-secondary, #525252);
  transition: fill 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  @media screen and (-ms-high-contrast: active), screen and (prefers-contrast) {
    .bx--btn.bx--text-input--password__visibility__toggle.bx--tooltip__trigger svg {
      fill: ButtonText; } }

.bx--btn.bx--text-input--password__visibility__toggle.bx--tooltip__trigger:focus {
  outline: 2px solid var(--cds-focus, #0f62fe);
  outline-offset: -2px; }
  @media screen and (prefers-contrast) {
    .bx--btn.bx--text-input--password__visibility__toggle.bx--tooltip__trigger:focus {
      outline-style: dotted; } }

.bx--btn.bx--text-input--password__visibility__toggle.bx--tooltip__trigger:hover svg,
.bx--btn.bx--text-input--password__visibility__toggle.bx--tooltip__trigger:focus svg {
  fill: var(--cds-icon-primary, #161616); }

.bx--text-input--invalid,
.bx--text-input--warning,
.bx--text-input-wrapper--readonly .bx--text-input {
  padding-right: 2.5rem; }

.bx--text-input--invalid.bx--password-input {
  padding-right: 4rem; }

.bx--text-input--invalid +
.bx--text-input--password__visibility,
.bx--text-input--invalid +
.bx--text-input--password__visibility__toggle {
  right: 1rem; }

.bx--password-input-wrapper .bx--text-input__invalid-icon {
  right: 2.5rem; }

.bx--text-input:disabled + .bx--text-input--password__visibility svg,
.bx--text-input:disabled + .bx--text-input--password__visibility__toggle.bx--tooltip__trigger svg {
  cursor: not-allowed;
  fill: var(--cds-disabled-02, #c6c6c6); }
  .bx--text-input:disabled + .bx--text-input--password__visibility svg:hover,
  .bx--text-input:disabled + .bx--text-input--password__visibility__toggle.bx--tooltip__trigger svg:hover {
    fill: var(--cds-disabled-02, #c6c6c6); }

.bx--text-input:disabled {
  outline: 2px solid transparent;
  outline-offset: -2px;
  border-bottom: 1px solid transparent;
  background-color: var(--cds-field-disabled, #f4f4f4);
  color: var(--cds-text-disabled, #c6c6c6);
  cursor: not-allowed;
  -webkit-text-fill-color: var(--cds-disabled-02, #c6c6c6); }

.bx--text-input--light:disabled {
  background-color: var(--cds-field-02, #ffffff); }

.bx--text-input:disabled::placeholder {
  color: var(--cds-disabled-02, #c6c6c6);
  opacity: 1; }

.bx--text-input--invalid {
  outline: 2px solid var(--cds-support-01, #da1e28);
  outline-offset: -2px;
  box-shadow: none; }
  @media screen and (prefers-contrast) {
    .bx--text-input--invalid {
      outline-style: dotted; } }
  .bx--text-input--invalid .bx--text-input--password__visibility,
  .bx--text-input--invalid .bx--text-input--password__visibility__toggle {
    right: 2.5rem; }

.bx--skeleton.bx--text-input {
  position: relative;
  padding: 0;
  border: none;
  background: var(--cds-skeleton-01, #e5e5e5);
  box-shadow: none;
  pointer-events: none; }
  .bx--skeleton.bx--text-input:hover, .bx--skeleton.bx--text-input:focus, .bx--skeleton.bx--text-input:active {
    border: none;
    cursor: default;
    outline: none; }
  .bx--skeleton.bx--text-input::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    animation: 3000ms ease-in-out skeleton infinite;
    background: var(--cds-skeleton-02, #c6c6c6);
    content: '';
    will-change: transform-origin, transform, opacity; }
    @media (prefers-reduced-motion: reduce) {
      .bx--skeleton.bx--text-input::before {
        animation: none; } }

.bx--form--fluid .bx--text-input-wrapper {
  position: relative;
  background: var(--cds-field-01, #f4f4f4);
  transition: background-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9), outline 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }

.bx--form--fluid .bx--label {
  position: absolute;
  z-index: 1;
  top: 0.8125rem;
  left: 1rem;
  margin: 0; }

.bx--form--fluid .bx--form__helper-text {
  display: none; }

.bx--form--fluid .bx--text-input {
  min-height: 4rem;
  padding: 2rem 1rem 0.8125rem; }

.bx--text-input__divider,
.bx--form--fluid .bx--text-input__divider {
  display: none; }

.bx--form--fluid .bx--text-input--invalid,
.bx--form--fluid .bx--text-input--warn {
  border-bottom: none; }

.bx--form--fluid .bx--text-input--invalid + .bx--text-input__divider,
.bx--form--fluid .bx--text-input--warn + .bx--text-input__divider {
  display: block;
  border-style: solid;
  border-color: var(--cds-ui-03, #e0e0e0);
  border-bottom: none;
  margin: 0 1rem; }

.bx--form--fluid .bx--text-input__invalid-icon {
  top: 5rem; }

.bx--form--fluid .bx--text-input-wrapper--light {
  background: var(--cds-field-02, #ffffff); }

.bx--form--fluid .bx--text-input__field-wrapper[data-invalid] > .bx--text-input--invalid {
  outline: 2px solid transparent;
  outline-offset: -2px; }

.bx--form--fluid .bx--text-input__field-wrapper[data-invalid]:not(:focus) {
  outline: 2px solid var(--cds-support-01, #da1e28);
  outline-offset: -2px; }
  @media screen and (prefers-contrast) {
    .bx--form--fluid .bx--text-input__field-wrapper[data-invalid]:not(:focus) {
      outline-style: dotted; } }

.bx--form--fluid .bx--text-input__field-wrapper[data-invalid] > .bx--text-input--invalid:focus {
  outline: 2px solid var(--cds-focus, #0f62fe);
  outline-offset: -2px; }
  @media screen and (prefers-contrast) {
    .bx--form--fluid .bx--text-input__field-wrapper[data-invalid] > .bx--text-input--invalid:focus {
      outline-style: dotted; } }

.bx--text-input-wrapper.bx--text-input-wrapper--inline {
  flex-flow: row wrap; }

.bx--text-input-wrapper .bx--label--inline {
  flex: 1;
  margin: 0.8125rem 0 0 0;
  overflow-wrap: break-word;
  word-break: break-word; }

.bx--text-input-wrapper .bx--label--inline--sm {
  margin-top: 0.5625rem; }

.bx--text-input-wrapper .bx--label--inline--xl,
.bx--text-input-wrapper .bx--label--inline--lg {
  margin-top: 1.0625rem; }

.bx--text-input__label-helper-wrapper {
  max-width: 8rem;
  flex: 2;
  flex-direction: column;
  margin-right: 1.5rem;
  overflow-wrap: break-word; }

.bx--text-input-wrapper .bx--form__helper-text--inline {
  margin-top: 0.125rem; }

.bx--text-input__field-outer-wrapper {
  display: flex;
  width: 100%;
  flex: 1 1 auto;
  flex-direction: column;
  align-items: flex-start; }

.bx--text-input__field-outer-wrapper--inline {
  flex: 8;
  flex-direction: column; }

.bx--form--fluid .bx--text-input-wrapper--readonly,
.bx--text-input-wrapper--readonly .bx--text-input {
  background: transparent; }

@media screen and (-ms-high-contrast: active), (forced-colors: active), (prefers-contrast) {
  .bx--text-input--password__visibility,
  .bx--btn.bx--btn--icon-only.bx--text-input--password__visibility__toggle.bx--tooltip__trigger svg,
  .bx--btn.bx--btn--icon-only.bx--text-input--password__visibility__toggle.bx--tooltip__trigger:hover svg {
    fill: ButtonText; } }

.bx--data-table-container + .bx--pagination {
  border-top: 0; }

.bx--pagination {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  font-size: var(--cds-body-short-01-font-size, 0.875rem);
  font-weight: var(--cds-body-short-01-font-weight, 400);
  line-height: var(--cds-body-short-01-line-height, 1.28572);
  letter-spacing: var(--cds-body-short-01-letter-spacing, 0.16px);
  display: flex;
  width: calc(100% - 1px);
  min-height: 2.5rem;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid var(--cds-ui-03, #e0e0e0);
  background-color: var(--cds-ui-01, #f4f4f4);
  overflow-x: auto; }
  .bx--pagination *,
  .bx--pagination *::before,
  .bx--pagination *::after {
    box-sizing: inherit; }
  @media (min-width: 42rem) {
    .bx--pagination {
      overflow: initial; }
      .bx--pagination .bx--pagination__control-buttons {
        display: flex; } }
  @media (max-width: 41.98rem) {
    .bx--pagination .bx--pagination__left > *,
    .bx--pagination .bx--pagination__right > * {
      display: none; }
    .bx--pagination .bx--pagination__items-count {
      display: initial; }
    .bx--pagination .bx--pagination__control-buttons {
      display: flex; } }

.bx--pagination--sm {
  min-height: 2rem; }

.bx--pagination--lg {
  min-height: 3rem; }

.bx--pagination .bx--select {
  height: 100%;
  align-items: center; }

.bx--pagination .bx--select-input--inline__wrapper {
  display: flex;
  height: 100%; }

.bx--pagination .bx--select-input {
  font-size: var(--cds-body-short-01-font-size, 0.875rem);
  font-weight: var(--cds-body-short-01-font-weight, 400);
  line-height: var(--cds-body-short-01-line-height, 1.28572);
  letter-spacing: var(--cds-body-short-01-letter-spacing, 0.16px);
  width: auto;
  min-width: auto;
  height: 100%;
  padding: 0 2.25rem 0 var(--cds-spacing-05, 1rem);
  background-color: var(--cds-ui-01, #f4f4f4);
  line-height: 2.5rem; }

.bx--pagination--sm .bx--select-input {
  line-height: 2rem; }

.bx--pagination--lg .bx--select-input {
  line-height: 3rem; }

.bx--pagination .bx--select-input:hover {
  background: var(--cds-hover-ui, #e5e5e5); }

.bx--pagination .bx--select__arrow {
  top: 50%;
  transform: translate(-0.5rem, -50%); }

.bx--pagination .bx--select__item-count .bx--select-input {
  border-right: 0.0625rem solid var(--cds-ui-03, #e0e0e0); }

.bx--pagination .bx--select__page-number .bx--select-input {
  border-left: 1px solid var(--cds-ui-03, #e0e0e0); }

.bx--pagination__left,
.bx--pagination__right {
  display: flex;
  height: 100%;
  align-items: center; }

.bx--pagination__left > .bx--form-item,
.bx--pagination__right > .bx--form-item {
  height: 100%; }

.bx--pagination__left .bx--pagination__text,
.bx--pagination__right .bx--pagination__text {
  white-space: nowrap; }

.bx--pagination__left .bx--pagination__text {
  margin-right: 0.0625rem; }

.bx--pagination__right .bx--pagination__text {
  margin-right: 1rem;
  margin-left: 0.0625rem; }

.bx--pagination__left {
  padding: 0 1rem 0 0; }
  @media (min-width: 42rem) {
    .bx--pagination__left {
      padding: 0 1rem; } }

@media (min-width: 42rem) {
  .bx--pagination__text {
    display: inline-block; } }

span.bx--pagination__text {
  margin-left: 1rem;
  color: var(--cds-text-02, #525252); }

.bx--pagination__button,
.bx--btn--ghost.bx--pagination__button {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  display: flex;
  width: 2.5rem;
  height: 2.5rem;
  min-height: 2rem;
  align-items: center;
  justify-content: center;
  border: none;
  border-left: 1px solid var(--cds-ui-03, #e0e0e0);
  margin: 0;
  background: none;
  cursor: pointer;
  fill: var(--cds-ui-05, #161616);
  transition: outline 110ms cubic-bezier(0.2, 0, 0.38, 0.9), background-color 110ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--pagination__button *,
  .bx--pagination__button *::before,
  .bx--pagination__button *::after,
  .bx--btn--ghost.bx--pagination__button *,
  .bx--btn--ghost.bx--pagination__button *::before,
  .bx--btn--ghost.bx--pagination__button *::after {
    box-sizing: inherit; }

.bx--pagination--sm .bx--pagination__button,
.bx--pagination--sm .bx--btn--ghost.bx--pagination__button {
  width: 2rem;
  height: 2rem; }

.bx--pagination--lg .bx--pagination__button,
.bx--pagination--lg .bx--btn--ghost.bx--pagination__button {
  width: 3rem;
  height: 3rem; }

.bx--pagination__button:focus,
.bx--btn--ghost:focus.bx--pagination__button {
  outline: 2px solid var(--cds-focus, #0f62fe);
  outline-offset: -2px;
  border-left: 0; }
  @media screen and (prefers-contrast) {
    .bx--pagination__button:focus,
    .bx--btn--ghost:focus.bx--pagination__button {
      outline-style: dotted; } }

.bx--pagination__button:hover,
.bx--btn--ghost:hover.bx--pagination__button {
  background: var(--cds-hover-ui, #e5e5e5); }

.bx--pagination__button--no-index,
.bx--btn--ghost.bx--pagination__button--no-index {
  cursor: not-allowed;
  fill: var(--cds-disabled-02, #c6c6c6); }

.bx--pagination__button:disabled:hover,
.bx--pagination__button--no-index:hover,
.bx--btn--ghost:disabled:hover.bx--pagination__button,
.bx--btn--ghost:hover.bx--pagination__button--no-index {
  border-color: var(--cds-ui-03, #e0e0e0);
  background: var(--cds-ui-01, #f4f4f4);
  cursor: not-allowed;
  fill: var(--cds-disabled-02, #c6c6c6); }

.bx--pagination.bx--skeleton .bx--skeleton__text {
  margin-right: 1rem;
  margin-bottom: 0; }

@media screen and (-ms-high-contrast: active), (forced-colors: active), (prefers-contrast) {
  .bx--pagination__button,
  .bx--btn--ghost.bx--pagination__button {
    outline: 1px solid transparent; } }

.bx--unstable-pagination {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  font-size: var(--cds-body-short-01-font-size, 0.875rem);
  font-weight: var(--cds-body-short-01-font-weight, 400);
  line-height: var(--cds-body-short-01-line-height, 1.28572);
  letter-spacing: var(--cds-body-short-01-letter-spacing, 0.16px);
  display: flex;
  width: 100%;
  height: 3rem;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid var(--cds-ui-03, #e0e0e0);
  border-bottom: 1px solid transparent;
  background-color: var(--cds-ui-01, #f4f4f4); }
  .bx--unstable-pagination *,
  .bx--unstable-pagination *::before,
  .bx--unstable-pagination *::after {
    box-sizing: inherit; }

.bx--unstable-pagination__text {
  margin: 0 1rem;
  color: var(--cds-text-02, #525252); }
  @media (min-width: 42rem) {
    .bx--unstable-pagination__text {
      display: inline-block; } }

.bx--unstable-pagination__left,
.bx--unstable-pagination__right {
  display: flex;
  height: 100%;
  align-items: center; }

.bx--unstable-pagination__left {
  padding: 0 1rem 0 0; }

.bx--unstable-pagination__left > .bx--form-item,
.bx--unstable-pagination__right > .bx--form-item {
  height: 100%; }

.bx--unstable-pagination__left .bx--unstable-pagination__text {
  margin-right: 0.0625rem; }

.bx--unstable-pagination__right .bx--unstable-pagination__text {
  margin-right: 1rem;
  margin-left: 0.0625rem; }

.bx--unstable-pagination__button {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding: 0 0.875rem;
  border: none;
  border-left: 1px solid var(--cds-ui-03, #e0e0e0);
  margin: 0;
  background: none;
  color: var(--cds-ui-05, #161616);
  cursor: pointer;
  fill: var(--cds-ui-05, #161616);
  transition: outline 110ms cubic-bezier(0.2, 0, 0.38, 0.9), background-color 110ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--unstable-pagination__button *,
  .bx--unstable-pagination__button *::before,
  .bx--unstable-pagination__button *::after {
    box-sizing: inherit; }

.bx--unstable-pagination__button .bx--btn__icon {
  width: initial;
  height: initial; }

.bx--unstable-pagination__button.bx--btn--icon-only.bx--tooltip__trigger:focus {
  outline: 2px solid var(--cds-focus, #0f62fe);
  outline-offset: -2px; }
  @media screen and (prefers-contrast) {
    .bx--unstable-pagination__button.bx--btn--icon-only.bx--tooltip__trigger:focus {
      outline-style: dotted; } }

.bx--unstable-pagination__button:hover {
  background: var(--cds-hover-ui, #e5e5e5);
  color: var(--cds-ui-05, #161616); }

.bx--unstable-pagination__button--no-index {
  cursor: not-allowed;
  fill: var(--cds-disabled-02, #c6c6c6); }

.bx--unstable-pagination__button.bx--btn:disabled {
  border-color: var(--cds-ui-03, #e0e0e0);
  background: transparent; }

.bx--unstable-pagination__button:disabled:hover,
.bx--unstable-pagination__button--no-index:hover {
  background: transparent;
  cursor: not-allowed;
  fill: var(--cds-disabled-02, #c6c6c6); }

.bx--unstable-pagination__page-selector,
.bx--unstable-pagination__page-sizer {
  height: 100%;
  align-items: center; }

.bx--unstable-pagination__page-selector .bx--select-input--inline__wrapper,
.bx--unstable-pagination__page-sizer .bx--select-input--inline__wrapper {
  display: flex;
  height: 100%; }

.bx--unstable-pagination__page-selector .bx--select-input,
.bx--unstable-pagination__page-sizer .bx--select-input {
  font-size: var(--cds-body-short-01-font-size, 0.875rem);
  font-weight: var(--cds-body-short-01-font-weight, 400);
  line-height: var(--cds-body-short-01-line-height, 1.28572);
  letter-spacing: var(--cds-body-short-01-letter-spacing, 0.16px);
  width: auto;
  min-width: auto;
  height: 100%;
  padding: 0 2.5rem 0 1rem;
  margin-right: -0.65rem; }
  @media (min-width: 42rem) {
    .bx--unstable-pagination__page-selector .bx--select-input,
    .bx--unstable-pagination__page-sizer .bx--select-input {
      padding-right: 2.25rem;
      margin-right: 0; } }

.bx--unstable-pagination__page-selector .bx--select-input:hover,
.bx--unstable-pagination__page-sizer .bx--select-input:hover {
  background: var(--cds-hover-ui, #e5e5e5); }

.bx--unstable-pagination__page-selector .bx--select__arrow,
.bx--unstable-pagination__page-sizer .bx--select__arrow {
  top: 50%;
  transform: translateY(-50%); }
  @media (min-width: 42rem) {
    .bx--unstable-pagination__page-selector .bx--select__arrow,
    .bx--unstable-pagination__page-sizer .bx--select__arrow {
      right: 1rem; } }

.bx--unstable-pagination__page-selector {
  border-left: 1px solid var(--cds-ui-03, #e0e0e0); }

.bx--unstable-pagination__page-sizer {
  border-right: 1px solid var(--cds-ui-03, #e0e0e0); }

.bx--radio-button-group {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  position: relative;
  display: flex;
  align-items: center; }
  .bx--radio-button-group *,
  .bx--radio-button-group *::before,
  .bx--radio-button-group *::after {
    box-sizing: inherit; }

.bx--label + .bx--form-item .bx--radio-button-group {
  margin-top: 0; }

.bx--radio-button-group--vertical {
  flex-direction: column;
  align-items: flex-start; }
  .bx--radio-button-group--vertical.bx--radio-button-group--label-left {
    align-items: flex-end; }
  .bx--radio-button-group--vertical .bx--radio-button__label {
    margin-right: 0;
    line-height: 1.25rem; }
  .bx--radio-button-group--vertical .bx--radio-button__label:not(:last-of-type) {
    margin-bottom: 0.5rem; }

.bx--radio-button {
  position: absolute;
  overflow: hidden;
  width: 1px;
  height: 1px;
  padding: 0;
  border: 0;
  margin: -1px;
  clip: rect(0, 0, 0, 0);
  visibility: inherit;
  white-space: nowrap;
  visibility: inherit; }

.bx--radio-button__label {
  font-size: var(--cds-body-short-01-font-size, 0.875rem);
  font-weight: var(--cds-body-short-01-font-weight, 400);
  line-height: var(--cds-body-short-01-line-height, 1.28572);
  letter-spacing: var(--cds-body-short-01-letter-spacing, 0.16px);
  display: flex;
  align-items: center;
  margin-right: 1rem;
  cursor: pointer; }

.bx--radio-button__appearance {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  width: 1.125rem;
  height: 1.125rem;
  flex-shrink: 0;
  border: 1px solid var(--cds-icon-01, #161616);
  margin: 0.0625rem 0.5rem 0.125rem 0.125rem;
  background-color: transparent;
  border-radius: 50%; }
  .bx--radio-button__appearance *,
  .bx--radio-button__appearance *::before,
  .bx--radio-button__appearance *::after {
    box-sizing: inherit; }

.bx--radio-button:checked + .bx--radio-button__label .bx--radio-button__appearance {
  display: flex;
  align-items: center;
  justify-content: center;
  border-color: var(--cds-icon-01, #161616); }
  .bx--radio-button:checked + .bx--radio-button__label .bx--radio-button__appearance::before {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 100%;
    background-color: var(--cds-icon-01, #161616);
    border-radius: 50%;
    content: '';
    transform: scale(0.5); }
    @media screen and (-ms-high-contrast: active) {
      .bx--radio-button:checked + .bx--radio-button__label .bx--radio-button__appearance::before {
        background-color: WindowText; } }
    @media screen and (prefers-contrast) {
      .bx--radio-button:checked + .bx--radio-button__label .bx--radio-button__appearance::before {
        border: 2px solid WindowText; } }

.bx--radio-button:disabled + .bx--radio-button__label {
  color: #c6c6c6;
  cursor: not-allowed; }

.bx--radio-button:disabled + .bx--radio-button__label .bx--radio-button__appearance,
.bx--radio-button:disabled:checked + .bx--radio-button__label .bx--radio-button__appearance {
  border-color: #c6c6c6; }
  .bx--radio-button:disabled + .bx--radio-button__label .bx--radio-button__appearance::before,
  .bx--radio-button:disabled:checked + .bx--radio-button__label .bx--radio-button__appearance::before {
    background-color: #c6c6c6; }

.bx--radio-button:focus + .bx--radio-button__label .bx--radio-button__appearance {
  box-shadow: 0 0 0 2px var(--cds-focus, #0f62fe);
  outline: 1px solid transparent; }

.bx--radio-button__label.bx--skeleton {
  position: relative;
  padding: 0;
  border: none;
  background: var(--cds-skeleton-01, #e5e5e5);
  box-shadow: none;
  pointer-events: none;
  width: 6.25rem;
  height: 1.125rem; }
  .bx--radio-button__label.bx--skeleton:hover, .bx--radio-button__label.bx--skeleton:focus, .bx--radio-button__label.bx--skeleton:active {
    border: none;
    cursor: default;
    outline: none; }
  .bx--radio-button__label.bx--skeleton::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    animation: 3000ms ease-in-out skeleton infinite;
    background: var(--cds-skeleton-02, #c6c6c6);
    content: '';
    will-change: transform-origin, transform, opacity; }
    @media (prefers-reduced-motion: reduce) {
      .bx--radio-button__label.bx--skeleton::before {
        animation: none; } }

.bx--radio-button__label.bx--skeleton .bx--radio-button__appearance {
  display: none; }

.bx--radio-button-wrapper .bx--radio-button__label {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin: 0; }

.bx--radio-button-wrapper:not(:last-of-type) {
  margin-right: 1rem; }

.bx--radio-button-group--vertical .bx--radio-button-wrapper:not(:last-of-type) {
  margin-right: 0;
  margin-bottom: 0.5rem; }

.bx--radio-button-group--label-right .bx--radio-button__label,
.bx--radio-button-wrapper.bx--radio-button-wrapper--label-right .bx--radio-button__label {
  flex-direction: row; }

.bx--radio-button-group--label-left .bx--radio-button__label,
.bx--radio-button-wrapper.bx--radio-button-wrapper--label-left .bx--radio-button__label {
  flex-direction: row-reverse; }

.bx--radio-button-group--label-left .bx--radio-button__appearance,
.bx--radio-button-wrapper.bx--radio-button-wrapper--label-left .bx--radio-button__appearance {
  margin-right: 0;
  margin-left: 0.5rem; }

.security--portal__overlay {
  position: fixed;
  z-index: 6000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  background-color: var(--cds-overlay-01, rgba(22, 22, 22, 0.5)); }
  .security--portal__overlay:focus {
    outline: unset; }

.security--portal__container {
  overflow-y: hidden; }
  .security--portal__container .bx--tooltip {
    z-index: 9000; }

@keyframes rotate {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes rotate-end-p1 {
  100% {
    transform: rotate(360deg); } }

@keyframes rotate-end-p2 {
  100% {
    transform: rotate(-360deg); } }

/* Stroke animations */
@keyframes init-stroke {
  0% {
    stroke-dashoffset: 276.4608; }
  100% {
    stroke-dashoffset: 52.52755; } }

@keyframes stroke-end {
  0% {
    stroke-dashoffset: 52.52755; }
  100% {
    stroke-dashoffset: 276.4608; } }

.security--tearsheet--small {
  position: fixed;
  z-index: 9000;
  bottom: 0;
  left: 50%;
  display: flex;
  width: calc(100% - 2rem);
  height: calc(100vh - 3rem);
  min-height: 20rem;
  margin-right: 1rem;
  margin-left: 1rem;
  background-color: var(--cds-ui-01, #f4f4f4);
  transform: translate3d(calc( -50% - 1rem), 0, 0);
  flex-direction: column; }
  .security--input__background__color--light .bx--number .security--tearsheet--small input[type='number'], .security--tearsheet--small .bx--date-picker__input, .security--tearsheet--small .bx--dropdown,
  .security--tearsheet--small .bx--number input[type='number'], .security--tearsheet--small .bx--search-input, .security--tearsheet--small .bx--select-input, .security--tearsheet--small .bx--text-area, .security--tearsheet--small .bx--text-input, .security--tearsheet--small .bx--list-box, .security--tearsheet--small .bx--list-box__menu, .security--tearsheet--small .security--button--icon:focus, .security--tearsheet--small .security--button--icon:hover, .security--tearsheet .bx--number .security--tearsheet--small input[type='number'] {
    background-color: var(--cds-ui-02, #ffffff); }
  .security--tearsheet--small__transition--enter--active, .security--tearsheet--small__transition--leave--active {
    transition-duration: 240ms;
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.2, 0, 0.38, 0.9); }
  .security--tearsheet--small__transition--enter {
    transform: translate3d(calc( -50% - 1rem), 100%, 0); }
    .security--tearsheet--small__transition--enter--active {
      transform: translate3d(calc( -50% - 1rem), 0, 0); }
  .security--tearsheet--small__transition--leave {
    transform: translate3d(calc( -50% - 1rem), 0, 0); }
    .security--tearsheet--small__transition--leave--active {
      transform: translate3d(calc( -50% - 1rem), 100%, 0); }
  .security--tearsheet--small__button--close {
    position: absolute;
    top: 0;
    right: 0; }
  .security--tearsheet--small > .bx--loading-overlay {
    position: absolute;
    background-color: var(--cds-overlay-01, rgba(22, 22, 22, 0.5)); }
  .security--tearsheet--small__loading .bx--loading__stroke {
    stroke: var(--cds-inverse-focus-ui, #ffffff); }
  @media (min-width: 42rem) {
    .security--tearsheet--small {
      width: 32rem; } }
  @media (min-width: 123rem) {
    .security--tearsheet--small {
      width: 64rem; } }
  .security--tearsheet--small__loading > .bx--loading {
    width: 4rem;
    height: 4rem; }
  .security--tearsheet--small__header {
    padding: 1rem; }
  .security--tearsheet--small__title {
    font-size: var(--cds-productive-heading-05-font-size, 2rem);
    font-weight: var(--cds-productive-heading-05-font-weight, 400);
    line-height: var(--cds-productive-heading-05-line-height, 1.25);
    letter-spacing: var(--cds-productive-heading-05-letter-spacing, 0);
    margin-top: 0;
    margin-bottom: 1rem; }
  .security--tearsheet--small__body {
    display: flex;
    width: 100%;
    min-height: 0;
    flex-grow: 1;
    margin-bottom: 5rem; }
  .security--tearsheet--small__scroll-gradient__content {
    padding-right: 1rem;
    padding-left: 1rem; }
  .security--tearsheet--small__description {
    font-size: var(--cds-body-short-01-font-size, 0.875rem);
    font-weight: var(--cds-body-short-01-font-weight, 400);
    line-height: var(--cds-body-short-01-line-height, 1.28572);
    letter-spacing: var(--cds-body-short-01-letter-spacing, 0.16px); }
  .security--tearsheet--small__content {
    flex-grow: inherit;
    word-break: break-word; }
    .security--tearsheet--small__content .security--filter__search-field {
      background-color: var(--cds-decorative-01, #e0e0e0); }
  .security--tearsheet--small__footer {
    position: fixed;
    bottom: 0;
    display: flex;
    width: 100%; }
    .security--tearsheet--small__footer__button {
      width: 100%;
      flex-direction: column;
      align-items: start;
      max-width: 50%;
      flex: 1;
      margin-left: auto; }
      .security--tearsheet--small__footer__button--secondary {
        margin-left: 0; }

.security--tearsheet {
  position: fixed;
  z-index: 9000;
  bottom: 0;
  left: 50%;
  display: flex;
  width: calc(100% - 2rem);
  height: calc(100vh - 3rem);
  min-height: 20rem;
  margin-right: 1rem;
  margin-left: 1rem;
  background-color: var(--cds-ui-01, #f4f4f4);
  transform: translate3d(calc( -50% - 1rem), 0, 0); }
  .security--input__background__color--light .bx--number .security--tearsheet input[type='number'], .security--tearsheet--small .bx--number .security--tearsheet input[type='number'], .security--tearsheet .bx--date-picker__input, .security--tearsheet .bx--dropdown,
  .security--tearsheet .bx--number input[type='number'], .security--tearsheet .bx--search-input, .security--tearsheet .bx--select-input, .security--tearsheet .bx--text-area, .security--tearsheet .bx--text-input, .security--tearsheet .bx--list-box, .security--tearsheet .bx--list-box__menu, .security--tearsheet .security--button--icon:focus, .security--tearsheet .security--button--icon:hover {
    background-color: var(--cds-ui-02, #ffffff); }
  .security--tearsheet__transition--enter--active, .security--tearsheet__transition--leave--active {
    transition-duration: 240ms;
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.2, 0, 0.38, 0.9); }
  .security--tearsheet__transition--enter {
    transform: translate3d(calc( -50% - 1rem), 100%, 0); }
    .security--tearsheet__transition--enter--active {
      transform: translate3d(calc( -50% - 1rem), 0, 0); }
  .security--tearsheet__transition--leave {
    transform: translate3d(calc( -50% - 1rem), 0, 0); }
    .security--tearsheet__transition--leave--active {
      transform: translate3d(calc( -50% - 1rem), 100%, 0); }
  .security--tearsheet__button--close {
    position: absolute;
    top: 0;
    right: 0; }
  .security--tearsheet > .bx--loading-overlay {
    position: absolute;
    background-color: var(--cds-overlay-01, rgba(22, 22, 22, 0.5)); }
  .security--tearsheet__loading .bx--loading__stroke {
    stroke: var(--cds-inverse-focus-ui, #ffffff); }
  .security--tearsheet__button {
    width: 100%;
    flex-direction: column;
    align-items: start;
    max-width: 16.5rem; }
    .security--tearsheet__button--tertiary {
      display: flex;
      flex-wrap: wrap;
      justify-content: left; }
      .security--tearsheet__button--tertiary__text {
        font-size: var(--cds-label-01-font-size, 0.75rem);
        font-weight: var(--cds-label-01-font-weight, 400);
        line-height: var(--cds-label-01-line-height, 1.33333);
        letter-spacing: var(--cds-label-01-letter-spacing, 0.32px);
        color: var(--cds-text-01, #161616); }
  .security--tearsheet__sidebar {
    position: relative;
    width: 33%;
    max-width: 16.5rem;
    padding: 1.5rem;
    border-right: 0.0625rem solid var(--cds-ui-background, #ffffff); }
    .security--tearsheet__sidebar__title {
      font-size: var(--cds-productive-heading-03-font-size, 1.25rem);
      font-weight: var(--cds-productive-heading-03-font-weight, 400);
      line-height: var(--cds-productive-heading-03-line-height, 1.4);
      letter-spacing: var(--cds-productive-heading-03-letter-spacing, 0);
      margin-top: 0;
      margin-bottom: 0.25rem; }
    .security--tearsheet__sidebar__subtitle {
      font-size: var(--cds-caption-01-font-size, 0.75rem);
      font-weight: var(--cds-caption-01-font-weight, 400);
      line-height: var(--cds-caption-01-line-height, 1.33333);
      letter-spacing: var(--cds-caption-01-letter-spacing, 0.32px);
      margin-top: 0;
      margin-bottom: 1.5rem;
      color: var(--cds-text-02, #525252); }
    .security--tearsheet__sidebar__footer {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      display: flex;
      padding-bottom: 0.5rem;
      padding-left: 0.5rem; }
  .security--tearsheet__main {
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 1.5rem; }
    .security--tearsheet__main__title, .security--tearsheet__main__content {
      padding-left: 1.5rem; }
    .security--tearsheet__main__scroll-gradient {
      width: 100%; }
      .security--tearsheet__main__scroll-gradient__content {
        padding-right: 1.5rem; }
    .security--tearsheet__main__title {
      font-size: var(--cds-productive-heading-04-font-size, 1.75rem);
      font-weight: var(--cds-productive-heading-04-font-weight, 400);
      line-height: var(--cds-productive-heading-04-line-height, 1.28572);
      letter-spacing: var(--cds-productive-heading-04-letter-spacing, 0);
      padding-right: 1.5rem;
      margin-top: 0;
      margin-bottom: 1.5rem; }
    .security--tearsheet__main__content {
      position: relative;
      display: flex;
      width: 100%;
      min-height: 0;
      box-sizing: border-box;
      flex: 1;
      margin-top: 1.5rem; }
  .security--tearsheet__container {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between; }
    .security--tearsheet__container__start, .security--tearsheet__container__end {
      display: flex;
      justify-content: flex-end; }
    .security--tearsheet__container__start {
      flex-direction: column; }
    .security--tearsheet__container__end {
      flex-grow: 1;
      flex-wrap: wrap; }

.bx--text-area {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  font-size: var(--cds-body-long-01-font-size, 0.875rem);
  font-weight: var(--cds-body-long-01-font-weight, 400);
  line-height: var(--cds-body-long-01-line-height, 1.42857);
  letter-spacing: var(--cds-body-long-01-letter-spacing, 0.16px);
  outline: 2px solid transparent;
  outline-offset: -2px;
  width: 100%;
  min-width: 10rem;
  height: 100%;
  min-height: 2.5rem;
  padding: 0.6875rem 1rem;
  border: none;
  border-bottom: 1px solid var(--cds-ui-04, #8d8d8d);
  background-color: var(--cds-field-01, #f4f4f4);
  color: var(--cds-text-01, #161616);
  resize: vertical;
  transition: background-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9), outline 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--text-area *,
  .bx--text-area *::before,
  .bx--text-area *::after {
    box-sizing: inherit; }

.bx--text-area:focus,
.bx--text-area:active {
  outline: 2px solid var(--cds-focus, #0f62fe);
  outline-offset: -2px; }
  @media screen and (prefers-contrast) {
    .bx--text-area:focus,
    .bx--text-area:active {
      outline-style: dotted; } }

.bx--text-area::placeholder {
  color: var(--cds-text-05, #6f6f6f);
  opacity: 1;
  font-size: var(--cds-body-long-01-font-size, 0.875rem);
  font-weight: var(--cds-body-long-01-font-weight, 400);
  line-height: var(--cds-body-long-01-line-height, 1.42857);
  letter-spacing: var(--cds-body-long-01-letter-spacing, 0.16px); }

.bx--text-area--light {
  background-color: var(--cds-field-02, #ffffff); }

.bx--text-area--invalid {
  padding-right: 2.5rem; }

.bx--text-area__wrapper {
  position: relative;
  display: flex;
  width: 100%; }

.bx--text-area__invalid-icon {
  position: absolute;
  top: 0.75rem;
  right: 1rem;
  fill: var(--cds-support-01, #da1e28); }

.bx--text-area:disabled {
  border-bottom: 1px solid transparent;
  background-color: var(--cds-disabled-01, #f4f4f4);
  color: var(--cds-disabled-02, #c6c6c6);
  cursor: not-allowed;
  outline: none; }

.bx--text-area:disabled::placeholder {
  color: var(--cds-disabled-02, #c6c6c6); }

.bx--text-area.bx--text-area--light:disabled {
  background-color: var(--cds-field-02, #ffffff); }

.bx--text-area.bx--skeleton {
  position: relative;
  padding: 0;
  border: none;
  background: var(--cds-skeleton-01, #e5e5e5);
  box-shadow: none;
  pointer-events: none;
  height: 6.25rem; }
  .bx--text-area.bx--skeleton:hover, .bx--text-area.bx--skeleton:focus, .bx--text-area.bx--skeleton:active {
    border: none;
    cursor: default;
    outline: none; }
  .bx--text-area.bx--skeleton::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    animation: 3000ms ease-in-out skeleton infinite;
    background: var(--cds-skeleton-02, #c6c6c6);
    content: '';
    will-change: transform-origin, transform, opacity; }
    @media (prefers-reduced-motion: reduce) {
      .bx--text-area.bx--skeleton::before {
        animation: none; } }
  .bx--text-area.bx--skeleton::placeholder {
    color: transparent; }

.bx--tile-group {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline; }
  .bx--tile-group *,
  .bx--tile-group *::before,
  .bx--tile-group *::after {
    box-sizing: inherit; }

.bx--tile {
  display: block;
  min-width: 8rem;
  min-height: 4rem;
  padding: var(--cds-spacing-05, 1rem);
  background-color: var(--cds-ui-01, #f4f4f4);
  outline: 2px solid transparent;
  outline-offset: -2px; }
  .bx--tile:focus {
    outline: 2px solid var(--cds-focus, #0f62fe);
    outline-offset: -2px; }
    @media screen and (prefers-contrast) {
      .bx--tile:focus {
        outline-style: dotted; } }

.bx--tile--light {
  background-color: var(--cds-ui-02, #ffffff); }

.bx--tile--clickable,
.bx--tile--selectable,
.bx--tile--expandable {
  cursor: pointer;
  transition: 150ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--tile--clickable:hover,
  .bx--tile--selectable:hover,
  .bx--tile--expandable:hover {
    background: var(--cds-hover-ui, #e5e5e5); }

.bx--tile--expandable .bx--link {
  color: var(--cds-link-secondary, #0043ce); }

.bx--tile--clickable:focus,
.bx--tile--expandable:focus {
  outline: 2px solid var(--cds-focus, #0f62fe);
  outline-offset: -2px; }
  @media screen and (prefers-contrast) {
    .bx--tile--clickable:focus,
    .bx--tile--expandable:focus {
      outline-style: dotted; } }

.bx--tile--clickable:hover .bx--tile__checkmark, .bx--tile--clickable:focus .bx--tile__checkmark,
.bx--tile--expandable:hover .bx--tile__checkmark,
.bx--tile--expandable:focus .bx--tile__checkmark {
  opacity: 1; }

.bx--tile--expandable::-moz-focus-inner {
  border: 0; }

.bx--tile--clickable {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  font-size: var(--cds-body-short-01-font-size, 0.875rem);
  font-weight: var(--cds-body-short-01-font-weight, 400);
  line-height: var(--cds-body-short-01-line-height, 1.28572);
  letter-spacing: var(--cds-body-short-01-letter-spacing, 0.16px);
  color: var(--cds-text-01, #161616);
  text-decoration: none; }
  .bx--tile--clickable *,
  .bx--tile--clickable *::before,
  .bx--tile--clickable *::after {
    box-sizing: inherit; }

.bx--tile--clickable:hover,
.bx--tile--clickable:active,
.bx--tile--clickable:visited,
.bx--tile--clickable:visited:hover {
  color: var(--cds-text-01, #161616);
  text-decoration: none; }

.bx--tile--clickable.bx--link--disabled {
  color: var(--cds-disabled-02, #c6c6c6); }

.bx--tile--clickable:hover.bx--link--disabled {
  display: block;
  background-color: var(--cds-disabled-01, #f4f4f4);
  color: var(--cds-disabled-02, #c6c6c6); }

.bx--tile--selectable {
  position: relative;
  padding-right: 3rem;
  border: 1px solid transparent; }

.bx--tile__checkmark,
.bx--tile__chevron {
  position: absolute;
  border: none;
  background: transparent;
  transition: 110ms cubic-bezier(0.2, 0, 0.38, 0.9); }

.bx--tile__checkmark {
  top: 1rem;
  right: 1rem;
  height: 1rem;
  opacity: 0; }
  .bx--tile__checkmark svg {
    border-radius: 50%;
    fill: var(--cds-icon-02, #525252); }
  .bx--tile__checkmark:focus {
    outline: 2px solid var(--cds-focus, #0f62fe);
    outline-offset: -2px; }
    @media screen and (prefers-contrast) {
      .bx--tile__checkmark:focus {
        outline-style: dotted; } }

.bx--tile__checkmark--persistent {
  opacity: 1; }

.bx--tile__chevron {
  position: absolute;
  right: 1rem;
  bottom: 1rem;
  display: flex;
  height: 1rem;
  align-items: flex-end; }
  .bx--tile__chevron svg {
    margin-left: 0.5rem;
    fill: var(--cds-ui-05, #161616);
    transform-origin: center;
    transition: 110ms cubic-bezier(0.2, 0, 0.38, 0.9); }
    @media screen and (-ms-high-contrast: active), screen and (prefers-contrast) {
      .bx--tile__chevron svg {
        fill: ButtonText; } }
  .bx--tile__chevron:hover {
    cursor: pointer; }
  .bx--tile__chevron:focus {
    outline: none; }

.bx--tile--expandable {
  overflow: hidden;
  width: 100%;
  border: 0;
  color: inherit;
  font-size: inherit;
  text-align: left;
  transition: max-height 150ms cubic-bezier(0.2, 0, 0.38, 0.9); }

.bx--tile-content__above-the-fold {
  display: block; }

.bx--tile-content__below-the-fold {
  display: block;
  opacity: 0;
  transition: opacity 110ms cubic-bezier(0.2, 0, 0.38, 0.9), visibility 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  visibility: hidden; }

.bx--tile--is-expanded {
  overflow: visible;
  transition: max-height 110ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--tile--is-expanded .bx--tile__chevron svg {
    transform: rotate(180deg); }
  .bx--tile--is-expanded .bx--tile-content__below-the-fold {
    opacity: 1;
    transition: opacity 110ms cubic-bezier(0.2, 0, 0.38, 0.9), visibility 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
    visibility: inherit; }
    @media not all and (min-resolution: 0.001dpcm) {
      @supports (-webkit-appearance: none) and (stroke-color: transparent) {
        .bx--tile--is-expanded .bx--tile-content__below-the-fold {
          overflow-y: auto; } } }

.bx--tile--is-selected {
  border: 1px solid var(--cds-ui-05, #161616); }

.bx--tile--is-selected .bx--tile__checkmark {
  opacity: 1; }

.bx--tile--is-selected .bx--tile__checkmark svg {
  fill: var(--cds-ui-05, #161616); }
  @media screen and (-ms-high-contrast: active), screen and (prefers-contrast) {
    .bx--tile--is-selected .bx--tile__checkmark svg {
      fill: ButtonText; } }

.bx--tile-content {
  width: 100%;
  height: 100%; }

.bx--tile-input {
  position: absolute;
  overflow: hidden;
  width: 1px;
  height: 1px;
  padding: 0;
  border: 0;
  margin: -1px;
  clip: rect(0, 0, 0, 0);
  visibility: inherit;
  white-space: nowrap; }

.bx--tile-input:focus + .bx--tile {
  outline: 2px solid var(--cds-focus, #0f62fe);
  outline-offset: -2px; }
  @media screen and (prefers-contrast) {
    .bx--tile-input:focus + .bx--tile {
      outline-style: dotted; } }

.bx--tile--disabled.bx--tile--selectable {
  background-color: var(--cds-ui-01, #f4f4f4);
  color: var(--cds-disabled-02, #c6c6c6);
  cursor: not-allowed; }

.bx--tile--disabled.bx--tile--selectable.bx--tile--light {
  background-color: var(--cds-ui-02, #ffffff); }

.bx--tile--disabled.bx--tile--is-selected {
  outline-color: var(--cds-disabled-02, #c6c6c6); }

.bx--tile--disabled.bx--tile--is-selected .bx--tile__checkmark svg {
  fill: var(--cds-disabled-02, #c6c6c6); }

.bx--time-picker {
  display: flex;
  align-items: flex-end; }

.bx--time-picker__select {
  justify-content: center; }
  .bx--time-picker__select:not(:last-of-type) {
    margin: 0 0.125rem; }

.bx--time-picker__input {
  display: flex;
  flex-direction: column; }

.bx--time-picker .bx--select-input {
  width: auto;
  min-width: auto;
  padding-right: 3rem;
  margin: 0;
  line-height: 1; }

.bx--time-picker__input-field {
  outline: 2px solid transparent;
  outline-offset: -2px;
  font-family: var(--cds-code-02-font-family, 'IBM Plex Mono', 'Menlo', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', Courier, monospace);
  font-size: var(--cds-code-02-font-size, 0.875rem);
  font-weight: var(--cds-code-02-font-weight, 400);
  line-height: var(--cds-code-02-line-height, 1.42857);
  letter-spacing: var(--cds-code-02-letter-spacing, 0.32px);
  display: flex;
  width: 4.875rem;
  height: 2.5rem;
  align-items: center;
  transition: outline 70ms cubic-bezier(0.2, 0, 0.38, 0.9), background-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--time-picker__input-field::placeholder {
    color: var(--cds-text-05, #6f6f6f);
    opacity: 1; }

.bx--time-picker--light .bx--select-input {
  background-color: var(--cds-field-02, #ffffff); }
  .bx--time-picker--light .bx--select-input:hover {
    background-color: var(--cds-hover-ui, #e5e5e5); }
  .bx--time-picker--light .bx--select-input:disabled, .bx--time-picker--light .bx--select-input:hover:disabled {
    border-bottom: 1px solid transparent;
    background-color: transparent;
    color: var(--cds-disabled-02, #c6c6c6);
    cursor: not-allowed; }

.bx--time-picker--sm .bx--select-input,
.bx--time-picker--sm .bx--time-picker__input-field {
  height: 2rem;
  max-height: 2rem; }

.bx--time-picker--xl .bx--select-input,
.bx--time-picker--xl .bx--time-picker__input-field,
.bx--time-picker--lg .bx--select-input,
.bx--time-picker--lg .bx--time-picker__input-field {
  height: 3rem;
  max-height: 3rem; }

.bx--modal {
  position: fixed;
  z-index: 9000;
  top: 0;
  left: 0;
  display: flex;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: var(--cds-overlay-01, rgba(22, 22, 22, 0.5));
  content: '';
  opacity: 0;
  transition: opacity 240ms cubic-bezier(0.4, 0.14, 1, 1), visibility 0ms linear 240ms;
  visibility: hidden; }
  .bx--modal.is-visible {
    opacity: 1;
    transition: opacity 240ms cubic-bezier(0, 0, 0.3, 1), visibility 0ms linear;
    visibility: inherit; }
  .bx--modal .bx--pagination,
  .bx--modal .bx--pagination__control-buttons,
  .bx--modal .bx--text-input,
  .bx--modal .bx--text-area,
  .bx--modal .bx--search-input,
  .bx--modal .bx--select-input,
  .bx--modal .bx--dropdown,
  .bx--modal .bx--dropdown-list,
  .bx--modal .bx--number input[type='number'],
  .bx--modal .bx--date-picker__input,
  .bx--modal .bx--multi-select {
    background-color: var(--cds-field-02, #ffffff); }

.bx--modal.is-visible .bx--modal-container {
  transform: translate3d(0, 0, 0);
  transition: transform 240ms cubic-bezier(0, 0, 0.3, 1); }

.bx--modal-container {
  position: fixed;
  top: 0;
  display: grid;
  overflow: hidden;
  width: 100%;
  height: 100%;
  max-height: 100%;
  background-color: var(--cds-ui-01, #f4f4f4);
  grid-template-columns: 100%;
  grid-template-rows: auto 1fr auto;
  outline: 3px solid transparent;
  outline-offset: -3px;
  transform: translate3d(0, -24px, 0);
  transform-origin: top center;
  transition: transform 240ms cubic-bezier(0.4, 0.14, 1, 1); }
  @media (min-width: 42rem) {
    .bx--modal-container {
      position: static;
      width: 84%;
      height: auto;
      max-height: 90%; } }
  @media (min-width: 66rem) {
    .bx--modal-container {
      width: 60%;
      max-height: 84%; } }
  @media (min-width: 82rem) {
    .bx--modal-container {
      width: 48%; } }

.bx--modal-content {
  font-size: var(--cds-body-long-01-font-size, 0.875rem);
  font-weight: var(--cds-body-long-01-font-weight, 400);
  line-height: var(--cds-body-long-01-line-height, 1.42857);
  letter-spacing: var(--cds-body-long-01-letter-spacing, 0.16px);
  position: relative;
  padding-top: var(--cds-spacing-03, 0.5rem);
  padding-right: var(--cds-spacing-05, 1rem);
  padding-left: var(--cds-spacing-05, 1rem);
  margin-bottom: var(--cds-spacing-09, 3rem);
  color: var(--cds-text-01, #161616);
  font-weight: 400;
  grid-column: 1/-1;
  grid-row: 2/-2;
  overflow-y: auto; }
  .bx--modal-content:focus {
    outline: 2px solid var(--cds-focus, #0f62fe);
    outline-offset: -2px; }
    @media screen and (prefers-contrast) {
      .bx--modal-content:focus {
        outline-style: dotted; } }

.bx--modal-content p,
.bx--modal-content__regular-content {
  padding-right: 20%;
  font-size: var(--cds-body-long-01-font-size, 0.875rem);
  font-weight: var(--cds-body-long-01-font-weight, 400);
  line-height: var(--cds-body-long-01-line-height, 1.42857);
  letter-spacing: var(--cds-body-long-01-letter-spacing, 0.16px); }

.bx--modal-content--with-form {
  padding-right: var(--cds-spacing-05, 1rem); }

.bx--modal-header {
  padding-top: var(--cds-spacing-05, 1rem);
  padding-right: var(--cds-spacing-09, 3rem);
  padding-left: var(--cds-spacing-05, 1rem);
  margin-bottom: var(--cds-spacing-03, 0.5rem);
  grid-column: 1/-1;
  grid-row: 1/1; }

.bx--modal-header__label {
  font-size: var(--cds-label-01-font-size, 0.75rem);
  font-weight: var(--cds-label-01-font-weight, 400);
  line-height: var(--cds-label-01-line-height, 1.33333);
  letter-spacing: var(--cds-label-01-letter-spacing, 0.32px);
  margin-bottom: var(--cds-spacing-02, 0.25rem);
  color: var(--cds-text-02, #525252); }

.bx--modal-header__heading {
  font-size: var(--cds-productive-heading-03-font-size, 1.25rem);
  font-weight: var(--cds-productive-heading-03-font-weight, 400);
  line-height: var(--cds-productive-heading-03-line-height, 1.4);
  letter-spacing: var(--cds-productive-heading-03-letter-spacing, 0);
  color: var(--cds-text-01, #161616); }

.bx--modal-container--xs .bx--modal-content__regular-content {
  padding-right: var(--cds-spacing-05, 1rem); }

.bx--modal-container--xs .bx--modal-content p {
  padding-right: 0; }

@media (min-width: 42rem) {
  .bx--modal-container--xs {
    width: 48%; } }

@media (min-width: 66rem) {
  .bx--modal-container--xs {
    width: 32%;
    max-height: 48%; } }

@media (min-width: 82rem) {
  .bx--modal-container--xs {
    width: 24%; } }

.bx--modal-container--sm .bx--modal-content__regular-content {
  padding-right: var(--cds-spacing-05, 1rem); }

.bx--modal-container--sm .bx--modal-content p {
  padding-right: 0; }

@media (min-width: 42rem) {
  .bx--modal-container--sm {
    width: 60%; } }

@media (min-width: 66rem) {
  .bx--modal-container--sm {
    width: 42%;
    max-height: 72%; }
    .bx--modal-container--sm .bx--modal-content p,
    .bx--modal-container--sm .bx--modal-content__regular-content {
      padding-right: 20%; } }

@media (min-width: 82rem) {
  .bx--modal-container--sm {
    width: 36%; } }

@media (min-width: 42rem) {
  .bx--modal-container--lg {
    width: 96%; } }

@media (min-width: 66rem) {
  .bx--modal-container--lg {
    width: 84%;
    max-height: 96%; } }

@media (min-width: 82rem) {
  .bx--modal-container--lg {
    width: 72%; } }

.bx--modal-scroll-content > *:last-child {
  padding-bottom: var(--cds-spacing-07, 2rem); }

.bx--modal-content--overflow-indicator {
  position: absolute;
  bottom: var(--cds-spacing-09, 3rem);
  left: 0;
  width: 100%;
  height: 2rem;
  background-image: linear-gradient(to bottom, rgba(var(--cds-ui-01, #f4f4f4), 0), var(--cds-ui-01, #f4f4f4));
  content: '';
  grid-column: 1/-1;
  grid-row: 2/-2;
  pointer-events: none; }

@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) and (stroke-color: transparent) {
    .bx--modal-content--overflow-indicator {
      background-image: linear-gradient(to bottom, rgba(var(--cds-layer, #f4f4f4), 0), var(--cds-layer, #f4f4f4)); } } }

.bx--modal-content:focus ~ .bx--modal-content--overflow-indicator {
  width: calc(100% - 4px);
  margin: 0 2px 2px; }

@media screen and (-ms-high-contrast: active) {
  .bx--modal-scroll-content > *:last-child {
    padding-bottom: 0; }
  .bx--modal-content--overflow-indicator {
    display: none; } }

.bx--modal-footer {
  display: flex;
  height: 4rem;
  justify-content: flex-end;
  margin-top: auto;
  grid-column: 1/-1;
  grid-row: -1/-1; }

.bx--modal-footer .bx--btn {
  max-width: none;
  height: 4rem;
  flex: 0 1 50%;
  padding-top: var(--cds-spacing-05, 1rem);
  padding-bottom: var(--cds-spacing-07, 2rem);
  margin: 0; }

.bx--modal-footer--three-button .bx--btn {
  flex: 0 1 25%;
  align-items: flex-start; }

.bx--modal-close {
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  overflow: hidden;
  width: 3rem;
  height: 3rem;
  padding: 0.75rem;
  border: 2px solid transparent;
  background-color: transparent;
  cursor: pointer;
  transition: background-color 110ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--modal-close:hover {
    background-color: var(--cds-hover-ui, #e5e5e5); }
  .bx--modal-close:focus {
    border-color: var(--cds-focus, #0f62fe);
    outline: none; }

.bx--modal-close::-moz-focus-inner {
  border: 0; }

.bx--modal-close__icon {
  width: 1.25rem;
  height: 1.25rem;
  fill: var(--cds-icon-01, #161616); }

.bx--body--with-modal-open {
  overflow: hidden; }

.bx--body--with-modal-open .bx--tooltip,
.bx--body--with-modal-open .bx--overflow-menu-options {
  z-index: 9000; }

/* stylelint-disable */
@media screen and (-ms-high-contrast: active), (forced-colors: active), (prefers-contrast) {
  .bx--modal-close__icon {
    fill: ButtonText; } }

@media screen and (-ms-high-contrast: active), (forced-colors: active), (prefers-contrast) {
  .bx--modal-close:focus {
    color: Highlight;
    outline: 1px solid Highlight; } }

/* stylelint-enable */
.sc--activity-tile, .sc--activity-tile-skeleton {
  max-width: 320px;
  height: 100%;
  min-height: 240px;
  width: 100%; }

a.sc--activity-tile {
  color: inherit;
  text-decoration: none;
  border: 1px solid #f4f4f4; }
  a.sc--activity-tile:hover {
    border-color: #e0e0e0; }
  a.sc--activity-tile .bx--tile {
    height: inherit; }

.sc--tile-detail {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: inherit; }

.sc--tile-title {
  font-size: var(--cds-productive-heading-03-font-size, 1.25rem);
  font-weight: var(--cds-productive-heading-03-font-weight, 400);
  line-height: var(--cds-productive-heading-03-line-height, 1.4);
  letter-spacing: var(--cds-productive-heading-03-letter-spacing, 0);
  display: flex;
  align-items: center;
  justify-content: space-between; }

.sc--title-count {
  font-size: var(--cds-productive-heading-07-font-size, 3.375rem);
  font-weight: var(--cds-productive-heading-07-font-weight, 300);
  line-height: var(--cds-productive-heading-07-line-height, 1.199);
  letter-spacing: var(--cds-productive-heading-07-letter-spacing, 0);
  text-align: right; }

.sc--request-menu, .sc--activity-menu {
  display: flex;
  align-items: center; }
  .sc--request-menu span, .sc--activity-menu span {
    font-size: var(--cds-helper-text-01-font-size, 0.75rem);
    line-height: var(--cds-helper-text-01-line-height, 1.33333);
    letter-spacing: var(--cds-helper-text-01-letter-spacing, 0.32px);
    margin-right: .25rem; }

.sc--activity-tile-container .bx--col {
  display: flex;
  justify-content: flex-start; }
  .sc--activity-tile-container .bx--col:nth-child(2) {
    justify-content: center; }
  .sc--activity-tile-container .bx--col:last-child {
    justify-content: flex-end; }

.sc--chart-column {
  display: flex;
  flex-wrap: nowrap;
  margin-top: var(--cds-layout-05, 4rem);
  position: relative;
  align-items: flex-start; }
  .sc--chart-column .sc--activity-tile-skeleton {
    max-width: none;
    min-height: 400px; }
  .sc--chart-column .sc--no-data-message {
    font-size: var(--cds-productive-heading-05-font-size, 2rem);
    font-weight: var(--cds-productive-heading-05-font-weight, 400);
    line-height: var(--cds-productive-heading-05-line-height, 1.25);
    letter-spacing: var(--cds-productive-heading-05-letter-spacing, 0);
    margin: auto; }
  .sc--chart-column .sc--activity-menu {
    position: absolute;
    right: 1rem;
    align-items: center; }

.bx--cc--simple-bar g.bars .bar {
  fill: #D8D8D8 !important;
  stroke: #9E9E9E !important; }

.sc--chart-column .ticks path.domain,
.sc--chart-column .bx--cc--grid g.x.grid g.tick line,
.sc--chart-column .bx--cc--grid g.y.grid g.tick line {
  stroke: none !important; }

.sc--chart-column .bx--cc--zero-line line.domain {
  stroke: #f3f3f3 !important; }

.sc--chart-column .bx--cc--axes .g-axis path.domain {
  stroke: #f3f3f3 !important; }

.sc--chart-column .bx--cc--axes .g-axis .tick .text {
  font-size: .75rem;
  fill: #565656 !important; }

.sc--chart-column .bx--cc--axes .g-axis .tick line {
  stroke: none; }

.sc--chart-column .bx--cc--grid rect.chart-grid-backdrop {
  fill: #F3F3F3 !important; }

.sc--activity-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between; }
  .sc--activity-wrapper .sc--activity-table {
    width: 100%; }

.sc--map-container {
  height: 450px; }
  .sc--map-container > div {
    border: 1px solid #8d8d8d; }
  .sc--map-container .map-loader {
    background: #F3F3F3;
    display: flex;
    height: inherit;
    align-items: center;
    justify-content: center; }
    .sc--map-container .map-loader .bx--inline-loading {
      display: flex;
      align-items: center; }

.sc--map-marker {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 18px;
  height: 18px;
  background-color: #d85040;
  border-radius: 100%;
  user-select: none;
  transform: translate(-50%, -50%); }
  .sc--map-marker span {
    font-weight: bold;
    line-height: 18px;
    width: 120px;
    display: block;
    margin-left: 20px; }

.sc--map-marker-notification .bx--toast-notification {
  color: inherit;
  border: none !important;
  background: #F3F3F3 !important; }
  .sc--map-marker-notification .bx--toast-notification__icon {
    display: none; }
  .sc--map-marker-notification .bx--toast-notification__title {
    font-size: var(--cds-productive-heading-03-font-size, 1.25rem);
    font-weight: var(--cds-productive-heading-03-font-weight, 400);
    line-height: var(--cds-productive-heading-03-line-height, 1.4);
    letter-spacing: var(--cds-productive-heading-03-letter-spacing, 0); }
  .sc--map-marker-notification .bx--toast-notification__subtitle {
    color: inherit;
    margin-bottom: 0;
    font-size: var(--cds-body-short-01-font-size, 0.875rem);
    font-weight: var(--cds-body-short-01-font-weight, 400);
    line-height: var(--cds-body-short-01-line-height, 1.28572);
    letter-spacing: var(--cds-body-short-01-letter-spacing, 0.16px); }
  .sc--map-marker-notification .bx--toast-notification__caption {
    color: #198038;
    font-size: var(--cds-body-short-01-font-size, 0.875rem);
    font-weight: var(--cds-body-short-01-font-weight, 400);
    line-height: var(--cds-body-short-01-line-height, 1.28572);
    letter-spacing: var(--cds-body-short-01-letter-spacing, 0.16px); }
  .sc--map-marker-notification .bx--toast-notification__close-button .bx--toast-notification__close-icon {
    fill: inherit; }

.sc--map-marker-notification {
  position: relative; }
  .sc--map-marker-notification--address {
    margin-top: var(--cds-spacing-06, 1.5rem);
    line-height: 1.4; }
  .sc--map-marker-notification--customer {
    margin-top: var(--cds-spacing-06, 1.5rem);
    line-height: 1.4; }
  .sc--map-marker-notification__caption {
    line-height: 1.4;
    display: flex;
    flex-direction: column; }
    .sc--map-marker-notification__caption svg {
      margin-top: 4px;
      flex: 1 0 auto;
      margin-right: 1rem; }

.service-locations-dropdown,
.service-locations-search-box {
  margin-bottom: var(--cds-spacing-07, 2rem); }

.map-modal-active {
  overflow: hidden !important; }

.sc--otp-inline-loader {
  display: flex;
  align-items: center;
  color: #161616; }
  .sc--otp-inline-loader.finished {
    color: #198038; }
    .sc--otp-inline-loader.finished svg {
      margin-right: 1rem;
      fill: #198038; }

.sc--otp-container {
  margin: var(--cds-spacing-07, 2rem) 0;
  line-height: 32px; }

.sc--map-marker-notification .sc--send-otp-link {
  display: inline-block;
  color: #0f62fe; }
  .sc--map-marker-notification .sc--send-otp-link--disabled {
    color: #c6c6c6; }

.sc--services-checkbox-wrapper {
  margin-top: var(--cds-spacing-07, 2rem); }

.sc--map-marker-notification--customer > div, .sc--map-marker-notification--address > div {
  margin-bottom: var(--cds-spacing-02, 0.25rem); }

.bx--list-box.bx--list-box--inline, .sc--map-marker-notification .bx--dropdown--inline {
  min-width: 200px;
  border-width: thin;
  background-color: #ffffff;
  border-color: #878d96; }

.helper-text {
  font-size: var(--cds-helper-text-01-font-size, 0.75rem);
  line-height: var(--cds-helper-text-01-line-height, 1.33333);
  letter-spacing: var(--cds-helper-text-01-letter-spacing, 0.32px); }

.bx--loading-overlay {
  background-color: rgba(255, 255, 255, 0.8); }

.bx--link:visited {
  color: #0f62fe; }

.bx--label--disabled {
  color: inherit; }

.sc--main-wrapper {
  margin-top: 3rem;
  margin-bottom: 3rem; }

.sc--main-title {
  margin-bottom: var(--cds-layout-05, 4rem);
  padding-top: 2rem; }

.sc--nav-item {
  position: relative; }
  .sc--nav-item.active::after {
    position: absolute;
    bottom: -2px;
    width: 100%;
    height: 3px;
    background: #0f62fe;
    display: block;
    content: "";
    left: 0;
    top: auto; }

h1 {
  font-size: var(--cds-productive-heading-07-font-size, 3.375rem);
  font-weight: var(--cds-productive-heading-07-font-weight, 300);
  line-height: var(--cds-productive-heading-07-line-height, 1.199);
  letter-spacing: var(--cds-productive-heading-07-letter-spacing, 0); }

.sc--current-page .bx--link {
  color: inherit !important; }

@media (max-width: 66rem) {
  .bx--header__nav {
    display: block; } }

.sc--composed-modal {
  height: 100vh; }
  .sc--composed-modal--body {
    font-size: var(--cds-body-short-01-font-size, 0.875rem);
    font-weight: var(--cds-body-short-01-font-weight, 400);
    line-height: var(--cds-body-short-01-line-height, 1.28572);
    letter-spacing: var(--cds-body-short-01-letter-spacing, 0.16px); }

.sc--location-contractor-send-otp-link {
  margin-bottom: var(--cds-spacing-07, 2rem); }

.sc--otp-valid {
  display: flex;
  align-items: center;
  margin-top: 2rem;
  color: #198038; }
  .sc--otp-valid svg {
    fill: #198038;
    margin-right: 1rem; }

.sc--table-wrapper .sc--search-input .bx--search-input {
  background-color: #f4f4f4; }

.sc--table-row td {
  vertical-align: middle;
  line-height: 38px; }

.bx--data-table th {
  height: 100%;
  padding: 0 !important; }
  .bx--data-table th:last-of-type {
    width: 50px; }

.sc--table-header th:last-child button {
  display: none; }

.sc--edit-table-icon {
  color: #4d5358;
  background: none !important;
  box-shadow: none !important;
  visibility: hidden;
  min-height: 0;
  border: none; }

.sc--table-row:hover .sc--edit-table-icon {
  visibility: visible; }
  .sc--table-row:hover .sc--edit-table-icon:hover {
    color: #0f62fe; }

.sc--business-logo {
  margin-top: 1rem; }
  .sc--business-logo img {
    max-width: 180px;
    height: auto;
    width: 100%; }

.sc--warning {
  display: flex;
  align-items: center;
  margin-top: 2rem;
  color: #da1e28; }
  .sc--warning svg {
    fill: #da1e28;
    margin-right: 1rem; }

body.create-flow-open {
  overflow: hidden; }

.sc--createflow {
  background: #ffffff;
  display: flex;
  position: fixed;
  width: auto;
  right: 0;
  left: 0;
  z-index: 2;
  padding-top: 3rem;
  bottom: 0;
  top: 3rem;
  justify-content: flex-start;
  flex-direction: column;
  overflow-y: scroll; }
  .sc--createflow-intro {
    align-items: center; }
  .sc--createflow-button-wrapper button {
    min-width: 200px; }
    .sc--createflow-button-wrapper button:first-of-type {
      margin-right: 2rem; }
  .sc--createflow-intro {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: var(--cds-layout-04, 3rem); }
  .sc--createflow-progress-indicator-wrapper {
    width: 100%;
    margin-bottom: var(--cds-layout-03, 2rem); }
  .sc--createflow-step {
    display: flex;
    justify-content: space-between;
    margin-left: -2rem;
    margin-right: -2rem; }
  .sc--createflow-fileupload {
    justify-content: space-between;
    flex-direction: row;
    display: flex;
    margin-top: 2rem; }
    .sc--createflow-fileupload.csv-upload {
      flex-direction: column; }
    .sc--createflow-fileupload label {
      width: 200px;
      height: 48px;
      margin: 1rem 0; }
    .sc--createflow-fileupload .bx--file__selected-file {
      min-width: 200px;
      height: 48px; }
    .sc--createflow-fileupload .bx--file__state-container .bx--file-close {
      fill: #525252; }

.sc--body {
  font-size: var(--cds-body-short-01-font-size, 0.875rem);
  font-weight: var(--cds-body-short-01-font-weight, 400);
  line-height: var(--cds-body-short-01-line-height, 1.28572);
  letter-spacing: var(--cds-body-short-01-letter-spacing, 0.16px); }
  .sc--body.description {
    margin-top: var(--cds-spacing-05, 1rem); }

.sc--createflow-column {
  padding-left: 2rem;
  padding-right: 2rem;
  flex: 0 1 auto;
  width: 100%;
  margin-bottom: 2rem; }
  .sc--createflow-column > div:not(.sc--details-title) {
    margin-bottom: var(--cds-spacing-06, 1.5rem); }
  .sc--createflow-column .title {
    margin-bottom: var(--cds-spacing-06, 1.5rem);
    font-size: var(--cds-productive-heading-03-font-size, 1.25rem);
    font-weight: var(--cds-productive-heading-03-font-weight, 400);
    line-height: var(--cds-productive-heading-03-line-height, 1.4);
    letter-spacing: var(--cds-productive-heading-03-letter-spacing, 0); }
  .sc--createflow-column .sc--details-postcode-state-wrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row; }
    .sc--createflow-column .sc--details-postcode-state-wrapper #customer-add--address-state {
      margin-right: var(--cds-spacing-07, 2rem); }
    .sc--createflow-column .sc--details-postcode-state-wrapper .bx--list-box__wrapper {
      flex: 1 1 auto;
      margin-right: 2rem; }
    .sc--createflow-column .sc--details-postcode-state-wrapper .bx--form-item {
      flex: 0 1 25%; }
  .sc--createflow-column .bx--file-container {
    margin-top: 1rem; }

.sc--details-country-wrapper .bx--dropdown__wrapper {
  width: 100%; }

.sc--createflow-progress-indicator .bx--progress-step--current .bx--progress-label {
  font-weight: bold; }

.sc--createflow-progress-indicator .bx--progress-step--disabled {
  pointer-events: none; }

.leads-details .bx--time-picker input, .leads-convert .bx--time-picker input {
  padding-left: var(--cds-spacing-05, 1rem);
  border-bottom: 1px solid #8d8d8d;
  border-right: 1px solid #ffffff; }
  .leads-details .bx--time-picker input:disabled, .leads-convert .bx--time-picker input:disabled {
    border-bottom-color: transparent; }

.leads-details .bx--inline-notification__details, .leads-convert .bx--inline-notification__details {
  margin-left: 0; }

.leads-details-date-time-wrapper, .leads-convert-date-time-wrapper {
  display: flex; }
  .leads-details-date-time-wrapper .bx--form-item, .leads-convert-date-time-wrapper .bx--form-item {
    flex-grow: 0; }
    .leads-details-date-time-wrapper .bx--form-item:first-of-type, .leads-convert-date-time-wrapper .bx--form-item:first-of-type {
      margin-right: var(--cds-spacing-04, 0.75rem); }

.leads-details-follow-up-notification, .leads-convert-follow-up-notification {
  background-color: transparent;
  border: none;
  color: #393939; }

.flatpickr-prev-month svg, .flatpickr-next-month svg {
  fill: inherit; }

.bx--time-picker {
  position: relative; }
  .bx--time-picker .bx--form-requirement {
    position: absolute;
    bottom: -22px; }

.sc--login-page .sc--login-form-wrapper {
  height: calc(100vh - 6rem - 48px);
  align-items: center; }

.sc--login-page .bx--text-input-wrapper {
  margin-bottom: 1rem; }

.sc--login-page .bx--form__requirements {
  color: var(--cds-support-01, #da1e28);
  line-height: 1.4; }

.sc--login-page .sc--success-message {
  color: var(--cds-support-02, #198038);
  font-size: var(--cds-body-short-01-font-size, 0.875rem);
  font-weight: var(--cds-body-short-01-font-weight, 400);
  line-height: var(--cds-body-short-01-line-height, 1.28572);
  letter-spacing: var(--cds-body-short-01-letter-spacing, 0.16px); }

.sc--login-page .login-intro p {
  margin-bottom: var(--cds-spacing-07, 2rem); }
  .sc--login-page .login-intro p:first-of-type {
    font-size: var(--cds-productive-heading-03-font-size, 1.25rem);
    font-weight: var(--cds-productive-heading-03-font-weight, 400);
    line-height: var(--cds-productive-heading-03-line-height, 1.4);
    letter-spacing: var(--cds-productive-heading-03-letter-spacing, 0); }
