
.sc--activity-tile, .sc--activity-tile-skeleton{
	max-width: 320px;
	height: 100%;
	min-height: 240px;
	width: 100%;
}

a.sc--activity-tile {
	color: inherit;
	text-decoration: none;
	border: 1px solid #f4f4f4;
	&:hover{
		border-color: $carbon--gray-20;
	}
	.bx--tile {
		height: inherit;
	}
}
.sc--tile-detail{
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	height: inherit;
}

.sc--tile-title{
	@include carbon--type-style('productive-heading-03', true);
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.sc--title-count{
	@include carbon--type-style('productive-heading-07');
	text-align: right;
}

.sc--request-menu, .sc--activity-menu{
	display: flex;
	align-items: center;
	span{
		@include carbon--type-style('helper-text-01');
		margin-right: .25rem;
	}
}

.sc--activity-tile-container{
	.bx--col{
		display:flex;
		justify-content: flex-start;
		&:nth-child(2){
			justify-content: center;
		}
		&:last-child{
			justify-content: flex-end;
		}
	}
}

.sc--chart-column{
	display: flex;
	flex-wrap: nowrap;
	margin-top: $layout-05;
	position: relative;
	align-items: flex-start;
	.sc--activity-tile-skeleton{
		max-width: none;
		min-height: 400px;
	}
	.sc--no-data-message{
		@include carbon--type-style('productive-heading-05');
		margin: auto;
	}
	.sc--activity-menu{
		position: absolute;
    right: 1rem;
    align-items: center;
	}
}
.bx--cc--simple-bar{
	g.bars .bar{
		fill: #D8D8D8!important;
    stroke: #9E9E9E!important;
	}
}

.sc--chart-column{
	.ticks path.domain,
	.bx--cc--grid g.x.grid g.tick line,
	.bx--cc--grid g.y.grid g.tick line {
		stroke: none!important;
	}
	.bx--cc--zero-line line.domain{
		stroke: #f3f3f3!important;
	}
	.bx--cc--axes .g-axis path.domain {
			stroke: #f3f3f3!important;
		}
	.bx--cc--axes .g-axis .tick{
		.text{
			font-size: .75rem;
			fill: #565656!important;
		}
		line{
			stroke: none;
		}
	}
	.bx--cc--grid{
		rect.chart-grid-backdrop{
			fill: #F3F3F3!important;
		}
	}
}